export enum OnboardingPageKeys {
  gender = "gender",
  metric = "metric",
  difficulty = "difficulty",
  currentUserWeight = "currentUserWeight",
  goalWeight = "goalWeight",
  age = "age",
  height = "height",
  days = "days",
  daysCount = "daysCount",
  desiredResults = "desiredResults",
  equipment = "equipment",
  goalBody = "goalBody",
  userBody = "userBody",
  bodyFat = "bodyFat",
  processPlan = "processPlan",
}

/*export enum OnboardingBG{

  gender = '../../../assets/newUI/BG/1.jpg',
  metric = '../../../assets/newUI/BG/2.jpg',
  difficulty = '../../../assets/newUI/BG/3.jpg',
  currentUserWeight = '../../../assets/newUI/BG/4.jpg',
  goalWeight = '../../../assets/newUI/BG/5.jpg',
  age = '../../../assets/newUI/BG/6.jpg',
  height = '../../../assets/newUI/BG/7.jpg',
  days = '../../../assets/newUI/BG/8.jpg',
  daysCount = 'd../../../assets/newUI/BG/9.jpg',
  desiredResults = '../../../assets/newUI/BG/10.jpg',
  processPlan = '../../../assets/newUI/BG/11.jpg'
}*/
