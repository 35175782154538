import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import {
  LoadingController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import { PurchasesEntitlementInfo } from '@revenuecat/purchases-capacitor';
import { getRemoteConfig, getValue } from 'firebase/remote-config';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SUB_OPTIONS_NO_ADS } from '../constants';
import { SubOption } from '../models/sale';
import { User } from '../models/user';
import { RemoteConfigSale } from '../pages/profile/profile.page';
import { AnalyticsService } from './analytics.service';
import { NotificationService } from './notification.service';
import { SubscriptionIosService } from './subscription-ios.service';
import { UserService } from './user.service';

export enum AndroidSubscriptionProducts {
  'com.lifebuddy.12monthslb' = 'com.lifebuddy.12monthslb',
  'com.lifebuddy.6monthslb' = 'com.lifebuddy.6monthslb',
  'com.lifebuddy.1monthlb' = 'com.lifebuddy.1monthlb',
}

enum iosSubscriptionProductsMapping {
  'com.lifebuddy.12monthslb' = 'com.lifebuddy.12monthslb',
  'com.lifebuddy.6monthslb' = 'com.lifebuddy.6monthslb',
  'com.lifebuddy.1monthlb' = 'com.lifebuddy.1monthlb',
}

enum iosSubscriptionProducts {
  'com.lifebuddy.12monthslb' = 'com.lifebuddy.12monthslb',
  'com.lifebuddy.6monthslb' = 'com.lifebuddy.6monthslb',
  'com.lifebuddy.1monthlb' = 'com.lifebuddy.1monthlb',
}

const NO_DECIMAL_CURRENCIES = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

const ROUND_TO_HUNDRED = ['ISK', 'UGX'];

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  productsList: Array<string>;
  isOwned: boolean;
  isSetup: boolean;
  showProcessing: boolean;
  isValidate: boolean;
  public additionalSubscriptionStatus: boolean;
  public additionalSubscriptionMode: boolean;
  public waitingStatus: boolean;

  canFreeTrial: boolean = true;

  subState: 'purchase' | 'restore' | 'load' = 'load';
  subscriptionId: AndroidSubscriptionProducts | iosSubscriptionProducts;
  checkingSubscription = false;

  saleConfig: RemoteConfigSale;
  salePercentage: number;

  user: User;

  _inAppProducts: BehaviorSubject<SubOption[]> = new BehaviorSubject<
    SubOption[]
  >([]);
  constructor(
    private notificationService: NotificationService,
    private modalCtrl: ModalController,
    private userService: UserService,
    private navCtrl: NavController,
    private platform: Platform,
    private loading: LoadingController,
    private nativeStorage: NativeStorage,
    private analyticsServ: AnalyticsService,
    private router: Router,
    private subscriptionIosService: SubscriptionIosService
  ) {
    this.setIAP(SUB_OPTIONS_NO_ADS);

    platform.ready().then(() => {
      if (Capacitor.getPlatform() == 'web') {
        return;
      }

      // this.store = CdvPurchase.store;

      const localIAP = localStorage.getItem('inAppProducts');

      console.log('Local IAP', localIAP);

      if (localIAP && localIAP != null) {
        const products = SUB_OPTIONS_NO_ADS;
        const parsedIAP = JSON.parse(localIAP);
        if (parsedIAP.length > 0) {
          // Check if there are any new products
          parsedIAP.forEach((iap) => {
            const foundIndex = products.findIndex(
              (product) =>
                (this.platform.is('ios') ? product.iosName : product.name) ==
                iap.name
            );
            if (foundIndex != -1) {
              products[foundIndex] = iap;
            }
          });

          this.setIAP(products);
        }
      }

      this.nativeStorage
        .getItem('subscribedStatus')
        .then((data) => this.setSubscriptionStatus(data))
        .catch((error) => {
          this.setSubscriptionStatus(false);
          console.error('Error getting subscribed status', error);
        });

      this.nativeStorage
        .getItem('subscribedMode')
        .then((data) => this.setSubscriptionMode(data))
        .catch((error) => {
          this.setSubscriptionMode(false);
          console.error('Error getting subscribed mode', error);
        });

      this.nativeStorage
        .getItem('subscriptionId')
        .then((data) => {
          this.subscriptionId = data;
        })
        .catch((err) => console.error('Error grabbing subscription ID', err));
    });
  }

  setIAP(products?: SubOption[]) {
    if (!products) {
      products = this._inAppProducts.value;
    }

    if (Capacitor.getPlatform() === 'web') {
      const country = localStorage.getItem('country');

      let tax = 0;

      console.log('Country: ', country);
      if (country && !country.includes('United States')) {
        products.forEach((p) => {
          console.log('Product: ', p);
          const foundIndex = p.pricingTable.findIndex(
            (price) =>
              price.country.includes(country) ||
              country.includes(price.country) ||
              price.country === country
          );
          const monthlyIndex = products.findIndex(
            (iap) => iap.time == '1' && iap.period == 'month'
          );

          if (foundIndex !== -1) {
            if (typeof p?.pricingTable[foundIndex].tax == 'number') {
              tax = p?.pricingTable[foundIndex].tax as number;
            }
            let price =
              p?.pricingTable[foundIndex].price +
              Math.round(tax * p?.pricingTable[foundIndex].price * 100) / 100;
            p.currencyCode = p?.pricingTable[foundIndex].currencyCode;

            if (NO_DECIMAL_CURRENCIES.includes(p.currencyCode)) {
              price = Math.round(price);
            } else if (ROUND_TO_HUNDRED.includes(p.currencyCode)) {
              price = Math.round(price / 100) * 100;
            }
            p.cost = price.toString();

            const monthlyCost = Number(p?.cost) / Number(p.time);
            const baseMonthly = price;

            (p.finalCost = p.time == '1' ? '' : monthlyCost.toFixed(2)),
              (p.discount = Math.round(
                100 - (monthlyCost / baseMonthly) * 100
              ));
          }
        });
      }
    }

    this._inAppProducts.next(products);
  }

  // DONE:
  initStore() {
    if (Capacitor.getPlatform() == 'web') {
      return;
    }

    this.subscriptionIosService._activeSubscription
      .asObservable()
      .subscribe((activeSubscription) => {
        if (activeSubscription) {
          if (this.subscriptionId != activeSubscription.id) {
            this.writeSubscriptionId(activeSubscription.id as any);
            this.productOwned(activeSubscription, this.subState);
          } else {
            this.updateWaitingStatus(false);
            this.productOwned(activeSubscription, 'load');
          }
        } else {
          this.setSubscriptionStatus(false);
          this.writeSubscriptionId(null);
          this.writeSubscriptionDateExp('');
          this.setSubscriptionMode(false);
        }
      });

    this.subscriptionIosService._updateWaitingStatus
      .asObservable()
      .subscribe((waitingStatus) => {
        if (waitingStatus != null) {
          this.waitingStatus = waitingStatus;
        }
      });

    this.subscriptionIosService._products
      .asObservable()
      .subscribe((products) => {
        localStorage.setItem('inAppProducts', JSON.stringify(products));
        this._inAppProducts.next(products);
      });
    return;
  }

  private updateWaitingStatus(status) {
    console.log(`$$$$ Status :: ${status}`);
    this.waitingStatus = status;
  }

  private setSubscriptionStatus(status) {
    this.additionalSubscriptionStatus = status;
    this.analyticsServ.setUserProperty('SUBSCRIBED', status ? 'yes' : 'no');
    this.nativeStorage.setItem('subscribedStatus', status).then(
      () => console.log(`#### Subscription status set to ${status}.`),
      (error) => console.error('#### Subscription status can not set.', error)
    );
  }

  private setSubscriptionMode(mode) {
    this.additionalSubscriptionMode = mode;
    this.analyticsServ.setUserProperty('SUBSCRIBED_MODE', mode ? 'yes' : 'no');
    this.nativeStorage.setItem('subscribedMode', mode).then(
      () =>
        console.log(`@@@@ Subscription mode set to ${mode} :: ${typeof mode}.`),
      (error) => console.error('#### Subscription mode can not set.', error)
    );
  }

  private writeSubscriptionDateExp(date: string) {
    this.nativeStorage.setItem('subscriptionDateExp', date).then(
      () => console.log(`#### Subscription date exp set. ${date}`),
      (error) => console.error('#### Subscription date exp can not set.', error)
    );
  }

  private writeSubscriptionId(
    id: AndroidSubscriptionProducts | iosSubscriptionProducts
  ) {
    this.subscriptionId = id;
    this.nativeStorage.setItem('subscriptionId', id).then(
      () => console.log(`#### Subscription date exp set. ${id}`),
      (error) => console.error('#### Subscription date exp can not set.', error)
    );
  }

  async checkCanFreeTrial() {
    if (this.platform.is('android')) {
      this.canFreeTrial = await this.subscriptionIosService.checkCanFreeTrial();
    }
    return this.canFreeTrial;
  }

  async trueCheckSubscription(state: 'load' | 'restore') {
    this.subState =
      state == 'load' || state == 'restore' ? state : this.subState;
    this.checkingSubscription = true;
    if (state == 'restore' && this.trueCheckSubscriptionForPlayer()) {
      await this.notificationService.showError(
        `Your subscription is already active.`
      );
      this.subState = 'load';
      return;
    }

    if (state == 'restore' && !(await this.loading.getTop())) {
      const loader = await this.loading.create();
      await loader.present();
    }

    if (!this.platform.is('hybrid')) {
      return;
    }

    if (this.platform.is('ios') || this.platform.is('android')) {
      if (state == 'restore') {
        await this.subscriptionIosService.restorePurchases();
        if (await this.loading.getTop()) {
          await this.loading.dismiss();
          if (!this.subscriptionId) {
            await this.notificationService.showError(
              `You don't have a subscription to restore.`
            );
          }
        }
      }
      return;
    }
  }

  observeInAppProducts() {
    return this._inAppProducts.asObservable();
  }

  private async productOwned(
    purchase: { id: string } & Partial<PurchasesEntitlementInfo>,
    state: 'purchase' | 'restore' | 'load'
  ) {
    console.log(`#### Check status object: ${JSON.stringify(purchase)}`);

    console.log(`#### Check status TRUE.`);
    this.setSubscriptionStatus(true);

    let expiryDate = new Date();
    if (purchase?.expirationDateMillis) {
      expiryDate = new Date(purchase.expirationDateMillis);
      this.writeSubscriptionDateExp(expiryDate.toLocaleString());
    } else if (this.platform.is('android')) {
      if (purchase.id.includes('com.lifebuddy.1monthlb')) {
        expiryDate.setDate(expiryDate.getDate() + 30);
      } else if (
        purchase.id.includes('halfyear') ||
        purchase.id.includes('6month')
      ) {
        expiryDate.setDate(expiryDate.getDate() + 182);
      } else if (
        purchase.id.includes('year') ||
        purchase.id.includes('12month')
      ) {
        expiryDate.setDate(expiryDate.getDate() + 365);
      }
      // }
      this.writeSubscriptionDateExp(expiryDate.toLocaleString());
    } else if (this.platform.is('ios')) {
      this.writeSubscriptionDateExp('Check App Store');
    }

    this.setSubscriptionMode(false);
    console.log(`#### Subscription mode :: without ads`);

    if (state == 'restore' && (await this.loading.getTop())) {
      await this.loading.dismiss();
      await this.notificationService.showSuccess(
        `Your subscription successfully restored.`
      );
      this.router.navigate(['home', 'plan']);
    } else if (state == 'purchase') {
      this.notificationService.presentSubscribedToast();
      this.closeModal(true);
      this.navCtrl.navigateRoot('/home/plan');
    }

    return true;
  }

  async closeModal(data?: any) {
    const modal = await this.modalCtrl.getTop();
    if (modal) {
      modal.dismiss(data).catch(() => {});
    }
    this.showProcessing = false;
  }

  trueCheckSubscriptionForPlayer() {
    console.log(
      `@@@@ 01 ${this.additionalSubscriptionStatus} :: ${typeof this
        .additionalSubscriptionStatus}`
    );
    if (
      this.additionalSubscriptionStatus == true /* ||
      location.hostname == 'localhost' */
    ) {
      return true;
    } else {
      return false;
    }
  }
  // DONE:
  async makePurchase(productId: AndroidSubscriptionProducts) {
    this.updateWaitingStatus(true);
    this.subState = 'purchase';

    if (this.platform.is('ios') || this.platform.is('android')) {
      let newProductId = this.platform.is('ios')
        ? iosSubscriptionProductsMapping[productId]
        : productId;
      try {
        return this.subscriptionIosService
          .subscribe(newProductId)
          .then(
            (result) => {
              console.log('order success', result);
            },
            (error) => {
              console.error('order failed', error);
              alert('Error: ' + JSON.stringify(error));
              this.updateWaitingStatus(false);
              return error;
            }
          )
          .catch((err) => {
            console.error('order failed', err);
            this.notificationService.showError(
              `Subscribe failed here - ${JSON.stringify(err)}`
            );
            this.updateWaitingStatus(false);
            return err;
          });
      } catch (err) {
        console.log('#### Error during purchase :: ' + JSON.stringify(err));
        this.updateWaitingStatus(false);
        return this.notificationService.showError(
          `Subscribe failed. Please try again later. Error: ${JSON.stringify(
            err
          )}`
        );
      }
    }
  }

  async checkSubscription() {
    if (this.subscriptionId) {
      let date = await this.nativeStorage.getItem('subscriptionDateExp');
      date = new Date(date);

      if (Date.now() > date?.getTime()) {
        if (navigator.onLine) {
          if (!this.checkingSubscription) {
            this.trueCheckSubscription('load');
          }
        } else {
          this.setSubscriptionStatus(false);
          this.writeSubscriptionId(null);
          this.writeSubscriptionDateExp('');
          this.setSubscriptionMode(false);
        }
      }
    }
  }

  manageSubscription() {
    return this.subscriptionIosService.manageSubscription();
  }

  isFreemium() {
    const remoteConfig = getRemoteConfig();
    const isFreemium = getValue(
      remoteConfig,
      environment.production ? 'freemium' : 'test_freemium'
    );
    return isFreemium?.asBoolean() || !this.userService.countdownEnded();
  }
}
