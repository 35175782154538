import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { ALL_VIDEOS_INFO } from '../constants';
import { Video } from '../models/video';

@Injectable({
  providedIn: 'root',
})
export class SqlService {
  protected workoutID: any;
  protected firebaseWorkoutID: any;
  private db = null;
  private name = '';

  constructor(public sqlite: SQLite, public platform: Platform) {}

  async openDB() {
    this.name = 'PilatesFitness.db';
    if (this.platform.is('hybrid')) {
      this.db = await this.sqlite.create({
        name: this.name,
        location: 'default',
      });
    }
  }

  private fetch(result) {
    return result ? (result.rows.length ? result.rows.item(0) : {}) : {};
  }

  private fetchAll(result): Array<any> {
    const output = [];

    for (let i = 0; i < result.rows.length; i++) {
      output.push(result.rows.item(i));
    }

    return output;
  }

  private async query(query: string, bindings = []) {
    if (Capacitor.getPlatform() == 'web') {
      return;
    }

    if (!this.db) {
      await this.openDB();
    }

    return new Promise((resolve, reject) =>
      this.db.transaction((transaction) => {
        transaction.executeSql(
          query,
          bindings,
          // tslint:disable-next-line:no-shadowed-variable
          (transaction, result) => {
            resolve(result);
          },
          // tslint:disable-next-line:no-shadowed-variable
          (transaction, error) => {
            reject(error);
          }
        );
      })
    );
  }

  setWorkoutId(id) {
    this.workoutID = id;
  }

  setFirebaseWorkoutID(firebaseWorkoutID) {
    this.firebaseWorkoutID = firebaseWorkoutID;
  }

  createAllTables() {
    return this.query(
      'CREATE TABLE IF NOT EXISTS video_info (video_id unique,category_id int,category_name,name,' +
        'primary_muscle,rep_range,secondary_muscle,tertiary_muscle,thumbnail,time,time_to_play,tools,url,video_length, subcategory_name,' +
        'video_number, file_video_url, file_thumbnail_url, is_other_side, is_three_video, group_name)',
      []
    );
  }

  fillVideosInfo(data: Array<any>) {
    return this.getAllVideosInfo().then(async (response) => {
      if (response.length) {
        return await Promise.all(
          response.map(async (video) => {
            if (!video.file_video_url && !video.file_thumbnail_url) {
              return video;
            }

            const vidUrl = await Filesystem.getUri({
              directory: Directory.Data,
              path: 'content/' + video.video_id + '.mp4',
            });
            let vidPath = Capacitor.convertFileSrc(vidUrl.uri);

            const thumbUrl = await Filesystem.getUri({
              directory: Directory.Data,
              path: 'content/' + video.video_id + '.png',
            });
            let thumbPath = Capacitor.convertFileSrc(thumbUrl.uri);

            return {
              ...video,
              file_video_url: vidPath,
              file_thumbnail_url: thumbPath,
            };
          })
        );
      } else {
        const promises = [];
        data.forEach((item) => {
          promises.push(
            this.query(
              'INSERT or IGNORE into video_info (video_id, category_id, category_name, name, primary_muscle, rep_range, time, subcategory_name,' +
                'tertiary_muscle, thumbnail, secondary_muscle, time_to_play, tools, url, video_length, video_number, file_video_url, ' +
                'file_thumbnail_url, is_other_side, is_three_video, group_name)' +
                ' VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
              [
                item.video_id,
                item.category_id,
                item.category_name,
                item.name,
                item.primary_muscle,
                item.rep_range,
                item.time,
                item.subcategory_name,
                item.tertiary_muscle,
                item.thumbnail,
                item.secondary_muscle,
                item.time_to_play,
                item.tools,
                item.url,
                item.video_length,
                item.video_number,
                '',
                '',
                item.is_other_side,
                item.is_three_video,
                item.group_name,
              ]
            ).catch((error) => {
              console.error('Error: fill videos info - ', error);
            })
          );
        });
        await Promise.all(promises);
        return data;
      }
    });
  }

  updateVideoInfoByVideoId(videoId: string, fieldName: string, value: any) {
    return this.query(`UPDATE video_info SET ${fieldName}=? WHERE video_id=?`, [
      value,
      videoId,
    ]).then((response) => {
      return this.fetch(response);
    });
  }

  getAllVideosInfo() {
    return this.query('SELECT * FROM video_info', []).then(
      (response: Array<Video>) => {
        response = this.fetchAll(response);
        response = response
          .filter((e) => e)
          .map((video) => {
            const videoInfo = ALL_VIDEOS_INFO.find(
              (e) => e.video_id === video.video_id
            );
            return { ...video, ...videoInfo };
          });
        return response.sort((a, b) => a.category_id - b.category_id);
      }
    );
  }
}
