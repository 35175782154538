import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import {
  AnimationController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { TranslatePipe } from '@ngx-translate/core';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';
import { FirebaseError } from 'firebase/app';
import { Achievement } from 'src/app/constants/achievements';
import { SaleModalComponent } from '../components/sale-modal/sale-modal.component';
import { RECCOMMENDED_ITEMS } from '../constants';
import { PlanConstructorPage } from '../pages/plan-constructor/plan-constructor.page';
import { TranslateConfigService } from './translate-config.service';
import { UserService } from './user.service';

export interface RecItem {
  id: string;
  title: string;
  description: string;
  iosLink: string;
  icon: string;
  bundleId: string;
}
@Injectable({
  providedIn: 'root',
})
export class HelperService {
  toasts: { pane: CupertinoPane; toast: HTMLIonToastElement }[] = [];

  language: any;
  constructor(
    private animationCtrl: AnimationController,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private router: Router,
    private translateConfigService: TranslateConfigService,
    private translatePipe: TranslatePipe,
    private loadingController: LoadingController,
    private platform: Platform,
    private appAvailability: AppAvailability,
    private userServ: UserService
  ) {
    this.language = this.translateConfigService.getCurrentLang();
  }

  async presentLoader() {
    const loading = await this.loadingController.create();
    return loading.present();
  }

  async dismissLoader() {
    return this.loadingController.dismiss();
  }

  sendBugEmail() {
    const emailBody = `Hello, \n\nPlease fill out the details below\n\nPlatform: ${Capacitor.getPlatform()}\nDescription of the issue:\nSteps to reproduce the issue:\n\nAlso, please attach a screenshot and/or a screen recording.`;
    const emailRecipient = 'support@lifebuddymobileapp.com';
    const emailSubject = 'Pilates Fitness Support Request';
    window.open(
      `mailto:${emailRecipient}?subject=${encodeURIComponent(
        emailSubject
      )}&body=${encodeURIComponent(emailBody)}`
    );
  }

  sendSupportEmail() {
    const emailBody = ``;
    const emailRecipient = 'support@lifebuddymobileapp.com';
    const emailSubject = `Pilates Fitness (${Capacitor.getPlatform()}) Support Request`;
    window.open(
      `mailto:${emailRecipient}?subject=${encodeURIComponent(
        emailSubject
      )}&body=${encodeURIComponent(emailBody)}`
    );
  }

  async presentPlanConstructor() {
    const modal = await this.modalCtrl.create({
      component: PlanConstructorPage,
      componentProps: {},
      leaveAnimation: this.leaveAnimation,
      enterAnimation: this.enterAnimation,
      cssClass: ['no-enter-animation'],
    });
    await modal.present();
    return modal.onWillDismiss();
  }

  async presentSalesModalOne(
    parent: 'constructor' | 'lifebuddy' | 'subscription'
  ) {
    const modal = await this.modalCtrl.create({
      component: SaleModalComponent,
      componentProps: { parent },
      leaveAnimation: this.leaveAnimation,
      enterAnimation: this.enterAnimation,
      cssClass: ['no-enter-animation'],
    });
    await modal.present();
    if (parent === 'subscription') {
      return modal.onWillDismiss();
    } else {
      return;
    }
  }

  enterAnimation = (baseEl: any) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper')!)
      .keyframes([{ opacity: '0.01' }, { opacity: '0.99' }]);

    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(250)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  leaveAnimation = (baseEl: any) => {
    return this.enterAnimation(baseEl).direction('reverse');
  };

  // async presentAchievementToast(ach: Achievement) {
  //   const toast = await this.toastCtrl.create({
  //     id: Math.round(Math.random() * 1000).toString(),
  //     header: ach.title,
  //     message: ach.description,
  //     position: "top",
  //     duration: 5000,
  //     cssClass: "achievement-toast",
  //     mode: "md",
  //     buttons: [
  //       {
  //         icon: "assets/achievements/" + ach.id + ".svg",
  //         side: "start",
  //         cssClass: "achievement-toast-icon",
  //       },
  //     ],
  //   });

  //   // this.toasts.push(toast);

  //   if (this.toasts.length == 1) {
  //     await toast.present();
  //   }

  //   toast.onDidDismiss().then(async (res) => {
  //     this.toasts.splice(0, 1);
  //     if (this.toasts.length > 0) {
  //       this.toasts[0]?.present();
  //     }
  //   });
  // }

  async presentAchievementToast(ach: Achievement) {
    const toastDuration = 5000;
    const id =
      'achievement-toast-' + Math.round(Math.random() * 1000).toString();
    const toast = await this.toastCtrl.create({
      id: id,
      header: this.translatePipe.transform(ach.title),
      message: this.translatePipe.transform(ach.description),
      position: 'top',
      duration: toastDuration,
      mode: 'md',
      buttons: [
        {
          icon: 'assets/achievements/' + ach.id + '.svg',
          side: 'start',
          cssClass: 'achievement-toast-icon',
        },
      ],
    });

    toast.classList.remove('overlay-hidden');
    toast.style.minHeight = 'inherit';

    let style = document.createElement('style');
    style.textContent = `
    .pane {
      opacity: 1 !important;
      pointer-events: none;
    }
    `;
    toast.shadowRoot.querySelector('.toast-wrapper').appendChild(style); //.classList.add("pane");
    toast.shadowRoot.querySelector('.toast-wrapper').classList.add('pane');

    toast.onclick = async () => {
      this.router.navigate(['/achievements']);
      await this.toasts[0].pane.destroy({ animate: true });
    };

    let settings: CupertinoSettings = {
      inverse: true,
      breaks: {
        top: { enabled: true, bounce: true },
      },
      fitHeight: true,
      topperOverflow: false,
      bottomClose: true,
      showDraggable: false,
      upperThanTop: true,
      cssClass: 'notification',
      events: {
        onDidDismiss: async () => {
          if (this.toasts.length == 0) return;

          const toastEle = document.getElementById(this.toasts[0].toast.id);
          toastEle.parentElement.removeChild(toastEle);

          this.toasts.splice(0, 1);

          if (this.toasts.length > 0) {
            this.toasts[0].toast.classList.add('achievement-toast');
            await this.toasts[0].pane.present({ animate: true });

            setTimeout(async () => {
              if (this.toasts.length > 0 && !this.toasts[0].pane.isHidden()) {
                await this.toasts[0].pane.destroy({ animate: true });
              }
            }, toastDuration);
          }
        },
        onWillDismiss: async () => {
          const toastEle = document.getElementById(this.toasts[0].toast.id);
          toastEle.style.pointerEvents = 'none';
          this.toasts[0].pane.disableDrag();
        },
      },
    };

    const newPane = new CupertinoPane(toast, settings);
    this.toasts.push({ pane: newPane, toast: toast });
    console.log('Toasts: ', this.toasts, ach.title);
    if (this.toasts.length == 1) {
      toast.classList.add('achievement-toast');
      await newPane.present({ animate: true });

      setTimeout(async () => {
        if (this.toasts.length > 0 && !this.toasts[0].pane.isHidden()) {
          await newPane.destroy({ animate: true });
        }
      }, toastDuration);
    }
  }

  mapErrorCode(error: FirebaseError) {
    if (error.code === 'auth/user-not-found') {
      return 'This email has not been registered. Please finish the series of questions to create an account';
    } else if (error.code === 'auth/email-already-in-use') {
      return 'An account already exists with this email address. Please sign in';
    } else if (error.code === 'auth/credential-already-in-use') {
      return 'An account already exists with this email address. Please sign in';
    } else if (error.code === 'auth/wrong-password') {
      return 'Wrong password';
    } else if (error.code === 'auth/user-disabled') {
      return 'User disabled';
    } else if (error.code === 'auth/too-many-requests') {
      return 'Too many requests';
    } else if (error.code === 'auth/network-request-failed') {
      return 'Network request failed';
    } else if (error.code === 'auth/operation-not-allowed') {
      return 'Operation not allowed';
    } else if (error.code === 'auth/invalid-credential') {
      return 'Invalid credential';
    } else if (error.code === 'auth/invalid-login-credentials') {
      return 'Invalid login credentials';
    } else if (error.code === 'auth/internal-error') {
      return 'Something went wrong. Please try again later.';
    } else if (error.code === 'auth/invalid-password') {
      return 'Invalid password';
    } else if (error.code === 'auth/invalid-email') {
      return 'Invalid email address';
    } else {
      return error.message;
    }
  }

  mapCategoryDescription(category: string) {
    category = category.split(', ').sort().join(', ');
    return CategoryDescriptions[category];
  }

  getRecItem() {
    const user = this.userServ.getSyncUserOnce();
    if (user.gender == 'male' && !user[RECCOMMENDED_ITEMS[0].id]) {
      return RECCOMMENDED_ITEMS[0];
    } else {
      return null;
    }
  }

  hasClosed(recItem: RecItem) {
    const user = this.userServ.getSyncUserOnce();
    if (user) {
      this.userServ.setSyncUser({ ...user, [recItem.id]: true });
    }
  }

  openRecItem(recItem: RecItem) {
    this.appAvailability.check(recItem.bundleId).then(
      (yes: boolean) => this.opener(recItem.bundleId),
      (no: boolean) => this.downloader(recItem)
    );
  }

  async opener(app) {
    console.log('Opening');
    await AppLauncher.openUrl({
      url: `${app}`,
    });
  }

  downloader(item: RecItem) {
    console.log('Downloading');
    if (this.platform.is('ios')) {
      window.open(item.iosLink, '_system');
    } else if (this.platform.is('android')) {
      window.open(`market://details?id=${item.bundleId}`, '_system');
    }
  }
}

enum CategoryDescriptions {
  'booty' = `Putting the 'booty' in beauty; this workout blends bold moves with bootyful results`,
  'booty, wall pilates' = `Wall wonders and booty wows; engage in a workout that stretches, strengthens, and sculpts from spine to behind`,
  'booty, upper body' = `Shape your arms to your rear in unison; this workout is a perfect blend of upper body conditioning and booty boosting for a stunning figure`,
  'abs, booty' = `Sizzle your middle and boost that booty; this workout is all about rocking abs and poppin' curves for a body that won't quit`,
  'booty, legs' = `Get legs for days and a booty that slays; this routine is your ticket to fabulous thighs and a backside that defies`,
  'booty, planks' = `Turn planks into thanks with a booty that ranks; this workout is your shortcut to a tantalizing tummy and a backside that turns heads`,

  'wall pilates' = 'Wall meets wow; in this Pilates pow-wow, where your fitness fantasy meets reality, honing a strong, balanced body from your shoulders down to your legs',
  'upper body, wall pilates' = 'At the wall, upper body meets its match; where pilates precision and harmonious movements shape a beautifully balanced womanly shape',
  'abs, wall pilates' = 'Press, stretch, and sculpt in style; this routine transforms your core into a powerhouse of grace, ready to conquer the world',
  'legs, wall pilates' = 'Lean into the wall, stride into strength; where wall pilates meets leg glam, sculpting legs that don’t just walk, they mesmerize',
  'planks, wall pilates' = 'Wall to plank, strength to thank; our chic routine tones your core, turning every session into a celebration of feminine fortitude',

  'upper body' = 'This workout elegantly tones and tightens your upper half, creating a silhouette that speaks in curves of confidence',
  'abs, upper body' = 'In the realm of curves and cores, sculpt an upper body that commands attention and abs that define intention, all in one empowering routine',
  'legs, upper body' = "Craft curves with class; this routine tones your upper body and enhances your legs, for a figure that's both elegantly sculpted and beautifully curvy",
  'planks, upper body' = 'Tone those arms, tighten that tummy; get ready for a routine that shapes sleek arms and a knockout core, turning fitness into your most fabulous fashion',

  'abs' = 'Get ready to carve out a core of envy; this ab workout is where flat meets fab, turning your midsection into a irresistible statement',
  'abs, legs' = "Crunch your way to a tight tummy and lunge into leg elegance, sculpting a physique that's as beautifully balanced as it is irresistibly curvy",
  'abs, planks' = "Redefine your core, plank by plank, crunch by crunch; sculpting a midsection that's not just toned but tantalizingly trim and terrifically strong",

  'legs' = "Shape a leg-acy with this routine, where you'll flaunt legs that don’t just walk, they mesmerize; crafting a irresistible lower body that turns heads and captivates with every step",
  'legs, planks' = "Flaunt a figure that's athletic and curvy; this routine carves a tight tummy and shapely legs, crafting a physique that dazzles and draws every eye",

  'planks' = "Tighten, tone, and totally transform; this routine is your ticket to a tummy that's as sleek as it is strong, ready to rock any outfit",

  'cardio' = 'Push your limits and see incredible results; this workout is designed to maximize weight loss and sculpt a fit, healthy body in record time',

  'hiit' = 'This routine is a game-changer in fitness; the pace is dynamic making it ideal for dramatic weight loss and building a strikingly toned body',

  'upper body stretch' = 'Embrace the benefits of an upper body stretch; unwind and release tension, as you sculpt a more flexible and resilient upper frame',

  'lower body stretch' = "Unwind with a lower body stretch routine, that's all about releasing tension and sculpting a more flexible, resilient set of legs, glutes, and hips",

  'lower body stretch, upper body stretch' = 'Bend into a routine that’s a stretch above the rest; a fusion of health and harmony that aids in injury prevention, boosts fitness, and gently burns calories',

  'total body' = 'Achieve head-to-toe fitness with a routine that leaves no muscle behind; tone, strengthen, and enhance your entire body for a stunning, well-rounded look',
}
