import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { TranslateConfigService } from "src/app/services/translate-config.service";
import { Difficulty } from "../../enums/Difficulty";
import { BottomSheetComponent, SheetStates } from "../../shared/bottom-sheet/bottom-sheet-component";

@Component({
  selector: "app-difficulty-level",
  templateUrl: "./difficulty-level.component.html",
  styleUrls: ["./difficulty-level.component.scss"],
})
export class DifficultyLevelComponent {
  @ViewChild("bottomSheetRadio", { static: true }) bottomSheetRadio: BottomSheetComponent;
  @Output() modalSelect: EventEmitter<string> = new EventEmitter();

  language: any;

  level: Difficulty;
  options: Array<{ name: string; value: string }>;

  constructor(private translateConfigServ: TranslateConfigService) {
    this.language = this.translateConfigServ.getCurrentLang();
    this.options = [
      { name: "Beginner", value: "Beginner" },
      { name: "Intermediate", value: "Intermediate" },
      { name: "Advanced", value: "Advanced" },
    ];
  }

  public openModal(level: Difficulty) {
    this.level = level;
    if (this.bottomSheetRadio) {
      setTimeout(() => {
        this.bottomSheetRadio.setState(SheetStates.Opened);
      }, 100);
    } else {
      setTimeout(() => {
        this.openModal(level);
      }, 150);
    }
  }

  cancel() {
    this.modalSelect.emit("");
    this.bottomSheetRadio.setState(SheetStates.Closed);
  }

  submit() {
    this.modalSelect.emit(this.level);
    this.bottomSheetRadio.setState(SheetStates.Closed);
  }
}
