import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { HelperService } from "src/app/services/helper.service";
import { TranslateConfigService } from "src/app/services/translate-config.service";

import { ModalController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.page.html",
  styleUrls: ["./forgot-password.page.scss"],
})
export class ForgotPasswordPage {
  language: any;

  backBtnSub: Subscription;

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private helperServ: HelperService,
    private translateConfigServ: TranslateConfigService,
    private modalCtrl: ModalController,
    private platform: Platform
  ) {
    this.language = this.translateConfigServ.getCurrentLang();
    this.backBtnSub = this.platform.backButton.subscribeWithPriority(300, (processNextHandler) => {
      this.backBtnSub.unsubscribe();
      this.dismiss();
    });
  }

  ionViewWillEnter() {}

  async onSubmit(form: NgForm) {
    const email = form.value.email;
    if (email === "") {
      this.notificationService.showError("Please enter email address");
      return;
    }
    // tslint:disable-next-line:max-line-length
    const regex =
      /[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    const re = new RegExp(regex);
    if (!re.test(email)) {
      this.notificationService.showError("Please enter correct email address");
      return;
    }

    try {
      await this.authService.forgotPassword(email);
      this.dismiss();
    } catch (error) {
      let message = this.helperServ.mapErrorCode(error);
      this.notificationService.showError(message);
    }
  }
  checkValidation(data) {
    if (data === "") {
      return false;
    }
    // tslint:disable-next-line:max-line-length
    const regex =
      /[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    const re = new RegExp(regex);
    if (!re.test(data)) {
      return false;
    }
    return true;
  }

  async dismiss() {
    await this.modalCtrl.dismiss();
  }
}
