export interface Quote {
  body: string;
  author: string;
}

export const QUOTES: Quote[] = [
  {
    body: "The Clock Is Ticking, Are You Becoming The Person You Wanna Be?",
    author: "Greg Plitt",
  },
  {
    body: "You Must Believe In Yourself Enough To Be The Person Now, That You Want Others To Remember You For Later",
    author: "Greg Plitt",
  },
  {
    body: "Opportunities don't come knocking on the door. They present themselves when you knock the door down",
    author: "Greg Plitt",
  },
  {
    body: "Life isn't about waiting for the storm to pass. It's about dancing in the rain",
    author: "Greg Plitt",
  },
  {
    body: "If you mentally believe it will happen, your body is going to find a way to make it physically happen",
    author: "Greg Plitt",
  },
  {
    body: "That fear will either create you or destroy you. I love fear, because behind every fear there is a person you wanna be",
    author: "Greg Plitt",
  },
  {
    body: "Even the Mightiest Warriors experience fears. What makes them a true Warrior is the courage that they posses to overcome their fears",
    author: "Vegeta DBZ",
  },
  {
    body: "We are what we repeatedly do. Excellence then is not an act but a habit",
    author: "Aristotle",
  },
  {
    body: "The little things in life add up to be the difference between winning it and losing it The little things, attention to detail",
    author: "Greg Plitt",
  },
  {
    body: "Bet on yourself, you'll never lose if you bet on yourself",
    author: "Greg Plitt",
  },
  {
    body: "Don't tell anyone you can't do something because you can",
    author: "Greg Plitt",
  },
  {
    body: "We're not ordinary, you don't want to be ordinary",
    author: "Greg Plitt",
  },
  {
    body: "Get the mind right, the body will follow",
    author: "Greg Plitt",
  },
  {
    body: "Stop going about the day as a servant. Become the master. Run your day and stop letting it run you",
    author: "Greg Plitt",
  },
  {
    body: "If you don't want it bad enough, you'll find an excuse",
    author: "Greg Plitt",
  },
  {
    body: "Having the will to win is everything",
    author: "Greg Plitt",
  },
  {
    body: "Fear is self-imposed. You create it, you can destroy it too. Behind every fear is the person you want to be. When fear is destroyed, it comes back as confidence",
    author: "Greg Plitt",
  },
  {
    body: "Make your dreams a reality, rep by rep",
    author: "Greg Plitt",
  },
  {
    body: "Mentally, you must believe it before you physically start it, or else you will never reach prosperity",
    author: "Greg Plitt",
  },
  {
    body: "The last three or four reps is what makes the muscle grow. This area of pain divides a champion from someone who is not a champion",
    author: "Arnold Schwarzenegger",
  },
  {
    body: "The only place where success comes before work is in the dictionary",
    author: "Vidal Sassoon",
  },
  {
    body: "Whether you think you can, or you think you can't, you're right",
    author: "Unknown",
  },
  {
    body: "You must expect great things of yourself before you can do them",
    author: "Michael Jordan",
  },
  {
    body: "Action is the foundational key to all success",
    author: "Pablo Picasso",
  },
  {
    body: "All our dreams can come true if we have the courage to pursue them",
    author: "Walt Disney",
  },
  {
    body: "What hurts today makes you stronger tomorrow",
    author: "Jay Cutler",
  },
  {
    body: "A champion is someone who gets up when they can't",
    author: "Jack Dempsey",
  },
  {
    body: "If you want something you've never had, you must be willing to do something you've never done ",
    author: "Thomas Jefferson",
  },

  {
    body: "You have to think it before you can do it. The mind is what makes it all possible",
    author: "Kai Greene",
  },
  {
    body: "If something stands between you and your success, move it. Never be denied",
    author: "Dwayne 'The Rock' Johnson",
  },
  { body: "Success is walking from failure to failure with no loss of enthusiasm", author: "Winston Churchill" },
  { body: "Today I will do what others won't, so tomorrow I can accomplish what others can't", author: "Jerry Rice" },
  { body: "Do something today that your future self will thank you for.", author: "Unknown" },
  { body: "No matter how slow you go, you are still lapping everybody on the couch", author: "Unknown" },
  { body: "You miss 100% of the shots you don't take", author: "Wayne Gretzky" },
  { body: "The difference between the impossible and the possible lies in a person's determination", author: "Tommy Lasorda" },
  { body: "Strength does not come from physical capacity. It comes from an indomitable will", author: "Mahatma Gandhi" },
  { body: "Don't count the days, make the days count", author: "Muhammad Ali" },
  {
    body: "No matter how many mistakes you make or how slow you progress, you are still way ahead of everyone who isn't trying",
    author: "Tony Robbins",
  },
  { body: "All great achievements require time", author: "Maya Angelou" },
  { body: "A year from now you may wish you had started today", author: "Karen Lamb" },
  { body: "Don't give up on your dreams, or your dreams will give up on you", author: "John Wooden" },
  { body: "Most people fail, not because of lack of desire, but, because of lack of commitment", author: "Vince Lombardi" },
  { body: "Look in the mirror. That's your competition", author: "John Assaraf" },
  { body: "Some people want it to happen, some wish it would happen, others make it happen", author: "Michael Jordan" },
  { body: "The pain you feel today will be the strength you feel tomorrow", author: "Arnold Schwarzenegger" },
  { body: "Great works are performed, not by strength, but by perseverance", author: "Samuel Johnson" },
  { body: "Perseverance is the hard work you do after you get tired of doing the hard work you already did", author: "Newt Gingrich" },
  { body: "Sometimes you don't realize your own strength until you come face to face with your greatest weakness", author: "Susan Gale" },
  { body: "Pain is temporary. Quitting lasts forever", author: "Lance Armstrong" },
  { body: "Every champion was once a contender that refused to give up", author: "Sylvester Stallone" },
  { body: "Nothing will work unless you do", author: "John Wooden" },
  { body: "You must expect great things of yourself before you can do them", author: "Michael Jordan" },
  {
    body: "I don't count my sit-ups; I only start counting when it starts hurting because they're the only ones that count",
    author: "Muhammad Ali",
  },
  {
    body: "Strength does not come from winning. Your struggles develop your strengths. When you go through hardships and decide not to surrender, that is strength",
    author: "Arnold Schwarzenegger",
  },
  { body: "Your past does not equal your future", author: "Tony Robbins" },
  { body: "The path to success is to take massive, determined action", author: "Tony Robbins" },
  { body: "If you can't, you must. If you must, you can", author: "Tony Robbins" },
  { body: "It's what you practice in private that you will be rewarded for in public", author: "Tony Robbins" },
  { body: "Setting goals is the first step turning the invisible into the visible", author: "Tony Robbins" },
  { body: "It is in your moments of decision that your destiny is shaped", author: "Tony Robbins" },
  { body: "The only thing that's keeping you from getting what you want is the story you keep telling yourself", author: "Tony Robbins" },
  { body: "It's not what we do once in a while that shapes our lives, but what we do consistently", author: "Tony Robbins" },
  { body: "Why live an ordinary life, when you can live an extraordinary one", author: "Tony Robbins" },
  { body: "People are not lazy, they simply have impotent goals. That is goals that do not inspire them", author: "Tony Robbins" },
  {
    body: "If you talk about it, it's a dream, if you envision it, it's possible, but if you schedule it, it's real",
    author: "Tony Robbins",
  },
  { body: "Any time you sincerely want to make a change, the first thing you must do is to raise your standards", author: "Tony Robbins" },
  {
    body: "The more rejection you get, the better you are, the more you've learned, the closer you are to your outcome… If you can handle rejection, you'll learn to get everything you want",
    author: "Tony Robbins",
  },
  { body: "Change the way you look at things and the things you look at change", author: "Wayne Dyer" },
  { body: "It's never crowded along the extra mile", author: "Wayne Dyer" },
  {
    body: "If you believe it will work out , you'll see opportunities. If you believe it won't you will see obstacles",
    author: "Wayne Dyer",
  },
  { body: "You are not stuck where you are unless you decide to be", author: "Wayne Dyer" },
  { body: "Imagination is everything. It is the preview of life's coming attractions", author: "Albert Einstein" },
  { body: "Life is like riding a bicycle. To keep your balance you must keep moving", author: "Albert Einstein" },
  { body: "Nothing happens until something moves", author: "Albert Einstein" },
  { body: "In the middle of difficulty lies opportunity", author: "Albert Einstein" },
  { body: "Once we accept our limits, we go beyond them", author: "Albert Einstein" },
  { body: "Only those who attempt the absurd can achieve the impossible", author: "Albert Einstein" },
  { body: "When something is important enough, you do it even if the odds are not in your favor", author: "Elon Musk" },
  {
    body: "If you get up in the morning and think the future is going to be better, it is a bright day. Otherwise, it's not",
    author: "Elon Musk",
  },
  { body: "We're going to make it happen. As God is my bloody witness, I'm hell-bent on making it work", author: "Elon Musk" },
  { body: "I think it is possible for ordinary people to choose to be extraordinary", author: "Elon Musk" },
  { body: "Good enough is not good enough if it can be better. And better is not good enough if it can be best", author: "Rick Rigsby" },
  { body: "What one does is what counts and not what one had the intention of doing", author: "Pablo Picasso" },
  { body: "Action is the foundational key to all success", author: "Pablo Picasso" },
  { body: "Don't let the noise of others' opinions drown out your own inner voice", author: "Steve Jobs" },
  {
    body: "Have the courage to follow your heart and intuition. They somehow already know what you truly want to become",
    author: "Steve Jobs",
  },
  {
    body: "The greatest thing is when you put your heart and soul into something over an extended period of time, and it is worth it",
    author: "Steve Jobs",
  },
  { body: "In order to succeed, we must first believe that we can", author: "Nikos Kazantazkis" },
  { body: "I can't change the direction of the wind, but I can adjust my sails to always reach my destination", author: "Jimmy Dean" },
  { body: "Perfection is not attainable, but if we chase perfection we can catch excellence.", author: "Vince Lombardi" },
  { body: "What you do today can improve all your tomorrows", author: "Ralph Marston" },
  { body: "Nothing is impossible, the word itself says 'I'm possible'!", author: "Audrey Hepburn" },
  { body: "Put your heart, mind, and soul into even your smallest acts. This is the secret of success", author: "Swami Sivananda" },
  { body: "Keep your eyes on the stars, and your feet on the ground", author: "Theodore Roosevelt" },
  { body: "The secret of getting ahead is getting started", author: "Mark Twain" },
  { body: "Believe you can and you're halfway there", author: "Theodore Roosevelt" },
  { body: "Always do your best. What you plant now, you will harvest later", author: "Og Mandino" },
  { body: "If opportunity doesn't knock, build a door", author: "Milton Berle" },
  {
    body: "I hated every minute of training, but I said, 'Don't quit. Suffer now and live the rest of your life as a champion",
    author: "Muhammad Ali",
  },
  {
    body: "I take rejection as someone blowing a bugle in my ear to wake me up and get going, rather than retreat",
    author: "Sylvester Stallone",
  },
  { body: "There are always going to be obstacles that come in your way, stay positive", author: "Michael Phelps" },
  { body: "You can't put a limit on anything. The more your dream, the farther you get", author: "Michael Phelps" },
  { body: "When I feel tired, I just think about how great I will feel once I finally reach my goal", author: "Michael Phelps" },
  { body: "The more you dream, the more you achieve", author: "Michael Phelps" },
  { body: "Goals should never be easy", author: "Michael Phelps" },
  { body: "Everything is possible as long as you put your mind to it and you put the work and time into it", author: "Michael Phelps" },
  { body: "Nothing is impossible", author: "Michael Phelps" },
  { body: "All it takes is an imagination", author: "Michael Phelps" },
  { body: "Dream as big as you can dream, and anything is possible", author: "Michael Phelps" },
  {
    body: "If you want to turn a vision into reality, you have to give 100% and never stop believing in your dream",
    author: "Arnold Schwarzenegger",
  },
  {
    body: "The mind is the limit. As long as the mind can envision the fact that you can do something, you can do it, as long as you really believe 100 percent",
    author: "Arnold Schwarzenegger",
  },
  { body: "You have to remember something: Everybody pities the weak; jealousy you have to earn", author: "Arnold Schwarzenegger" },
  { body: "You can't climb the ladder of success with your hands in your pockets", author: "Arnold Schwarzenegger" },
  { body: "Life may be full of pain but that's not an excuse to give up", author: "Arnold Schwarzenegger" },
  {
    body: "Life's six rules for success. 1. Trust yourself. 2. Break some rules. 3. Don't be afraid to fail. 4. Ignore the naysayers. 5. Work like hell. 6. Give something back",
    author: "Arnold Schwarzenegger",
  },
  { body: "The only one you can accurately compare yourself to is you", author: "Mike Mentzer" },
  { body: "One cannot actualize his goals until he visualizes them clearly in the minds eye", author: "Mike Mentzer" },
  { body: "The more you're obsessed by something, the better chance you have of achieving it", author: "Frank Zane" },
  { body: "Dreams are not what you see in your sleep, dreams are things which do not let you sleep", author: "Cristiano Ronaldo" },
  {
    body: "Don't let small obstacles be in the way of being victorious. Remember you are stronger than the challenges you face",
    author: "Cristiano Ronaldo",
  },
  { body: "The greatest thing about tomorrow is I will be better than I am today", author: "Tiger Woods" },
  {
    body: "Doubt is only removed by action. If you're not working then that's where doubt comes in",
    author: "Conor McGregor",
  },
  { body: "My success isn't the result of arrogance, it's the result of belief ", author: "Conor McGregor" },
  { body: "If you train 100% the results will be 100%", author: "Anderson Silva" },
  { body: "A champion is defined by the adversity he overcomes", author: "Anderson Silva" },
  { body: "You just can't beat the person who never gives up", author: "Babe Ruth" },
  { body: "Do not pray for an easy life, pray for the strength to endure a difficult one", author: "Bruce Lee" },
  { body: "To hell with circumstances; I create opportunities", author: "Bruce Lee" },
  { body: "The successful warrior is the average man, with laser-like focus", author: "Bruce Lee" },
  {
    body: "Life's battles don't always go to the stronger or faster man. But sooner or later the man who wins, is the man who thinks he can",
    author: "Bruce Lee",
  },
  { body: "If you love life, don't waste time, for time is what life is made up of", author: "Bruce Lee" },
  { body: "Knowing is not enough, we must apply. Willing is not enough, we must do", author: "Bruce Lee" },
  { body: "In the middle of chaos lies opportunity", author: "Bruce Lee" },
  {
    body: "If you spend too much time thinking about a thing, you'll never get it done. Make at least one definite move daily toward your goal",
    author: "Bruce Lee",
  },
  { body: "As you think, so shall you become", author: "Bruce Lee" },
  { body: "If you don't want to slip up tomorrow, speak the truth today", author: "Bruce Lee" },
  { body: "Defeat is not defeat unless accepted as a reality in your own mind", author: "Bruce Lee" },
  { body: "Empty your cup so that it may be filled; become devoid to gain totality", author: "Bruce Lee" },
  { body: "It's not the daily increase but daily decrease. Hack away at the unessential", author: "Bruce Lee" },
  { body: "If you think a thing is impossible, you'll only make it impossible", author: "Bruce Lee" },
  { body: "What you habitually think largely determines what you will ultimately become", author: "Bruce Lee" },
  {
    body: "Don't fear failure. Not failure, but low aim, is the crime. In great attempts it is glorious even to fail",
    author: "Bruce Lee",
  },
  { body: "Preparation for tomorrow is hard work today", author: "Bruce Lee" },
  { body: "Never be afraid to fail. Failure is only a stepping stone to improvement", author: "Tatchakorn Yeerum" },
  { body: "Nothing makes me more determined to succeed than someone telling me something's impossible", author: "Jackie Chan" },
  {
    body: "Why did I become Jackie Chan? Mostly because I work very hard. When people were sleeping, I was still training",
    author: "Jackie Chan",
  },
  { body: "Every champion was once a contender that refused to give up", author: "Sylvester Stallone" },
  { body: "There is no substitute for hard work. Always be humble and hungry", author: "Dwayne Johnson" },
  { body: "All successes begin with Self-Discipline. It starts with you", author: "Dwayne Johnson" },
  { body: "We do today what they won't, so tomorrow we accomplish what they can't", author: "Dwayne Johnson" },
  {
    body: "I know how Important it is to have people who believe in you even when you don't believe in yourself",
    author: "Dwayne Johnson",
  },
  {
    body: "Success isn't always about “greatness”. It's about consistency. Consistent hard work gains success. Greatness will come",
    author: "Dwayne Johnson",
  },
  { body: "Grind Hard, Shine Hard", author: "Dwayne Johnson" },
  { body: "I like to use the hard times of the past to motivate me today", author: "Dwayne Johnson" },
  { body: "Once you believe in who you are and who you were born to be, it can be very powerful", author: "Dwayne Johnson" },
  {
    body: "One hard day of work proves you have heart. But day after day is what proves you have maturity and commitment",
    author: "Dwayne Johnson",
  },
  { body: "Wake up determined. Go to bed satisfied", author: "Dwayne Johnson" },
  { body: "It's better to do something badly than to not do it at all", author: "Jordan Peterson" },
  { body: "Compare yourself to who you were yesterday, not to who someone else is today", author: "Jordan Peterson" },
  { body: "The successful among us delay gratification and bargain with the future", author: "Jordan Peterson" },
  { body: "Notice that opportunity lurks where responsibility has been abdicated", author: "Jordan Peterson" },
  {
    body: "Can you imagine yourself in 10 years if instead of avoiding the things you know you should do, you actually did them every single day - that's powerful",
    author: "Jordan Peterson",
  },
  { body: "If you quit once it becomes a habit. Never quit!", author: "Michael Jordan" },
  { body: "Never say never, because limits, like fears, are often just an illusion", author: "Michael Jordan" },
  { body: "I've failed over and over and over again in my life and that is why I succeed", author: "Michael Jordan" },
  { body: "If you do the work you get rewarded. There are no shortcuts in life", author: "Michael Jordan" },
  { body: "Once I made a decision, I never thought about it again", author: "Michael Jordan" },
  { body: "Some people want it to happen, some wish it would happen, others make it happen", author: "Michael Jordan" },
  {
    body: "If it turns out that my best wasn't good enough, at least I won't look back and say I was afraid to try",
    author: "Michael Jordan",
  },
  {
    body: "My father used to say that it's never too late to do anything you wanted to do. And he said you never know what you can accomplish until you try",
    author: "Michael Jordan",
  },
  { body: "You have to set yourself goals so you can push yourself harder. Desire is the key to success", author: "Usain Bolt" },
  { body: "Your body is your temple. You do your body good it does you good", author: "Floyd Mayweather" },
  { body: "Everything people said I couldn't do, I've done", author: "Floyd Mayweather" },
  { body: "Showing is better than telling", author: "Floyd Mayweather" },
  { body: "Everything negative - pressure, challenges - is all an opportunity for me to rise", author: "Kobe Bryant" },
  { body: "The moment you give up, is the moment you let someone else win", author: "Kobe Bryant" },
  { body: "There is no way to completely fail unless you completely quit", author: "Jon Jones" },
  { body: "We must not allow other people's limited perceptions to define us", author: "Virginia Satir" },
  { body: "I haven't failed. I've just found 10,000 ways that won't work", author: "Thomas Edison" },
  { body: "Believe and act as if it were impossible to fail", author: "Charles Kettering" },
  { body: "The best way to predict your future is to create it", author: "Abe Lincoln" },
  {
    body: "Start by doing what's necessary; then do what's possible; and suddenly you are doing the impossible",
    author: "Francis of Assisi",
  },
  {
    body: "The danger is not to set your goal too high and fail to reach it. It's to set your goal too low and reach it",
    author: "Georges St-Pierre",
  },
  { body: "Every little thing you do leads up to a bigger thing", author: "Georges St-Pierre" },
  { body: "What you do speaks so loudly that I cannot hear what you say", author: "Ralph Waldo Emerson" },
  { body: "The only person you are destined to become is the person you decide to be", author: "Ralph Waldo Emerson" },
  { body: "Life is 10% what happens to you and 90% how you react to it", author: "Charles R. Swindoll" },
  { body: "The best preparation for tomorrow is doing your best today", author: "H. Jackson Brown, Jr." },
  { body: "The more difficult the victory, the greater the happiness in winning", author: "Pele" },
  { body: "It is during our darkest moments that we must focus to see the light", author: "Aristotle" },
  { body: "It does not matter how slowly you go as long as you do not stop", author: "Confucius" },
  { body: "Making excuses burns zero calories per hour", author: "Unknown" },
  { body: "When you feel like quitting, think about why you started", author: "Unknown" },
  { body: "Strive for progress, not perfection", author: "Unknown" },
  { body: "If it doesn't challenge you it won't change you", author: "Unknown" },
  { body: "Your desire to change must be greater than your desire to stay the same", author: "Unknown" },
  { body: "You will never always be motivated. You have to learn to be disciplined", author: "Unknown" },
  { body: "When your body feels like giving up, that's when your workout really starts", author: "Unknown" },
  { body: "The harder you work for something, the greater you'll feel when you achieve it", author: "Unknown" },
  { body: "Results happen over time, not overnight. Work hard, stay consistent, and be patient", author: "Unknown" },
  { body: "Every problem is a gift--without problems we would not grow", author: "Unknown" },
  { body: "If you do what you've always done, you'll get what you've always gotten", author: "Unknown" },
  { body: "Never leave that till tomorrow which you can do today", author: "Benjamin Franklin" },
  { body: "Defeat is not bitter unless you swallow it", author: "Joe Clark" },
  { body: "Life has no limitations, except the ones you make", author: "Les Brown" },
  { body: "99% of failures come from people who make excuses", author: "George Washington" },
  {
    body: "Many of life's failures are people who did not realize how close they were to success when they gave up",
    author: "Thomas Edison",
  },
];
