<div class="ion-padding">
  <ion-label>{{ "To link your social account, please enter your password for:" | translate }} {{ email }}</ion-label>
  <ion-item class="ion-margin-bottom">
    <ion-input [(ngModel)]="password" type="password" (keyup.enter)="link()" placeholder="password"></ion-input>
  </ion-item>
  <div class="btn-container">
    <ion-button color="light" (click)="cancel()">{{ "Cancel" | translate }}</ion-button>
    <ion-button (click)="link()">{{ "Link" | translate }}</ion-button>
  </div>
</div>
