<bottom-sheet #bottomSheetRadio [theme]="theme" [noScroll]="true">
  <div class="modal-select-container" [ngClass]="theme">
    <div
      style="
        overflow: scroll;
        padding: 0 20px;
        max-height: calc(
          100vh - 24px - 152px - 54px - var(--ion-safe-area-top) -
            var(--ion-safe-area-bottom)
        );
      "
    >
      <p class="modal-header">{{ title | translate }}</p>

      <div *ngFor="let option of options" class="workout-item">
        <div class="check-box-container">
          <ion-label
            class="checkbox-title"
            [class.uppercase]="option.value == 'hiit'"
            >{{ option.value | translate }}</ion-label
          >
          <div
            (click)="onChange(option)"
            class="checkbox-wrap"
            [style.pointer-events]="option.isDisabled ? 'none' : 'all'"
          >
            <ion-checkbox
              mode="ios"
              slot="end"
              [disabled]="option.isDisabled"
              [checked]="option.isChecked"
            >
            </ion-checkbox>
            <span *ngIf="option.index" class="checkbox-index">
              {{ option.index }}
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="options?.length > 3" style="height: 2rem"></div>
    </div>

    <div class="modal-btn-container" [class.shadow]="options?.length > 3">
      <ion-button
        expand="full"
        mode="ios"
        class="btn-cancel"
        (click)="cancel()"
        shape="round"
        color="grey"
      >
        {{ "cancel" | translate }}
      </ion-button>

      <ion-button
        expand="full"
        mode="ios"
        [disabled]="isDisabledSelect"
        class="btn-submit"
        (click)="submit()"
        shape="round"
        color="primary"
      >
        <span *ngIf="!loading">{{ "select" | translate }}</span>
        <ion-spinner *ngIf="loading"></ion-spinner>
      </ion-button>
    </div>
  </div>
</bottom-sheet>
