<ion-header mode="ios"></ion-header>
<ion-content fullscreen="true">
  <div class="header-container">
    <ion-toolbar mode="ios">
      <ion-buttons slot="start" (click)="goBack()" mode="ios">
        <ion-back-button
          text
          style="pointer-events: none"
          mode="ios"
          icon="assets/newUI/icons/backBtnBlack.svg"
          [defaultHref]="''"
        ></ion-back-button>
      </ion-buttons>
      <ion-title color="black" style="text-transform: capitalize"
        >{{"Create plan" | translate}}</ion-title
      >
      <ion-buttons slot="end" mode="ios">
        <ion-button mode="ios" (click)="openModal(bottomSheetReset)">
          <ion-icon
            slot="icon-only"
            src="assets/newUI/icons/rotate.svg"
          ></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>

  <bottom-sheet #bottomSheetInfo class="z30000">
    <div class="modal-select-container">
      <p class="modal-header" style="padding: 0 20px">
        {{infoString | translate}}
      </p>
      <div class="modal-btn-container">
        <ion-button
          expand="full"
          mode="ios"
          class="btn-submit"
          (click)="hideModal(bottomSheetReset)"
          fill="clear"
        >
          {{"ok" | translate}}
        </ion-button>
      </div>
    </div>
  </bottom-sheet>

  <bottom-sheet #bottomSheetReset class="z30000">
    <div class="modal-select-container">
      <p class="modal-header">
        {{"Are you sure you want to reset?" | translate}}
      </p>
      <p style="text-align: center">
        {{"Your create a plan data will be lost" | translate}}
      </p>
      <div class="modal-btn-container">
        <ion-button
          expand="full"
          mode="ios"
          class="btn-cancel"
          (click)="hideModal(bottomSheetReset)"
          fill="clear"
        >
          {{"no" | translate}}
        </ion-button>

        <ion-button
          expand="full"
          mode="ios"
          class="btn-submit"
          (click)="resetPlan(bottomSheetReset)"
          fill="clear"
        >
          {{"yes" | translate}}
        </ion-button>
      </div>
    </div>
  </bottom-sheet>

  <bottom-sheet #bottomSheetUpdatePlan class="z30000">
    <div class="modal-select-container">
      <p class="modal-header">
        {{"Are you sure you want to update your current plan?" | translate}}
      </p>
      <p style="text-align: center">
        {{"You will start fresh from this plan" | translate}}
      </p>
      <div class="modal-btn-container">
        <ion-button
          expand="full"
          mode="ios"
          class="btn-cancel"
          (click)="hideModal(bottomSheetUpdatePlan)"
          fill="clear"
        >
          {{"no" | translate}}
        </ion-button>

        <ion-button
          expand="full"
          mode="ios"
          class="btn-submit"
          (click)="updatePlan(bottomSheetUpdatePlan)"
          fill="clear"
        >
          <ion-spinner *ngIf="loadingPlan" style="margin: auto"> </ion-spinner>
          <span *ngIf="!loadingPlan">{{"yes" | translate}} </span>
        </ion-button>
      </div>
    </div>
  </bottom-sheet>

  <div class="content-container">
    <div class="drag-and-drop-title">
      {{"To create your workout plan, drag and drop the workout categories onto\
      \ the corresponding days" | translate}}
    </div>
    <div
      inViewport
      (inViewportAction)="onInViewportChange($event, workoutListContainer)"
    ></div>
    <div class="workout-list-container" #workoutListContainer>
      <div
        *ngFor="let workout of workoutTypeList; let i = index"
        class="workout-list-item"
        [dndDraggable]="workout"
        dndDraggingClass="dragging-workout-item"
        [dndType]="'workout'"
        dndEffectAllowed="copy"
      >
        <p
          class="workout-name"
          [innerText]="workout.name | translate"
          [style.font-size]="workoutFontSizes.length >= i-1 ? workoutFontSizes[i] : '14px'"
        ></p>
      </div>
    </div>

    <div class="week-days-container">
      <div *ngFor="let day of weekDays; let j = index" class="week-days-item">
        <div class="week-day">
          <p class="week-day-p" [style.font-size]="'16px'">
            {{day.name | translate}}
          </p>
        </div>
        <div class="week-workout">
          <div
            *ngFor="let item of day.data; let i=index"
            [style.width]="item.isMergeIcon ? 'unset' : day.data.length > 1 ? '40%' : '100%'"
          >
            <div
              *ngIf="!item.isMergeIcon"
              class="week-workout-item-wrapper"
              [dndDropzone]="['workout']"
              (dndDrop)="onDropWorkout($event, day, i)"
            >
              <div
                class="week-workout-item"
                [style.background]="day.isMerge ? 'var(--ion-color-primary)' : ''"
                [style.color]="day.isMerge ? 'white' : ''"
                [dndDraggable]="item"
                dndDraggingClass="dragging-item"
                [dndDisableDragIf]="day.data[0].name === 'Rest'"
                [dndType]="'day-workout'"
                (dndMoved)="onDragged($event, day, i)"
                (dndStart)="onDragStart($event)"
                (dndEnd)="onDragEnd($event)"
                dndEffectAllowed="move"
              >
                <p class="workout-selected" [style.font-size]="'16px'">
                  {{item.name | translate}}
                </p>
              </div>
            </div>
            <div
              *ngIf="item.isMergeIcon"
              (click)="combineUncombineWorkout(day, bottomSheetInfo)"
              class="merge-button"
            >
              <svg
                width="24"
                [style.stroke]="day.isMerge ? 'var(--ion-color-primary)' : '#72758e'"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M1.0498 12H6.9998"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17.0098 12H22.9598"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="merge-info">
        <span>{{"Click the merge icon" | translate}} (</span>
        <ion-icon src="assets/newUI/icons/merge.svg"></ion-icon>
        <span>) {{"to combine/uncombine your workouts" | translate}}</span>
      </div>

      <div class="submit-wrapper">
        <ion-button
          style="margin: 0 auto"
          mode="ios"
          (click)="submit(bottomSheetUpdatePlan, bottomSheetInfo)"
          expand="full"
          fill="clear"
        >
          <ion-spinner *ngIf="loading || loadingPlan" style="margin: auto">
          </ion-spinner>
          <span *ngIf="!loading && !loadingPlan"
            >{{"confirm_plan" | translate}}</span
          >
        </ion-button>
      </div>
    </div>
    <div
      [ngClass]="{'show-remove-button': showRemoveBtn === 'show', 'hide-remove-button': showRemoveBtn === 'hide'}"
      [dndDropzone]="['day-workout']"
      class="remove-button"
    >
      <ion-icon src="assets/newUI/icons/trash.svg"></ion-icon>
    </div>
  </div>
</ion-content>

<app-auth
  [customClass]="'z30000'"
  id="authModal"
  #authModal
  [mode]="'signup'"
  (auth)="submit(bottomSheetUpdatePlan, bottomSheetInfo, $event)"
></app-auth>
