import { Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

const HTML_TEMPLATE = `
    <div class="BottomSheetBackdrop" id="BottomSheetBackdrop" (click)="ChangeBottomSheetStateToClosed()"></div>

    <div [ngClass]="['BottomSheetOuter', noScroll ? 'no-scroll' : '', !landscape ? 'portrait' : '']" id="BottomSheetOuter">
        <div
            id="BottomSheet"
            class="BottomSheetBox"
            [class.darkBackground]="theme === 'dark'"
            [class.light]="theme !== 'dark'"
            [class.landscape]="landscape"
            [class.no-border-radius-mobile]="payment"
             (click)="PreventClose($event)">
                 <div class="BottomSheetContent" 
                   [class.no-border-radius-mobile]="payment"
                 ><ng-content></ng-content></div>
        </div>
    </div>
`;

const CSS_STYLE = `
    .BottomSheetBackdrop {
        position: absolute;
        height: 100%;
        width: 100vw;
        top: 0;
        left: 0;
        background-color: black;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        z-index: 5;
        cursor: pointer;
    }

    .BottomSheetOuter {
        position: fixed;
        height: 200vh;
        width: 100vw;
        z-index: 100;
        top: auto;
        bottom: -100vh;
        left: 0;
        overflow: hidden;
        overflow-y: auto;
        scroll-snap-type: y mandatory;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        visibility: hidden;
        transition: 0.3s ease-out;
        cursor: pointer;
        pointer-events: none;
    }

    .darkBackground {
        background-color: rgba(255, 255, 255, 0.9);
    }

    .landscape {
      max-width: fit-content !important;
      position: relative !important;
      left: 50% !important;
      transform: translate(-50%,0%) !important;
      border-radius: 15px 15px 0px 0px !important;
    }

    @supports ((-webkit-backdrop-filter: blur(15px)) or (backdrop-filter: blur(15px))) {
        .darkBackground {
          background-color: rgba(255, 255, 255, 0.7);
          backdrop-filter: blur(15px);
          -webkit-backdrop-filter: blur(15px);
        }
    }

    .light {
        background-color: white;
    }

    .no-border-radius-mobile {
         border-radius: 0px !important;
     }

    .ShowBottomSheetOuter {
        top: auto;
        bottom: 0px;
        transition: bottom 0.4s ease;
        visibility: visible;
    }

    .HideBottomSheetOuter {
        bottom: -100vh;
        top: auto;
        transition: bottom 0.4s ease;
        visibility: visible;
    }

    .ShowBackdrop {
        opacity: 0.6;
        transition: opacity 0.4s ease;
        visibility: visible;
    }

    .HideBackdrop {
        opacity: 0;
        transition: opacity 0.4s ease;
        visibility: visible;
    }

    .BottomSheetOuter::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0;
        height: 0;
    }

    .BottomSheetOuter::-webkit-scrollbar {
        display: none;
    }

    .BottomSheetBox {
        scroll-snap-align: center;
        width: 100vw;
        height: auto;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: var(--ion-text-color);
        cursor: default;
        pointer-events: all;

        &::-webkit-scrollbar {
           display: none;
         }
    }

    .BottomSheetContent {
        transform: translate(0, 0);
        overflow: scroll;
        max-height: calc(100vh - 54px - var(--ion-safe-area-top) + var(--ion-safe-area-bottom));
        -ms-overflow-style: block;
        padding-bottom: var(--ion-safe-area-bottom);
        border-top-left-radius: 15px;
         border-top-right-radius: 15px;

         &::-webkit-scrollbar {
           display: none;
         }
    }

    .Fullscreen {
        height: calc(100vh - 54px - var(--ion-safe-area-top));
    }

    .no-scroll {
      .BottomSheetContent {
        overflow: hidden;
      }
    }

    @media only screen and (min-width: 768px) {
      .no-border-radius-mobile {
             border-radius: unset !important;
         }
        .BottomSheetOuter.portrait {
            position: fixed;
            height: 100vh;
            width: 100vw;
            z-index: 100;
            top: 200%;
            left: 0;
            overflow: hidden;
            visibility: hidden;
            transition: 0.5s ease-out;
        }

        .BottomSheetBox {
            max-width: fit-content;
            position: relative;
            top: 50%;
            left: 50%;
            padding: 0;
            transform: translate(-50%, -50%);
            border-radius: 15px;
            overflow: scroll;
        }

        .ShowBottomSheetOuter.portrait {
            top: 0;
            transition: top 0.3s;
            visibility: visible;
        }

        .Fullscreen {
            height: 600px
        }
    }
`;

@Component({
  selector: 'bottom-sheet',
  template: HTML_TEMPLATE,
  styles: [CSS_STYLE],
})
export class BottomSheetComponent implements OnDestroy {
  private static randomString: string;
  private static wasTriggeredRecently = false;
  private state: SheetStates = SheetStates.Closed;

  @Input() public ShowBackdrop = true;
  @Input() public Fullscreen = false;
  @Input() public theme = 'dark';
  @Input() public noScroll = false;
  @Input() public landscape = false;
  @Input() public payment = false;

  public isResentClosed: boolean;

  subscriptions: Subscription[] = [];

  constructor(private elementRef: ElementRef, private platform: Platform) {
    this.isResentClosed = false;
    this.subscriptions.push(
      this.platform.backButton.subscribeWithPriority(
        300,
        (processNextHandler) => {
          if (this.state !== SheetStates.Closed) {
            this.setState(SheetStates.Closed);
          } else {
            processNextHandler();
          }
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public async setState(value: SheetStates) {
    if (!BottomSheetComponent.wasTriggeredRecently) {
      this.triggerBottomSheet(true);
      this.state = value;
      if (value === SheetStates.Closed) {
        this.isResentClosed = true;
        setTimeout(() => {
          this.isResentClosed = false;
        }, 500);
      }
      if (value === SheetStates.Opened || value === SheetStates.Docked) {
        await this.ShowBottomSheet();
      } else {
        console.log('Closing bottom sheet');
        await this.CloseBottomSheet();
      }
      return this.triggerBottomSheet(false);
    }
  }

  private triggerBottomSheet(trigger) {
    if (trigger) {
      BottomSheetComponent.wasTriggeredRecently = true;
    } else {
      setTimeout(() => {
        BottomSheetComponent.wasTriggeredRecently = false;
      }, 200);
    }
  }

  private async ShowBottomSheet() {
    const SheetPresenter: HTMLElement = this.elementRef.nativeElement;

    BottomSheetComponent.randomString = await this.MakeId(10);
    const ionRouter = window.document.querySelector(
      'ion-router-outlet'
    ) as HTMLElement;

    SheetPresenter.setAttribute(
      'id',
      `SheetPresenter${BottomSheetComponent.randomString}`
    );
    SheetPresenter.style.zIndex = '20000';
    SheetPresenter.getElementsByClassName(
      'BottomSheetBackdrop'
    )[0].id = `BottomSheetBackdrop${BottomSheetComponent.randomString}`;
    SheetPresenter.getElementsByClassName(
      'BottomSheetOuter'
    )[0].id = `BottomSheetOuter${BottomSheetComponent.randomString}`;

    await ionRouter.parentNode.insertBefore(SheetPresenter, ionRouter);

    const BottomSheetOuter = window.document.getElementById(
      `BottomSheetOuter${BottomSheetComponent.randomString}`
    ) as HTMLElement;
    const Backdrop = window.document.getElementById(
      `BottomSheetBackdrop${BottomSheetComponent.randomString}`
    ) as HTMLElement;
    const Content = window.document.getElementsByClassName(
      'BottomSheetContent'
    )[0] as HTMLElement;
    if (this.Fullscreen) {
      Content.classList.add('Fullscreen');
    }
    if (this.state === SheetStates.Opened) {
      this.OpenBottomSheet(BottomSheetOuter, true);
    } else if (this.state === SheetStates.Docked) {
      this.DockBottomSheet(BottomSheetOuter);
    }

    BottomSheetOuter.classList.remove('HideBottomSheetOuter');
    Backdrop.classList.remove('HideBackdrop');
    BottomSheetOuter.classList.add('ShowBottomSheetOuter');

    if (!this.ShowBackdrop) {
      Backdrop.style.opacity = '0';
    }

    Backdrop.classList.add('ShowBackdrop');
  }

  private OpenBottomSheet(bottomSheetOuter: HTMLElement, isInit) {
    if (isInit) {
      bottomSheetOuter.style.overflowY = 'hidden';
    }

    const height = 'auto'; //this.platform.width() >= 768 ? "100vh" : "200vh";
    const tick =
      /* this.platform.is("ios") ? `calc(${height} - 1px)` : */ height;

    bottomSheetOuter.style.height = tick;
    bottomSheetOuter.scrollTop = this.platform.height();
    setTimeout(() => {
      bottomSheetOuter.style.height = height;
    });
    let count = 0;
    const intervalRef = setInterval(() => {
      bottomSheetOuter.style.height = tick;
      bottomSheetOuter.scrollTop = this.platform.height();
      setTimeout(() => {
        bottomSheetOuter.style.height = height;
      });
      count++;
      if (count === (isInit ? 2 : 1)) {
        if (isInit) {
          bottomSheetOuter.style.overflowY = 'auto';
        }
        clearInterval(intervalRef);
      }
    }, 300);
  }

  public scrollToTop() {
    const BottomSheetOuter = window.document.getElementById(
      `BottomSheetOuter${BottomSheetComponent.randomString}`
    ) as HTMLElement;
    if (this.state === SheetStates.Opened) {
      this.OpenBottomSheet(BottomSheetOuter, false);
    }
  }

  private DockBottomSheet(bottomSheetOuter: HTMLElement) {
    bottomSheetOuter.scrollTop = document.body.scrollHeight;
  }

  private async CloseBottomSheet() {
    return new Promise<boolean>((resolve, reject) => {
      try {
        console.log('tring to close bottom sheet');
        const BottomSheetOuter = window.document.getElementById(
          `BottomSheetOuter${BottomSheetComponent.randomString}`
        ) as HTMLElement;
        const Backdrop = window.document.getElementById(
          `BottomSheetBackdrop${BottomSheetComponent.randomString}`
        ) as HTMLElement;
        if (BottomSheetOuter != null) {
          Backdrop.classList.replace('ShowBackdrop', 'HideBackdrop');
          BottomSheetOuter.classList.replace(
            'ShowBottomSheetOuter',
            'HideBottomSheetOuter'
          );

          setTimeout(() => {
            BottomSheetOuter.scrollTop = 0;
            const SheetPresenter = document.querySelector(
              `bottom-sheet#SheetPresenter${BottomSheetComponent.randomString}`
            );
            if (SheetPresenter) {
              try {
                const ionApp = SheetPresenter.parentElement;
                ionApp.removeChild(SheetPresenter);
              } catch (e) {
                console.log('ERROR', e);
              }
            }
            resolve(true);
          }, 400);
        } else {
          resolve(true);
        }
      } catch (e) {
        console.error(
          `ERROR IN BOTTOM-SHEET COMPONENT WHILE DISMISSING CARD: ${e}`
        );
        reject(e);
      }
    });
  }

  private MakeId(length) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public ChangeBottomSheetStateToClosed() {
    this.setState(SheetStates.Closed);
  }

  public PreventClose(event) {
    event.stopPropagation();
  }
}

export enum SheetStates {
  Closed = 0,
  Docked = 1,
  Opened = 2,
}
