import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { base64StringToBlob } from 'blob-util';
import { isEqual } from 'lodash';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { FileManagerService } from './file-manager.service';
import { LoggerService } from './logger.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class WeightProgressService {
  private isSyncWeightProgressPhotos: boolean = false;

  constructor(
    private storageServ: StorageService,
    private fireDB: AngularFirestore,
    private afStorage: AngularFireStorage,
    private loggerService: LoggerService,
    private fileManagerService: FileManagerService,
    private userService: UserService
  ) {}

  getUserWeightProgress() {
    const userUID = { ...this.userService.getSyncUserOnce() }?.uid;
    if (userUID) {
      const promis = this.userService.networkStatus
        ? this.fireDB
            .collection('UsersWeightProgress')
            .doc(userUID)
            .ref.get()
            .then((data) => {
              return this.syncWeightProgress(data);
            })
        : Promise.reject('getUserWeightProgress -> offline');

      return promis
        .catch(async (error) => {
          this.loggerService.logError(error);
          const result = await this.storageServ.get('UsersWeightProgress');
          return Promise.resolve({
            data: () => {
              return result && result[userUID] ? result[userUID] : {};
            },
          });
        })
        .then(async (data) => {
          const checkFiles = [];
          const result = data.data() || {};
          Object.keys(result).forEach((key) => {
            result[key].filePath = '';
          });
          Object.keys(result).forEach((key) => {
            if (result[key].imageUrl) {
              checkFiles.push(`user_${userUID}_file_${key}.jpeg`);
            }
          });
          const response =
            (await this.fileManagerService.checkWeightProgressImage(
              checkFiles
            )) as Array<any>;
          (Array.isArray(response) ? response : []).forEach((info) => {
            if (info.status) {
              const id = info.path.substring(
                info.path.lastIndexOf('file_') + 5,
                info.path.lastIndexOf('.jpeg')
              );
              result[id].filePath = info.fullPath;
            }
          });
          return Promise.resolve({
            data: () => {
              return result;
            },
          });
        });
    }
    return Promise.resolve({
      data: () => {
        return {};
      },
    });
  }

  async getCurrentUserWeightProgress() {
    console.log('UserService::getCurrentUserWeightProgress()');
    const data = await this.getUserWeightProgress();
    console.log('User weight progress', data);
    const list = data.data()
      ? (Object.values(data.data()) as any)
      : ([] as any);

    list.sort((a, b) =>
      new Date(a.date).getTime() > new Date(b.date).getTime() ? -1 : 1
    );
    if (list.length) {
      await this.userService.setSyncUser({
        currentUserWeight: {
          ...list[0],
          startWeightKg: list.length ? list[list.length - 1].weightKg : null,
          startWeightPound: list.length
            ? list[list.length - 1].weightPound
            : null,
        },
      });
      return list[0];
    }
    return null;
  }

  async setInitialUserWeight(data) {
    console.log('setInitialUserWeight::', data);
    if (
      Capacitor.getPlatform() !== 'web' &&
      data.weightPound &&
      data.weightKg
    ) {
      try {
        await this.addWeightItem({
          date: new Date(),
          weightPound: data.weightPound,
          weightKg: data.weightKg,
        });
        await this.getCurrentUserWeightProgress();
      } catch (error) {
        console.log('ERROR::setInitialUserWeight::', data, error);
        this.loggerService.logError(error);
      }
    }
  }

  async addWeightItem(data) {
    data.date = moment(data.date).utc().toISOString();
    const userUID = { ...this.userService.getSyncUserOnce() }?.uid;
    const timeID = data.id ? data.id : Date.now();
    data.id = timeID;

    const weightData = await this.getUserWeightProgress();
    const result = weightData.data() || {};

    if (data.imageUrl && data.imageUrl.startsWith('data:image')) {
      console.log(
        'UserService::addWeightItem()',
        'upload weight progress image'
      );
      const file = base64StringToBlob(
        data.imageUrl.split(',')[1],
        'image/jpeg'
      );
      console.log('UserService::addWeightItem()', 'start uploading');

      await this.fileManagerService
        .saveProgressImage(`user_${userUID}_file_${timeID}`, file)
        .catch((error) => this.loggerService.logError(error));

      if (this.userService.networkStatus) {
        this.userService.uploadRef = this.afStorage.upload(
          `/users/progress/${userUID}/file_${timeID}`,
          file
        );
        return this.userService.uploadRef.then(async () => {
          const ref = this.afStorage.ref(
            `/users/progress/${userUID}/file_${timeID}`
          );
          const url = await ref.getDownloadURL().pipe(take(1)).toPromise();
          console.log('UserService::syncImage()', 'image uploaded');
          data.imageUrl = url;

          result[timeID] = data;
          result[timeID].sync = true;
          await this.fireDB
            .collection('UsersWeightProgress')
            .doc(userUID)
            .set(result);

          await this.storageServ.set('UsersWeightProgress', {
            [userUID]: result,
          });

          return this.getCurrentUserWeightProgress().then((res) => {
            this.removeAllUnusedProgressPhotos();
            this.userService.setSyncUser({ weightProgressInit: true });
            return res;
          });
        });
      } else {
        console.log('UserService::syncImage()', 'offline');
        data.imageUrl = 'local';

        result[timeID] = data;
        result[timeID].sync = false;
        await this.storageServ.set('UsersWeightProgress', {
          [userUID]: result,
        });

        return this.getCurrentUserWeightProgress().then((res) => {
          this.removeAllUnusedProgressPhotos();
          this.userService.setSyncUser({ weightProgressInit: true });
          return res;
        });
      }
    }

    result[timeID] = data;
    console.log('UserService::addWeightItem()::add');

    if (this.userService.networkStatus) {
      result[timeID].sync = true;
      await this.fireDB
        .collection('UsersWeightProgress')
        .doc(this.userService.getSyncUserOnce()?.uid)
        .set(result);
    } else {
      result[timeID].sync = false;
    }

    await this.storageServ.set('UsersWeightProgress', {
      [this.userService.getSyncUserOnce()?.uid]: result,
    });

    return this.getCurrentUserWeightProgress().then((res) => {
      this.removeAllUnusedProgressPhotos();
      this.userService.setSyncUser({ weightProgressInit: true });
      return res;
    });
  }

  async syncWeightProgress(serverList) {
    console.log('UserService::syncWeightProgress()::sync');
    const userUID = { ...this.userService.getSyncUserOnce() }?.uid;
    const data = await this.storageServ.get('UsersWeightProgress');
    const result = data && data[userUID] ? data[userUID] : {};
    const sList = serverList.data() || {};
    const uploadList = [];

    Object.keys(result).forEach((key) => {
      if (result[key].imageUrl === 'local') {
        uploadList.push(key);
      }
      if (!result[key].sync) {
        sList[key] = { ...result[key], sync: true };
      }
    });

    if (uploadList.length) {
      this.uploadWeightProgressPhotos(uploadList);
    }
    if (serverList && isEqual(sList, serverList?.data())) {
      return {
        data: () => {
          return sList;
        },
      };
    }

    await this.storageServ.set('UsersWeightProgress', { [userUID]: sList });
    return await this.fireDB
      .collection('UsersWeightProgress')
      .doc(userUID)
      .set(sList, { merge: true })
      .then(() =>
        Promise.resolve({
          data: () => {
            return sList;
          },
        })
      );
  }

  uploadWeightProgressPhotos(ids) {
    if (this.isSyncWeightProgressPhotos) {
      return;
    }
    const userUID = { ...this.userService.getSyncUserOnce() }?.uid;
    console.log('UserService::uploadWeightProgressPhotos()', ids);
    this.isSyncWeightProgressPhotos = true;
    this.fileManagerService
      .getAllFilesWhichStartWith(`user_${userUID}_file_`)
      .then(async (list: Array<any>) => {
        const uploadList = [];
        list.forEach((item) => {
          ids.forEach((id) => {
            if (`user_${userUID}_file_${id}.jpeg` === item.name) {
              uploadList.push({
                id,
                pathName: item.name,
              });
            }
          });
        });

        const promises = [];
        if (uploadList.length) {
          uploadList.forEach((item) => {
            promises.push(
              new Promise((resolve, reject) => {
                const path = `/users/progress/${userUID}/file_${item.id}`;

                const fullPath = 'content/';
                Filesystem.readFile({
                  directory: Directory.Data,
                  path: fullPath + item.pathName,
                })
                  .then((base64) => {
                    const file = base64StringToBlob(
                      (base64?.data as string).split(',')[1],
                      'image/jpeg'
                    );
                    return this.afStorage.upload(path, file);
                  })
                  .then(async () => {
                    const ref = this.afStorage.ref(path);
                    const url = await ref
                      .getDownloadURL()
                      .pipe(take(1))
                      .toPromise();
                    console.log(
                      'UserService::uploadWeightProgressPhotos()',
                      'image uploaded',
                      url
                    );
                    resolve({
                      id: item.id,
                      url,
                    });
                  })
                  .catch((error) => {
                    resolve({
                      id: '',
                    });
                  });
              })
            );
          });
        }

        const result = await Promise.all(promises);
        console.log(
          'UserService::uploadWeightProgressPhotos()::uploadList',
          result
        );

        const weightData = await this.getUserWeightProgress();
        const userWeights = weightData.data() || {};

        result.forEach((item) => {
          if (item.id && item.url) {
            userWeights[item.id].imageUrl = item.url;
          }
        });

        await this.fireDB
          .collection('UsersWeightProgress')
          .doc(userUID)
          .set(userWeights);

        await this.storageServ.set('UsersWeightProgress', {
          [userUID]: userWeights,
        });

        this.isSyncWeightProgressPhotos = false;
      })
      .catch((error) => {
        this.isSyncWeightProgressPhotos = false;
        this.loggerService.logError(error);
      });
  }

  async removeAllUnusedProgressPhotos() {
    const weightData = await this.getUserWeightProgress();
    const result = weightData.data() || {};
    console.log('UserService::removeAllUnusedProgressPhotos()');
    const userUID = { ...this.userService.getSyncUserOnce() }?.uid;

    this.fileManagerService
      .getAllFilesWhichStartWith(`user_${userUID}_file_`)
      .then((list: Array<any>) => {
        list.forEach((item) => {
          Object.keys(result).forEach((id) => {
            if (
              `user_${userUID}_file_${id}.jpeg` === item.name &&
              !result[id].imageUrl
            ) {
              this.fileManagerService
                .deleteWeightProgressPhoto(item.name)
                .catch((error) => {
                  this.loggerService.logError(error);
                });
              console.log(
                'UserService::removeAllUnusedProgressPhotos()::delete(local):',
                item.name
              );
            }
          });
        });
      })
      .catch((error) => {
        this.loggerService.logError(error);
      });

    this.afStorage.storage
      .ref(`/users/progress/${userUID}`)
      .listAll()
      .then((list) => {
        list.items.forEach((imageRef) => {
          Object.keys(result).forEach((id) => {
            if (`file_${id}` === imageRef.name && !result[id].imageUrl) {
              this.afStorage
                .ref(imageRef.fullPath)
                .delete()
                .toPromise()
                .catch((error) => {
                  this.loggerService.logError(error);
                });
              console.log(
                'UserService::removeAllUnusedProgressPhotos()::delete(remote):',
                imageRef.fullPath
              );
            }
          });
        });
      })
      .catch((error) => {
        this.loggerService.logError(error);
      });
  }

  async downloadWeightProgressPhotos() {
    const weightData = await this.getUserWeightProgress();
    const result = weightData.data();
    if (result && Object.keys(result).length) {
      const items = [];
      Object.keys(result).forEach((key) => {
        if (result[key].imageUrl && !result[key].filePath) {
          items.push({
            url: result[key].imageUrl,
            fileName: `user_${
              this.userService.getSyncUserOnce()?.uid
            }_file_${key}.jpeg`,
          });
        }
      });
      if (items.length) {
        this.fileManagerService.downloadWeightProgressPhotos(items);
      }
    }
  }

  async deleteWeight(id) {
    console.log('UserService::deleteWeight()', 'start deleting');
    const data = await this.getUserWeightProgress();
    const result = data.data();

    if (result[id] && result[id].imageUrl) {
      this.afStorage
        .ref(
          `/users/progress/${
            this.userService.getSyncUserOnce()?.uid
          }/file_${id}`
        )
        .delete()
        .toPromise()
        .catch((error) => {
          this.loggerService.logError(error);
        });
    }

    delete result[id];

    this.fireDB
      .collection('UsersWeightProgress')
      .doc(this.userService.getSyncUserOnce()?.uid)
      .set(result);
  }
}
