import { Injectable } from '@angular/core';
import { AlertButton, AlertController, ToastController } from '@ionic/angular';
import { TranslatePipe } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private isAlertActive: boolean;

  language: any;

  constructor(
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private translateConfigService: TranslateConfigService,
    private translatePipe: TranslatePipe
  ) {
    this.language = this.translateConfigService.getCurrentLang();
  }

  async showSuccess(message: string, header = 'Info'): Promise<any> {
    if (this.isAlertActive) {
      return Promise.resolve();
    }
    this.isAlertActive = true;
    const alert = await this.alertController.create({
      header: this.translatePipe.transform(header),
      message: this.translatePipe.transform(message),
      buttons: [this.translatePipe.transform('ok')],
      animated: true,
      keyboardClose: true,
      cssClass: 'custom-alert',
    });

    await alert.present();
    return alert.onWillDismiss().then(() => (this.isAlertActive = false));
  }

  async showError(message: string, header = 'error'): Promise<any> {
    if (this.isAlertActive) {
      return Promise.resolve();
    }
    this.isAlertActive = true;
    let buttons: AlertButton[] = [
      { text: this.translatePipe.transform('ok'), role: 'cancel' },
    ];
    if (
      message ==
      'An account already exists with this email address. Please sign in'
    ) {
      buttons.push({
        text: this.translatePipe.transform('log in'),
        role: 'login',
      });
    }
    const alert = await this.alertController.create({
      header: this.translatePipe.transform(header),
      message: this.translatePipe.transform(message),
      buttons,
      animated: true,
      keyboardClose: true,
      cssClass: 'custom-alert',
    });

    await alert.present();
    return alert.onWillDismiss().then((res) => {
      this.isAlertActive = false;
      return res;
    });
  }

  async presentSubscribedToast() {
    const toast = await this.toastCtrl.create({
      message: this.translatePipe.transform(
        'Successfully upgraded to premium version! Thanks for your support. Enjoy your LifeBuddy fitness journey!'
      ),
      position: 'top',
      cssClass: 'subscribed',
      duration: 5000,
      buttons: [
        {
          icon: 'checkmark-circle',
          cssClass: 'subscribed-toast-button',
          side: 'start',
        },
      ],
    });

    await toast.present();
  }

  async presentLinkAccountToast() {
    const toast = await this.toastCtrl.create({
      message: this.translatePipe.transform(
        'Your account has successfully been linked!'
      ),
      position: 'top',
      cssClass: 'subscribed',
      duration: 5000,
      buttons: [
        {
          icon: 'checkmark-circle',
          cssClass: 'subscribed-toast-button',
          side: 'start',
        },
      ],
    });

    await toast.present();
  }
}
