<ion-content fullscreen="true">
  <div class="header-container">
    <ion-toolbar mode="ios">
      <ion-buttons slot="start" (click)="goBack()" mode="ios">
        <ion-back-button
          *ngIf="!modal"
          text
          style="pointer-events: none; height: 48px; width: 48px"
          mode="ios"
          icon="assets/newUI/icons/backBtnBlack.svg"
          defaultHref="/home/profile"
        ></ion-back-button>
        <ion-button *ngIf="modal">
          <ion-icon [src]="'assets/newUI/icons/backBtnBlack.svg'"></ion-icon>
        </ion-button>
      </ion-buttons>

      <ion-title color="light">Terms and Conditions</ion-title>
    </ion-toolbar>
  </div>

  <div class="content-container">
    <div class="ion-text-center ion-margin-bottom">
      <img draggable="false" class="logo" />
    </div>

    <div class="header-text">LifeBuddy Terms and Conditions of Use</div>
    <div class="sub-text">
      <p>
        These Terms and Conditions of Use (the “Agreement”) set out the terms on
        which LifeBuddy LLC (“LifeBuddy” or “we”) will provide LifeBuddy
        software and services on the LifeBuddy website and through LifeBuddy's
        mobile device applications (collectively, the “Service”) to you, a user
        of the Service (“you” or “User”).
      </p>

      <p>
        By providing your email address and creating an account as a User of the
        Service, or by otherwise using the Service, you agree to comply with and
        be bound by this Agreement, which we may change at any time by posting
        notice on the Service.
      </p>

      <p>
        PLEASE READ THIS AGREEMENT CAREFULLY, AND PLEASE CHECK THESE TERMS AND
        CONDITIONS PERIODICALLY FOR CHANGES. If you do not agree to these terms
        and conditions, please do not use the Service.
      </p>

      <u>Registration</u>

      <p>
        By using the Service, you represent and warrant to LifeBuddy that: (i)
        you are of legal age to form a binding contract; (ii) you will provide
        LifeBuddy with accurate, current and complete registration information;
        and (iii) your registration and your use of the Service is not
        prohibited by law.
      </p>

      <p>
        When you create an account with us, you guarantee that you are above the
        age of 18, and that the information you provide us is accurate,
        complete, and current at all times. Inaccurate, incomplete, or obsolete
        information may result in the immediate termination of your account on
        the Service.
      </p>

      <p>
        You are responsible for maintaining the confidentiality of your account
        and password, including but not limited to the restriction of access to
        your computer and/or account. You agree to accept responsibility for any
        and all activities or actions that occur under your account and/or
        password, whether your password is with our Service or a third-party
        service. You must notify us immediately upon becoming aware of any
        breach of security or unauthorized use of your account.
      </p>

      <p>
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than you,
        without appropriate authorization. You may not use as a username any
        name that is offensive, vulgar or obscene.
      </p>

      <p>
        We reserve the right to refuse service, terminate accounts, remove or
        edit content in our sole discretion.
      </p>

      <u>Minors</u>

      <p>
        The Service is not directed at Users under the age of 18. If you are
        under the age of 18, you are not permitted to register as a User or use
        the Service.
      </p>

      <u> Ownership of the Service and Related Intellectual Property </u>

      <p>
        The Service and its original content, features and functionality are and
        will remain the exclusive property of LifeBuddy. The Service is
        protected by copyright, trademark, and other laws of both the United
        States and foreign countries. No Service content, including our
        trademarks and trade dress, may be used in connection with any product
        or service without the prior written consent of LifeBuddy.
      </p>

      <u>License to Use the Service</u>

      <p>
        Subject to the terms of this Agreement, LifeBuddy authorizes you to use
        the Service for your personal, non-commercial purposes. The Service
        includes certain premium features and services that LifeBuddy offers for
        a fee (“Premium Services”); you are only authorized to use Premium
        Services if you have paid the applicable fees. You may not remove any
        copyright, trademark or other proprietary notices that have been placed
        in the Service. Except as expressly permitted above, modification,
        reproduction, redistribution, republication, uploading, posting,
        transmitting, distributing or otherwise exploiting in any way the
        Service, or any portion of the Service, is strictly prohibited without
        the prior written permission of LifeBuddy. The Service is licensed, not
        sold, and you obtain no rights in any copy of software related to the
        Service other than the rights described in this paragraph.
      </p>

      <p>
        You agree, and represent and warrant, that your use of the Service, or
        any portion thereof, will be consistent with the foregoing license,
        covenants and restrictions and will neither infringe nor violate the
        rights of any other party or breach any contract or legal duty to any
        other parties. In addition, you agree that you will comply with all
        applicable laws, regulations and ordinances relating to the Service or
        your use of it, and you will be solely responsible for your own
        individual violations of any of any such laws.
      </p>

      <p>
        You are solely responsible for obtaining the equipment and
        telecommunication services necessary to access the Service, and all fees
        associated therewith (such as computing devices and Internet service
        provider and airtime charges).
      </p>

      <p>
        You acknowledge and agree that the availability of the Services is
        dependent on the third party platform from which you access the
        Services, e.g., the Apple iPhone or Android app stores (“App Store”).
        You acknowledge that this Agreement is between you and LifeBuddy and not
        with the App Store. LifeBuddy, not the App Store, is solely responsible
        for the Services, the content thereof, maintenance, support services,
        and warranty therefor, and addressing any claims relating thereto. You
        also agree to pay all fees (if any) charged by the App Store in
        connection with the Services. You agree to comply with, and your license
        to use the Services is conditioned upon your compliance with, all
        applicable third-party terms of agreement (e.g., the App Store’s terms
        and policies) when using the Services. You acknowledge that the App
        Store (and its subsidiaries) are third-party beneficiaries of this
        Agreement and will have the right to enforce it to the extent the rights
        of the operator of any App Store are affected hereby.
      </p>

      <p>
        We retain the right to implement any changes to the Service (whether to
        unpaid or Premium Services) at any time, with or without notice. You
        acknowledge that a variety of LifeBuddy actions may impair or prevent
        you from accessing the Service at certain times and/or in the same way,
        for limited periods or permanently, and agree that LifeBuddy has no
        responsibility or liability as a result of any such actions or results,
        including, without limitation, for the deletion of, or failure to make
        available to you, any content or services.
      </p>

      <u>Payment</u>

      <p>
        <u>Fees and Premium Services.</u>
        We reserve the right, at our sole discretion, to modify or adjust
        current and future pricing models for the use of our service, at any
        time.
      </p>

      <p>
        By using the Service, you agree to any new terms that may result in an
        increase for its subscription services effective the first day of a
        billing renewal. Prior to any subscription increase we will notify you
        when we deem is an appropriate time. If you do not cancel your
        subscription, you will be deemed to have accepted the new fees.
      </p>

      <p>
        If you purchase a subscription through an App Store application, the
        sale is final, and we will not provide a refund. Your purchase will be
        subject to the App Store’s applicable payment policy, which also may not
        provide for refunds. To cancel or dispute any charges you will need to
        communicate with the platform (iTunes, Google Play) of which that app
        and payment was charged.
      </p>

      <p>
        <u>Automatic Renewal Options.</u>
        By signing up for certain subscriptions, you agree that your
        subscription will be automatically renewed. Unless you cancel your
        subscription, you authorize us to charge your payment method for the
        renewal term. The period of auto-renewal will be the same as your
        initial subscription period unless otherwise disclosed to you at the
        time of subscription purchase. The renewal rate will be no more than the
        rate for the immediately prior subscription period, excluding any
        promotional and discount pricing, unless we notify you of a rate change
        prior to your auto-renewal. You must cancel your subscription in
        accordance with the cancellation procedures disclosed to you for the
        particular subscription.
      </p>

      <p>
        We will not refund fees that may have accrued to your account and will
        not prorate fees for a cancelled subscription.
      </p>

      <p>
        <u>Trials.</u>
        From time to time, we may offer a free trial subscription for a Premium
        Service. If you register for a free trial subscription, we will begin to
        bill your account when the free trial subscription expires, unless you
        cancel your subscription before that time.
      </p>

      <u>Links to Other Web Sites</u>

      <p>
        Our Service may contain links to third party web sites or services that
        are not owned or controlled by LifeBuddy.
      </p>

      <p>
        LifeBuddy has no control over, and assumes no responsibility for the
        content, privacy policies, or practices of any third party web sites or
        services. We do not warrant the offerings of any of these
        entities/individuals or their websites.
      </p>

      <p>
        You acknowledge and agree that LifeBuddy shall not be responsible or
        liable, directly or indirectly, for any damage or loss caused or alleged
        to be caused by or in connection with use of or reliance on any such
        content, goods or services available on or through any such third party
        web sites or services.
      </p>

      <p>
        We strongly advise you to read the terms and conditions and privacy
        policies of any third party web sites or services that you visit.
      </p>

      <u> Warranty Disclaimers, Limitations of Liability and Indemnity </u>

      <p>
        You acknowledge that your diet and exercise activities involve risks,
        which may involve risk of bodily injury or death, and that you assume
        those risks. You should consult a licensed/certified healthcare
        professional prior to beginning or modifying any diet or exercise
        program that you undertake, and you acknowledge that LifeBuddy has
        advised you of the necessity for obtaining such consultations. The
        Service should not be used by pregnant women. The Service is a source of
        information, but it does not provide medical advice. LifeBuddy makes no
        representation that LifeBuddy is a provider of medical services as
        defined by federal and state laws and regulations pertaining to medical
        providers and other health care related matters, or that LifeBuddy has
        any obligations with respect to (a) the appropriateness of your engaging
        in a weight loss and/or exercise program; (b) the results (or lack of
        results) obtained by your use of the Services; and (c) any
        health-related matters arising in connection with your use of LifeBuddy.
        In no event shall LifeBuddy be liable for any death or bodily injury
        that you suffer, or that you cause to any third party, in connection
        with your use of the Service or any diet, exercise or other activity you
        undertake in connection with your use of the Service.
      </p>

      <p>
        WITHOUT LIMITATION OF THE FOREGOING, LIFEBUDDY AND LIFEBUDDY AFFILIATES
        MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING THE SERVICE
        OR USER CONTENT. THE SERVICE AND USER CONTENT ARE PROVIDED IN “AS-IS”
        CONDITION, AND LIFEBUDDY AND LIFEBUDDY AFFILIATES EXPRESSLY DISCLAIM ANY
        AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED TO THE EXTENT PERMITTED
        BY LAW, INCLUDING: ALL WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, TITLE, NONINFRINGEMENT, AND ANY AND ALL WARRANTIES
        ARISING FROM COURSE OF DEALING AND USAGE OF TRADE; THAT THE SERVICE AND
        USER CONTENT WILL MEET YOUR REQUIREMENTS, WILL ALWAYS BE AVAILABLE,
        ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE OR OPERATE WITHOUT ERROR, AS
        TO THE RESULTS THAT MAY BE OBTAINED FROM THE OPERATION, USE OR OTHER
        EXPLOITATION OF THE SERVICE AND USER CONTENT, AND AS TO THE ACCURACY OR
        RELIABILITY OF ANY INFORMATION OBTAINED FROM THE SERVICE OR THE USER
        CONTENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY
        YOU FROM LIFEBUDDY OR ANY LIFEBUDDY AFFILIATE OR THROUGH THE SERVICE OR
        USER CONTENT WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.
      </p>

      <p>
        YOU USE THE SERVICE AND USER CONTENT AT YOUR OWN RISK, AND NEITHER
        LIFEBUDDY NOR LIFEBUDDY AFFILIATES WILL BE LIABLE FOR ANY DAMAGES OF ANY
        KIND ARISING FROM OR RELATING TO ANY OF THEIR OPERATION, USE OR OTHER
        EXPLOITATION.
      </p>

      <p>
        UNDER NO CIRCUMSTANCES WILL YOU BE ENTITLED TO RECOVER FROM LIFEBUDDY OR
        LIFEBUDDY AFFILIATES ANY INCIDENTAL, CONSEQUENTIAL, INDIRECT, PUNITIVE
        OR SPECIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF
        PROFITS OR LOSS OF USE), WHETHER BASED ON CONTRACT, TORT (INCLUDING
        NEGLIGENCE), OR OTHERWISE ARISING FROM OR RELATING TO THE SERVICE OR
        USER CONTENT, EVEN IF LIFEBUDDY OR LIFEBUDDY AFFILIATES HAVE BEEN
        INFORMED OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
      </p>

      <p>
        THE MAXIMUM AGGREGATE LIABILITY OF LIFEBUDDY AND LIFEBUDDY AFFILIATES
        FOR ANY AND ALL DAMAGES ARISING IN CONNECTION WITH YOUR USE OF THE
        SERVICE OR THIS AGREEMENT SHALL BE LIMITED TO THE AMOUNT OF FEES PAID BY
        YOU TO LIFEBUDDY IN THE THREE (3) MONTHS BEFORE THE LIABILITY IS ALLEGED
        TO HAVE ARISEN, OR, IF NO AMOUNTS WERE PAID DURING SUCH PERIOD, THE
        AMOUNT OF $1. THIS LIMITATION WILL APPLY WHETHER THE DAMAGES ARE BASED
        ON CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE.
      </p>

      <p>
        Some jurisdictions do not allow the limitation or exclusion of
        warranties or of liability, so some of the above limitations or
        exclusions may not apply to you.
      </p>

      <p>
        You shall defend, indemnify and hold harmless LifeBuddy against any and
        all claims, actions, proceedings, suits, liabilities, losses, damages,
        costs, expenses and attorneys' fees arising in connection with your use
        of the Service or your breach of any provision of this Agreement.
        LifeBuddy reserves the right the assume the sole control of the defense
        and settlement of any claim, action, suit or proceeding for which you
        are obliged to provide indemnification hereunder. You will cooperate
        with LifeBuddy with respect to such defense and settlement.
      </p>

      <u>Privacy</u>

      <p>
        LifeBuddy operates the Service under the Privacy Policy published at
        <a routerLink="/privacy-policy" routerDirection="forward"> LINK </a>
        . We urge you to read this policy now and periodically because the
        policy is updated from time to time. If we make material changes, as
        determined by us, in the way in which personal information is collected,
        used or transferred, we will notify you of these changes by email and by
        posting a modified version of this Privacy Policy on our website. Your
        continued use of the Service following any changes means that you accept
        those changes.
      </p>

      <u>Cloud Services and Third Party Services</u>

      <p>
        <u>Cloud Services.</u>
        You acknowledge and agree as follows: (a) we provide the Service using
        cloud computing services of one or more third party cloud providers
        (collectively, the “Cloud Providers”); (b) the price at which we could
        afford to offer the Service would vary if we provided the Service other
        than using such cloud services; and (c) we shall not be responsible or
        liable to you for any act, omission or failure of any Cloud Provider.
      </p>

      <p>
        <u>Third Party Services.</u>
        The Service may depend upon, interact with or enable access to the
        information, other content, services or websites of third parties (each,
        a “Third Party Service”), which may in each case be accompanied by
        separate terms of use. Use of each Third Party Service may require that
        you accept additional terms of use. You must comply with the applicable
        terms of use when using the Third Party Service and the Service.
      </p>

      <u>Governing Law</u>

      <p>
        The Agreement, and all future agreements you shall enter into with
        LifeBuddy, unless otherwise indicated on such other agreement, shall be
        governed by the law of the State of Tennessee, without regard to
        conflicts of law principles thereof. This is the case regardless of
        whether you reside or transact business with LifeBuddy or any of its
        affiliates or agents in the State of Tennessee or elsewhere. You agree
        to submit to the personal and exclusive jurisdiction of the courts
        located within the county of Davidson in Tennessee.
      </p>

      <u>Miscellaneous Provisions</u>

      <p>
        No delay or omission by LifeBuddy in exercising any of its rights
        occurring upon any noncompliance or default by you with respect to this
        Agreement will impair any such right or be construed to be a waiver
        thereof, and a waiver by LifeBuddy of any of the covenants, conditions
        or agreements to be performed by you will not be construed to be a
        waiver of any succeeding breach thereof or of any other covenant,
        condition or agreement hereof contained.
      </p>

      <p>
        As used in this Agreement, “including” means “including but not limited
        to.”
      </p>

      <p>
        If any provision of this Agreement is found by a court of competent
        jurisdiction to be invalid or unenforceable, then this Agreement will
        remain in full force and effect and will be reformed to be valid and
        enforceable while reflecting the intent of the parties to the greatest
        extent permitted by law.
      </p>

      <p>
        Except as otherwise expressly provided herein, this Agreement sets forth
        the entire agreement between you and LifeBuddy regarding its subject
        matter, and supersedes all prior promises, agreements or
        representations, whether written or oral, regarding such subject matter.
      </p>

      <p>
        You agree that the electronic text of this Agreement constitutes a
        writing and your use of the Services and assent to the terms and
        conditions hereof constitutes a “signing” for all purposes.
      </p>

      <p>
        In no event shall LifeBuddy be liable for any failure to comply with
        this Agreement to the extent that such failure arises from factors
        outside LifeBuddy's reasonable control.
      </p>

      <u>Contact Information</u>

      <p>
        We welcome your input on our Terms and Conditions of Use. If you have
        any questions or suggestions regarding our Terms and Conditions of Use,
        please contact LifeBuddy at
        <a (click)="mailTo()">support@lifebuddymobileapp.com</a>
        .
      </p>
    </div>
  </div>
</ion-content>
