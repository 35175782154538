import { Component, OnDestroy } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

import { AppAvailability } from '@ionic-native/app-availability/ngx';

import { Subscription } from 'rxjs';

import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-spotify',
  templateUrl: './spotify.component.html',
  styleUrls: ['./spotify.component.scss'],
})
export class SpotifyComponent implements OnDestroy {
  playlists = [
    {
      id: '0nHv1KtTqZCPLFvybRfl8q',
      image:
        'https://mosaic.scdn.co/300/ab67616d0000b27309cd3b75625ff85382613425ab67616d0000b2733d7a85786292feb040e66cccab67616d0000b273952fa1e1192eaafa15f68d92ab67616d0000b273c0e7bf5cdd630f314f20586a',
      title: 'Top_Rap_Songs',
    },
    {
      id: '7KWePoFLP3tJQGCrPESqEk',
      image:
        'https://mosaic.scdn.co/300/ab67616d0000b27316667761b90f600c1d7b08e6ab67616d0000b2738663058486e0dc76dea96fd3ab67616d0000b273d5fa46459e5489190606f47fab67616d0000b273e16015c3852fce23898e9db1',
      title: 'Rap_Songs',
    },
    {
      id: '2eJakSqVFHs3600q8TNpUi',
      image:
        'https://mosaic.scdn.co/300/ab67616d0000b2730f206fe737d46f2f2d826d83ab67616d0000b27358599e61e8a0487443d31bb6ab67616d0000b2739c188c494d8bfaf895411890ab67616d0000b273de048a43d03fb53a466c1c9f',
      title: "Let's_Workout",
    },
    {
      id: '7zWEzo4YHjK4CuYPuEvj0R',
      image:
        'https://mosaic.scdn.co/300/ab67616d0000b2735901aaa980d3e714bf01171cab67616d0000b27389b56f56323925d57b38944dab67616d0000b2739e884b7922a905cf2df7db50ab67616d0000b273adb5dbf4251255a00146d8eb',
      title: 'Hip_Hop',
    },
    {
      id: '5O3GFQEalJLMTRSfz2fuQ2',
      image:
        'https://mosaic.scdn.co/300/ab67616d0000b2734637341b9f507521afa9a778ab67616d0000b2738f9880cf20ea75676ab96ac3ab67616d0000b27399581550ef9746ca582bb3ccab67616d0000b273aff4aef671b2510be7c115b3',
      title: 'All_Time_Hits',
    },
    {
      id: '6JfoDDUPSQXYWR7hZNw0T4',
      image:
        'https://mosaic.scdn.co/300/ab67616d0000b2732717c3966fae9e30a0f41aadab67616d0000b2735b7821446f938f70bce43afbab67616d0000b27386bd44e4b3e2eb335c17c625ab67616d0000b273b6ddf81b0d6a4c80ed803326',
      title: 'Top_Rock_Songs_2020',
    },
    {
      id: '7HoXKQkvNOAw9b8lqtxLN9',
      image:
        'https://mosaic.scdn.co/300/ab67616d0000b27305c5be85b64eaff732f7cb0bab67616d0000b2730b51f8d91f3a21e8426361aeab67616d0000b2739662c6535fb4bf5767e50f32ab67616d0000b273a496dc8c33ca6d10668b3157',
      title: 'Rock-N-Roll',
    },
    {
      id: '4wv9kDyuTDbA89BWvfAYrG',
      image:
        'https://mosaic.scdn.co/300/ab67616d0000b2733469ef94acfa666fde83dec5ab67616d0000b27355edae0d1a09c0f90351a0a7ab67616d0000b273ae63b58e90bef8888658ae21ab67616d0000b273bb7d33186e4a26421c6a77ae',
      title: 'Rock_Hard',
    },
    {
      id: '5bYXiT3wVaLoVRaOmZts3C',
      image:
        'https://mosaic.scdn.co/300/ab67616d0000b27362f0f15dfc1cec391dfe577cab67616d0000b273b685ff96bab37a27c35d2ac4ab67616d0000b273e58a0b8672f8c88ea6099cbaab67616d0000b273f58c468c711f44450a87cb42',
      title: 'Metallic',
    },
    {
      id: '72R4ODixV6lCbv1Ia0tyTJ',
      image:
        'https://mosaic.scdn.co/640/5d866ac1040c05ef8b6a5ac8353e5ec9d84b4f56ab67616d0000b2730c13d3d5a503c84fcc60ae94ab67616d0000b273232711f7d66a1e19e89e28c5ab67616d0000b2736eb0b9e73adcf04e4ed3eca4',
      title: 'Pop',
    },
  ];

  language: any;

  subscriptions: Subscription[] = [];

  constructor(
    private userService: UserService,
    private modalCtrl: ModalController,
    private appAvailability: AppAvailability,
    private platform: Platform,
    private translateConfigServ: TranslateConfigService
  ) {
    this.language = this.translateConfigServ.getCurrentLang();

    this.subscriptions.push(
      this.platform.backButton.subscribeWithPriority(200, () => {
        this.dismiss();
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  dismiss() {
    this.modalCtrl.dismiss().catch(() => {});
  }

  openSong(song) {
    let app;

    if (this.platform.is('ios')) {
      app = 'spotify://';
    } else if (this.platform.is('android')) {
      app = 'com.spotify.music';
    }

    this.appAvailability.check(app).then(
      (yes: boolean) => this.spotifyOpener(song.id),
      (no: boolean) => this.spotifyDownloader(app)
    );
  }

  spotifyOpener(id) {
    console.log('Spotify available');
    window.open(`spotify:playlist:${id}`, '_system');
  }

  spotifyDownloader(app) {
    console.log(app + ' is NOT available');
    if (this.platform.is('ios')) {
      window.open(
        'itms-apps://apps.apple.com/us/app/spotify-music-and-podcasts/id324684580',
        '_system'
      );
    } else if (this.platform.is('android')) {
      window.open('market://details?id=com.spotify.music', '_system');
    }
  }
}
