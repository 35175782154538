import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "weight",
})
export class WeightPipe implements PipeTransform {
  transform(value: string | number, ...args: unknown[]): string {
    if (typeof value == "number") {
      if (args[0] == "metric" && args[1] == "number") {
        const kgs = +Number(value) * 0.45359237;
        return kgs.toFixed((kgs * 10) % 10 > 0 ? 1 : 0);
      }
      return value.toFixed(1);
    } else if (typeof value == "string") {
      const arrStr = value?.toLowerCase().split(" ");
      const foundPoundsIndex = arrStr.findIndex(
        (str) => str == "pound" || str == "pounds" || str == "pounds!" || str == "lbs" || str == "pounds."
      );

      if (foundPoundsIndex != -1 && args[0] == "metric") {
        let originalArrStr = value.split(" ");
        arrStr.forEach((str, index) => {
          if (str.match(/\d+/)?.length > 0 && arrStr[index + 1] != "days,") {
            const weight = arrStr[index];
            let kgs = +Number(weight) * 0.45359237;
            if (args[1]) {
              kgs = this.roundHalf(kgs);
            }
            arrStr[index] = kgs.toFixed((kgs * 10) % 10 > 0 ? 1 : 0);
            originalArrStr[index] = arrStr[index];
          }
        });

        arrStr[foundPoundsIndex] = "kg";

        originalArrStr[foundPoundsIndex] = arrStr[foundPoundsIndex];

        return originalArrStr.join(" ");
      }
    }

    return value;
  }

  roundHalf(num) {
    return Math.round(num * 2) / 2;
  }
}
