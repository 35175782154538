<ion-content fullscreen="true">
  <div class="header-container">
    <div class="safe-area-bg-top"></div>

    <ion-toolbar mode="ios">
      <ion-buttons slot="start" mode="ios">
        <ion-button mode="ios" (click)="dismiss()">
          <ion-icon
            slot="icon-only"
            src="assets/newUI/icons/backBtnBlack.svg"
          ></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>

  <div class="content-container">
    <div
      id="pull-area"
      style="
        padding: calc(28vw + var(--ion-safe-area-top)) 0 2.5vw;
        position: relative;
      "
    >
      <div class="img-bg-wrap">
        <div
          class="img-bg"
          [style.background-image]="
            'linear-gradient(rgb(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgb(var(--ion-color-white-rgb)) 100%), url(assets/newUI/images/music.jpg)'
          "
        ></div>
      </div>

      <div style="padding: 1rem">
        <h2
          style="
            font-weight: 700;
            font-size: 36px;
            line-height: 22px;
            margin-bottom: 1.5rem;
          "
        >
          {{ "LifeBuddy_Music" | translate }}
        </h2>
        <p
          style="
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 0px;
          "
        >
          {{
            "Get in the workout mood with our carefully selected playlists!"
              | translate
          }}
        </p>
      </div>
    </div>

    <div class="newspotify-list">
      <a
        *ngFor="let playlist of playlists"
        (click)="openSong(playlist)"
        class="openplaylist-button"
        draggable="false"
      >
        <div style="display: flex; align-items: center">
          <img [src]="playlist.image" class="openplaylist-cover" />
          <span class="openplaylist-name">{{
            playlist.title | translate
          }}</span>
        </div>
        <ion-icon name="chevron-forward" style="color: #5b5f84"></ion-icon>
      </a>
    </div>
  </div>
</ion-content>

<!-- <div class="safe-area-bg-bottom"></div> -->
