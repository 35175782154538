import { Component, Input } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { TranslateConfigService } from "src/app/services/translate-config.service";

@Component({
  selector: "app-password-input",
  templateUrl: "./password-input.component.html",
  styleUrls: ["./password-input.component.scss"],
})
export class PasswordInputComponent {
  @Input() email: string;
  @Input() name: string;
  password: string;

  language: any;

  constructor(private popoverCtrl: PopoverController, private translateConfigServ: TranslateConfigService) {
    this.language = this.translateConfigServ.getCurrentLang();
  }

  cancel() {
    this.popoverCtrl.dismiss().catch(() => {});
  }

  link() {
    this.popoverCtrl.dismiss({ password: this.password }).catch(() => {});
  }
}
