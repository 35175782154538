import { getCurrencySymbol } from '@angular/common';
import {
  AfterContentInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import {
  CHOOSE_PLANS,
  SUB_OPTIONS_NO_ADS,
  WEB_CHOOSE_PLANS,
  userDesiredResultsData,
} from 'src/app/constants';
import { SubOption } from 'src/app/models/sale';
import { PrivacyPolicyPage } from 'src/app/pages/privacy-policy/privacy-policy.page';
import { RemoteConfigSale } from 'src/app/pages/profile/profile.page';
// import { RemoteConfigSale } from "src/app/pages/sales/sales.page";
import { Capacitor } from '@capacitor/core';
import { PaymentComponent } from 'src/app/modals/payment/payment.component';
import { SubscriptionTermsPage } from 'src/app/pages/subscription-terms/subscription-terms.page';
import { TermsAndConditionsPage } from 'src/app/pages/terms-and-conditions/terms-and-conditions.page';
import { WeightPipe } from 'src/app/pipes/weight.pipe';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { UserService } from 'src/app/services/user.service';
import SwiperCore, {
  EffectCoverflow,
  FreeMode,
  Lazy,
  Navigation,
  Pagination,
  SwiperOptions,
} from 'swiper';
import { bodyTypes } from '../../pages/onboarding/components/goal-body/goal-body.component';

SwiperCore.use([EffectCoverflow, Lazy, Pagination, Navigation, FreeMode]);
@Component({
  selector: 'app-sale-modal',
  templateUrl: './sale-modal.component.html',
  styleUrls: ['./sale-modal.component.scss'],
})
export class SaleModalComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild('paymentModal') paymentModal: PaymentComponent;

  stats: {
    before: {
      image: string;
    };
    after: {
      image: string;
    };
  };

  bodyFatRanges = [
    '10-14_slim_body',
    '15-19_fit_body',
    '20-24_mesomorph',
    '25-29_endomorph',
    '30-34',
    '35-39',
    '40+',
  ];
  // @ViewChild(IonSlides) slider: IonSlides;

  // @Input() plan;
  @Input() exerciseInfo;
  @Input() workouts;
  @Input() parent = 'onboarding';

  isWeb = Capacitor.getPlatform() == 'web';

  userPlan: string = '';

  CHOOSE_PLANS = this.isWeb ? WEB_CHOOSE_PLANS : CHOOSE_PLANS;

  language: any;

  public waitingLayer: boolean = false;

  isAndroid: boolean;
  waitingChecker: any; //NodeJS.Timeout;

  subOptions: Array<SubOption> = [];

  saleConfig: RemoteConfigSale;
  selectedSub: SubOption;

  isFreemium = false;

  canFreeTrial = true;

  subscriptions: Subscription[] = [];

  slideOpts: SwiperOptions = {
    speed: 300,
    pagination: false,
    slidesPerView: 'auto',
    freeMode: {
      enabled: this.isWeb && this.platform.is('desktop'),
      sticky: this.isWeb && this.platform.is('desktop'),
    },
  };

  screenshotSlideOpts: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    lazy: {
      enabled: true,
      loadPrevNext: true,
      loadPrevNextAmount: 5,
      loadOnTransitionStart: true,
    },
    preloadImages: false,
    watchSlidesProgress: true,
    centeredSlides: true,
    effect: 'coverflow',
    coverflowEffect: { scale: 0.9, rotate: 0, slideShadows: true },
    loop: true,
    pagination: true,
    navigation: true,
  };

  saleOptions: Array<any> = [
    {
      title:
        'What makes your app different? How do I know if it will work for me?',
      description: 'workout_program_explanation',
      condition: 'any',
    },
    {
      title: 'equipment',
      description: `You have chosen "Curvy Body Bootycamp" program, no equipment is required. While a yoga mat or soft ground is recommended for certain exercises, it's not essential. You also have the option to incorporate an armless chair into your routine by heading to profile, but again, it's completely optional.`,
      condition: 'Curvy Body Bootycamp',
    },
    {
      title: 'equipment',
      description: `You have chosen "ToneTastic Trek" program, no equipment is required. While a yoga mat or soft ground is recommended for certain exercises, it's not essential. You also have the option to incorporate an armless chair into your routine by heading to profile, but again, it's completely optional.`,
      condition: 'ToneTastic Trek',
    },
    {
      title: 'equipment',
      description:
        'You have chosen our "PoundPlummet Path" program and for this program you will need no equipment.',
      condition: 'PoundPlummet Path',
    },
    {
      title: 'equipment',
      description: `You have chosen "Wonderland of Wellness" program, no equipment is required. While a yoga mat or soft ground is recommended for certain exercises, it's not essential. You also have the option to incorporate an armless chair into your routine by heading to profile, but again, it's completely optional.`,
      condition: 'Wonderland of Wellness',
    },
    {
      title: 'equipment',
      description: `You have chosen "Bendy Body Boss" program, no equipment is required. While a yoga mat or soft ground is recommended for certain exercises, it's not essential. You also have the option to incorporate an armless chair into your routine by heading to profile, but again, it's completely optional.`,
      condition: 'Bendy Body Boss',
    },
    {
      title: 'diet',
      description:
        'You will see results either way, but with eating properly according to your goal, body type and current stats will definitely provide you the best results possible. For more details check out our Macro Calculator section and click on the information button.',
      condition: 'any',
    },
    {
      title: 'beginner_workouts',
      description:
        "My workouts are scalable to any fitness level. Plus, with easy-to-follow video demonstration on each and every exercise, you'll never be left wondering how to do a move!",
      condition: 'any',
    },
    {
      title: 'gym_membership',
      description:
        'A gym is not required, all of our workouts are designed to give you a gym quality workout from home in a fraction of the time.',
      condition: 'any',
    },
    {
      title: 'not_free',
      description:
        'This is the only way for us to make improvements in terms of adding more features, improving existing features, fixing bugs, designing, and developing. It helps create & maintain a better app that supports people from all over the world to achieve their fitness goals & dreams.',
      condition: 'any',
    },
  ];

  saleVideoUrl: any;
  salesThumbnail: string;
  salesVideoText: { title: string; description: string };

  isFullScreen = false;

  orientationSub: Subscription;
  iapSub: Subscription;

  currencySymbol = '$';

  constructor(
    public platform: Platform,
    private modalCtrl: ModalController,
    public subscriptionService: SubscriptionService,
    private onboardingServ: OnboardingService,
    private translateConfigServ: TranslateConfigService,
    public userServ: UserService,
    private weightPipe: WeightPipe,
    private sanitizer: DomSanitizer,
    private analyticsServ: AnalyticsService
  ) {
    this.language = this.translateConfigServ.getCurrentLang();

    this.isAndroid = this.platform.is('android');

    this.subscriptions.push(
      this.subscriptionService.observeInAppProducts().subscribe((products) => {
        this.subOptions = [];
        products.forEach((option) => {
          const subOption = { ...option };
          this.subOptions.push({
            ...subOption,
            checked: subOption.time == '6',
          });
          if (subOption.time == '6') {
            this.selectedSub = subOption;
          }
        });

        this.currencySymbol = getCurrencySymbol(
          this.subOptions[0].currencyCode
            ? this.subOptions[0].currencyCode
            : 'USD',
          'narrow',
          this.language
        );
      })
    );

    const user = this.userServ.getSyncUserOnce();
    console.log('User: ', user);
    this.userPlan = user?.desiredResults;

    if (Capacitor.getPlatform() == 'web' && !this.userPlan) {
      this.userPlan = this.onboardingServ.getControl('desiredResults')?.value;
    }

    let removeList = [];
    for (let index = 0; index < this.saleOptions.length; index++) {
      const element = this.saleOptions[index];
      if (element.condition != 'any') {
        if (element.condition != this.userPlan) {
          removeList.push(index);
        }
      }
    }
    for (let index2 = removeList.length - 1; index2 >= 0; index2--) {
      const element = removeList[index2];
      this.saleOptions.splice(element, 1);
    }

    userDesiredResultsData.forEach((goal) => {
      if (goal.title == this.userPlan) {
        this.saleVideoUrl = this.sanitizer.bypassSecurityTrustUrl(
          goal.salesVideo
        );
        this.salesThumbnail = goal.thumbnail;
        this.salesVideoText = {
          title: goal.title,
          description: goal.description,
        };
      }
    });

    this.orientationSub = ScreenOrientation.onChange().subscribe(
      async (event) => {
        if (this.platform.is('capacitor')) {
          if (
            ScreenOrientation.type.includes(
              ScreenOrientation.ORIENTATIONS.LANDSCAPE
            )
          ) {
            await StatusBar.hide();
          } else {
            await StatusBar.show();
          }
        }
      }
    );
  }

  async ngOnInit() {
    await this.analyticsServ.logEvent('sale_modal_opened', {});
    this.canFreeTrial = await this.subscriptionService.checkCanFreeTrial();

    this.subscriptions.push(
      this.platform.backButton.subscribeWithPriority(101, () => {
        if (this.parent == 'subscription') {
          if (this.isFullScreen) {
            document.exitFullscreen();
            return;
          } else {
            this.dismiss();
          }
        }
        // Disabled
        return;
      })
    );

    this.isFreemium = this.subscriptionService.isFreemium();
  }

  ionViewWillEnter() {
    this.setStats();
    if (!this.platform.is('capacitor')) {
      return;
    }
    StatusBar.setStyle({ style: Style.Dark });
  }

  ionViewWillLeave() {
    if (!this.platform.is('capacitor')) {
      return;
    }

    StatusBar.setStyle({ style: Style.Light });
  }

  ngAfterContentInit(): void {
    // this.salesVideo = this.salesVideoRef.nativeElement;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.orientationSub?.unsubscribe();
  }

  setStats() {
    let after = {
      image: '',
    };

    const user = this.userServ.getSyncUserOnce();

    if (!this.userPlan) {
      this.userPlan = user.desiredResults;
    }

    const bodyFat = Number(user.bodyFat);
    let before = {
      image: this.bodyFatRanges[bodyFat],
    };

    switch (user.desiredResults) {
      case 'PoundPlummet Path':
        after.image = this.bodyFatRanges[Math.max(bodyFat - 2, 0)];
        break;
      case 'Curvy Body Bootycamp':
        after.image = bodyTypes[user.goalBody].icon;
        break;
      case 'ToneTastic Trek':
        after.image = bodyTypes[user.goalBody].icon;
        break;
      case 'Bendy Body Boss':
        after.image = bodyTypes[user.goalBody].icon;
        break;
      case 'Wonderland of Wellness':
        after.image = bodyTypes[user.goalBody].icon;
        break;
    }
    console.log('After image: ', after.image, before.image);

    this.stats = {
      before,
      after,
    };
  }

  selectSub(sub, index) {
    const currIndex = this.subOptions.findIndex((op) => op.checked);
    if (currIndex !== -1 && currIndex !== index) {
      this.subOptions[currIndex].checked = !this.subOptions[currIndex].checked;
      this.subOptions[index].checked = !this.subOptions[index].checked;
    }
    this.selectedSub = sub;
  }

  async purchase() {
    if (this.isWeb) {
      this.paymentModal.openModal();
    } else {
      this.waitingChecker = setInterval(() => {
        if (this.subscriptionService.waitingStatus == false) {
          this.waitingLayer = false;
          console.log(
            `#### Waiting satus :: 1 :: ${this.subscriptionService.waitingStatus}`
          );
          clearInterval(this.waitingChecker);
        } else if (this.subscriptionService.waitingStatus == true) {
          this.waitingLayer = true;
        } else {
          console.log(
            `#### Waiting satus :: 3 :: ${this.subscriptionService.waitingStatus}`
          );
        }
      }, 500);

      //this.waitingLayer = true
      const selectedSub = this.subOptions.find((op) => op.checked);
      console.log(`#### Subscription: ${JSON.stringify(selectedSub)}`);

      this.subscriptionService.makePurchase(selectedSub.name);
    }
  }

  async openTerms() {
    const modal = await this.modalCtrl.create({
      component: TermsAndConditionsPage,
      componentProps: { modal: true },
    });
    await modal.present();
  }

  async dismiss() {
    if (this.parent != 'subscription') {
      await this.onboardingServ.setPlan();

      setTimeout(async () => {
        await this.modalCtrl.dismiss();
      }, 500);
    } else {
      await this.modalCtrl.dismiss();
    }
  }

  async openSubscription() {
    const modal = await this.modalCtrl.create({
      component: SubscriptionTermsPage,
      componentProps: { modal: true },
    });

    await modal.present();
  }

  async openPrivacyPolicy() {
    const modal = await this.modalCtrl.create({
      component: PrivacyPolicyPage,
      componentProps: { modal: true },
    });
    await modal.present();
  }

  noAdsChange(ev) {
    this.prepareNoAdsOptions();
  }

  prepareNoAdsOptions() {
    for (let i = 0; i < this.subOptions.length; i++) {
      const sub = this.subOptions[i];
      console.log('Sub: ', this.subOptions[i]);
      switch (sub.name) {
        case 'com.lifebuddy.12monthslb':
          sub.name = SUB_OPTIONS_NO_ADS[i].name;
          sub.finalCost = SUB_OPTIONS_NO_ADS[i].finalCost;
          sub.cost = SUB_OPTIONS_NO_ADS[i].cost;
          sub.discount = SUB_OPTIONS_NO_ADS[i].discount;
          break;

        case 'com.lifebuddy.6monthslb':
          sub.name = SUB_OPTIONS_NO_ADS[i].name;
          sub.finalCost = SUB_OPTIONS_NO_ADS[i].finalCost;
          sub.cost = SUB_OPTIONS_NO_ADS[i].cost;
          sub.discount = SUB_OPTIONS_NO_ADS[i].discount;
          break;

        case 'com.lifebuddy.1monthlb':
          sub.name = SUB_OPTIONS_NO_ADS[i].name;
          sub.finalCost = SUB_OPTIONS_NO_ADS[i].finalCost;
          sub.cost = SUB_OPTIONS_NO_ADS[i].cost;
          sub.discount = SUB_OPTIONS_NO_ADS[i].discount;
          break;

        default:
          break;
      }
    }
  }

  onInViewport({ target, visible }: { target: Element; visible: boolean }) {
    if (!this.platform.is('capacitor')) {
      return;
    }
    if (visible) {
      StatusBar.setStyle({ style: Style.Dark });
    } else {
      StatusBar.setStyle({ style: Style.Light });
    }
  }
}
