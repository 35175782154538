import { Component, ViewChild } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { TranslatePipe } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { ALL_VIDEOS_INFO, Category, CategorySettings } from '../../constants';
import { FileManagerService } from '../../services/file-manager.service';
import { LoggerService } from '../../services/logger.service';
import { NetworkService } from '../../services/network.service';
import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/user.service';
import { VideoService } from '../../services/video.service';
import {
  BottomSheetComponent,
  SheetStates,
} from '../../shared/bottom-sheet/bottom-sheet-component';

@Component({
  selector: 'app-download-delete',
  templateUrl: './download-delete.component.html',
  styleUrls: ['./download-delete.component.scss'],
})
export class DownloadDeleteComponent {
  @ViewChild('bottomSheet', { static: true })
  public bottomSheet: BottomSheetComponent;

  language: any;

  downloadedCategories: Array<string> = [];
  private isAborted: boolean;

  action: 'download' | 'delete';
  title: string;
  options: Array<{ name: string; value: string }>;
  progress: Observable<{ total: number; current: number }>;
  videosList: Array<any>;
  targetList: Array<string>;
  targetPresentList: Array<string>;
  inProgress: boolean;
  sizeToDownload: string;
  isSingleCategory: boolean;

  constructor(
    private fileManager: FileManagerService,
    private toastController: ToastController,
    private videoService: VideoService,
    private storageServ: StorageService,
    private networkService: NetworkService,
    private notificationService: NotificationService,
    private loggerService: LoggerService,
    private userService: UserService,
    private loadingCtrl: LoadingController,
    private translateConfigServ: TranslateConfigService,
    private translatePipe: TranslatePipe
  ) {
    this.language = this.translateConfigServ.getCurrentLang();
    this.isSingleCategory = false;
    this.init();
  }

  init() {
    this.storageServ.get('DownloadedCategories').then((e) => {
      this.downloadedCategories = e || [];
      this.userService.downloadedCategories = JSON.parse(
        JSON.stringify(this.downloadedCategories)
      );
      if (this.videosList?.length) {
        this.videosList = this.videosList.map((e) => {
          return {
            ...e,
            isDownloaded: this.downloadedCategories.indexOf(e.name) !== -1,
          };
        });
      }
    });
    this.inProgress = false;
    this.isAborted = false;
    this.sizeToDownload = this.formatBytes(0, 0);
    this.progress = this.fileManager.categoryProgress.asObservable();
    const videoDownloadInfo = this.videoService.videoDownloadIsDone.value;
    this.inProgress = !videoDownloadInfo.isDone;
    if (this.inProgress) {
      if (
        videoDownloadInfo.action === 'download' ||
        videoDownloadInfo.action === 'delete'
      ) {
        this.action = videoDownloadInfo.action;
      }
      this.sizeToDownload = videoDownloadInfo.sizeToDownload;
    }
  }

  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public openModal(mode: 'download' | 'delete', targetList = []) {
    this.action = mode;
    this.targetList = targetList;
    if (targetList.findIndex((vid) => vid == 'total body') != -1) {
      this.targetList.push(
        Category.booty,
        Category['wall pilates'],
        Category['upper body'],
        Category['legs']
      );
    }
    this.targetPresentList = targetList.map((e) =>
      this.capitalizeFirstLetter(e)
    );
    this.isSingleCategory = targetList.length > 0;

    this.videosList = Object.values(CategorySettings);

    this.videosList = this.videosList.map((e) => {
      return {
        ...e,
        isDownloaded: this.downloadedCategories.indexOf(e.name) !== -1,
      };
    });

    this.options = [];
    this.title = '';
    if (this.bottomSheet) {
      setTimeout(() => {
        this.init();
        this.bottomSheet.setState(SheetStates.Opened);
      }, 100);
    } else {
      setTimeout(() => {
        this.openModal(mode, targetList);
      }, 150);
    }
  }

  private async showNotificationResult(msg: string) {
    const toast = await this.toastController.create({
      message: this.translatePipe.transform(msg),
      duration: 3000,
    });
    await toast.present();
  }

  calcTotalSize() {
    let totalSize = 0;
    this.videosList.forEach((e, index: number) => {
      if (e.isChecked) {
        ALL_VIDEOS_INFO.forEach((item) => {
          if (
            item.subcategory_name === e.value ||
            (e.value === 'abs' && item.category_name === e.value)
          ) {
            if (this.action === 'download') {
              totalSize += 2;
            } else {
              totalSize += 2;
            }
          }
        });
      }
    });
    this.fileManager.categoryProgress.next({
      ...this.fileManager.categoryProgress.value,
      total: totalSize,
    });
  }

  onClickSingle() {
    this.videosList = this.videosList.map((e) => {
      return { ...e, isChecked: this.targetList.indexOf(e.value) !== -1 };
    });
    this.calcVideosSize({}, null);
    this.onClick();
  }

  async onClick() {
    if (this.inProgress) {
      return;
    }
    this.fileManager.cancelDownloadObs.next(false);
    this.fileManager.categoryProgress.next({ total: 0, current: 0 });
    let initPromise = new Promise<any>((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 500);
    });
    const categoryNameList = [];
    let itemIsSelected = false;
    this.calcTotalSize();
    this.videosList.forEach((e, index: number) => {
      if (e.isChecked) {
        categoryNameList.push(e.name);
        itemIsSelected = true;
        if (this.action === 'download') {
          initPromise = initPromise.then((_) => {
            return this.videoService.downloadVideoCategory(e.value);
          });
        } else {
          initPromise = initPromise.then((_) => {
            return this.videoService.deleteVideoCategory(e.value);
          });
        }
      }
    });

    if (itemIsSelected) {
      this.inProgress = true;

      setTimeout(() => {
        this.bottomSheet.scrollToTop();
      });
    } else {
      return;
    }

    this.videoService.videoDownloadIsDone.next({
      refresh: false,
      isDone: false,
      sizeToDownload: this.sizeToDownload,
      action: `${this.action}`,
    });
    await initPromise
      .then(async (e) => {
        if (this.isAborted) {
          this.videoService.videoDownloadIsDone.next({
            refresh: false,
            isDone: true,
            sizeToDownload: this.sizeToDownload,
            action: `${this.action}`,
          });
          return;
        }
        if (!this.showStatusCategoryAlert(categoryNameList)) {
          // tslint:disable-next-line:max-line-length
          const multiple =
            Math.abs(
              this.action === 'download'
                ? categoryNameList.length - this.downloadedCategories.length
                : categoryNameList.length
            ) > 1;
          await this.showNotificationResult(
            this.translatePipe.transform(
              `${
                multiple ? 'Workout categories' : 'Workout category'
              } successfully ${
                this.action === 'download' ? 'downloaded' : 'deleted'
              }`
            )
          );
          this.videoService.videoDownloadIsDone.next({
            refresh: true,
            isDone: true,
            sizeToDownload: this.sizeToDownload,
            action: `${this.action}`,
          });
        } else {
          this.videoService.videoDownloadIsDone.next({
            refresh: false,
            isDone: true,
            sizeToDownload: this.sizeToDownload,
            action: `${this.action}`,
          });
        }
        this.submit();
      })
      .catch((error) => {
        console.log(error);
        this.isAborted = false;
        this.inProgress = false;
        this.fileManager.cancelDownloadObs.next(true);
        if (!this.networkService.getStatusOnce()) {
          this.cancel();
          setTimeout(() => {
            this.notificationService.showError(
              'Make sure you have stable internet connection and try again'
            );
          }, 400);
        } else {
          this.showNotificationResult(
            `Oops! Something went wrong. ${
              this.action === 'download'
                ? 'Please download videos again'
                : 'Please delete videos again'
            }.`
          );
        }
        this.loggerService.logError(error);
        this.fileManager.categoryProgress.next({ total: 0, current: 0 });
        this.videoService.videoDownloadIsDone.next({
          refresh: false,
          isDone: true,
          sizeToDownload: this.sizeToDownload,
          action: `${this.action}`,
        });
      });
  }

  calcVideosSize(event, item) {
    if (item && item.value == 'total body') {
      this.videosList.forEach((vid) => {
        if (
          vid.value == Category.booty ||
          vid.value == Category['wall pilates'] ||
          vid.value == Category['upper body'] ||
          vid.value == Category['legs']
        ) {
          vid.isChecked = item.isChecked;
        }
      });
    }

    const videos = [...ALL_VIDEOS_INFO];
    let size = 0;

    const searchItems = [];
    this.videosList.forEach((e) => {
      if (e.isChecked) {
        searchItems.push(e.value);
      }
    });
    if (searchItems.length) {
      videos.forEach((e) => {
        if (e.category_name === 'abs' && searchItems.indexOf('abs') !== -1) {
          size += +e.size;
        } else if (searchItems.indexOf(e.subcategory_name) !== -1) {
          size += +e.size;
        }
      });
    }
    this.sizeToDownload = size
      ? this.formatBytes(size, 2)
      : this.formatBytes(0, 0);
    this.bottomSheet.scrollToTop();
  }

  formatBytes(bytes, decimals, k = 1024) {
    if (bytes === 0) {
      return '0 MB';
    }
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['bytes', 'kb', 'mb', 'gb'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
      ' ' +
      this.translatePipe.transform(sizes[i])
    );
  }

  async cancelDownload() {
    this.isAborted = true;
    const categoryNameList = [];

    this.videosList.forEach((e, index: number) => {
      if (e.isChecked) {
        categoryNameList.push(e.name);
      }
    });

    this.cancel();
    const loading = await this.loadingCtrl.create({
      message: this.translatePipe.transform('Please wait') + '...',
    });
    await loading.present();
    await this.fileManager.cancelDownload(categoryNameList);
    loading.dismiss();
    this.notificationService.showSuccess('Download_Aborted');
  }

  private showStatusCategoryAlert(names: Array<string>) {
    if (Array.isArray(this.downloadedCategories) && names && names.length) {
      const categoryList = [];
      if (this.action === 'download') {
        this.downloadedCategories.forEach((e) => {
          if (names.indexOf(e) !== -1) {
            categoryList.push(e);
          }
        });
      } else {
        names.forEach((e) => {
          if (this.downloadedCategories.indexOf(e) === -1) {
            categoryList.push(e);
          }
        });
      }

      if (categoryList.length) {
        // tslint:disable-next-line:max-line-length
        this.notificationService.showSuccess(
          this.translatePipe.transform(
            `The following ${
              categoryList.length > 1 ? 'categories are' : 'category is'
            } already ${this.action === 'download' ? 'downloaded' : 'deleted'}`
          ) +
            ': ' +
            categoryList
              .map((cat) => this.translatePipe.transform(cat))
              .join(', ')
        );
      }
    }
    if (names && names.length) {
      let list = [];
      if (this.action === 'download') {
        list =
          this.downloadedCategories && this.downloadedCategories.length
            ? this.arrayUnique([...this.downloadedCategories, ...names])
            : names;
      } else if (
        this.downloadedCategories &&
        this.downloadedCategories.length
      ) {
        list = this.downloadedCategories.filter((e) => {
          return names.indexOf(e) === -1;
        });
      }
      this.storageServ.set('DownloadedCategories', list);
      this.userService.downloadedCategories = JSON.parse(JSON.stringify(list));
    }
    return Array.isArray(this.downloadedCategories) && names && names.length
      ? names.every((v) =>
          this.action === 'download'
            ? this.downloadedCategories.includes(v)
            : !this.downloadedCategories.includes(v)
        )
      : false;
  }

  private arrayUnique(array) {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) {
          a.splice(j--, 1);
        }
      }
    }
    return a;
  }

  cancel() {
    this.bottomSheet.setState(SheetStates.Closed);
  }

  submit() {
    this.bottomSheet.setState(SheetStates.Closed);
  }
}
