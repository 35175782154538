import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getRemoteConfig } from 'firebase/remote-config';

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBEmJvOK8yA72QchfDvW6MmXsi8rrML50M',
    authDomain: 'pilates-fitness.firebaseapp.com',
    projectId: 'pilates-fitness',
    storageBucket: 'pilates-fitness.appspot.com',
    messagingSenderId: '743337614300',
    appId: '1:743337614300:web:2543c3227af1d6f43c0d19',
    measurementId: 'G-PXVBZQ66XK',
  },
  stripeApiKey:
    'pk_live_51LLwsxJHiIokgvzXiFdA12bDhc1tJ2ccl9tikXXblKPxKMUvGMbLmVd7NRryPMW68q0ZCBZHOTEaPsuPvhBiOKvL00JjjyY7nu',
};

const app = initializeApp(environment.firebase);

export const analytics = getAnalytics(app);
export const db = getFirestore(app);

export const remoteConfig = getRemoteConfig(app);

remoteConfig.defaultConfig = {
  freemium: false,
  test_freemium: false,
  freemium_countdown: 144000,
  test_freemium_countdown: 144000,
};
