import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { RemoteConfigSale } from "src/app/pages/profile/profile.page";
import { SubscriptionService } from "src/app/services/subscription.service";

interface CountDownValues {
  // days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

@Component({
  selector: "app-sale-banner",
  templateUrl: "./sale-banner.component.html",
  styleUrls: ["./sale-banner.component.scss"],
})
export class SaleBannerComponent implements OnInit {
  @Input() saleConfig: RemoteConfigSale;
  @Input() type: number = 1;

  @Output() showSale: EventEmitter<boolean> = new EventEmitter<boolean>(null);

  showing: boolean = false;

  countdown: CountDownValues = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  timer: NodeJS.Timeout;

  saleHours: number;
  // salePercent: number;

  constructor(public subscriptionServ: SubscriptionService) {}

  async ngOnInit() {
    console.log(this.saleConfig);
    if (!this.saleConfig.active) {
      return;
    }

    console.log("Sale config:", this.saleConfig);

    if (this.subscriptionServ.trueCheckSubscriptionForPlayer()) {
      this.showSale.emit(false);
      this.showing = false;
    } else {
      if (this.saleConfig.start) {
        const startDate = new Date(this.saleConfig.start);
        const endDate = new Date(this.saleConfig.end);
        const seconds = Math.floor(endDate.getTime() / 1000) - Math.floor(startDate.getTime() / 1000);
        this.saleHours = Math.round(seconds / 60 / 60);
      }
      this.startTimer();
      this.showSale.emit(true);
      this.showing = true;
    }
  }

  startTimer() {
    const endDate = new Date(this.saleConfig.end);

    const now = Date.now();
    const nowSec = Math.floor(now / 1000);
    const time = Math.floor(endDate.getTime() / 1000) - nowSec;
    const minute = 60;
    const hour = minute * 60;
    // const day = hour * 24;

    // const days = Math.floor(time / day);
    const hours = Math.floor(time / hour);
    const minutes = Math.floor((time % hour) / minute);
    const seconds = time % minute;

    this.countdown = {
      // days,
      hours,
      minutes,
      seconds,
    };

    this.timer = setInterval(() => {
      const now = Date.now();
      const nowSec = Math.floor(now / 1000);
      const time = Math.floor(endDate.getTime() / 1000) - nowSec;
      const minute = 60;
      const hour = minute * 60;
      // const day = hour * 24;

      // const days = Math.floor(time / day);
      const hours = Math.floor(time / hour);
      const minutes = Math.floor((time % hour) / minute);
      const seconds = time % minute;

      this.countdown = {
        // days,
        hours,
        minutes,
        seconds,
      };

      if (hours == 0 && minutes == 0 && seconds == 0) {
        clearInterval(this.timer);
        this.showSale.emit(false);
        this.showing = false;
      }
    }, 1000);

    // this.listeners.tick.forEach((listener) => listener({ days, hours, minutes, seconds }));

    // const timeToNextSecond = (nowSec + 1) * 1000 - now;
    // this.timer = setTimeout(tick, timeToNextSecond);
  }
}
