import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
import { Achievement, ACHIEVEMENTS } from '../constants/achievements';
import { Workout as PlanWorkout } from '../models/plan';
import { User } from '../models/user';
import { Workout } from '../models/workout';
import { WeightPipe } from '../pipes/weight.pipe';
import { HelperService } from './helper.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AchievementService {
  private _achievements: BehaviorSubject<Achievement[]> = new BehaviorSubject(
    []
  );
  $achievements = this._achievements.asObservable();

  user: User;

  constructor(
    private fireDB: AngularFirestore,
    private userServ: UserService,
    private helperServ: HelperService,
    private storageServ: StorageService,
    public afAuth: Auth,
    private weightPipe: WeightPipe
  ) {
    this.userServ.getSyncUser().subscribe((user) => {
      if (user?.uid && !this.user?.uid) {
        this.getFirestoreAchievements();
      }
      this.user = user;
    });
  }

  private async updateMinutesAchievements(minutes: number) {
    const achievements = this._achievements.getValue();
    const minutesAchievements = achievements.filter(
      (achievement) =>
        achievement.parentId === 'minutes' && !achievement.completed
    );
    if (minutesAchievements?.length > 0) {
      minutesAchievements.forEach(async (ach) => {
        if (ach.currentProgress == minutes) {
          return;
        }

        ach.currentProgress = minutes;
        if (!ach.completed && minutes >= ach.target) {
          ach.completed = true;
          await this.helperServ.presentAchievementToast(ach);
        }

        await this.setFirestoreAchievement(ach);
        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  private async updateCaloriesAchievements(calories: number) {
    const achievements = this._achievements.getValue();
    const caloriesAchievements = achievements.filter(
      (achievement) =>
        achievement.parentId === 'calories' && !achievement.completed
    );
    if (caloriesAchievements?.length > 0) {
      caloriesAchievements.forEach(async (ach) => {
        if (ach.currentProgress == calories) {
          return;
        }

        ach.currentProgress = calories;
        if (!ach.completed && calories >= ach.target) {
          ach.completed = true;
          await this.helperServ.presentAchievementToast(ach);
        }

        await this.setFirestoreAchievement(ach);
        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  private async updateWorkoutSessionsAchievements(workoutSessions: number) {
    const achievements = this._achievements.getValue();
    const workoutSessionsAchievements = achievements.filter(
      (achievement) =>
        achievement.parentId === 'workout_sessions' && !achievement.completed
    );
    if (workoutSessionsAchievements?.length > 0) {
      workoutSessionsAchievements.forEach(async (ach) => {
        if (ach.currentProgress == workoutSessions) {
          return;
        }

        ach.currentProgress = workoutSessions;
        if (!ach.completed && workoutSessions >= ach.target) {
          ach.completed = true;
          await this.helperServ.presentAchievementToast(ach);
        }

        await this.setFirestoreAchievement(ach);
        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  async update8WeekPlanAchievements(newPlansCompleted: number) {
    this.update8WeekAdvancedAchievements(newPlansCompleted);

    const achievements = this._achievements.getValue();
    const planAchievements = achievements.filter(
      (achievement) =>
        achievement.parentId === '8_week_plan' && !achievement.completed
    );
    if (planAchievements?.length > 0) {
      planAchievements.forEach(async (ach) => {
        if (
          ach.currentProgress == ach.currentProgress
            ? ach.currentProgress + newPlansCompleted
            : newPlansCompleted
        ) {
          return;
        }

        ach.currentProgress = ach.currentProgress
          ? ach.currentProgress + newPlansCompleted
          : newPlansCompleted;
        if (!ach.completed && ach.currentProgress >= ach.target) {
          ach.completed = true;
          await this.helperServ.presentAchievementToast(ach);
        }

        await this.setFirestoreAchievement(ach);
        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  private async update8WeekAdvancedAchievements(newPlansCompleted: number) {
    const user = this.userServ.getSyncUserOnce();
    if (user.plan.difficulty != 'Advanced') {
      return;
    }

    const achievements = this._achievements.getValue();
    const planAchievements = achievements.filter(
      (achievement) => achievement.id === 'beast_mode' && !achievement.completed
    );
    if (planAchievements?.length > 0) {
      planAchievements.forEach(async (ach) => {
        if (
          ach.currentProgress == ach.currentProgress
            ? ach.currentProgress + newPlansCompleted
            : newPlansCompleted
        ) {
          return;
        }

        ach.currentProgress = ach.currentProgress
          ? ach.currentProgress + newPlansCompleted
          : newPlansCompleted;
        if (!ach.completed && ach.currentProgress >= ach.target) {
          ach.completed = true;
          await this.helperServ.presentAchievementToast(ach);
        }

        await this.setFirestoreAchievement(ach);
        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  async updateWeightLossAchievements(weightLost: number) {
    const achievements = this._achievements.getValue();
    const weightAchievements = achievements.filter(
      (achievement) =>
        achievement.parentId === 'weight' && !achievement.completed
    );
    if (weightAchievements?.length > 0) {
      weightAchievements.forEach(async (ach) => {
        if (!ach.currentProgress) {
          ach.currentProgress = 0;
        }
        if (
          ach.currentProgress ==
          Math.max(Number(weightLost), Number(ach.currentProgress) ?? 0)
        ) {
          return;
        }

        ach.currentProgress = Math.max(
          Number(weightLost),
          Number(ach.currentProgress) ?? 0
        );
        if (!ach.completed && ach.currentProgress >= ach.target) {
          ach.completed = true;
          await this.helperServ.presentAchievementToast({
            ...ach,
            description: this.weightPipe.transform(
              ach.description,
              this.user.metric
            ) as string,
          });
        }

        await this.setFirestoreAchievement(ach);
        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  async updateAvatarAchievement() {
    const achievements = this._achievements.getValue();
    const avatarAchievements = achievements.filter(
      (achievement) =>
        achievement.id === 'self_portrait' && !achievement.completed
    );
    if (avatarAchievements?.length > 0) {
      avatarAchievements.forEach(async (ach) => {
        ach.currentProgress = 1;
        ach.completed = true;

        await this.helperServ.presentAchievementToast(ach);
        await this.setFirestoreAchievement(ach);

        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  async updateProgressPhotoAchievement() {
    const achievements = this._achievements.getValue();
    const photoAchievements = achievements.filter(
      (achievement) =>
        achievement.id === 'picture_day' && !achievement.completed
    );
    if (photoAchievements?.length > 0) {
      photoAchievements.forEach(async (ach) => {
        ach.currentProgress = 1;
        ach.completed = true;

        await this.helperServ.presentAchievementToast(ach);
        await this.setFirestoreAchievement(ach);

        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  async updateReminderAchievement() {
    const achievements = this._achievements.getValue();
    const reminderAchievements = achievements.filter(
      (achievement) =>
        achievement.id === 'note_to_self' && !achievement.completed
    );
    if (reminderAchievements?.length > 0) {
      reminderAchievements.forEach(async (ach) => {
        ach.currentProgress = 1;
        ach.completed = true;

        await this.helperServ.presentAchievementToast(ach);
        await this.setFirestoreAchievement(ach);

        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  async updateCustomPlanAchievement() {
    const achievements = this._achievements.getValue();
    const customPlanAchievements = achievements.filter(
      (achievement) => achievement.id === 'creator' && !achievement.completed
    );
    if (customPlanAchievements?.length > 0) {
      customPlanAchievements.forEach(async (ach) => {
        ach.currentProgress = 1;
        ach.completed = true;

        await this.helperServ.presentAchievementToast(ach);
        await this.setFirestoreAchievement(ach);

        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  async updateMergerAchievement() {
    const achievements = this._achievements.getValue();
    const mergerAchievements = achievements.filter(
      (achievement) => achievement.id === 'merger' && !achievement.completed
    );
    if (mergerAchievements?.length > 0) {
      mergerAchievements.forEach(async (ach) => {
        ach.currentProgress = 1;
        ach.completed = true;

        await this.helperServ.presentAchievementToast(ach);
        await this.setFirestoreAchievement(ach);

        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  private async updateTrophiesAchievement(achievementsCompleted: number) {
    const achievements = this._achievements.getValue();
    const trophyAchievements = achievements.filter(
      (achievement) =>
        achievement.id === 'trophy_hunter' && !achievement.completed
    );
    if (trophyAchievements?.length > 0) {
      trophyAchievements.forEach(async (ach) => {
        const currentProgress = Math.max(
          achievementsCompleted,
          ach.currentProgress ?? 0
        );
        if (ach.completed || ach.currentProgress == currentProgress) {
          return;
        }

        ach.currentProgress = currentProgress;

        if (!ach.completed && ach.currentProgress >= ach.target) {
          ach.completed = true;
          await this.helperServ.presentAchievementToast(ach);
        }

        await this.setFirestoreAchievement(ach);

        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  private async updateHoursAchievement(hours: number) {
    const achievements = this._achievements.getValue();
    const hoursAchievements = achievements.filter(
      (achievement) => achievement.id === 'full_day' && !achievement.completed
    );
    if (hoursAchievements?.length > 0) {
      hoursAchievements.forEach(async (ach) => {
        if (ach.currentProgress == hours) {
          return;
        }

        ach.currentProgress = hours;

        if (!ach.completed && hours >= ach.target) {
          ach.completed = true;
          await this.helperServ.presentAchievementToast(ach);
        }

        await this.setFirestoreAchievement(ach);
        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  private async updateWorkoutCategoryAchievements(
    newSessionsCompleted: number,
    category: string
  ) {
    const achievements = this._achievements.getValue();
    const workoutCategoryAchievements = achievements.filter(
      (achievement) =>
        achievement.parentId === 'workout_category' &&
        achievement.categories.findIndex(
          (cat) => category.match(cat)?.length > 0
        ) != -1 &&
        !achievement.completed
    );

    if (workoutCategoryAchievements?.length > 0) {
      workoutCategoryAchievements.forEach(async (ach) => {
        if (
          ach.currentProgress == ach.currentProgress
            ? ach.currentProgress + newSessionsCompleted
            : newSessionsCompleted
        ) {
          return;
        }

        ach.currentProgress = ach.currentProgress
          ? ach.currentProgress + newSessionsCompleted
          : newSessionsCompleted;
        if (!ach.completed && ach.currentProgress >= ach.target) {
          ach.completed = true;
          await this.helperServ.presentAchievementToast(ach);
        }

        await this.setFirestoreAchievement(ach);
        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  private async updateRestDaysAchievement(
    newSessionsCompleted: number,
    workoutHistory: Workout[]
  ) {
    const storageWorkouts = await this.storageServ.get('Workouts');
    if (!Array.isArray(storageWorkouts) || !storageWorkouts.length) {
      return Promise.resolve();
    }
    let workouts: (Workout & PlanWorkout)[] = storageWorkouts;

    const today = new Date();
    const targetWorkout: Workout & PlanWorkout = workouts.find((w) => {
      const date = new Date(w.workoutDate);
      return (
        date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
      );
    });
    const workedOutToday = workoutHistory.find((w) => {
      const date = new Date(w?.dateTime);
      return (
        date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
      );
    });

    if (!targetWorkout?.restDay || workedOutToday) {
      return;
    }

    const achievements = this._achievements.getValue();
    const restDaysAchievements = achievements.filter(
      (achievement) =>
        achievement.id === 'no_rest_for_the_wicked' && !achievement.completed
    );

    if (restDaysAchievements.length > 0) {
      restDaysAchievements.forEach(async (ach) => {
        if (
          ach.currentProgress == ach.currentProgress
            ? ach.currentProgress + newSessionsCompleted
            : newSessionsCompleted
        ) {
          return;
        }

        ach.currentProgress = ach.currentProgress
          ? ach.currentProgress + newSessionsCompleted
          : newSessionsCompleted;
        if (!ach.completed && ach.currentProgress >= ach.target) {
          ach.completed = true;
          await this.helperServ.presentAchievementToast(ach);
        }

        await this.setFirestoreAchievement(ach);
        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = ach;
        }
      });
      this._achievements.next(achievements);
    }
  }

  async updateWorkoutEndedAchievements(workout: Workout, workoutHistory: any) {
    const user = this.userServ.getSyncUserOnce();
    await this.updateHoursAchievement(
      Math.floor(user.exerciseInfo.allTime.workoutTime / 1000 / 60 / 60)
    );
    await this.updateMinutesAchievements(
      Math.floor(user.exerciseInfo.allTime.workoutTime / 1000 / 60)
    );
    await this.updateCaloriesAchievements(
      Math.floor(user.exerciseInfo.allTime.caloriesBurned)
    );
    await this.updateWorkoutSessionsAchievements(
      Math.floor(user.exerciseInfo.allTime.workoutCount)
    );
    await this.updateWorkoutCategoryAchievements(1, workout.categoryName);
    return this.updateRestDaysAchievement(1, workoutHistory);
  }

  async updateLoggedInAchievement() {
    const achievements = this._achievements.getValue();

    if (achievements.length === 0) {
      return;
    }

    const loggedInAchievements = achievements.filter(
      (achievement) =>
        achievement.parentId === 'logged_in' && !achievement.completed
    );

    const today = new Date();

    if (loggedInAchievements?.length > 0) {
      loggedInAchievements.forEach(async (ach) => {
        if (ach?.lastLogin && this.isDatesEqual(today, ach.lastLogin)) {
          return;
        }

        let lastLogin = ach?.lastLogin ? new Date(ach.lastLogin) : null;

        if (!lastLogin) {
          lastLogin = today;
          lastLogin.setDate(lastLogin.getDate() - 1);
        }

        lastLogin.setDate(lastLogin.getDate() + 1);

        ach.currentProgress =
          ach?.currentProgress && this.isDatesEqual(today, lastLogin.toString())
            ? ach.currentProgress + 1
            : 1;

        if (!ach.completed && ach.currentProgress >= ach.target) {
          ach.completed = true;
          await this.helperServ.presentAchievementToast(ach);
        }

        await this.setFirestoreAchievement({
          ...ach,
          lastLogin: today.toString(),
        });

        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = { ...ach, lastLogin: today.toString() };
        }
      });
      this._achievements.next(achievements);
    }
  }

  async updateAnniversaryAchievements() {
    const achievements = this._achievements.getValue();

    if (achievements.length === 0) {
      return;
    }

    const anniversaryAchievements = achievements.filter(
      (achievement) =>
        achievement.parentId === 'anniversary' && !achievement.completed
    );

    const today = new Date();
    const createdDate = new Date(this.afAuth.currentUser.metadata.creationTime);

    if (anniversaryAchievements?.length > 0) {
      anniversaryAchievements.forEach(async (ach) => {
        const differenceInYears = Math.max(
          0,
          Math.floor(
            (today.getTime() - createdDate.getTime()) /
              1000 /
              60 /
              60 /
              24 /
              365
          )
        );

        if (differenceInYears == 0) {
          return;
        }

        if (ach?.currentProgress && differenceInYears == ach?.currentProgress) {
          return;
        }

        ach.currentProgress = differenceInYears;

        if (!ach.completed && ach.currentProgress >= ach.target) {
          ach.completed = true;
          await this.helperServ.presentAchievementToast(ach);
        }

        await this.setFirestoreAchievement({
          ...ach,
          lastLogin: today.toString(),
        });

        const foundIndex = achievements.findIndex((a) => a.id === ach.id);
        if (foundIndex != -1) {
          achievements[foundIndex] = { ...ach, lastLogin: today.toString() };
        }
      });
      this._achievements.next(achievements);
    }
  }

  getRelevantMostProgressAchievement() {
    const parentIds = [
      'calories',
      'minutes',
      'workout_category',
      '8_week_plan',
      'workout_sessions',
    ];
    const achIds = ['no_rest_for_the_wicked', 'beast_mode', 'full_day'];

    const achievements = this._achievements
      .getValue()
      .sort((a, b) => a.order - b.order);

    let relevantAchievements = achievements.filter(
      (achievement) =>
        (parentIds.some((ach) => ach === achievement.parentId) ||
          achIds.some((ach) => ach === achievement.id)) &&
        !achievement.completed &&
        achievement.currentProgress < achievement.target
    );

    relevantAchievements.sort((a, b) =>
      a.currentProgress / a.target > b.currentProgress / b.target ? -1 : 1
    );
    console.log(achievements, relevantAchievements);
    return relevantAchievements.length > 0 ? relevantAchievements[0] : null;
  }

  getAchievements() {
    return this._achievements.getValue();
  }

  private async setFirestoreAchievement(achievement: Achievement) {
    const user = this.userServ.getSyncUserOnce();
    await this.fireDB
      .collection('Users')
      .doc(user.uid)
      .collection('achievements')
      .doc(achievement.id)
      .update(achievement);
    if (this._achievements.value.filter((ach) => ach.completed).length > 0) {
      await this.updateTrophiesAchievement(
        this._achievements.value.filter((ach) => ach.completed).length
      );
    }
  }

  async getFirestoreAchievements() {
    const user = this.userServ.getSyncUserOnce();
    if (!user.uid || Capacitor.getPlatform() == 'web') {
      return;
    }
    return this.fireDB
      .collection('Users')
      .doc(user.uid)
      .collection('achievements')
      .get()
      .toPromise()
      .then(async (docs) => {
        if (docs.empty) {
          ACHIEVEMENTS.forEach(async (ach) => {
            const user = this.userServ.getSyncUserOnce();
            await this.fireDB
              .collection('Users')
              .doc(user.uid)
              .collection('achievements')
              .doc(ach.id)
              .set({ ...ach, currentProgress: 0, completed: false });
          });
          this._achievements.next(ACHIEVEMENTS);
        } else {
          this._achievements.next(
            docs.docs.map((doc) => doc.data() as Achievement)
          );
        }

        return docs;
      })
      .catch((err) => {
        console.error('Error: ', err);
        return;
      });
  }

  resetAchievements() {
    const achievements = this._achievements.getValue();

    const relevantAchievements = achievements.filter(
      (achievement) => achievement.parentId != 'anniversary'
    );

    relevantAchievements.forEach((ach) => {
      if (ach.lastLogin) {
        const today = new Date();
        ach.lastLogin = today.toString();
        ach.currentProgress = 1;
      } else {
        ach.currentProgress = 0;
      }
      ach.completed = false;
      this.setFirestoreAchievement(ach);
      const foundIndex = achievements.findIndex((a) => a.id === ach.id);
      if (foundIndex != -1) {
        achievements[foundIndex] = ach;
      }
    });
    this._achievements.next(achievements);
  }

  isDatesEqual(date1: Date, dateStr: string) {
    const date2 = new Date(dateStr);
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
}
