import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { RoundProgressModule } from "angular-svg-round-progressbar";
// import { AngularCropperjsModule } from 'angular-cropperjs';
import { DndModule } from "ngx-drag-drop";
import { SwiperModule } from "swiper/angular";
import { CustomLoaderComponent } from "../components/custom-loader/custom-loader.component";
import { MuscleDiagramComponent } from "../components/muscle-diagram/muscle-diagram.component";
import { PasswordInputComponent } from "../components/password-input/password-input.component";
import { SaleBannerComponent } from "../components/sale-banner/sale-banner.component";
// import { ScrollbarModule } from "../components/scrollbar/scrollbar.module";
import { SingleVideoComponent } from "../components/single-video/single-video.component";
import { SpotifyComponent } from "../components/spotify/spotify.component";
import { WeightCrudComponent } from "../components/weight-crud/weight-crud.component";
import { WeightProgressSlideShowComponent } from "../components/weight-progress-slide-show/weight-progress-slide-show.component";
import { WorkoutResultPage } from "../components/workout-result/workout-result.page";
import { DebounceClickDirective } from "../directives/debounce-click.directive";
import { ScrollIntoViewDirective } from "../directives/scroll-into-view.directive";
import { BottomSheetComponent } from "./bottom-sheet/bottom-sheet-component";
import { BottomSheetModule } from "./bottom-sheet/bottom-sheet.module";
import { GlobalModule } from "./global.module";
import { ModalsModule } from "./modals.module";

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RoundProgressModule,
    FormsModule,
    // ScrollbarModule,
    // AngularCropperjsModule,
    BottomSheetModule,
    DndModule,
    GlobalModule,
    ModalsModule,
    TranslateModule,
    SwiperModule,
  ],
  exports: [
    SingleVideoComponent,
    MuscleDiagramComponent,
    DndModule,
    GlobalModule,
    DebounceClickDirective,
    ScrollIntoViewDirective,
    WorkoutResultPage,
    // ScrollbarModule,
    PasswordInputComponent,
    BottomSheetModule,
    BottomSheetComponent,
    SpotifyComponent,
    WeightCrudComponent,
    CustomLoaderComponent,
    WeightProgressSlideShowComponent,
    SaleBannerComponent,
  ],
  declarations: [
    SingleVideoComponent,
    MuscleDiagramComponent,
    DebounceClickDirective,
    ScrollIntoViewDirective,
    WorkoutResultPage,
    PasswordInputComponent,
    SpotifyComponent,
    WeightCrudComponent,
    CustomLoaderComponent,
    WeightProgressSlideShowComponent,
    SaleBannerComponent,
  ],
  providers: [TranslatePipe],
})
export class SharedModule {}
