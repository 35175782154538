import { AfterViewInit, Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-muscle-diagram",
  templateUrl: "./muscle-diagram.component.html",
  styleUrls: ["./muscle-diagram.component.scss"],
})
export class MuscleDiagramComponent implements OnInit, AfterViewInit {
  @Input() primary: string;
  @Input() secondary: string;
  @Input() tertiary: string;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    console.log(this.primary, this.secondary, this.tertiary);
    const primaryArray = this.primary.split(", ");
    const secondaryArray = this.secondary.split(", ");
    const tertiaryArray = this.tertiary.split(", ");

    document.querySelectorAll("g").forEach((group) => {
      const id = group.id.toLowerCase();

      // if (primaryArray.findIndex((prim) => prim.toLowerCase() == "cardiovascular system") != -1) {
      //   for (let i = 0; i < group.children.length; i++) {
      //     group.children[i].classList.add("secondary-muscle");
      //   }
      // } else {
      primaryArray.forEach((prim) => {
        if (prim != "" && id.includes(prim.toLowerCase())) {
          for (let i = 0; i < group.children.length; i++) {
            group.children[i]?.classList.add("primary-muscle");
          }
        }
      });
      console.log(group.children);
      if (!group.children[0]?.classList.contains("primary-muscle")) {
        secondaryArray.forEach((sec) => {
          if (sec != "" && id.includes(sec.toLowerCase())) {
            for (let i = 0; i < group.children.length; i++) {
              group.children[i].classList.add("secondary-muscle");
            }
          }
        });

        if (!group.children[0]?.classList.contains("secondary-muscle")) {
          tertiaryArray.forEach((tert) => {
            if (tert != "" && id.includes(tert.toLowerCase())) {
              for (let i = 0; i < group.children.length; i++) {
                group.children[i].classList.add("tertiary-muscle");
              }
            }
          });
        }
      }
      // }
    });
  }
}
