import { Injectable } from '@angular/core';
import { Functions } from '@angular/fire/functions';
import { Capacitor } from '@capacitor/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { NavController, Platform } from '@ionic/angular';
import { httpsCallable } from 'firebase/functions';
import { getRemoteConfig, getValue } from 'firebase/remote-config';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  ALL_VIDEOS_INFO,
  DifficultySettings,
  WorkoutDaysAndMuscles,
} from '../constants';
import { Difficulty } from '../enums/Difficulty';
import { Plan, PreRequiredPlanData, Workout } from '../models/plan';
import { Video } from '../models/video';
import { LoggerService } from './logger.service';
import { NetworkService } from './network.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  public plan$: Observable<any>;
  private planSubject$: BehaviorSubject<Workout[]>;
  private subSubject: Subject<boolean>;
  public isLast: boolean;

  constructor(
    private storageServ: StorageService,
    private userService: UserService,
    private platform: Platform,
    private networkService: NetworkService,
    private loggerService: LoggerService,
    private navCtrl: NavController,
    private func: Functions,
    private nativeStorage: NativeStorage
  ) {
    this.isLast = false;
    this.planSubject$ = new BehaviorSubject(null);
    this.subSubject = new Subject();
    this.plan$ = this.planSubject$.asObservable().pipe(
      map((workouts: Workout[]) => {
        workouts = workouts?.map((wo) => {
          wo.displayName =
            wo.displayName == 'plank' ? 'planks' : wo.displayName;
          wo.workoutName =
            wo.workoutName == 'plank' ? 'planks' : wo.workoutName;
          return wo;
        });

        if (workouts && workouts.length) {
          workouts.sort((a, b) => {
            return new Date(a.workoutDate).getTime() >
              new Date(b.workoutDate).getTime()
              ? 1
              : -1;
          });

          const markWorkouts = this.markLastElement(workouts);
          return markWorkouts;
        }

        return workouts;
      })
    );
  }

  getPlanOnce() {
    const workouts = [...this.planSubject$.value];
    if (workouts && workouts.length) {
      workouts.sort((a, b) => {
        return new Date(a.workoutDate).getTime() >
          new Date(b.workoutDate).getTime()
          ? 1
          : -1;
      });

      const markWorkouts = this.markLastElement(workouts);
      return markWorkouts;
    }
    return workouts;
  }

  initPlan() {
    this.platform
      .ready()
      .then(() => {
        return this.storageServ.get('Workouts');
      })
      .then((workouts: Workout[]) => {
        if (!Array.isArray(workouts) || !workouts.length) {
          return this.getWorkoutsFromDB();
        }

        this.planSubject$.next(workouts);
      });
  }

  private async getWorkoutsFromDB() {
    return this.userService
      .getUserFromLocalStorage()
      .then((user) => {
        if (!user || !user.uid) {
          return Promise.resolve([]);
        }
        return this.getDBWorkouts(user.uid);
      })
      .then((workouts: Workout[]) => {
        this.setWorkouts(workouts || []);
        this.planSubject$.next(workouts || []);
      });
  }

  markLastElement(workouts: Workout[]) {
    if (!workouts || !workouts.length) {
      return [];
    }

    const rworkouts = workouts.slice().reverse();
    const last = rworkouts.find((w) => w.workoutName !== 'rest');

    if (last) {
      const filtered = rworkouts.filter((w) =>
        this.isSameWorkoutDates(w.workoutDate, last.workoutDate)
      );

      if (filtered.length === 1) {
        const id = workouts.indexOf(last);
        workouts[id] = { ...last, lastWorkout: true };

        return workouts;
      }

      if (filtered.length === 2) {
        const firstId = workouts.indexOf(filtered[0]);
        const secondId = workouts.indexOf(filtered[1]);

        workouts[firstId] = { ...filtered[0], lastWorkout: true };
        workouts[secondId] = { ...filtered[1], lastWorkout: true };

        return workouts;
      }
    }

    return workouts;
  }

  async setWorkouts(workouts: Workout[]) {
    if (!Array.isArray(workouts) || !workouts.length) {
      return Promise.resolve({});
    }
    return await this.storageServ.set('Workouts', workouts);
  }

  compareAndMergeWorkouts(userId: string, workoutsST: Workout[]) {
    if (!workoutsST) {
      return Promise.resolve({});
    }

    const updates = [];

    for (const workout of workoutsST) {
      updates.push(this.validateWorkout(workout));
    }

    return this.updateWorkouts(userId, updates);
  }

  async updateWorkouts(userId: string, workouts: Workout[]) {
    const callable = httpsCallable(this.func, 'updateUserPlan');
    const data = callable({ userUID: userId, workouts });
    return data;
  }

  validateWorkout(workout: Workout) {
    delete workout.isDisabled;
    delete workout.isMenuOpen;
    delete workout.isToday;
    delete workout.isReschedule;
    delete workout.index;
    delete workout.displayName;
    delete workout.displayDate;

    return workout;
  }

  async syncWorkouts() {
    if (!this.networkService.getStatusOnce()) {
      return;
    }

    const user = this.userService.getSyncUserOnce();
    if (!user || !user.uid) {
      return;
    }
    const storageWorkouts = await this.storageServ.get('Workouts');
    await this.compareAndMergeWorkouts(user.uid, storageWorkouts);
  }

  async setUserDBPlanToStorage(uid: string) {
    const dbPlan = await this.getDBWorkouts(uid);

    if (dbPlan && dbPlan.length) {
      this.planSubject$.next(dbPlan);
      return await this.storageServ.set('Workouts', dbPlan);
    }
  }

  async getDBWorkouts(userId: string): Promise<Workout[]> {
    if (Capacitor.getPlatform() == 'web') {
      return;
    }

    const callable = httpsCallable(this.func, 'getUserPlan');
    const data = await callable({ userUID: userId });
    return data.data as Workout[];
  }

  workoutsNext(workouts: Workout[]) {
    this.setWorkouts(workouts)
      .then(() => {
        console.log('Next workouts: ', workouts);
        this.planSubject$.next(workouts);
      })
      .then(() => {
        this.syncWorkouts();
      });
  }

  async workoutNext(workout: Workout, isCompleted = false) {
    return this.storageServ
      .get('Workouts')
      .then((workouts: Workout[]) => {
        if (!workouts || !workouts.length) {
          return null;
        }

        const index = workouts.findIndex(
          (w) =>
            w.workoutId === workout.workoutId &&
            w.workoutDate === workout.workoutDate
        );
        if (index === -1) {
          return null;
        }
        workouts[index] = workout;

        if (!isCompleted) {
          workouts = this.changeWorkoutAllWeeks(workout, workouts, '');
        }
        this.planSubject$.next(workouts);
        return workouts;
      })
      .then((workouts) => {
        if (!Array.isArray(workouts) || workouts.length === 0) {
          return null;
        }
        return this.setWorkouts(workouts);
      })
      .then(() => {
        this.syncWorkouts();
      });
  }

  changeWorkoutAllWeeks(
    workout: Workout,
    workouts: Workout[],
    oldCategory?: string
  ) {
    const startDate = moment(workout.workoutDate);

    let index = null;
    if (oldCategory) {
      index = workouts.findIndex(
        (w) =>
          new Date(w.workoutDate).getTime() ===
            new Date(startDate.toString()).getTime() &&
          w.workoutName === oldCategory
      );
    } else {
      index = workouts.findIndex(
        (w) =>
          new Date(w.workoutDate).getTime() ===
          new Date(startDate.toString()).getTime()
      );
    }

    if (index !== -1 && workouts[index] && !workouts[index].isCompleted) {
      workouts[index] = {
        ...workout,
        workoutId: workouts[index].workoutId,
        workoutDate: workouts[index].workoutDate,
        isCompleted: workouts[index].isCompleted,
      };
    }

    return workouts;
  }

  async setUserWorkouts(workoutObj) {
    const userId = this.userService.getSyncUserOnce().uid;
    console.log(userId);
    if (!userId) {
      return;
    }
    const workouts: Workout[] = Object.values(workoutObj);
    this.workoutsNext(workouts);
  }

  async rescheduleTwoWorkouts(firstW: Workout, secondW: Workout) {
    let workouts = JSON.parse(JSON.stringify(this.planSubject$.value));
    if (!Array.isArray(workouts) || workouts.length === 0) {
      return;
    }

    const index1 = workouts.findIndex(
      (w) =>
        w.workoutDate === firstW.workoutDate &&
        w.workoutName === firstW.workoutName
    );
    const index2 = workouts.findIndex(
      (w) =>
        w.workoutDate === secondW.workoutDate &&
        w.workoutName === secondW.workoutName
    );

    let next1 = { ...firstW, workoutDate: secondW.workoutDate };
    let next2 = { ...secondW, workoutDate: firstW.workoutDate };

    if (index1 === -1 || index2 === -1) {
      return;
    }

    workouts[index1] = next2;
    workouts[index2] = next1;

    this.planSubject$.next(workouts);

    if (next1.isCompleted) {
      next1 = { ...next1, isCompleted: false };
    }

    if (next2.isCompleted) {
      next2 = { ...next2, isCompleted: false };
    }

    workouts = this.changeWorkoutAllWeeks(next1, workouts, secondW.workoutName);
    workouts = this.changeWorkoutAllWeeks(next2, workouts, firstW.workoutName);
    return this.workoutsNext(workouts);
  }

  getPlanId() {
    return 'xxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx-xxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c) => {
        // tslint:disable-next-line:no-bitwise
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  async createPlan(formValue) {
    const user = this.userService.getSyncUserOnce();

    const preRequiredPlanData: PreRequiredPlanData = {
      startDate: moment().toString(),
      daysCount: /* formValue?.days ||  */ formValue?.whatDays?.length,
      days: formValue?.whatDays,
      difficulty: formValue?.difficulty,
      desiredResults: formValue?.desiredResults,
    };

    const plan: Plan = {
      duration: 56,
      difficulty: formValue?.difficulty || user?.difficulty,
      goal: formValue?.desiredResults,
      goalWeight: formValue?.goalWeight,
      startWorkoutPlanDate: moment().toString(),
      endWorkoutPlanDate: moment().add(55, 'days').toString(),
      workoutPlan: await this.getPlan(preRequiredPlanData),
    };

    return plan;
  }

  getWorkoutsCurrentWeek(calendarWeek: any[], workouts: Workout[]): Workout[] {
    const firstDayIndex = workouts.findIndex(
      (w) =>
        moment(w.workoutDate).get('D') === calendarWeek[0].day &&
        moment(w.workoutDate).format('MMMM') === calendarWeek[0].month
    );

    const res = [];

    if (firstDayIndex >= 0) {
      let firstDate = moment(
        moment(workouts[firstDayIndex].workoutDate)
          .set('h', 0)
          .set('m', 0)
          .set('s', 0)
          .format()
      );

      for (let i = 0; i < 7; i++) {
        const workout = workouts.filter(
          (e) =>
            moment(e.workoutDate).date() == firstDate.date() &&
            moment(e.workoutDate).month() == firstDate.month()
        );
        if (workout && workout.length) {
          res.push(...workout);
        }
        firstDate = firstDate.add(1, 'day');
      }
    }

    return res;
  }

  async createPlanFromConstructor(formValue) {
    const preRequiredPlanData: PreRequiredPlanData = {
      startDate: moment().toString(),
      daysCount: formValue.days,
      days: formValue.whatDays,
      difficulty: formValue.difficulty,
      desiredResults: formValue.desiredResults,
      workoutList: formValue.workoutList,
    };

    const plan: Plan = {
      duration: 56,
      difficulty: formValue.difficulty,
      goal: formValue.desiredResults,
      goalWeight: formValue.goalWeight,
      startWorkoutPlanDate: moment().toString(),
      endWorkoutPlanDate: moment().add(55, 'days').toString(),
      workoutPlan: await this.getConstructorPlan(preRequiredPlanData),
    };
    return plan;
  }

  async getConstructorPlan(
    preRequiredData: PreRequiredPlanData
  ): Promise<Workout[]> {
    const { startDate, difficulty, workoutList } = preRequiredData;

    const startWeekDay = moment(startDate).get('weekday');
    const startDay = moment(startDate);
    const workoutPlan = [];

    const subscribed = await this.checkSubscription();
    const planId = this.getPlanId();

    for (let i = 0; i < 8; i++) {
      const workouts = [];
      let step = startWeekDay - 1;

      for (let j = 0; j < 7; j++) {
        if (step + 1 < 7) {
          step++;
        } else {
          step = 0;
        }

        const promises = workoutList[j].map(async (workoutName, inx) => {
          workouts.push({
            isCompleted: false,
            workoutName,
            difficulty,
            workoutTimeList: await this.getWorkoutTimeList(
              workoutName,
              subscribed
            ),
            workoutId: `${i}-${j}-${planId}-${inx}`,
            workoutDate: startDay.clone().add('second', inx).toString(),
            restDay: workoutName === 'rest',
          });
        });

        await Promise.all(promises);

        startDay.add(1, 'day');
      }
      workoutPlan.push(...workouts);
    }
    return workoutPlan;
  }

  async getPlan(preRequiredData: PreRequiredPlanData): Promise<Workout[]> {
    const { startDate, days, daysCount, difficulty, desiredResults } =
      preRequiredData;
    console.log('Pre required data: ', preRequiredData);
    const startWeekDay = moment(startDate).get('weekday');
    const startDay = moment(startDate);
    const workoutPlan = [];

    const subscribed = await this.checkSubscription();
    const planId = this.getPlanId();

    for (let i = 0; i < 8; i++) {
      const muscles = WorkoutDaysAndMuscles[desiredResults][daysCount][i];
      const musclesArray = muscles.slice();
      const workouts = [];
      let step = startWeekDay - 1;

      for (let j = 0; j < 7; j++) {
        if (step + 1 < 7) {
          step++;
        } else {
          step = 0;
        }

        const workoutName = this.getWorkoutName(
          startDay.toString(),
          days,
          musclesArray
        );

        const workout = {
          isCompleted: false,
          workoutName,
          difficulty,
          workoutTimeList: await this.getWorkoutTimeList(
            workoutName,
            subscribed
          ),
          workoutId: `${i}-${j}-${planId}`,
          workoutDate: startDay.toString(),
          restDay: workoutName === 'rest',
        };

        workouts.push(workout);
        startDay.add(1, 'day');
      }

      workoutPlan.push(...workouts);
    }

    return workoutPlan;
  }

  calcWorkoutTime(
    difficulty: Difficulty,
    categories: string[],
    isMin: boolean,
    videoDataByCategory: Video[]
  ) {
    let settings;
    if (categories.length === 1) {
      if (categories[0] === 'rest') {
        return 0;
      }
      settings = DifficultySettings[categories[0]][difficulty];
    } else {
      settings = DifficultySettings[difficulty];
    }

    if (isMin) {
      videoDataByCategory.sort((a, b) =>
        a.time_to_play > b.time_to_play ? 1 : -1
      );
    } else {
      videoDataByCategory.sort((a, b) =>
        a.time_to_play > b.time_to_play ? -1 : 1
      );
    }

    let totalTime = 0;
    videoDataByCategory.slice(0, settings.numOfVideos).forEach((element) => {
      totalTime += element.time_to_play;
    });

    if (!isMin) {
      const oterSideList = videoDataByCategory.filter(
        (a) => a.is_other_side || a.is_three_video
      );

      oterSideList.slice(0, settings.numOfVideos).forEach((element) => {
        totalTime += element.time_to_play;
      });
    }
    if (
      categories.findIndex((cat) => cat == 'upper body stretch') == -1 &&
      categories.findIndex((cat) => cat == 'lower body stretch') == -1
    ) {
      totalTime += settings.restTime * (settings.numOfVideos - 1);
    }

    return totalTime * 60 * 1000;
  }

  getWorkoutName(date, days: string[], workouts: string[]) {
    const weekDayName = moment(date).format('dddd');
    const isDayEqual = this.isDayEqual(days, weekDayName);

    return isDayEqual ? this.getWorkoutExercise(workouts) : 'rest';
  }

  isDayEqual(days: string[], day: string) {
    const index = days.indexOf(day);
    return index >= 0 ? true : false;
  }

  getWorkoutExercise(workoutExercise: string[]) {
    return workoutExercise.shift();
  }

  async getWorkoutTimeList(workoutName: string, subscribed?: boolean) {
    if (subscribed == undefined) {
      subscribed = await this.checkSubscription();
    }

    if (!workoutName) {
      return {
        [Difficulty.Beginner]: {
          minTime: 0,
          maxTime: 0,
        },
        [Difficulty.Intermediate]: {
          minTime: 0,
          maxTime: 0,
        },
        [Difficulty.Advanced]: {
          minTime: 0,
          maxTime: 0,
        },
      };
    }

    const categories = workoutName.split(', ');

    const videos = JSON.parse(JSON.stringify(ALL_VIDEOS_INFO));
    let videoDataByCategory = videos.filter((item) => {
      return categories.indexOf(item.subcategory_name) !== -1;
    });

    if (!subscribed && this.isFreemium()) {
      videoDataByCategory = videoDataByCategory.filter((vid) => vid.is_free);
    }

    return {
      [Difficulty.Beginner]: {
        minTime: this.calcWorkoutTime(
          Difficulty.Beginner,
          workoutName.split(', '),
          true,
          videoDataByCategory
        ),
        maxTime: this.calcWorkoutTime(
          Difficulty.Beginner,
          workoutName.split(', '),
          false,
          videoDataByCategory
        ),
      },
      [Difficulty.Intermediate]: {
        minTime: this.calcWorkoutTime(
          Difficulty.Intermediate,
          workoutName.split(', '),
          true,
          videoDataByCategory
        ),
        maxTime: this.calcWorkoutTime(
          Difficulty.Intermediate,
          workoutName.split(', '),
          false,
          videoDataByCategory
        ),
      },
      [Difficulty.Advanced]: {
        minTime: this.calcWorkoutTime(
          Difficulty.Advanced,
          workoutName.split(', '),
          true,
          videoDataByCategory
        ),
        maxTime: this.calcWorkoutTime(
          Difficulty.Advanced,
          workoutName.split(', '),
          false,
          videoDataByCategory
        ),
      },
    };
  }

  async updateWorkoutsDifficulty(difficulty: string) {
    const workouts: Workout[] = this.planSubject$.value;
    if (!workouts || !workouts.length) {
      return;
    }

    for (const w of workouts) {
      if (!w.isCompleted) {
        w.difficulty = difficulty;
      }
    }

    this.workoutsNext(workouts);
  }

  async workoutCompleted(workoutId) {
    let workouts = this.planSubject$.value;

    if (!Array.isArray(workouts) || !workouts.length) {
      const storageWorkouts = await this.storageServ.get('Workouts');
      if (!Array.isArray(storageWorkouts) || !storageWorkouts.length) {
        return Promise.resolve();
      }
      workouts = storageWorkouts;
    }

    const targetWorkout: Workout = workouts.find(
      (w) => w.workoutId === workoutId
    );
    if (!targetWorkout.isCompleted) {
      targetWorkout.isCompleted = true;
    }

    return this.workoutNext(targetWorkout, true).then(() => {
      const isLast = this.isLastCompleted(workouts, targetWorkout);
      if (isLast) {
        this.navCtrl.navigateRoot('/plan-completed');
      }
    });
  }

  async markWorkoutAsCompleted(workoutId: string) {
    let workouts = this.planSubject$.value;

    if (!Array.isArray(workouts) || !workouts.length) {
      const storageWorkouts = await this.storageServ.get('Workouts');
      if (!Array.isArray(storageWorkouts) || !storageWorkouts.length) {
        return Promise.resolve();
      }
      workouts = storageWorkouts;
    }

    const targetWorkout: Workout = workouts.find(
      (w) => w.workoutId === workoutId
    );

    if (!targetWorkout.isCompleted) {
      targetWorkout.isCompleted = true;
    }

    return this.workoutNext(targetWorkout, true);
  }

  isLastCompleted(workouts: Workout[], targetWorkout: Workout) {
    const filtered = workouts.filter((w) =>
      this.isSameWorkoutDates(w.workoutDate, targetWorkout.workoutDate)
    );
    if (!filtered.length) {
      return false;
    }

    if (filtered.length === 1) {
      return filtered[0].isCompleted && filtered[0].lastWorkout;
    }

    if (filtered.length === 2) {
      return (
        filtered[0].isCompleted &&
        filtered[0].lastWorkout &&
        filtered[1].isCompleted &&
        filtered[1].lastWorkout
      );
    }

    return false;
  }

  isSameWorkoutDates(date1, date2) {
    const d1 = moment(date1).set('h', 0).set('m', 0).set('s', 0).format();
    const d2 = moment(date2).set('h', 0).set('m', 0).set('s', 0).format();
    return moment(d1).isSame(moment(d2));
  }

  completeRestOnMidnight() {
    const sub = new Subject();
    this.plan$.pipe(takeUntil(sub)).subscribe(async (plan) => {
      if (!plan || !plan.length) {
        return;
      }

      const restList = plan.filter((e) => {
        const time = moment(e.workoutDate)
          .hour(23)
          .minute(59)
          .second(59)
          .toDate()
          .getTime();

        return (
          e.workoutName === 'rest' &&
          time <= new Date().getTime() &&
          !e.isCompleted
        );
      });

      const workouts = this.planSubject$.value;
      if (restList.length && workouts.length) {
        restList.forEach((e) => {
          try {
            const inx = workouts.findIndex((w) => w.workoutId === e.workoutId);

            if (inx !== -1) {
              workouts[inx].isCompleted = true;
            }
          } catch (error) {
            console.log(error);
            this.loggerService.logError(error);
          }
        });

        await this.workoutsNext(workouts);
      }
      sub.next(true);
    });
  }

  async getAllCompletedWorkoutsCount() {
    try {
      const workouts = this.planSubject$.value;
      let completedCount = 0;

      if (!workouts || !workouts.length) {
        return {
          allCompleted: 0,
          allWorkouts: 0,
        };
      }

      for (const w of workouts) {
        if (w.isCompleted) {
          completedCount++;
        }
      }

      return {
        allCompleted: completedCount,
        allWorkouts: workouts.length,
      };
    } catch (error) {
      console.log(error);
      return {
        allCompleted: 0,
        allWorkouts: 0,
      };
    }
  }

  isAllWorkoutsCompleted() {
    const workouts = this.planSubject$.value;
    return workouts.every((w) => w.isCompleted);
  }

  isFreemium() {
    const remoteConfig = getRemoteConfig();
    const isFreemium = getValue(
      remoteConfig,
      environment.production ? 'freemium' : 'test_freemium'
    );
    return isFreemium?.asBoolean() || !this.userService.countdownEnded();
  }

  async checkSubscription() {
    return this.nativeStorage
      ?.getItem('subscribedStatus')
      .then((res) => res)
      .catch((err) => false);
  }
}
