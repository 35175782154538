import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { TimeoutError } from "rxjs";
import { ErrorService } from "./services/error.service";
import { LoggerService } from "./services/logger.service";
import { NotificationService } from "./services/notification.service";

@Injectable({
  providedIn: "root",
})
export class GlobalErrorHandler implements ErrorHandler {
  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse | TimeoutError) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggerService);
    const notifier = this.injector.get(NotificationService);

    let message;
    let stackTrace;

    if (error && error.message && "Attempted to assign to readonly property." === error.message) {
      return;
    }

    if (error instanceof HttpErrorResponse || error instanceof TimeoutError) {
      // Server Error
      message = errorService.getServerMessage(error);
      stackTrace = errorService.getServerStack(error);
      notifier.showError(message);
    } else {
      // Client Error
      message = errorService.getClientMessage(error);
      stackTrace = errorService.getClientStack(error);
      notifier.showError(message);
    }
    // try {
    //   Sentry.captureException(error);
    // } catch (e) {
    //   console.error(e);
    // }
    // Always log errors
    logger.logError(error);
  }
}
