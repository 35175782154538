<ion-content>
  <div class="exit-btn">
    <ion-buttons>
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>
  <ion-slides *ngIf="viewEntered" pager="true" [options]="slideOpts">
    <ion-slide *ngFor="let item of weightHistory">
      <img [src]="item.filePath ? item.sanitizedImage : item.imageUrl" (load)="item.loaded = true" alt />

      <ion-spinner style="color: white" *ngIf="!item.loaded"></ion-spinner>
      <div class="image-info">
        <h1>{{ metric === "us" ? item.weightPound : item.weightKg }} {{ (metric === "us" ? "lbs" : "kg") | translate }}</h1>
        <h3>{{ item.date | extendDate : "MMM dd, yyyy" }}</h3>
      </div>
    </ion-slide>
  </ion-slides>
</ion-content>
