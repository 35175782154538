import { Capacitor } from '@capacitor/core';
import { Difficulty } from './enums/Difficulty';
import { OnboardingPageKeys } from './enums/Onboarding';
//import { OnboardingBG } from "./enums/Onboarding";
import sixmonthly from 'src/assets/pricingTables/6monthly.json';
import monthly from 'src/assets/pricingTables/monthly.json';
import yearly from 'src/assets/pricingTables/yearly.json';
import { SubOption } from './models/sale';
import { RecItem } from './services/helper.service';

export const DEFAULT_LANGUAGE = 'English';
export const LANGUAGE_LIST = [
  { name: 'Afrikaans', value: 'af' },
  { name: 'Albanian', value: 'sq' },
  { name: 'Amharic', value: 'am' },
  { name: 'Arabic', value: 'ar' },
  { name: 'Armenian', value: 'hy' },
  { name: 'Azerbaijani', value: 'az' },
  { name: 'Bangla', value: 'bn' },
  { name: 'Basque', value: 'eu' },
  { name: 'Belarusian', value: 'be' },
  { name: 'Bulgarian', value: 'bg' },
  { name: 'Burmese', value: 'my' },
  { name: 'Catalan', value: 'ca' },
  { name: 'Chinese (Simplified)', value: 'zh-Hans' },
  { name: 'Chinese (Traditional)', value: 'zh-Hant' },
  { name: 'Croatian', value: 'hr' },
  { name: 'Czech', value: 'cs' },
  { name: 'Danish', value: 'da' },
  { name: 'Dutch', value: 'nl' },
  { name: 'English', value: 'en' },
  { name: 'Estonian', value: 'et' },
  { name: 'Filipino', value: 'fil' },
  { name: 'Finnish', value: 'fi' },
  { name: 'French', value: 'fr' },
  { name: 'Galician', value: 'gl' },
  { name: 'Georgian', value: 'ka' },
  { name: 'German', value: 'de' },
  { name: 'Greek', value: 'el' },
  { name: 'Gujarati', value: 'gu' },
  { name: 'Hebrew', value: 'he' },
  { name: 'Hindi', value: 'hi' },
  { name: 'Hungarian', value: 'hu' },
  { name: 'Icelandic', value: 'is' },
  { name: 'Indonesian', value: 'id' },
  { name: 'Italian', value: 'it' },
  { name: 'Japanese', value: 'ja' },
  { name: 'Kannada', value: 'kn' },
  { name: 'Kazakh', value: 'kk' },
  { name: 'Khmer', value: 'km' },
  { name: 'Korean', value: 'ko' },
  { name: 'Kyrgyz', value: 'ky' },
  { name: 'Lao', value: 'lo' },
  { name: 'Latvian', value: 'lv' },
  { name: 'Lithuanian', value: 'lt' },
  { name: 'Macedonian', value: 'mk' },
  { name: 'Malay', value: 'ms' },
  { name: 'Malayalam', value: 'ml' },
  { name: 'Marathi', value: 'mr' },
  { name: 'Mongolian', value: 'mn' },
  { name: 'Nepali', value: 'ne' },
  { name: 'Norwegian', value: 'no' },
  { name: 'Persian', value: 'fa' },
  { name: 'Polish', value: 'pl' },
  { name: 'Portuguese', value: 'pt' },
  { name: 'Punjabi', value: 'pa' },
  { name: 'Romanian', value: 'ro' },
  { name: 'Russian', value: 'ru' },
  { name: 'Serbian', value: 'sr' },
  { name: 'Sinhala', value: 'si' },
  { name: 'Slovak', value: 'sk' },
  { name: 'Slovenian', value: 'sl' },
  { name: 'Spanish', value: 'es' },
  { name: 'Swahili', value: 'sw' },
  { name: 'Swedish', value: 'sv' },
  { name: 'Tamil', value: 'ta' },
  { name: 'Telugu', value: 'te' },
  { name: 'Thai', value: 'th' },
  { name: 'Turkish', value: 'tr' },
  { name: 'Ukrainian', value: 'uk' },
  { name: 'Urdu', value: 'ur' },
  { name: 'Vietnamese', value: 'vi' },
  { name: 'Zulu', value: 'zu' },
];

export const DEFAULT_WEEKLY_DAY = 'Monday 11:59 PM';
export const WEEKLY_DAY_LIST = [
  'Monday 11:59 PM',
  'Tuesday 11:59 PM',
  'Wednesday 11:59 PM',
  'Thursday 11:59 PM',
  'Friday 11:59 PM',
  'Saturday 11:59 PM',
  'Sunday 11:59 PM',
];

export enum AndroidSubscriptionProducts {
  'com.lifebuddy.12monthslb' = 'com.lifebuddy.12monthslb',
  'com.lifebuddy.6monthslb' = 'com.lifebuddy.6monthslb',
  'com.lifebuddy.1monthlb' = 'com.lifebuddy.1monthlb',
}

export const DifficultySettings = {
  [Difficulty.Beginner]: {
    restCount: 7,
    numOfVideos: 8,
    restTime: 1,
  },
  [Difficulty.Intermediate]: {
    restCount: 9,
    numOfVideos: 10,
    restTime: 1,
  },
  [Difficulty.Advanced]: {
    restCount: 11,
    numOfVideos: 12,
    restTime: 1,
  },
  WarmUpCoolDown: {
    restCount: 0,
    numOfVideos: 2,
    restTime: 0,
  },
  'total body': {
    [Difficulty.Beginner]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 7,
      numOfVideos: 8,
      restTime: 1,
    },
  },
  'upper body': {
    [Difficulty.Beginner]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 7,
      numOfVideos: 8,
      restTime: 1,
    },
  },
  booty: {
    [Difficulty.Beginner]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 7,
      numOfVideos: 8,
      restTime: 1,
    },
  },
  'wall pilates': {
    [Difficulty.Beginner]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 7,
      numOfVideos: 8,
      restTime: 1,
    },
  },
  abs: {
    [Difficulty.Beginner]: {
      restCount: 3,
      numOfVideos: 4,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 7,
      numOfVideos: 8,
      restTime: 1,
    },
  },
  legs: {
    [Difficulty.Beginner]: {
      restCount: 4,
      numOfVideos: 5,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 1,
    },
  },
  cardio: {
    [Difficulty.Beginner]: {
      restCount: 0,
      numOfVideos: 5,
      restTime: 0,
    },
    [Difficulty.Intermediate]: {
      restCount: 0,
      numOfVideos: 6,
      restTime: 0,
    },
    [Difficulty.Advanced]: {
      restCount: 0,
      numOfVideos: 7,
      restTime: 0,
    },
  },
  hiit: {
    [Difficulty.Beginner]: {
      restCount: 0,
      numOfVideos: 5,
      restTime: 0,
    },
    [Difficulty.Intermediate]: {
      restCount: 0,
      numOfVideos: 6,
      restTime: 0,
    },
    [Difficulty.Advanced]: {
      restCount: 6,
      numOfVideos: 7,
      restTime: 0,
    },
  },
  'lower body stretch': {
    ['WarmUpCoolDown']: {
      restCount: 0,
      numOfVideos: 2,
      restTime: 0,
    },
    [Difficulty.Beginner]: {
      restCount: 0,
      numOfVideos: 6,
      restTime: 0,
    },
    [Difficulty.Intermediate]: {
      restCount: 0,
      numOfVideos: 7,
      restTime: 0,
    },
    [Difficulty.Advanced]: {
      restCount: 0,
      numOfVideos: 8,
      restTime: 0,
    },
  },
  'upper body stretch': {
    ['WarmUpCoolDown']: {
      restCount: 0,
      numOfVideos: 2,
      restTime: 0,
    },
    [Difficulty.Beginner]: {
      restCount: 0,
      numOfVideos: 6,
      restTime: 0,
    },
    [Difficulty.Intermediate]: {
      restCount: 0,
      numOfVideos: 7,
      restTime: 0,
    },
    [Difficulty.Advanced]: {
      restCount: 0,
      numOfVideos: 8,
      restTime: 0,
    },
  },
  planks: {
    [Difficulty.Beginner]: {
      restCount: 3,
      numOfVideos: 4,
      restTime: 1,
    },
    [Difficulty.Intermediate]: {
      restCount: 5,
      numOfVideos: 6,
      restTime: 1,
    },
    [Difficulty.Advanced]: {
      restCount: 7,
      numOfVideos: 8,
      restTime: 1,
    },
  },
};

export enum Category {
  'booty' = 'booty',
  'wall pilates' = 'wall pilates',
  'total body' = 'total body',
  'upper body' = 'upper body',
  abs = 'abs',
  legs = 'legs',
  cardio = 'cardio',
  hiit = 'hiit',
  'lower body stretch' = 'lower body stretch',
  'upper body stretch' = 'upper body stretch',
  planks = 'planks',
}

export interface CategorySetting {
  name: string;
  value: string;
  equipment: string[];
  textDirection: string;
  imgUrl: string;
  isChecked: boolean;
  isDisabled: boolean;
  id: number;
  titleWidth?: string;
}

export const CategorySettings: {
  [key: string]: CategorySetting;
} = {
  booty: {
    name: 'Booty',
    value: 'booty',
    equipment: [],
    textDirection: 'left',
    imgUrl: 'assets/newUI/images/booty.png',
    isChecked: false,
    isDisabled: false,
    id: 13,
  },
  'wall pilates': {
    name: 'Wall Pilates',
    value: 'wall pilates',
    equipment: [],
    textDirection: 'left',
    imgUrl: 'assets/newUI/images/wall_pilates.png',
    isChecked: false,
    isDisabled: false,
    id: 14,
  },
  'upper body': {
    name: 'Upper Body',
    value: 'upper body',
    equipment: ['chair'],
    textDirection: 'left',
    imgUrl: 'assets/newUI/images/upper_body.png',
    isChecked: false,
    isDisabled: false,
    id: 0,
  },
  abs: {
    name: 'Abs',
    value: 'abs',
    equipment: [],
    textDirection: 'left',
    imgUrl: 'assets/newUI/images/abs.png',
    isChecked: false,
    isDisabled: false,
    id: 2,
  },
  legs: {
    name: 'Legs',
    value: 'legs',
    equipment: ['chair'],
    textDirection: 'left',
    imgUrl: 'assets/newUI/images/legs.png',
    isChecked: false,
    isDisabled: false,
    id: 8,
  },
  cardio: {
    name: 'Cardio',
    value: 'cardio',
    equipment: [],
    textDirection: 'left',
    imgUrl: 'assets/newUI/images/cardio.png',
    isChecked: false,
    isDisabled: false,
    id: 4,
  },
  hiit: {
    name: 'HIIT',
    value: 'hiit',
    equipment: [],
    textDirection: 'left',
    imgUrl: 'assets/newUI/images/hiit.png',
    isChecked: false,
    isDisabled: false,
    id: 5,
  },
  planks: {
    name: 'Planks',
    value: 'planks',
    equipment: ['chair'],
    textDirection: 'left',
    imgUrl: 'assets/newUI/images/planks.png',
    isChecked: false,
    isDisabled: false,
    id: 10,
  },
  'lower body stretch': {
    name: 'Lower Body Stretch',
    value: 'lower body stretch',
    equipment: ['chair'],
    textDirection: 'left',
    imgUrl: 'assets/newUI/images/lower_body_stretch.png',
    isChecked: false,
    isDisabled: false,
    id: 12,
    titleWidth: '100px',
  },
  'upper body stretch': {
    name: 'Upper Body Stretch',
    value: 'upper body stretch',
    equipment: ['chair'],
    textDirection: 'left',
    imgUrl: 'assets/newUI/images/upper_body_stretch.png',
    isChecked: false,
    isDisabled: false,
    id: 11,
    titleWidth: '100px',
  },
  'total body': {
    name: 'Total Body',
    value: 'total body',
    equipment: ['chair'],
    textDirection: 'left',
    imgUrl: 'assets/newUI/images/total_body.png',
    isChecked: false,
    isDisabled: false,
    id: 15,
    titleWidth: '100px',
  },
};

export const ACTIVITIES = [
  {
    name: 'Basal Metabolic Rate (BMR)',
    shortName: 'BMR',
    id: 0,
  },
  {
    name: 'Sedentary - little or no exercise',
    shortName: 'Sedentary',
    id: 1,
  },
  {
    name: 'Lightly Active - exercise/sports 1-3 times/week',
    shortName: 'Lightly Active',
    id: 2,
  },
  {
    name: 'Moderately Active - exercise/sports 3-5 times/week',
    shortName: 'Moderately Active',
    id: 3,
  },
  {
    name: 'Very_Active - hard exercise/sports 6-7 times/week',
    shortName: 'Very Active',
    id: 4,
  },
  {
    name: 'Extra Active - very hard exercise/sports or physical job',
    shortName: 'Extra Active',
    id: 5,
  },
];

export const EquipmentSettings = {
  chair: {
    name: 'Chair',
    details: 'Keep an armless chair with strong back support on standby.',
  },
  // carpet: {
  //   name: "Yoga mat",
  //   details: "Keep a carpet or an exercise mat on standby.",
  // },
  // wall: {
  //   name: "Wall",
  //   details: "Support of a wall is needed to perform some of these exercises.",
  // },
};

export const ALL_VIDEOS_INFO = [
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Flutter Kick',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFlutter%20Kick.jpg?alt=media&token=5191d36c-f0a5-40f4-a690-d2ae86e19e77',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFlutter%20Kick.mp4?alt=media&token=26163154-38bc-422c-9436-89fa9fe66020',
    video_id: 'Abs_Flutter_Kick_1',
    video_length: 0.07,
    video_number: '199',
    size: 2152319,
    hints: 'Abs_Flutter_Kick_1_hints',
    common_mistakes: 'Abs_Flutter_Kick_1_common_mistakes',
    breathing: 'Abs_Flutter_Kick_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Running Women',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRunning%20Women.jpg?alt=media&token=ebd5b02f-876c-4a72-bc5c-f3bf6309211c',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRunning%20Women.mp4?alt=media&token=b225bd1b-4762-467c-99bf-4e157f692278',
    video_id: 'h_Running_Women',
    video_length: 0.07,
    video_number: '578',
    size: 1655058,
    hints: 'h_Running_Women_hints',
    common_mistakes: 'h_Running_Women_common_mistakes',
    breathing: 'h_Running_Women_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Romanian Deadlift Into Calf Raise',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Erector Spinae, Calves',
    rep_range: '8-20',
    secondary_muscle:
      'Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRomanian%20Deadlift%20Into%20Calf%20Raise.jpg?alt=media&token=010e5e36-8401-4e21-a518-eee93d935474',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRomanian%20Deadlift%20Into%20Calf%20Raise.mp4?alt=media&token=339f4a3c-2c1c-4a0e-8f75-ca927bdc520e',
    video_id: 'LnB_Romanian_Deadlift_Into_Calf_Raise_1',
    video_length: 0.05,
    video_number: '267',
    size: 2001899,
    hints: 'LnB_Romanian_Deadlift_Into_Calf_Raise_1_hints',
    common_mistakes: 'LnB_Romanian_Deadlift_Into_Calf_Raise_1_common_mistakes',
    breathing: 'LnB_Romanian_Deadlift_Into_Calf_Raise_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Alternating Lateral Lunges',
    is_free: false,
    primary_muscle: 'Adductors, Quads, Glutes',
    rep_range: '10-16',
    secondary_muscle: 'Hamstrings',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Lateral%20Lunges.jpg?alt=media&token=408d07cf-e6e7-4fba-869b-8cab0194c360',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Lateral%20Lunges.mp4?alt=media&token=a0468d3f-f62b-4288-a3b6-0da57f02d270',
    video_id: 'LnB_Alternating_Lateral_Lunges_13',
    video_length: 0.1,
    video_number: '115',
    size: 683717,
    hints: 'LnB_Alternating_Lateral_Lunges_13_hints',
    common_mistakes: 'LnB_Alternating_Lateral_Lunges_13_common_mistakes',
    breathing: 'LnB_Alternating_Lateral_Lunges_13_breathing',
  },
  {
    category_id: 1,
    category_name: 'upper body',
    name: 'Wide Curl Into Hammer Curl',
    is_free: true,
    primary_muscle: 'Biceps, Brachialis',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Forearm',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWide%20Curl%20Into%20Hammer%20Curl.jpg?alt=media&token=0c6273fb-2254-4909-8ec3-d80a704844e1',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWide%20Curl%20Into%20Hammer%20Curl.mp4?alt=media&token=cf8d0afb-60dc-48a9-9990-419d7cdebe50',
    video_id: 'CnB_Wide_Curl_Into_Hammer_Cur_1',
    video_length: 0.1,
    video_number: '161',
    size: 2454863,
    hints: 'CnB_Wide_Curl_Into_Hammer_Cur_1_hints',
    common_mistakes: 'CnB_Wide_Curl_Into_Hammer_Cur_1_common_mistakes',
    breathing: 'CnB_Wide_Curl_Into_Hammer_Cur_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Lying Arm Lean Back',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Latissimus Dorsi, Serratus Anterior, Triceps',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLying%20Arm%20Lean%20Back.jpg?alt=media&token=9a98d393-1fd2-4f2c-b12b-5a8e3a7cf11f',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLying%20Arm%20Lean%20Back.mp4?alt=media&token=e27a0d81-3191-4f43-9b0a-9dcb76f9fec9',
    video_id: 'lst_Lying_Arm_Lean_Back_1',
    video_length: 0.07,
    video_number: '722',
    size: 4002527,
    hints: 'lst_Lying_Arm_Lean_Back_1_hints',
    common_mistakes: 'lst_Lying_Arm_Lean_Back_1_common_mistakes',
    breathing: 'lst_Lying_Arm_Lean_Back_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Slow Negative Floor Tap Squat',
    is_free: true,
    primary_muscle: 'Quads, Adductors, Hip Flexors Glutes',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSlow%20Negative%20Floor%20Tap%20Squat.jpg?alt=media&token=ec7687be-964a-46a1-8feb-5a9a08e51124',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSlow%20Negative%20Floor%20Tap%20Squat.mp4?alt=media&token=d4d24afa-e0ec-46c3-ad1b-40a4f52df49a',
    video_id: 'LnB_Slow_Negative_Floor_Tap_Squat_1',
    video_length: 0.05,
    video_number: '288',
    size: 2606913,
    hints: 'LnB_Slow_Negative_Floor_Tap_Squat_1_hints',
    common_mistakes: 'LnB_Slow_Negative_Floor_Tap_Squat_1_common_mistakes',
    breathing: 'LnB_Slow_Negative_Floor_Tap_Squat_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Single Leg Seated Hamstring Stretch',
    is_other_side: 0,
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Hamstring',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Leg%20Seated%20Hamstring%20Stretch%20(L).jpg?alt=media&token=5052ecca-27ad-487e-8c53-8d75bfe428c6',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Leg%20Seated%20Hamstring%20Stretch%20(L).mp4?alt=media&token=de0b6e48-0051-4e0a-8c5a-1e55afca9873',
    video_id: 'lst_Single_Leg_Seated_Hamstring_Stretch_1',
    video_length: 0.07,
    video_number: '635',
    size: 1563850,
    hints: 'lst_Single_Leg_Seated_Hamstring_Stretch_1_hints',
    common_mistakes:
      'lst_Single_Leg_Seated_Hamstring_Stretch_1_common_mistakes',
    breathing: 'lst_Single_Leg_Seated_Hamstring_Stretch_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Pigeon Variation',
    is_other_side: 0,
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Glutes, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPigeon%20Variation%20(L).jpg?alt=media&token=1b08f7d9-e2eb-4fba-a275-bc796436720f',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPigeon%20Variation%20(L).mp4?alt=media&token=3c0dbcd9-2d38-4f7b-8331-9bb8fb9d5c0a',
    video_id: 'lst_Pigeon_Variation_1',
    video_length: 0.07,
    video_number: '633',
    size: 1033214,
    hints: 'lst_Pigeon_Variation_1_hints',
    common_mistakes: 'lst_Pigeon_Variation_1_common_mistakes',
    breathing: 'lst_Pigeon_Variation_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Bending Windmill Stretch',
    is_free: true,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstrings',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBending%20Windmill%20Stretch.jpg?alt=media&token=82a3c5c5-2e25-4e33-9284-63e6d8bde326',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBending%20Windmill%20Stretch.mp4?alt=media&token=e01fa011-429f-4561-9c67-7732af9cd1d1',
    video_id: 'lst_Bending_Windmill_Stretch_1',
    video_length: 0.07,
    video_number: '642',
    size: 3449808,
    hints: 'lst_Bending_Windmill_Stretch_1_hints',
    common_mistakes: 'lst_Bending_Windmill_Stretch_1_common_mistakes',
    breathing: 'lst_Bending_Windmill_Stretch_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Across Side Reach',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Latissimus Dorsi, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAcross%20Side%20Reach.jpg?alt=media&token=fe690949-1127-46d7-9265-12f560499c25',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAcross%20Side%20Reach.mp4?alt=media&token=b860e507-43b9-4d67-baf5-72e455382e40',
    video_id: 'lst_Across_Side_Reach_1',
    video_length: 0.07,
    video_number: '726',
    size: 5845179,
    hints: 'lst_Across_Side_Reach_1_hints',
    common_mistakes: 'lst_Across_Side_Reach_1_common_mistakes',
    breathing: 'lst_Across_Side_Reach_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Leg Pull',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstrings',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLeg%20Pull.jpg?alt=media&token=851d8adb-14e2-4061-9e3e-d92339f0f10f',
    time: '75 seconds',
    time_to_play: 1.25,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLeg%20Pull.mp4?alt=media&token=49effaaa-22f7-47a3-bd49-f1ef06b5d8bb',
    video_id: 'lst_Leg_Pull_1',
    video_length: 0.07,
    video_number: '661',
    size: 5155180,
    hints: 'lst_Leg_Pull_1_hints',
    common_mistakes: 'lst_Leg_Pull_1_common_mistakes',
    breathing: 'lst_Leg_Pull_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Front Wrist Curl',
    is_free: false,
    primary_muscle: 'Forearm',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: '',
    subcategory_name: 'upper body',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFront%20Wrist%20Curl.jpg?alt=media&token=122c877b-f9d6-4e87-abb6-dae6be6d395e',
    time: '45 seconds',
    time_to_play: 0.75,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFront%20Wrist%20Curl.mp4?alt=media&token=4dd16d45-28fe-4ca6-9c00-5f8928cc3a63',
    video_id: 'TnS_Front_Wrist_Curl_1',
    video_length: 0.07,
    video_number: '495',
    size: 894721,
    hints: 'TnS_Front_Wrist_Curl_1_hints',
    common_mistakes: 'TnS_Front_Wrist_Curl_1_common_mistakes',
    breathing: 'TnS_Front_Wrist_Curl_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Side Raise',
    is_free: true,
    primary_muscle: 'Middle Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Front Shoulders, Rear Shoulders, Traps, Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Raise.jpg?alt=media&token=9bafa593-c693-4252-8d51-7019b914a437',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Raise.mp4?alt=media&token=91605418-cb3f-4176-a67c-915b7dfa781e',
    video_id: 'ub_Side_Raise',
    video_length: 0.07,
    video_number: '81',
    size: 520173,
    hints: 'ub_Side_Raise_hints',
    common_mistakes: 'ub_Side_Raise_common_mistakes',
    breathing: 'ub_Side_Raise_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Pigeon Variation',
    is_other_side: 1,
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Glutes, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPigeon%20Variation%20(R).jpg?alt=media&token=eb476ced-acb4-4c5f-83c6-bd6b2acb8c09',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPigeon%20Variation%20(R).mp4?alt=media&token=2eed10ca-db50-4c42-a827-f4d479599883',
    video_id: 'lst_Pigeon_Variation_2',
    video_length: 0.07,
    video_number: '634',
    size: 954471,
    hints: 'lst_Pigeon_Variation_2_hints',
    common_mistakes: 'lst_Pigeon_Variation_2_common_mistakes',
    breathing: 'lst_Pigeon_Variation_2_breathing',
  },
  {
    category_id: 1,
    category_name: 'upper body',
    name: 'Double Bicep Curl 3 Second Negative',
    is_free: true,
    primary_muscle: 'Biceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Brachialis',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FDouble%20Bicep%20Curl%203%20Second%20Negative.jpg?alt=media&token=01ad1935-71e1-4610-821e-8e103e4fe9ed',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FDouble%20Bicep%20Curl%203%20Second%20Negative.mp4?alt=media&token=942a4cf1-9003-405e-8b5d-4f0672587846',
    video_id: 'CnB_Double_Bicep_Curl_3_Second_Negative_1',
    video_length: 0.1,
    video_number: '159',
    size: 1673677,
    hints: 'CnB_Double_Bicep_Curl_3_Second_Negative_1_hints',
    common_mistakes:
      'CnB_Double_Bicep_Curl_3_Second_Negative_1_common_mistakes',
    breathing: 'CnB_Double_Bicep_Curl_3_Second_Negative_1_breathing',
  },
  {
    category_id: 1,
    category_name: 'upper body',
    name: 'In And Out Curls',
    is_free: true,
    primary_muscle: 'Biceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Brachialis, Forearm',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FIn%20And%20Out%20Curls.jpg?alt=media&token=7a7672f6-3176-4025-8c94-0669003b12ac',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FIn%20And%20Out%20Curls.mp4?alt=media&token=cc7df29d-9d39-45ab-9823-b907db85c51c',
    video_id: 'CnB_In_And_Out_Curls_64',
    video_length: 0.05,
    video_number: '37',
    size: 654645,
    hints: 'CnB_In_And_Out_Curls_64_hints',
    common_mistakes: 'CnB_In_And_Out_Curls_64_common_mistakes',
    breathing: 'CnB_In_And_Out_Curls_64_breathing',
  },
  {
    category_id: 1,
    category_name: 'upper body',
    name: 'Straight Curls',
    is_free: true,
    primary_muscle: 'Biceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Brachialis, Forearm',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStraight%20Curls.jpg?alt=media&token=0f792873-6778-4118-8729-f9185649bcba',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStraight%20Curls.mp4?alt=media&token=17ec9e15-1159-4da5-a2cf-0afa2e6cb718',
    video_id: 'ub_Straight_Curls',
    video_length: 0.08,
    video_number: '66',
    size: 580443,
    hints: 'ub_Straight_Curls_hints',
    common_mistakes: 'ub_Straight_Curls_common_mistakes',
    breathing: 'ub_Straight_Curls_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Dynamic Downward Dog',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FDynamic%20Downward%20Dog.jpg?alt=media&token=c77fb31e-7df7-42aa-80b3-c2e7161116be',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FDynamic%20Downward%20Dog.mp4?alt=media&token=6320a68a-4a62-4643-856a-4a93850b3ec5',
    video_id: 'lst_Dynamic_Downward_Dog_1',
    video_length: 0.07,
    video_number: '652',
    size: 2021845,
    hints: 'lst_Dynamic_Downward_Dog_1_hints',
    common_mistakes: 'lst_Dynamic_Downward_Dog_1_common_mistakes',
    breathing: 'lst_Dynamic_Downward_Dog_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Neck Extension Backwards',
    is_free: false,
    primary_muscle: 'Front Neck',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FNeck%20Extension%20Backwards.jpg?alt=media&token=7bbab3e1-9241-490e-9c03-eba57e1e629e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FNeck%20Extension%20Backwards.mp4?alt=media&token=46f53aea-762d-45ca-8e70-9899e1925249',
    video_id: 'lst_Neck_Extension_Backward_1',
    video_length: 0.07,
    video_number: '688',
    size: 1666899,
    hints: 'lst_Neck_Extension_Backward_1_hints',
    common_mistakes: 'lst_Neck_Extension_Backward_1_common_mistakes',
    breathing: 'lst_Neck_Extension_Backward_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'High Plank Jacks',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHigh%20Plank%20Jacks.jpg?alt=media&token=9574c903-f650-4a1b-b53b-6831e1483e72',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHigh%20Plank%20Jacks.mp4?alt=media&token=d56671cf-1df7-4c34-97cf-06e626d616a5',
    video_id: 'pl_High_Plank_Jacks_1',
    video_length: 0.07,
    video_number: '615',
    size: 1406803,
    hints: 'pl_High_Plank_Jacks_1_hints',
    common_mistakes: 'pl_High_Plank_Jacks_1_common_mistakes',
    breathing: 'pl_High_Plank_Jacks_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Reach Across',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle:
      'Middle Shoulders, Rear Shoulders, Serratus Anterior, Triceps',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FReach%20Across.jpg?alt=media&token=ae5679da-d439-4435-96fe-aaa91e0e6e1d',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FReach%20Across.mp4?alt=media&token=2adb3c36-e3f2-4c0c-8c99-5387f5680148',
    video_id: 'lst_Reach_Across_1',
    video_length: 0.07,
    video_number: '725',
    size: 4163510,
    hints: 'lst_Reach_Across_1_hints',
    common_mistakes: 'lst_Reach_Across_1_common_mistakes',
    breathing: 'lst_Reach_Across_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Arm Circles Backwards',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Shoulders',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FArm%20Circles%20Backwards.jpg?alt=media&token=d39c0285-c2a1-40f0-8384-5c9306de2749',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FArm%20Circles%20Backwards.mp4?alt=media&token=90be878b-70db-490b-86de-9c4db29cd7e9',
    video_id: 'lst_Arm_Circles_Backward_1',
    video_length: 0.07,
    video_number: '705',
    size: 930537,
    hints: 'lst_Arm_Circles_Backward_1_hints',
    common_mistakes: 'lst_Arm_Circles_Backward_1_common_mistakes',
    breathing: 'lst_Arm_Circles_Backward_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Backstabbers',
    is_free: true,
    primary_muscle: 'Triceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Forearm, Shoulders',
    subcategory_name: 'upper body',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBackstabbers.jpg?alt=media&token=9a8bfbfd-0e50-4d2a-9add-5f43b0ea53b3',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBackstabbers.mp4?alt=media&token=f99e37c2-0aa4-4381-b9dc-8ba14e82266c',
    video_id: 'TnS_Backstabbers_28',
    video_length: 0.08,
    video_number: '34',
    size: 589552,
    hints: 'TnS_Backstabbers_28_hints',
    common_mistakes: 'TnS_Backstabbers_28_common_mistakes',
    breathing: 'TnS_Backstabbers_28_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Wide Side Squat',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWide%20Side%20Squat%20(R).jpg?alt=media&token=d91fa9a5-d8da-464f-8714-17f91608cbbb',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWide%20Side%20Squat%20(R).mp4?alt=media&token=698d1edf-9491-46f4-97bc-9ad2977f5540',
    video_length: 0.05,
    video_number: '795',
    size: 1942840,
    hints: 'b_Wide_Side_Squat_2_hints',
    common_mistakes: 'b_Wide_Side_Squat_2_common_mistakes',
    breathing: 'b_Wide_Side_Squat_2_breathing',
    is_other_side: 1,
    video_id: 'b_Wide_Side_Squat_2',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Back Lunges',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings',
    rep_range: '10-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBack%20Lunges.jpg?alt=media&token=c23956a1-ed5b-4448-b1cb-c591c41e7098',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBack%20Lunges.mp4?alt=media&token=eb7e82ef-a5db-4a6e-bf68-cdd76c316df7',
    video_id: 'LnB_Back_Lunges_45',
    video_length: 0.07,
    video_number: '109',
    size: 453992,
    hints: 'LnB_Back_Lunges_45_hints',
    common_mistakes: 'LnB_Back_Lunges_45_common_mistakes',
    breathing: 'LnB_Back_Lunges_45_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Child Pose With Lat Focus',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Latissimus Dorsi, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FChild%20Pose%20With%20Lat%20Focus.jpg?alt=media&token=123b1d65-62a0-470b-b060-7daa207fb592',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FChild%20Pose%20With%20Lat%20Focus.mp4?alt=media&token=876d04b5-30af-477a-971e-612dbac25fba',
    video_id: 'lst_Child_Pose_With_Lat_Focus_1',
    video_length: 0.07,
    video_number: '729',
    size: 743569,
    hints: 'lst_Child_Pose_With_Lat_Focus_1_hints',
    common_mistakes: 'lst_Child_Pose_With_Lat_Focus_1_common_mistakes',
    breathing: 'lst_Child_Pose_With_Lat_Focus_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Plank Jump',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPlank%20Jump.jpg?alt=media&token=996fc2dd-3b7b-4d91-924f-8cdef4fbb4fe',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPlank%20Jump.mp4?alt=media&token=98991206-ede1-4952-bbec-35637a2dd555',
    video_length: 0.05,
    video_number: '771',
    size: 1942840,
    hints: 'b_Plank_Jump_hints',
    common_mistakes: 'b_Plank_Jump_common_mistakes',
    breathing: 'b_Plank_Jump_breathing',
    video_id: 'b_Plank_Jump',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Lying On Stomach Quad Stretch',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Quads',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLying%20On%20Stomach%20Quad%20Stretch.jpg?alt=media&token=0416b5b3-467d-47c9-ac9f-1977bd8a08b8',
    time: '75 seconds',
    time_to_play: 1.25,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLying%20On%20Stomach%20Quad%20Stretch.mp4?alt=media&token=b0d1f36f-26fa-4298-8f18-ed6e5ff5f897',
    video_id: 'lst_Lying_On_Stomach_Quad_Stretch_1',
    video_length: 0.07,
    video_number: '665',
    size: 5275193,
    hints: 'lst_Lying_On_Stomach_Quad_Stretch_1_hints',
    common_mistakes: 'lst_Lying_On_Stomach_Quad_Stretch_1_common_mistakes',
    breathing: 'lst_Lying_On_Stomach_Quad_Stretch_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Single Leg Hamstring',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstrings, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Leg%20Hamstring.jpg?alt=media&token=2831ce05-6cc0-42bc-b887-b5c6f64af4e8',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Leg%20Hamstring.mp4?alt=media&token=1671440a-52f0-4289-bcb9-cd63526b1d38',
    video_id: 'lst_Single_Leg_Hamstring_1',
    video_length: 0.07,
    video_number: '678',
    size: 5023675,
    hints: 'lst_Single_Leg_Hamstring_1_hints',
    common_mistakes: 'lst_Single_Leg_Hamstring_1_common_mistakes',
    breathing: 'lst_Single_Leg_Hamstring_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Hamstring Curl',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHamstring%20Curl%20(L).jpg?alt=media&token=9159f1d2-2294-4bfe-a237-2860957aa358',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHamstring%20Curl%20(L).mp4?alt=media&token=5576ef4c-6907-486c-bc08-11bd86b0873c',
    video_length: 0.05,
    video_number: '758',
    size: 1942840,
    hints: 'b_Hamstring_Curl_1_hints',
    common_mistakes: 'b_Hamstring_Curl_1_common_mistakes',
    breathing: 'b_Hamstring_Curl_1_breathing',
    is_other_side: 0,
    video_id: 'b_Hamstring_Curl_1',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Opposite Toe Reach',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOpposite%20Toe%20Reach.jpg?alt=media&token=15c5b811-98c1-4719-94cb-655dadfdc199',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOpposite%20Toe%20Reach.mp4?alt=media&token=1379209f-90be-4137-8d4d-43241c431a08',
    video_length: 0.05,
    video_number: '810',
    size: 1942840,
    hints: 'wp_Opposite_Toe_Reach_hints',
    common_mistakes: 'wp_Opposite_Toe_Reach_common_mistakes',
    breathing: 'wp_Opposite_Toe_Reach_breathing',
    video_id: 'wp_Opposite_Toe_Reach',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Step Up From Ground',
    is_free: false,
    primary_muscle: 'Quads, Hamstrings, Glutes',
    rep_range: '5-10',
    secondary_muscle: 'Adductors, Calves',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStep%20Up%20From%20Ground.jpg?alt=media&token=01290ac9-c7a9-465a-bde5-58b39cb06d89',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStep%20Up%20From%20Ground.mp4?alt=media&token=e343925e-2566-4791-a1cc-76d7364e8949',
    video_id: 'LnB_Step_Up_From_Ground_1',
    video_length: 0.05,
    video_number: '265',
    size: 4498149,
    hints: 'LnB_Step_Up_From_Ground_1_hints',
    common_mistakes: 'LnB_Step_Up_From_Ground_1_common_mistakes',
    breathing: 'LnB_Step_Up_From_Ground_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Cat & Cow Sequence',
    is_free: true,
    muscle_category: ['planks', 'abs'],
    primary_muscle: 'Spine, Core, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCat%20%26%20Cow%20Sequence.jpg?alt=media&token=e5f4ff4f-01b7-4b3f-bb1c-947ab32d0f2c',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCat%20%26%20Cow%20Sequence.mp4?alt=media&token=5a90edfc-0100-4b2c-8b19-82425c3ecaf3',
    video_id: 'lst_Cat_Cow_Sequence_1',
    video_length: 0.07,
    video_number: '718',
    size: 1953154,
    hints: 'lst_Cat_Cow_Sequence_1_hints',
    common_mistakes: 'lst_Cat_Cow_Sequence_1_common_mistakes',
    breathing: 'lst_Cat_Cow_Sequence_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'total body',
    name: 'High Pull Lateral Raise From Floor',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Middle Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Hamstrings, Adductors, Erector Spinae, Front Shoulders, Rear Shoulders, Traps, Serratus Anterior, Rhomboids, Teres Major, Teres Minor, Infraspinatus',
    subcategory_name: 'total body',
    tertiary_muscle: 'Core, Calves, Latissimus Dorsi, Lower Back',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHigh%20Pull%20Lateral%20Raise%20From%20Floor.jpg?alt=media&token=a20a73dd-1f44-428e-bfb5-cc1a7cdf7f67',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHigh%20Pull%20Lateral%20Raise%20From%20Floor.mp4?alt=media&token=5167dc93-54b6-4eb2-8c66-1be74c7a7911',
    video_id: 'TnS_High_Pull_Lateral_Raise_From_Floor_1',
    video_length: 0.07,
    video_number: '470',
    size: 1198477,
    hints: 'TnS_High_Pull_Lateral_Raise_From_Floor_1_hints',
    common_mistakes: 'TnS_High_Pull_Lateral_Raise_From_Floor_1_common_mistakes',
    breathing: 'TnS_High_Pull_Lateral_Raise_From_Floor_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'upper body',
    is_free: true,
    name: 'Kickbacks',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FKickbacks.jpg?alt=media&token=da4287b1-7636-4556-8a11-288416adefef',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FKickbacks.mp4?alt=media&token=a66a6eff-e915-4197-a1a3-abedb4bf3ad2',
    video_length: 0.05,
    video_number: '832',
    size: 1942840,
    hints: 'ub_Kickbacks_hints',
    common_mistakes: 'ub_Kickbacks_common_mistakes',
    breathing: 'ub_Kickbacks_breathing',
    video_id: 'ub_Kickbacks',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Quadricep Stretch',
    is_free: true,
    muscle_category: ['legs'],
    primary_muscle: 'Quads',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FQuadricep%20Stretch.jpg?alt=media&token=27594a2e-0535-4b6f-86f8-208eef828376',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FQuadricep%20Stretch.mp4?alt=media&token=940a0377-a062-4497-bdc8-43d58c9f4806',
    video_id: 'lst_Quadricep_Stretch_1',
    video_length: 0.07,
    video_number: '667',
    size: 4470813,
    hints: 'lst_Quadricep_Stretch_1_hints',
    common_mistakes: 'lst_Quadricep_Stretch_1_common_mistakes',
    breathing: 'lst_Quadricep_Stretch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Pulsing Squat',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPulsing%20Squat.jpg?alt=media&token=2655139d-f2ae-4320-b5ca-043f8b2a78ce',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPulsing%20Squat.mp4?alt=media&token=07b6fd84-3bf2-44c8-b7b7-9d24620989dd',
    video_id: 'l_Pulsing_Squat',
    video_length: 0.05,
    video_number: '306',
    size: 758874,
    hints: 'l_Pulsing_Squat_hints',
    common_mistakes: 'l_Pulsing_Squat_common_mistakes',
    breathing: 'l_Pulsing_Squat_breathing',
  },
  {
    category_id: 3,
    category_name: 'total body',
    name: 'Double RDL Into Double Squat',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Erector Spinae, Rear Shoulders',
    rep_range: '4-8',
    secondary_muscle:
      'Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi, Erector Spinae, Middle Shoulders, Rhomboids, Infraspinatus, Teres Minor',
    subcategory_name: 'total body',
    tertiary_muscle: 'Core, Calves, Front Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FDouble%20RDL%20Into%20Double%20Squat.jpg?alt=media&token=6a6e74c8-d4b0-445b-b582-9d526144b012',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FDouble%20RDL%20Into%20Double%20Squat.mp4?alt=media&token=4fb98572-648d-4917-9c2e-8c3b0eca6efb',
    video_id: 'TnS_Double_RDL_Into_Double_Squat_1',
    video_length: 0.07,
    video_number: '465',
    size: 5312836,
    hints: 'TnS_Double_RDL_Into_Double_Squat_1_hints',
    common_mistakes: 'TnS_Double_RDL_Into_Double_Squat_1_common_mistakes',
    breathing: 'TnS_Double_RDL_Into_Double_Squat_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Wall Butterfly Pose',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Butterfly%20Pose.jpg?alt=media&token=663cf39d-a087-44bf-874e-f016b3e3739b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Butterfly%20Pose.mp4?alt=media&token=69340ca3-400b-439d-b906-2dbe2c8d1218',
    video_length: 0.05,
    video_number: '822',
    size: 1942840,
    hints: 'wp_Wall_Butterfly_Pose_hints',
    common_mistakes: 'wp_Wall_Butterfly_Pose_common_mistakes',
    breathing: 'wp_Wall_Butterfly_Pose_breathing',
    video_id: 'wp_Wall_Butterfly_Pose',
  },
  {
    category_id: 3,
    category_name: 'total body',
    name: 'Alternating Shoulder Press Into Squat',
    is_free: false,
    primary_muscle: 'Shoulders, Quads, Glutes',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Serratus Anterior, Hamstrings, Adductors, Erector Spinae',
    subcategory_name: 'total body',
    tertiary_muscle: 'Traps, Chest, Latissimus Dorsi, Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Shoulder%20Press%20Into%20Squat.jpg?alt=media&token=e645f544-e190-4ff7-9666-8b6841f08cb7',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Shoulder%20Press%20Into%20Squat.mp4?alt=media&token=60310209-5bd2-43fa-a54a-76b3ec456e7b',
    video_id: 'TnS_Alternating_Shoulder_Press_Into_Squat_1',
    video_length: 0.07,
    video_number: '475',
    size: 1739366,
    hints: 'TnS_Alternating_Shoulder_Press_Into_Squat_1_hints',
    common_mistakes:
      'TnS_Alternating_Shoulder_Press_Into_Squat_1_common_mistakes',
    breathing: 'TnS_Alternating_Shoulder_Press_Into_Squat_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Straight Curl Into Reverse Shoulder Press',
    is_free: true,
    primary_muscle: 'Biceps, Front Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Brachialis, Forearm, Chest, Traps, Middle Shoulders, Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Rear Shoulders, Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStraight%20Curl%20Into%20Reverse%20Shoulder%20Press.jpg?alt=media&token=0334da08-8893-4836-8f6d-777d1fae7605',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStraight%20Curl%20Into%20Reverse%20Shoulder%20Press.mp4?alt=media&token=6251bdc5-ccbd-4a39-9147-059b474af63a',
    video_id: 'TnS_Straight_Curl_Into_Reverse_Shoulder_Press_1',
    video_length: 0.07,
    video_number: '481',
    size: 1838999,
    hints: 'TnS_Straight_Curl_Into_Reverse_Shoulder_Press_1_hints',
    common_mistakes:
      'TnS_Straight_Curl_Into_Reverse_Shoulder_Press_1_common_mistakes',
    breathing: 'TnS_Straight_Curl_Into_Reverse_Shoulder_Press_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Squats',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquats.jpg?alt=media&token=091d35bf-0f9a-44dd-957d-030b4f60b0f2',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquats.mp4?alt=media&token=7b39dc51-344e-4eb7-85a3-ab901a0249bf',
    video_length: 0.05,
    video_number: '784',
    size: 1942840,
    hints: 'b_Squats_hints',
    common_mistakes: 'b_Squats_common_mistakes',
    breathing: 'b_Squats_breathing',
    video_id: 'b_Squats',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Alternating Leg Abduction',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Leg%20Abduction.jpg?alt=media&token=a220439b-606e-41ec-970b-643a2d657c80',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Leg%20Abduction.mp4?alt=media&token=aafd353d-1c7b-4909-a36a-6af53b9effdf',
    video_length: 0.05,
    video_number: '800',
    size: 1942840,
    hints: 'wp_Alternating_Leg_Abduction_hints',
    common_mistakes: 'wp_Alternating_Leg_Abduction_common_mistakes',
    breathing: 'wp_Alternating_Leg_Abduction_breathing',
    video_id: 'wp_Alternating_Leg_Abduction',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Standing Leg Abduction',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStanding%20Leg%20Abduction%20(R).jpg?alt=media&token=880c30bb-ef57-4502-800f-298d791dc4fc',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStanding%20Leg%20Abduction%20(R).mp4?alt=media&token=d28b76e2-0992-4460-94c4-2e6e2f45cd87',
    video_length: 0.05,
    video_number: '785',
    size: 1942840,
    hints: 'b_Standing_Leg_Abduction_2_hints',
    common_mistakes: 'b_Standing_Leg_Abduction_2_common_mistakes',
    breathing: 'b_Standing_Leg_Abduction_2_breathing',
    is_other_side: 1,
    video_id: 'b_Standing_Leg_Abduction_2',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Supported Semi Lunges',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSupported%20Semi%20Lunges%20(L).jpg?alt=media&token=ff02bbf8-d123-487d-823b-8c9d93aabb8b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSupported%20Semi%20Lunges%20(L).mp4?alt=media&token=e36bd69f-4200-4ff1-a487-8bec2bc48b16',
    video_length: 0.05,
    video_number: '817',
    size: 1942840,
    hints: 'wp_Supported_Semi_Lunges_1_hints',
    common_mistakes: 'wp_Supported_Semi_Lunges_1_common_mistakes',
    breathing: 'wp_Supported_Semi_Lunges_1_breathing',
    is_other_side: 0,
    video_id: 'wp_Supported_Semi_Lunges_1',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Straight Kick Pulses',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStraight%20Kick%20Pulses%20(L).jpg?alt=media&token=d53729ad-1a74-4a71-9ba8-09206f42f625',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStraight%20Kick%20Pulses%20(L).mp4?alt=media&token=3d176f9f-b9cd-45f0-8f54-7d07ee8c8501',
    video_length: 0.05,
    video_number: '788',
    size: 1942840,
    hints: 'b_Straight_Kick_Pulses_1_hints',
    common_mistakes: 'b_Straight_Kick_Pulses_1_common_mistakes',
    breathing: 'b_Straight_Kick_Pulses_1_breathing',
    is_other_side: 0,
    video_id: 'b_Straight_Kick_Pulses_1',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Rear Leg Lift',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRear%20Leg%20Lift%20(L).jpg?alt=media&token=1abcf4b2-0028-497f-8b1c-2b07c2cb7cb4',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRear%20Leg%20Lift%20(L).mp4?alt=media&token=76b45c1b-defc-4192-8177-b2588a52200f',
    video_length: 0.05,
    video_number: '773',
    size: 1942840,
    hints: 'b_Rear_Leg_Lift_1_hints',
    common_mistakes: 'b_Rear_Leg_Lift_1_common_mistakes',
    breathing: 'b_Rear_Leg_Lift_1_breathing',
    is_other_side: 0,
    video_id: 'b_Rear_Leg_Lift_1',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Low Runner',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLow%20Runner.jpg?alt=media&token=8a2db585-8b52-43fd-a36a-c828ff5f8d50',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLow%20Runner.mp4?alt=media&token=f8171d90-1b92-4511-b607-0ba972cec159',
    video_id: 'C_Low_Runner_1',
    video_length: 0.07,
    video_number: '518',
    size: 1426200,
    hints: 'C_Low_Runner_1_hints',
    common_mistakes: 'C_Low_Runner_1_common_mistakes',
    breathing: 'C_Low_Runner_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Arm Circles',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FArm%20Circles.jpg?alt=media&token=27cf0c2e-9f47-4f96-bed0-28de2b7f0652',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FArm%20Circles.mp4?alt=media&token=e9d79b74-5179-48af-9aba-5a72a78c96be',
    video_id: 'C_Arm_Circles',
    video_length: 0.07,
    video_number: '508',
    size: 1709183,
    hints: 'C_Arm_Circles_hints',
    common_mistakes: 'C_Arm_Circles_common_mistakes',
    breathing: 'C_Arm_Circles_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Hip Raise Planks',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHip%20Raise%20Planks.jpg?alt=media&token=c03208e9-5759-45f3-885d-96b8dc211d43',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHip%20Raise%20Planks.mp4?alt=media&token=c6c94edb-13e4-420c-b22d-fa0403d42a90',
    video_id: 'pl_Hip_Raise_Planks_1',
    video_length: 0.07,
    video_number: '616',
    size: 2321289,
    hints: 'pl_Hip_Raise_Planks_1_hints',
    common_mistakes: 'pl_Hip_Raise_Planks_1_common_mistakes',
    breathing: 'pl_Hip_Raise_Planks_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'X Plank',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FX%20Plank.jpg?alt=media&token=07d55f0b-5cc3-47aa-a50a-c2a5d895e077',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FX%20Plank.mp4?alt=media&token=b14082e7-af32-41b2-8582-a945dbd89fd7',
    video_id: 'pl_X_Plank_1',
    video_length: 0.07,
    video_number: '625',
    size: 567494,
    hints: 'pl_X_Plank_1_hints',
    common_mistakes: 'pl_X_Plank_1_common_mistakes',
    breathing: 'pl_X_Plank_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Leg Swing Side To Side',
    is_other_side: 1,
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Adductors, Glutes, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLeg%20Swing%20Side%20To%20Side%20(R).jpg?alt=media&token=f33d86b3-2954-491d-b493-1ed218e4f365',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLeg%20Swing%20Side%20To%20Side%20(R).mp4?alt=media&token=ed192160-10ac-4e0f-a565-7df92b37ca34',
    video_id: 'lst_Leg_Swing_Side_To_Side_2',
    video_length: 0.07,
    video_number: '630',
    size: 1972156,
    hints: 'lst_Leg_Swing_Side_To_Side_2_hints',
    common_mistakes: 'lst_Leg_Swing_Side_To_Side_2_common_mistakes',
    breathing: 'lst_Leg_Swing_Side_To_Side_2_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Wide Stance Squats',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWide%20Stance%20Squats.jpg?alt=media&token=902752b1-c3ea-4307-b5a3-6e254c992996',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWide%20Stance%20Squats.mp4?alt=media&token=5ecdf11a-4fed-481a-b893-81804f7090b9',
    video_id: 'LnB_Wide_Stance_Squat_1',
    video_length: 0.05,
    video_number: '279',
    size: 874495,
    hints: 'LnB_Wide_Stance_Squat_1_hints',
    common_mistakes: 'LnB_Wide_Stance_Squat_1_common_mistakes',
    breathing: 'LnB_Wide_Stance_Squat_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Side Bend Stretch',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Latissimus Dorsi, Serratus Anterior, Triceps',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Bend%20Stretch.jpg?alt=media&token=8ab70191-b83c-4c70-877c-330f54bcbc9d',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Bend%20Stretch.mp4?alt=media&token=bf469212-83cb-4958-9dc5-b2f228a8d89f',
    video_id: 'lst_Side_Bend_Stretch_1',
    video_length: 0.07,
    video_number: '724',
    size: 3473419,
    hints: 'lst_Side_Bend_Stretch_1_hints',
    common_mistakes: 'lst_Side_Bend_Stretch_1_common_mistakes',
    breathing: 'lst_Side_Bend_Stretch_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Mountain Climbers',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FMountain%20Climbers.jpg?alt=media&token=dbee6dd9-661c-4eb2-8493-ea8dfdaf637a',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FMountain%20Climbers.mp4?alt=media&token=caf8c589-a2e9-4e72-b5bd-ebfefec09ed7',
    video_id: 'pl_Mountain_Climbers_1',
    video_length: 0.07,
    video_number: '620',
    size: 1719189,
    hints: 'pl_Mountain_Climbers_1_hints',
    common_mistakes: 'pl_Mountain_Climbers_1_common_mistakes',
    breathing: 'pl_Mountain_Climbers_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs',
    name: 'Wide Squat Left & Right',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWide%20Squat%20Left%20%26%20Right.jpg?alt=media&token=a5e7fe1d-96c7-41e6-b4e1-7ded45958c2e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWide%20Squat%20Left%20%26%20Right.mp4?alt=media&token=b2fd5bbe-bf2f-46d8-8a7a-717125e381a4',
    video_length: 0.05,
    video_number: '796',
    size: 1942840,
    hints: 'b_Wide_Squat_Left_Right_1_hints',
    common_mistakes: 'b_Wide_Squat_Left_Right_1_common_mistakes',
    breathing: 'b_Wide_Squat_Left_Right_1_breathing',
    video_id: 'b_Wide_Squat_Left_Right_1',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Jumping Lunges',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FJumping%20Lunges.jpg?alt=media&token=32a1995a-51dc-480d-ac51-b18734f48a39',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FJumping%20Lunges.mp4?alt=media&token=0c373e08-7f88-46ff-aef4-5690e6095985',
    video_length: 0.05,
    video_number: '765',
    size: 1942840,
    hints: 'b_Jumping_Lunges_hints',
    common_mistakes: 'b_Jumping_Lunges_common_mistakes',
    breathing: 'b_Jumping_Lunges_breathing',
    video_id: 'b_Jumping_Lunges',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Loaded Beast To High Plank',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLoaded%20Beast%20To%20High%20Plank.jpg?alt=media&token=398ffbf9-4f18-477a-ac3c-ef65ff7f300c',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLoaded%20Beast%20To%20High%20Plank.mp4?alt=media&token=f1804ac5-3c0f-4efb-8a5b-5df38ae95cb6',
    video_id: 'pl_Loaded_Beast_To_High_Plank_1',
    video_length: 0.07,
    video_number: '614',
    size: 1656487,
    hints: 'pl_Loaded_Beast_To_High_Plank_1_hints',
    common_mistakes: 'pl_Loaded_Beast_To_High_Plank_1_common_mistakes',
    breathing: 'pl_Loaded_Beast_To_High_Plank_1_breathing',
  },
  {
    category_id: 1,
    category_name: 'upper body',
    name: 'Alternating Curls',
    is_free: true,
    primary_muscle: 'Biceps (Short Head)',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Biceps (Long Head), Brachialis, Forearm',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Curls.jpg?alt=media&token=4f7da851-f248-461a-b43f-9a5d60396df0',
    time: '75 seconds',
    time_to_play: 1.25,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Curls.mp4?alt=media&token=febfc01c-9ec2-4bb1-ae7e-20da7cb8b078',
    video_id: 'ub_Alternating_Curls',
    video_length: 0.05,
    video_number: '835',
    size: 418646,
    hints: 'ub_Alternating_Curls_hints',
    common_mistakes: 'ub_Alternating_Curls_common_mistakes',
    breathing: 'ub_Alternating_Curls_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Reverse Grip Kickbacks',
    is_free: true,
    primary_muscle: 'Triceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Rear Shoulders',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Latissimus Dorsi, Traps, Rhomboids',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FReverse%20Grip%20Kickbacks.jpg?alt=media&token=2e68e49f-100a-412b-bf70-13f49aeaf83e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FReverse%20Grip%20Kickbacks.mp4?alt=media&token=278c8a7c-3925-4272-bf5a-893e00f97c29',
    video_id: 'TnS_Reverse_Grip_Kickbacks_6',
    video_length: 0.08,
    video_number: '42',
    size: 539401,
    hints: 'TnS_Reverse_Grip_Kickbacks_6_hints',
    common_mistakes: 'TnS_Reverse_Grip_Kickbacks_6_common_mistakes',
    breathing: 'TnS_Reverse_Grip_Kickbacks_6_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Pulse Lunge',
    is_other_side: 1,
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPulse%20Lunge%20(R).jpg?alt=media&token=d80ed6f4-dd8c-4950-b431-b7b6a8f7cb79',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPulse%20Lunge%20(R).mp4?alt=media&token=b854a87b-1592-4e27-aae5-f6da323f0154',
    video_length: 0.05,
    video_number: '765',
    size: 1942840,
    hints: 'b_Pulse_Lunges_hints',
    common_mistakes: 'b_Pulse_Lunges_common_mistakes',
    breathing: 'b_Pulse_Lunges_breathing',
    video_id: 'b_Pulse_Lunges',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Pulse Lunge',
    is_other_side: 0,
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPulse%20Lunge%20(L).jpg?alt=media&token=09ac6c79-4394-44ec-81a0-574ef7a8ef83',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPulse%20Lunge%20(L).mp4?alt=media&token=dff7c6ce-c784-4524-9130-c412aeabec83',
    video_length: 0.05,
    video_number: '765',
    size: 1942840,
    hints: 'b_Pulse_Lunges_hints',
    common_mistakes: 'b_Pulse_Lunges_common_mistakes',
    breathing: 'b_Pulse_Lunges_breathing',
    video_id: 'b_Pulse_Lunges',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Pronated Fingers Down Pull',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Wrist, Biceps',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPronated%20Fingers%20Down%20Pull.jpg?alt=media&token=928d8e9b-b807-4eb2-a7b8-3966bec088a4',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPronated%20Fingers%20Down%20Pull.mp4?alt=media&token=736522e7-ff32-489e-a639-65e4646da08c',
    video_id: 'lst_Pronated_Fingers_Down_Pull_1',
    video_length: 0.07,
    video_number: '702',
    size: 4016746,
    hints: 'lst_Pronated_Fingers_Down_Pull_1_hints',
    common_mistakes: 'lst_Pronated_Fingers_Down_Pull_1_common_mistakes',
    breathing: 'lst_Pronated_Fingers_Down_Pull_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Side To Side Lunge',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20To%20Side%20Lunge.jpg?alt=media&token=5ece10aa-cef3-4756-bea1-41d0c036daff',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20To%20Side%20Lunge.mp4?alt=media&token=04d3a1ae-5afb-4963-85e7-c2a4a54dc57b',
    video_id: 'LnB_Side_To_Side_Lunge_1',
    video_length: 0.05,
    video_number: '302',
    size: 2407459,
    hints: 'LnB_Side_To_Side_Lunge_1_hints',
    common_mistakes: 'LnB_Side_To_Side_Lunge_1_common_mistakes',
    breathing: 'LnB_Side_To_Side_Lunge_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Twisted Cross',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Front Shoulders, Chest, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTwisted%20Cross.jpg?alt=media&token=8b677d08-71fd-45d2-b6f5-cacfc20573f3',
    time: '75 seconds',
    time_to_play: 1.25,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTwisted%20Cross.mp4?alt=media&token=4702a535-3990-4297-b63b-b70e250c241b',
    video_id: 'lst_Twisted_Cross_1',
    video_length: 0.07,
    video_number: '696',
    size: 6557998,
    hints: 'lst_Twisted_Cross_1_hints',
    common_mistakes: 'lst_Twisted_Cross_1_common_mistakes',
    breathing: 'lst_Twisted_Cross_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Lunge & Leg Raise',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLunge%20%26%20Leg%20Raise%20(L).jpg?alt=media&token=1628ad98-9cbd-4460-8ef4-86afd2fd43a2',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLunge%20%26%20Leg%20Raise%20(L).mp4?alt=media&token=19b9a34f-3ab7-49d4-b710-4b84ef6ca68d',
    video_length: 0.05,
    video_number: '764',
    size: 1942840,
    hints: 'b_Lunge_&_Leg_Raise_1_hints',
    common_mistakes: 'b_Lunge_&_Leg_Raise_1_common_mistakes',
    breathing: 'b_Lunge_&_Leg_Raise_1_breathing',
    is_other_side: 0,
    video_id: 'b_Lunge_&_Leg_Raise_1',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Lizard',
    is_other_side: 0,
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Quad, Glutes, Hamstrings, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLizard%20(L).jpg?alt=media&token=86d04d7d-3172-40b5-b9fb-30dda9414afe',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLizard%20(L).mp4?alt=media&token=c2a0ccab-c6e3-4c9b-af16-e2817fe91be5',
    video_id: 'lst_Lizard_1',
    video_length: 0.07,
    video_number: '631',
    size: 164524,
    hints: 'lst_Lizard_1_hints',
    common_mistakes: 'lst_Lizard_1_common_mistakes',
    breathing: 'lst_Lizard_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Hammer Kickbacks',
    is_free: false,
    primary_muscle: 'Triceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Rear Shoulders',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Latissimus Dorsi, Traps, Rhomboids',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHammer%20Kickbacks.jpg?alt=media&token=e3e9669b-050a-454b-a5f2-189b6c27546e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHammer%20Kickbacks.mp4?alt=media&token=117cdbd6-8cfb-497b-82e2-4eafe2ebe982',
    video_id: 'TnS_Hammer_Kickbacks_21',
    video_length: 0.05,
    video_number: '48',
    size: 411057,
    hints: 'TnS_Hammer_Kickbacks_21_hints',
    common_mistakes: 'TnS_Hammer_Kickbacks_21_common_mistakes',
    breathing: 'TnS_Hammer_Kickbacks_21_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Lying Walks To Bridge',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLying%20Walks%20To%20Bridge.jpg?alt=media&token=839fc6d2-8a16-41d2-ba9c-f351b604fcfa',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLying%20Walks%20To%20Bridge.mp4?alt=media&token=70a412f4-e523-4e1c-94ae-faa62d9ff080',
    video_length: 0.05,
    video_number: '809',
    size: 1942840,
    hints: 'wp_Lying_Walks_To_Bridge_hints',
    common_mistakes: 'wp_Lying_Walks_To_Bridge_common_mistakes',
    breathing: 'wp_Lying_Walks_To_Bridge_breathing',
    video_id: 'wp_Lying_Walks_To_Bridge',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Lunge Rotation Stretch',
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Glutes, Hip Flexors, Adductors, Spinal, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLunge%20Rotation%20Stretch.jpg?alt=media&token=2ad09458-ba67-47b7-b11f-17435ba843b5',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLunge%20Rotation%20Stretch.mp4?alt=media&token=3c1c6fcf-32f8-445d-87be-aaeaa2f4ef4d',
    video_id: 'lst_Lunge_Rotation_Stretch_1',
    video_length: 0.07,
    video_number: '663',
    size: 5276739,
    hints: 'lst_Lunge_Rotation_Stretch_1_hints',
    common_mistakes: 'lst_Lunge_Rotation_Stretch_1_common_mistakes',
    breathing: 'lst_Lunge_Rotation_Stretch_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Outer Thigh Circles',
    is_other_side: 1,
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOuter%20Thigh%20Circles%20(R).jpg?alt=media&token=b320db98-6b57-4fdf-80ce-e8a832ed31c5',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOuter%20Thigh%20Circles%20(R).mp4?alt=media&token=823a9830-60a4-42c0-a196-cd7bd089bfba',
    video_id: 'lst_Outer_Thigh_Circles',
    video_length: 0.07,
    video_number: '657',
    size: 2003097,
    hints: 'lst_Outer_Thigh_Circles_hints',
    common_mistakes: 'lst_Outer_Thigh_Circles_common_mistakes',
    breathing: 'lst_Outer_Thigh_Circles_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Arm Downward Across',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Middle Shoulders, Rear Shoulders',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FArm%20Downward%20Across.jpg?alt=media&token=eec45fae-e20b-485d-a946-aa705b4bcfb5',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FArm%20Downward%20Across.mp4?alt=media&token=ff731002-5ffe-43b9-8ba2-f534ea24dd9d',
    video_id: 'lst_Arm_Downward_Across_1',
    video_length: 0.07,
    video_number: '707',
    size: 4913331,
    hints: 'lst_Arm_Downward_Across_1_hints',
    common_mistakes: 'lst_Arm_Downward_Across_1_common_mistakes',
    breathing: 'lst_Arm_Downward_Across_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Shoulder Sawing',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Shoulders',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FShoulder%20Sawing.jpg?alt=media&token=119bd41b-1544-407d-8ba2-772193ee6380',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FShoulder%20Sawing.mp4?alt=media&token=bc45d7b4-0a48-457e-9882-fe3e279cc1e3',
    video_id: 'lst_Shoulder_Sawing_1',
    video_length: 0.07,
    video_number: '717',
    size: 926355,
    hints: 'lst_Shoulder_Sawing_1_hints',
    common_mistakes: 'lst_Shoulder_Sawing_1_common_mistakes',
    breathing: 'lst_Shoulder_Sawing_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Back & Forth Hamstring Stretch',
    is_free: true,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstrings',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBack%20%26%20Forth%20Hamstring%20Stretch.jpg?alt=media&token=be03e758-e01d-48ee-874f-ace03d18ff0e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBack%20%26%20Forth%20Hamstring%20Stretch.mp4?alt=media&token=8605f616-874f-4a16-aff2-091cf9dae9f9',
    video_id: 'lst_Back_Forth_Hamstring_Stretch_1',
    video_length: 0.07,
    video_number: '640',
    size: 2000334,
    hints: 'lst_Back_Forth_Hamstring_Stretch_1_hints',
    common_mistakes: 'lst_Back_Forth_Hamstring_Stretch_1_common_mistakes',
    breathing: 'lst_Back_Forth_Hamstring_Stretch_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Overhead Stretches',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Latissimus Dorsi, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOverhead%20Stretches.jpg?alt=media&token=6442cc67-a292-49b2-8bd5-f7b435765731',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Wall',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOverhead%20Stretches.mp4?alt=media&token=1c731ed4-a5c2-4fe0-b922-fca4b27fe421',
    video_id: 'lst_Overhead_Stretches',
    video_length: 0.07,
    video_number: '734',
    size: 1731854,
    hints: 'lst_Overhead_Stretches_hints',
    common_mistakes: 'lst_Overhead_Stretches_common_mistakes',
    breathing: 'lst_Overhead_Stretches_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'High Plank Leg Lifts',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHigh%20Plank%20Leg%20Lifts.jpg?alt=media&token=6f162bc7-112d-45ec-84d8-a0e13e3b557d',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHigh%20Plank%20Leg%20Lifts.mp4?alt=media&token=abe2f5f3-e995-407f-ac4d-97239244f614',
    video_id: 'pl_High_Plank_Leg_Lifts_1',
    video_length: 0.07,
    video_number: '621',
    size: 1451359,
    hints: 'pl_High_Plank_Leg_Lifts_1_hints',
    common_mistakes: 'pl_High_Plank_Leg_Lifts_1_common_mistakes',
    breathing: 'pl_High_Plank_Leg_Lifts_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Squat To Courtsey Lunge',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20To%20Courtsey%20Lunge.jpg?alt=media&token=2c644a00-0732-425a-9a67-dcf0f4acecc2',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20To%20Courtsey%20Lunge.mp4?alt=media&token=895df7f5-48ca-4737-a2ae-e95c294b861d',
    video_length: 0.05,
    video_number: '782',
    size: 1942840,
    hints: 'b_Squat_To_Courtsey_Lunge_hints',
    common_mistakes: 'b_Squat_To_Courtsey_Lunge_common_mistakes',
    breathing: 'b_Squat_To_Courtsey_Lunge_breathing',
    video_id: 'b_Squat_To_Courtsey_Lunge',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'High Plank Knee Drops',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHigh%20Plank%20Knee%20Drops.jpg?alt=media&token=b272c9c3-ed8d-4201-b3a8-708bb10d33d9',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHigh%20Plank%20Knee%20Drops.mp4?alt=media&token=196896f6-b2f5-439c-978d-35ac53bfe9cc',
    video_id: 'pl_High_Plank_Knee_Drops_1',
    video_length: 0.07,
    video_number: '623',
    size: 1223735,
    hints: 'pl_High_Plank_Knee_Drops_1_hints',
    common_mistakes: 'pl_High_Plank_Knee_Drops_1_common_mistakes',
    breathing: 'pl_High_Plank_Knee_Drops_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Body Saw',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBody%20Saw.jpg?alt=media&token=cbca9a57-921f-46e2-baa5-cab90fdba4a4',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBody%20Saw.mp4?alt=media&token=a278c007-ef0b-48ce-b9be-6fe8ca0ae4d3',
    video_id: 'pl_Body_Saw_1',
    video_length: 0.07,
    video_number: '602',
    size: 1340549,
    hints: 'pl_Body_Saw_1_hints',
    common_mistakes: 'pl_Body_Saw_1_common_mistakes',
    breathing: 'pl_Body_Saw_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Plank Jack Taps',
    is_free: true,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPlank%20Jack%20Taps.jpg?alt=media&token=6d5bda81-5834-425d-8125-d33f49db39eb',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPlank%20Jack%20Taps.mp4?alt=media&token=4243e2e7-678b-46c0-8ae9-a2ff97c46c1f',
    video_id: 'pl_Plank_Jack_Taps_1',
    video_length: 0.07,
    video_number: '617',
    size: 1326058,
    hints: 'pl_Plank_Jack_Taps_1_hints',
    common_mistakes: 'pl_Plank_Jack_Taps_1_common_mistakes',
    breathing: 'pl_Plank_Jack_Taps_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    group_name: 'Calves',
    name: 'Out Calf Raise',
    is_free: false,
    primary_muscle: 'Outer Calves',
    rep_range: '15-20',
    secondary_muscle: 'Inner Calves',
    subcategory_name: 'legs',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOut%20Calf%20Raise.jpg?alt=media&token=54b1f2cc-59c1-40b6-aa43-9d0b421d8ac7',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOut%20Calf%20Raise.mp4?alt=media&token=2445392b-d0cc-4a46-bc7d-82ac1d411504',
    video_id: 'LnB_Out_Calf_Raise',
    video_length: 0.1,
    video_number: '122',
    size: 663747,
    hints: 'LnB_Out_Calf_Raise_hints',
    common_mistakes: 'LnB_Out_Calf_Raise_common_mistakes',
    breathing: 'LnB_Out_Calf_Raise_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Sumo Squat & Arm Circle',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSumo%20Squat%20%26%20Arm%20Circle.jpg?alt=media&token=073aaabc-7f06-48fc-8fc9-d1683327c1d3',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSumo%20Squat%20%26%20Arm%20Circle.mp4?alt=media&token=ca064d1d-d480-4387-b82a-1619c8bc7d98',
    video_length: 0.05,
    video_number: '792',
    size: 1942840,
    hints: 'b_Sumo_Squat_&_Arm_Circle_hints',
    common_mistakes: 'b_Sumo_Squat_&_Arm_Circle_common_mistakes',
    breathing: 'b_Sumo_Squat_&_Arm_Circle_breathing',
    video_id: 'b_Sumo_Squat_&_Arm_Circle',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Plie With Knee Extension',
    is_other_side: 1,
    is_free: true,
    primary_muscle: 'Quads, Glutes',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPlie%20With%20Knee%20Extension%20(R).jpg?alt=media&token=2dafbc7c-3953-4936-9275-9517cd1b2f41',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPlie%20With%20Knee%20Extension%20(R).mp4?alt=media&token=1320b930-46e2-4c82-9589-1d902cda141f',
    video_id: 'l_Plie_With_Knee_Extension',
    video_length: 0.05,
    video_number: '285',
    size: 1430151,
    hints: 'l_Plie_With_Knee_Extension_hints',
    common_mistakes: 'l_Plie_With_Knee_Extension_common_mistakes',
    breathing: 'l_Plie_With_Knee_Extension_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Deep Lunge',
    is_free: true,
    muscle_category: ['booty'],
    primary_muscle: 'Hamstrings, Adductors, Glutes, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FDeep%20Lunge.jpg?alt=media&token=b8b8f74b-2f79-4872-a84b-a3b3e2713c45',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FDeep%20Lunge.mp4?alt=media&token=a47ad699-d701-4374-b6d8-6648bd077963',
    video_id: 'lst_Deep_Lunge_1',
    video_length: 0.07,
    video_number: '651',
    size: 4836970,
    hints: 'lst_Deep_Lunge_1_hints',
    common_mistakes: 'lst_Deep_Lunge_1_common_mistakes',
    breathing: 'lst_Deep_Lunge_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Bear Plank',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBear%20Plank.jpg?alt=media&token=38ac0a2f-57d8-4cb4-8759-fbffb5c39ecd',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBear%20Plank.mp4?alt=media&token=a3d31e8a-1593-4b2a-8756-a812ddccf0f1',
    video_id: 'pl_Bear_Plank_1',
    video_length: 0.07,
    video_number: '601',
    size: 349503,
    hints: 'pl_Bear_Plank_1_hints',
    common_mistakes: 'pl_Bear_Plank_1_common_mistakes',
    breathing: 'pl_Bear_Plank_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    is_other_side: 0,
    name: 'Split Squats',
    is_free: false,
    primary_muscle: 'Quads, Glutes',
    rep_range: '10-15',
    secondary_muscle: 'Hamstrings Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSplit%20Squats%20(L).jpg?alt=media&token=0c1be89e-292f-41e0-b3fd-43b19bd0946b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSplit%20Squats%20(L).mp4?alt=media&token=fe2840b3-1ee7-4b2a-874e-99e145bbbf20',
    video_id: 'LnB_Split_Squats_39',
    video_length: 0.05,
    video_number: '105',
    size: 412626,
    hints: 'LnB_Split_Squats_39_hints',
    common_mistakes: 'LnB_Split_Squats_39_common_mistakes',
    breathing: 'LnB_Split_Squats_39_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Single Leg Glute Bridge',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Leg%20Glute%20Bridge%20(R).jpg?alt=media&token=0d4effe8-1791-471c-9c97-70b76879c18a',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Leg%20Glute%20Bridge%20(R).mp4?alt=media&token=9c601d63-6835-4d0b-8f13-c0de09acf654',
    video_length: 0.05,
    video_number: '775',
    size: 1942840,
    hints: 'b_Single_Leg_Glute_Bridge_2_hints',
    common_mistakes: 'b_Single_Leg_Glute_Bridge_2_common_mistakes',
    breathing: 'b_Single_Leg_Glute_Bridge_2_breathing',
    is_other_side: 1,
    video_id: 'b_Single_Leg_Glute_Bridge_2',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Woodchopper',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWoodchopper.jpg?alt=media&token=9d3cabb9-5d3d-4797-b265-2d6da6ec4a70',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWoodchopper.mp4?alt=media&token=6a65181c-65cb-4092-86ab-ce04938c76b1',
    video_id: 'C_Woodchopper_1',
    video_length: 0.07,
    video_number: '534',
    size: 2358802,
    hints: 'C_Woodchopper_1_hints',
    common_mistakes: 'C_Woodchopper_1_common_mistakes',
    breathing: 'C_Woodchopper_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Single Leg Seated Hamstring Stretch',
    is_other_side: 1,
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstring',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Leg%20Seated%20Hamstring%20Stretch%20(R).jpg?alt=media&token=d2b929d8-70b1-4e2b-b4a0-248b13028f81',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Leg%20Seated%20Hamstring%20Stretch%20(R).mp4?alt=media&token=e44492a0-8fc0-4b6d-8516-65156864b66f',
    video_id: 'lst_Single_Leg_Seated_Hamstring_Stretch_2',
    video_length: 0.07,
    video_number: '636',
    size: 1766716,
    hints: 'lst_Single_Leg_Seated_Hamstring_Stretch_2_hints',
    common_mistakes:
      'lst_Single_Leg_Seated_Hamstring_Stretch_2_common_mistakes',
    breathing: 'lst_Single_Leg_Seated_Hamstring_Stretch_2_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Lift It Up Plank',
    is_free: true,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLift%20It%20Up%20Plank.jpg?alt=media&token=ff2776be-eca5-45fa-a9e1-b7eaa4751f3a',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLift%20It%20Up%20Plank.mp4?alt=media&token=2bc9f928-440d-47d0-bb4c-2198d048de69',
    video_id: 'pl_Lift_It_Up_Plank',
    video_length: 0.07,
    video_number: '618',
    size: 2439064,
    hints: 'pl_Lift_It_Up_Plank_hints',
    common_mistakes: 'pl_Lift_It_Up_Plank_common_mistakes',
    breathing: 'pl_Lift_It_Up_Plank_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Knees On Ground Plank Hold',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FKnees%20On%20Ground%20Plank%20Hold.jpg?alt=media&token=f1dac3bf-0af0-47de-9f4f-a05c8c842690',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FKnees%20On%20Ground%20Plank%20Hold.mp4?alt=media&token=b3830226-9bdf-41fc-9b36-070b9710d804',
    video_id: 'pl_Knees_On_Ground_Plank_Hold_1',
    video_length: 0.07,
    video_number: '612',
    size: 696695,
    hints: 'pl_Knees_On_Ground_Plank_Hold_1_hints',
    common_mistakes: 'pl_Knees_On_Ground_Plank_Hold_1_common_mistakes',
    breathing: 'pl_Knees_On_Ground_Plank_Hold_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Alternating Shoulder Rotations',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Shoulder%20Rotations.jpg?alt=media&token=cdc5fead-e5a3-41a3-b8a0-77c3c757e921',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Shoulder%20Rotations.mp4?alt=media&token=3093711d-546c-42e9-87ae-4e3743cd7d24',
    video_length: 0.05,
    video_number: '801',
    size: 1942840,
    hints: 'wp_Alternating_Shoulder_Rotations_hints',
    common_mistakes: 'wp_Alternating_Shoulder_Rotations_common_mistakes',
    breathing: 'wp_Alternating_Shoulder_Rotations_breathing',
    video_id: 'wp_Alternating_Shoulder_Rotations',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Alternating Knee To Elbow',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Knee%20To%20Elbow.jpg?alt=media&token=f4ef6500-4377-495b-9a91-213e324cb339',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Knee%20To%20Elbow.mp4?alt=media&token=42ab21d0-c0e6-40a1-8791-11de0505e833',
    video_id: 'h_Alternating_Knee_To_Elbow_1',
    video_length: 0.07,
    video_number: '538',
    size: 3099447,
    hints: 'h_Alternating_Knee_To_Elbow_1_hints',
    common_mistakes: 'h_Alternating_Knee_To_Elbow_1_common_mistakes',
    breathing: 'h_Alternating_Knee_To_Elbow_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Shoulder Tap High Plank',
    is_free: true,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FShoulder%20Tap%20High%20Plank.jpg?alt=media&token=77b02acb-92a8-469f-b7a0-3382b3361188',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FShoulder%20Tap%20High%20Plank.mp4?alt=media&token=b19ff526-bee9-4883-b3cd-bf771acbd6be',
    video_id: 'pl_Shoulder_Tap_High_Plank_1',
    video_length: 0.07,
    video_number: '605',
    size: 1159604,
    hints: 'pl_Shoulder_Tap_High_Plank_1_hints',
    common_mistakes: 'pl_Shoulder_Tap_High_Plank_1_common_mistakes',
    breathing: 'pl_Shoulder_Tap_High_Plank_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Straight Leg Curl',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStraight%20Leg%20Curl%20(R).jpg?alt=media&token=e2a3e543-49de-4c89-b6b7-f35485d9aeb4',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStraight%20Leg%20Curl%20(R).mp4?alt=media&token=819023c5-5151-4a96-b415-2c16a1fd8b1e',
    video_length: 0.05,
    video_number: '789',
    size: 1942840,
    hints: 'b_Straight_Leg_Curl_hints',
    common_mistakes: 'b_Straight_Leg_Curl_common_mistakes',
    breathing: 'b_Straight_Leg_Curl_breathing',
    is_other_side: 1,
    video_id: 'b_Straight_Leg_Curl',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Alternating Elbow Towards Hip',
    is_free: true,
    primary_muscle: 'Upper Abs, Obliques',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Elbow%20Towards%20Hip.jpg?alt=media&token=48d8b32c-544c-43e7-8559-8c5ab162a6dd',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Elbow%20Towards%20Hip.mp4?alt=media&token=6b071445-c76d-41a9-bd83-ea173ae2abfb',
    video_id: 'Abs_Alternating_Elbow_Towards_Hip_1',
    video_length: 0.07,
    video_number: '186',
    size: 1732734,
    hints: 'Abs_Alternating_Elbow_Towards_Hip_1_hints',
    common_mistakes: 'Abs_Alternating_Elbow_Towards_Hip_1_common_mistakes',
    breathing: 'Abs_Alternating_Elbow_Towards_Hip_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Bear Crawl',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBear%20Crawl.jpg?alt=media&token=6f1e4221-2b0c-46b8-b478-192231cb72f7',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBear%20Crawl.mp4?alt=media&token=d41bbb21-f952-46e5-9159-e4f1bff61744',
    video_length: 0.05,
    video_number: '746',
    size: 1942840,
    hints: 'b_Bear_Crawl_hints',
    common_mistakes: 'b_Bear_Crawl_common_mistakes',
    breathing: 'b_Bear_Crawl_breathing',
    video_id: 'b_Bear_Crawl',
  },
  {
    category_id: 3,
    category_name: 'total body',
    name: 'Push Press Into Sumo Squat',
    is_free: false,
    primary_muscle: 'Front Shoulders, Quads, Glutes, Gracilis',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Chest, Traps, Middle Shoulders, Serratus Anterior, Hamstrings, Adductors',
    subcategory_name: 'total body',
    tertiary_muscle: 'Rear Shoulders, Latissimus Dorsi, Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPush%20Press%20Into%20Sumo%20Squat.jpg?alt=media&token=827202ed-d3db-46c7-a040-96fb130d6b92',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPush%20Press%20Into%20Sumo%20Squat.mp4?alt=media&token=9b85dd68-880c-4f82-9153-b4f5637c86bc',
    video_id: 'TnS_Push_Press_Into_Sumo_Squat_1',
    video_length: 0.07,
    video_number: '443',
    size: 1609401,
    hints: 'TnS_Push_Press_Into_Sumo_Squat_1_hints',
    common_mistakes: 'TnS_Push_Press_Into_Sumo_Squat_1_common_mistakes',
    breathing: 'TnS_Push_Press_Into_Sumo_Squat_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Ab Crunch Into Touch The Sky',
    is_free: false,
    primary_muscle: 'Upper Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs, Obliques',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAb%20Crunch%20Into%20Touch%20The%20Sky.jpg?alt=media&token=db5ed3ca-6c49-4f3e-8982-a570beda92bd',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAb%20Crunch%20Into%20Touch%20The%20Sky.mp4?alt=media&token=0557bb8c-803b-4553-9dcb-00286ad786b5',
    video_id: 'Abs_Ab_Crunch_Into_Touch_The_Sky_1',
    video_length: 0.07,
    video_number: '212',
    size: 2315915,
    hints: 'Abs_Ab_Crunch_Into_Touch_The_Sky_1_hints',
    common_mistakes: 'Abs_Ab_Crunch_Into_Touch_The_Sky_1_common_mistakes',
    breathing: 'Abs_Ab_Crunch_Into_Touch_The_Sky_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Plank Hip Twist',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle: 'Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPlank%20Hip%20Twist.jpg?alt=media&token=85003662-ae3b-40a6-a831-6e5735f2c790',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPlank%20Hip%20Twist.mp4?alt=media&token=a028b4e5-0da4-4021-a217-6be56a0a3398',
    video_id: 'pl_Plank_Hip_Twist_1',
    video_length: 0.07,
    video_number: '587',
    size: 2346769,
    hints: 'pl_Plank_Hip_Twist_1_hints',
    common_mistakes: 'pl_Plank_Hip_Twist_1_common_mistakes',
    breathing: 'pl_Plank_Hip_Twist_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Alternating Toe Tap',
    is_free: false,
    primary_muscle: 'Obliques, Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Toe%20Tap.jpg?alt=media&token=e118ef12-79f8-4b54-b68d-d7163f520822',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Toe%20Tap.mp4?alt=media&token=e3a14290-9157-49af-a8f0-7a72bda5ad8b',
    video_id: 'Abs_Alternating_Toe_Tap_1',
    video_length: 0.07,
    video_number: '219',
    size: 3225427,
    hints: 'Abs_Alternating_Toe_Tap_1_hints',
    common_mistakes: 'Abs_Alternating_Toe_Tap_1_common_mistakes',
    breathing: 'Abs_Alternating_Toe_Tap_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Reverse Frog Stretch',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FReverse%20Frog%20Stretch%20(R).jpg?alt=media&token=bfee85ad-df2d-4253-be0f-dd93d7f64f0a',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FReverse%20Frog%20Stretch%20(R).mp4?alt=media&token=fb7743fb-f312-4f99-9ef0-0319440d8864',
    video_length: 0.05,
    video_number: '812',
    size: 1942840,
    hints: 'wp_Reverse_Frog_Stretch_2_hints',
    common_mistakes: 'wp_Reverse_Frog_Stretch_2_common_mistakes',
    breathing: 'wp_Reverse_Frog_Stretch_2_breathing',
    is_other_side: 1,
    video_id: 'wp_Reverse_Frog_Stretch_2',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Low Side Plank Twists',
    is_other_side: 0,
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLow%20Side%20Plank%20Twists%20(L).jpg?alt=media&token=5086c0ab-a3ed-45a2-bb20-14b430b953e1',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLow%20Side%20Plank%20Twists%20(L).mp4?alt=media&token=71c20e9c-c7e3-47a9-9c0d-158b70ad92fc',
    video_id: 'pl_Low_Side_Plank_Twists_1',
    video_length: 0.07,
    video_number: '595',
    size: 921340,
    hints: 'pl_Low_Side_Plank_Twists_1_hints',
    common_mistakes: 'pl_Low_Side_Plank_Twists_1_common_mistakes',
    breathing: 'pl_Low_Side_Plank_Twists_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Calf Stretch Assisted',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Calves',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCalf%20Stretch%20Assisted.jpg?alt=media&token=a4084a04-7094-41a7-ad8b-fe067a180b03',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Chair',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCalf%20Stretch%20Assisted.mp4?alt=media&token=20ed1f63-2fcd-494c-a83c-cbd00706364b',
    video_id: 'lst_Calf_Stretch_Assisted_1',
    video_length: 0.07,
    video_number: '645',
    size: 4286308,
    hints: 'lst_Calf_Stretch_Assisted_1_hints',
    common_mistakes: 'lst_Calf_Stretch_Assisted_1_common_mistakes',
    breathing: 'lst_Calf_Stretch_Assisted_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Squat To Jump Squat',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20To%20Jump%20Squat.jpg?alt=media&token=056223c6-e44e-42f7-98d3-c181470c5ecb',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20To%20Jump%20Squat.mp4?alt=media&token=14196b8b-eb9c-4a3a-bc24-258a46ac15ca',
    video_id: 'l_Squat_To_Jump_Squat',
    video_length: 0.05,
    video_number: '306',
    size: 758874,
    hints: 'l_Squat_To_Jump_Squat_hints',
    common_mistakes: 'l_Squat_To_Jump_Squat_common_mistakes',
    breathing: 'l_Squat_To_Jump_Squat_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Alternating Single Leg Crunches',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Single%20Leg%20Crunches.jpg?alt=media&token=4896f5bf-cd0c-43a5-bac7-20453146fd42',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Single%20Leg%20Crunches.mp4?alt=media&token=7f77f0d4-2c59-42c8-a859-d5980a454d1c',
    video_id: 'Abs_Alternating_Single_Leg_Crunches_1',
    video_length: 0.07,
    video_number: '215',
    size: 3118151,
    hints: 'Abs_Alternating_Single_Leg_Crunches_1_hints',
    common_mistakes: 'Abs_Alternating_Single_Leg_Crunches_1_common_mistakes',
    breathing: 'Abs_Alternating_Single_Leg_Crunches_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Half Way Rear Delt Raises',
    is_free: false,
    primary_muscle: 'Rear Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Middle Shoulders, Traps, Rhomboids, Infraspinatus, Teres Minor',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Front Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHalf%20Way%20Rear%20Delt%20Raises.jpg?alt=media&token=95c6155f-d0cc-4ba3-8e74-3ae58525a15b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHalf%20Way%20Rear%20Delt%20Raises.mp4?alt=media&token=37118a50-eb96-4b2e-b4d4-46f8951a87b8',
    video_id: 'TnS_HalfWayRearDeltRaises_43',
    video_length: 0.08,
    video_number: '89',
    size: 547871,
    hints: 'TnS_HalfWayRearDeltRaises_43_hints',
    common_mistakes: 'TnS_HalfWayRearDeltRaises_43_common_mistakes',
    breathing: 'TnS_HalfWayRearDeltRaises_43_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'V Ups',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle:
      'Obliques, Core, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FV%20Ups.jpg?alt=media&token=44f49874-ff8f-4a44-bbec-7f85bb8c1e87',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FV%20Ups.mp4?alt=media&token=803eea1b-d3da-4221-94d1-f1cccb446f83',
    video_id: 'Abs_V_Ups_3',
    video_length: 0.1,
    video_number: '7',
    size: 731046,
    hints: 'Abs_V_Ups_3_hints',
    common_mistakes: 'Abs_V_Ups_3_common_mistakes',
    breathing: 'Abs_V_Ups_3_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Child Pose With Pec Twist',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Chest, Front Shoulder, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FChild%20Pose%20With%20Pec%20Twist.jpg?alt=media&token=4b5e4f94-0bce-4b75-aab5-f8be4276b138',
    time: '75 seconds',
    time_to_play: 1.25,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FChild%20Pose%20With%20Pec%20Twist.mp4?alt=media&token=e8f5fe18-2289-4d1a-933e-9a82c571c216',
    video_id: 'lst_Child_Pose_With_Pec_Twist_1',
    video_length: 0.07,
    video_number: '694',
    size: 4391554,
    hints: 'lst_Child_Pose_With_Pec_Twist_1_hints',
    common_mistakes: 'lst_Child_Pose_With_Pec_Twist_1_common_mistakes',
    breathing: 'lst_Child_Pose_With_Pec_Twist_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Half Squat Box',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHalf%20Squat%20Box.jpg?alt=media&token=4a869e8c-2cef-4f72-80a7-aa0fe45780ec',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHalf%20Squat%20Box.mp4?alt=media&token=e9782dbf-ce20-4bf2-a789-1f6494e28b54',
    video_length: 0.05,
    video_number: '756',
    size: 1942840,
    hints: 'b_Half_Squat_Box_hints',
    common_mistakes: 'b_Half_Squat_Box_common_mistakes',
    breathing: 'b_Half_Squat_Box_breathing',
    video_id: 'b_Half_Squat_Box',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'One Arm Plank',
    is_other_side: 0,
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOne%20Arm%20Plank%20(L).jpg?alt=media&token=7c9cd4f7-3ee2-4cb4-92a1-641022cdb9ce',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOne%20Arm%20Plank%20(L).mp4?alt=media&token=cd8fe0c1-4b8f-4d11-ad12-de7ef8581c0e',
    video_id: 'pl_One_Arm_Plank_1',
    video_length: 0.07,
    video_number: '597',
    size: 514744,
    hints: 'pl_One_Arm_Plank_1_hints',
    common_mistakes: 'pl_One_Arm_Plank_1_common_mistakes',
    breathing: 'pl_One_Arm_Plank_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Rear Leg Lift',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRear%20Leg%20Lift%20(R).jpg?alt=media&token=e0566e51-3ece-48af-bcb7-37a8984faa5d',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRear%20Leg%20Lift%20(R).mp4?alt=media&token=1826a241-ba13-4b19-9223-481e3fd26dff',
    video_length: 0.05,
    video_number: '772',
    size: 1942840,
    hints: 'b_Rear_Leg_Lift_2_hints',
    common_mistakes: 'b_Rear_Leg_Lift_2_common_mistakes',
    breathing: 'b_Rear_Leg_Lift_2_breathing',
    is_other_side: 1,
    video_id: 'b_Rear_Leg_Lift_2',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Lizard',
    is_other_side: 1,
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Quad, Glutes, Hamstrings, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLizard%20(R).jpg?alt=media&token=cf3427b9-5839-46be-b051-ef70a4dd1e15',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLizard%20(R).mp4?alt=media&token=86584199-85bd-4d83-935b-2f13e342e87a',
    video_id: 'lst_Lizard_2',
    video_length: 0.07,
    video_number: '632',
    size: 150214,
    hints: 'lst_Lizard_2_hints',
    common_mistakes: 'lst_Lizard_2_common_mistakes',
    breathing: 'lst_Lizard_2_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Crunchy Frog',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCrunchy%20Frog.jpg?alt=media&token=494d0782-c605-4a9c-8312-5758f52e716c',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCrunchy%20Frog.mp4?alt=media&token=a80ef87d-4bd3-400b-9962-85a47d07061c',
    video_id: 'Abs_Crunchy_Frog_1',
    video_length: 0.07,
    video_number: '46',
    size: 497718,
    hints: 'Abs_Crunchy_Frog_1_hints',
    common_mistakes: 'Abs_Crunchy_Frog_1_common_mistakes',
    breathing: 'Abs_Crunchy_Frog_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Wide Legged V-Ups',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWide%20Legged%20V-Ups.jpg?alt=media&token=55321bf8-42ad-46a5-98d2-664b66bd8f18',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWide%20Legged%20V-Ups.mp4?alt=media&token=02606fc7-c58c-4088-9087-73a2a6732e4f',
    video_id: 'Abs_Wide_Legged_V_Ups_1',
    video_length: 0.07,
    video_number: '183',
    size: 1589193,
    hints: 'Abs_Wide_Legged_V_Ups_1_hints',
    common_mistakes: 'Abs_Wide_Legged_V_Ups_1_common_mistakes',
    breathing: 'Abs_Wide_Legged_V_Ups_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Squat Hops',
    is_free: false,
    primary_muscle: 'Quads, Hamstrings, Glutes, Calves, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20Hops.jpg?alt=media&token=955850c0-514e-4b71-b868-d8a520dcfd17',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20Hops.mp4?alt=media&token=6ed53648-2bc1-4fbd-9895-0bce7a9f32a6',
    video_id: 'LnB_Squat_Hops_1',
    video_length: 0.05,
    video_number: '301',
    size: 1609861,
    hints: 'LnB_Squat_Hops_1_hints',
    common_mistakes: 'LnB_Squat_Hops_1_common_mistakes',
    breathing: 'LnB_Squat_Hops_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Side Lunge Shoulder Press',
    is_other_side: 1,
    is_free: true,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors, Shoulders',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings Calves Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Lunge%20Shoulder%20Press%20(R).jpg?alt=media&token=87fb4427-1338-46a9-8435-2578e44e5e7a',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Lunge%20Shoulder%20Press%20(R).mp4?alt=media&token=ab7e0421-b0c5-4c20-979b-c395962e7124',
    video_id: 'l_Side_Lunge_Shoulder_Press',
    video_length: 0.05,
    video_number: '309',
    size: 3148991,
    hints: 'l_Side_Lunge_Shoulder_Press_hints',
    common_mistakes: 'l_Side_Lunge_Shoulder_Press_common_mistakes',
    breathing: 'l_Side_Lunge_Shoulder_Press_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Squat & Raise',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors, Shoulders',
    rep_range: 'Time Based',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20%26%20Raise.jpg?alt=media&token=d68b0293-712c-4047-863b-650c38303541',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20%26%20Raise.mp4?alt=media&token=c2cb5c9f-f48f-4d27-be99-a1849802684d',
    video_id: 'l_Squat_Raise',
    video_length: 0.05,
    video_number: '306',
    size: 758874,
    hints: 'l_Squat_Raise_hints',
    common_mistakes: 'l_Squat_Raise_common_mistakes',
    breathing: 'l_Squat_Raise_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Psycho Crunches',
    is_free: true,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPsycho%20Crunches.jpg?alt=media&token=3bee540e-c675-42a0-a621-21f7237b10bf',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPsycho%20Crunches.mp4?alt=media&token=b0cf6d5a-2dfc-4dbd-9ef9-2eb934145e29',
    video_id: 'Abs_Psycho_Crunches_1',
    video_length: 0.07,
    video_number: '187',
    size: 1961441,
    hints: 'Abs_Psycho_Crunches_1_hints',
    common_mistakes: 'Abs_Psycho_Crunches_1_common_mistakes',
    breathing: 'Abs_Psycho_Crunches_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Reach Up & Put Down',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Shoulders',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FReach%20Up%20%26%20Put%20Down.jpg?alt=media&token=1616b8b8-d25c-44a5-a08f-0c9a9ecee87b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FReach%20Up%20%26%20Put%20Down.mp4?alt=media&token=90c3fa4c-469b-4fb7-a703-1e74dd2d8817',
    video_id: 'l_Reach_Up_Put_Down',
    video_length: 0.05,
    video_number: '285',
    size: 1430151,
    hints: 'l_Reach_Up_Put_Down_hints',
    common_mistakes: 'l_Reach_Up_Put_Down_common_mistakes',
    breathing: 'l_Reach_Up_Put_Down_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Bicycles',
    is_free: true,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBicycles.jpg?alt=media&token=02eadeca-bf48-455a-9c83-464e91d59fec',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBicycles.mp4?alt=media&token=08d950a7-e9d6-4fa6-91aa-8f383e49d51f',
    video_id: 'Abs_Bicycles_20',
    video_length: 0.07,
    video_number: '6',
    size: 521446,
    hints: 'Abs_Bicycles_20_hints',
    common_mistakes: 'Abs_Bicycles_20_common_mistakes',
    breathing: 'Abs_Bicycles_20_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    is_other_side: 1,
    name: 'One Leg Wall Squat',
    is_free: false,
    primary_muscle: 'Quads, Glutes',
    rep_range: 'Time Based',
    secondary_muscle: 'Hamstrings, Adductors',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOne%20Leg%20Wall%20Squat%20(R).jpg?alt=media&token=04a690cc-54f4-429d-a262-ea630432639c',
    time: '45 seconds',
    time_to_play: 0.5,
    tools: 'Wall',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOne%20Leg%20Wall%20Squat%20(R).mp4?alt=media&token=714b5cbc-f9ca-4700-88af-e9b76a82b64e',
    video_id: 'LnB_One_Leg_Wall_Squat_12',
    video_length: 0.05,
    video_number: '128',
    size: 378776,
    hints: 'LnB_One_Leg_Wall_Squat_12_hints',
    common_mistakes: 'LnB_One_Leg_Wall_Squat_12_common_mistakes',
    breathing: 'LnB_One_Leg_Wall_Squat_12_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Front Kick',
    is_other_side: 0,
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFront%20Kick%20(L).jpg?alt=media&token=f1945a4b-ae0b-41ab-a4bc-c9faa61fcc5f',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFront%20Kick%20(L).mp4?alt=media&token=0f0969a7-a9ef-4c01-8d16-3eff30eeda3f',
    video_id: 'C_Front_Kick_1',
    video_length: 0.07,
    video_number: '508',
    size: 1709183,
    hints: 'C_Front_Kick_1_hints',
    common_mistakes: 'C_Front_Kick_1_common_mistakes',
    breathing: 'C_Front_Kick_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'High Plank Opposite Knee Touches',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHigh%20Plank%20Opposite%20Knee%20Touches.jpg?alt=media&token=5c210634-72c5-4fde-8af4-1460db3f2b32',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHigh%20Plank%20Opposite%20Knee%20Touches.mp4?alt=media&token=3f84b9b4-df10-4cf7-88ea-b1bb4be33fe0',
    video_id: 'pl_High_Plank_Opposite_Knee_Touches_1',
    video_length: 0.07,
    video_number: '606',
    size: 2743716,
    hints: 'pl_High_Plank_Opposite_Knee_Touches_1_hints',
    common_mistakes: 'pl_High_Plank_Opposite_Knee_Touches_1_common_mistakes',
    breathing: 'pl_High_Plank_Opposite_Knee_Touches_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    is_other_side: 1,
    name: 'One Leg Squats',
    is_free: false,
    primary_muscle: 'Quads, Glutes',
    rep_range: '5-10',
    secondary_muscle: 'Hamstrings, Adductors',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOne%20Leg%20Squats%20(R).jpg?alt=media&token=727c2430-c821-4146-8fe3-7734866d1862',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOne%20Leg%20Squats%20(R).mp4?alt=media&token=8995c1d1-30cd-4a43-b1bb-cf4cd87bacba',
    video_id: 'LnB_One_Leg_Squats_50',
    video_length: 0.08,
    video_number: '116(2)',
    size: 599586,
    hints: 'LnB_One_Leg_Squats_50_hints',
    common_mistakes: 'LnB_One_Leg_Squats_50_common_mistakes',
    breathing: 'LnB_One_Leg_Squats_50_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Plyo Split Squat',
    is_other_side: 1,
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPlyo%20Split%20Squat%20(R).jpg?alt=media&token=6f678e3d-2766-4654-a087-16a6ba1e7dd8',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPlyo%20Split%20Squat%20(R).mp4?alt=media&token=5fadc507-94b3-4f1c-bada-9d7621e9e309',
    video_id: 'LnB_Plyo_Split_Squat_2',
    video_length: 0.05,
    video_number: '283',
    size: 1942840,
    hints: 'LnB_Plyo_Split_Squat_2_hints',
    common_mistakes: 'LnB_Plyo_Split_Squat_2_common_mistakes',
    breathing: 'LnB_Plyo_Split_Squat_2_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Toe Crunches',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FToe%20Crunches.jpg?alt=media&token=115cd492-b83f-4810-aacc-e649a67cef80',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FToe%20Crunches.mp4?alt=media&token=3e5f32a8-0703-4cb8-8cdc-c3b46577f368',
    video_id: 'Abs_Toe_Crunches_29',
    video_length: 0.08,
    video_number: '32',
    size: 553861,
    hints: 'Abs_Toe_Crunches_29_hints',
    common_mistakes: 'Abs_Toe_Crunches_29_common_mistakes',
    breathing: 'Abs_Toe_Crunches_29_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Side Squat Steps',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Squat%20Steps.jpg?alt=media&token=14788242-eb38-417f-904b-a63fec8bb994',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Squat%20Steps.mp4?alt=media&token=300cf10c-5529-4998-94ff-61eff13e7cfb',
    video_length: 0.05,
    video_number: '774',
    size: 1942840,
    hints: 'b_Side_Squat_Steps_hints',
    common_mistakes: 'b_Side_Squat_Steps_common_mistakes',
    breathing: 'b_Side_Squat_Steps_breathing',
    video_id: 'b_Side_Squat_Steps',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Knee To Chest',
    is_free: true,
    muscle_category: ['booty'],
    primary_muscle: 'Glutes, Hamstrings, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FKnee%20To%20Chest.jpg?alt=media&token=d4c2d564-de77-4ff0-9ec5-262a4b31095e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FKnee%20To%20Chest.mp4?alt=media&token=39980ff3-68d0-447c-90eb-3f0408f6af21',
    video_id: 'lst_Knee_To_Chest_1',
    video_length: 0.07,
    video_number: '660',
    size: 4770106,
    hints: 'lst_Knee_To_Chest_1_hints',
    common_mistakes: 'lst_Knee_To_Chest_1_common_mistakes',
    breathing: 'lst_Knee_To_Chest_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Calf Raise Squats',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Calves',
    rep_range: '15-20',
    secondary_muscle: 'Hamstrings, Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCalf%20Raise%20Squats.jpg?alt=media&token=907b9f40-b81d-441a-82d7-2c252ed3027e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCalf%20Raise%20Squats.mp4?alt=media&token=d54f3499-ab38-443a-9dbb-17959859e8a8',
    video_id: 'LnB_Calf_Raise_Squats_31',
    video_length: 0.1,
    video_number: '103',
    size: 710803,
    hints: 'LnB_Calf_Raise_Squats_31_hints',
    common_mistakes: 'LnB_Calf_Raise_Squats_31_common_mistakes',
    breathing: 'LnB_Calf_Raise_Squats_31_breathing',
  },
  {
    category_id: 1,
    category_name: 'upper body',
    name: 'In And Out Hammer Curls',
    is_free: true,
    primary_muscle: 'Biceps, Brachialis',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Forearm',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FIn%20And%20Out%20Hammer%20Curls.jpg?alt=media&token=7f84c507-08fe-4b54-869b-77d68e96d5d5',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FIn%20And%20Out%20Hammer%20Curls.mp4?alt=media&token=51a86286-7721-4bf3-bdf5-6cc59fbc2b9f',
    video_id: 'CnB_In_And_Out_Hammer_Curls_36',
    video_length: 0.07,
    video_number: '63',
    size: 464243,
    hints: 'CnB_In_And_Out_Hammer_Curls_36_hints',
    common_mistakes: 'CnB_In_And_Out_Hammer_Curls_36_common_mistakes',
    breathing: 'CnB_In_And_Out_Hammer_Curls_36_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Butt Kickers',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FButt%20Kickers.jpg?alt=media&token=4c27126b-aeb5-4764-8147-b701ae094a24',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FButt%20Kickers.mp4?alt=media&token=abdc066e-f290-46f7-b729-4c563f53413c',
    video_id: 'h_Butt_Kickers_1',
    video_length: 0.07,
    video_number: '553',
    size: 1728692,
    hints: 'h_Butt_Kickers_1_hints',
    common_mistakes: 'h_Butt_Kickers_1_common_mistakes',
    breathing: 'h_Butt_Kickers_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: "Child's Pose",
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Adductors, Lower Back, Glutes, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FChild's%20Pose.jpg?alt=media&token=1100fc97-4935-44e7-9dd9-ba52c7eee423",
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: "https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FChild's%20Pose.mp4?alt=media&token=270540da-e9df-4c59-b133-45d87fb46318",
    video_id: 'lst_Child_pose_1',
    video_length: 0.07,
    video_number: '649',
    size: 802755,
    hints: 'lst_Child_pose_1_hints',
    common_mistakes: 'lst_Child_pose_1_common_mistakes',
    breathing: 'lst_Child_pose_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'High Heel',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHigh%20Heel.jpg?alt=media&token=1ece9b02-7402-49e3-9c89-66a25167520c',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHigh%20Heel.mp4?alt=media&token=f9d306d2-7401-4586-bd60-d544289848e5',
    video_id: 'h_High_Heel_1',
    video_length: 0.07,
    video_number: '546',
    size: 2208924,
    hints: 'h_High_Heel_1_hints',
    common_mistakes: 'h_High_Heel_1_common_mistakes',
    breathing: 'h_High_Heel_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Seated Active Forward Fold',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSeated%20Active%20Forward%20Fold.jpg?alt=media&token=0449e738-fba5-4250-82da-cc897c812a8b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSeated%20Active%20Forward%20Fold.mp4?alt=media&token=df25d8fd-c12e-4a81-aef2-71a91f8b6ea8',
    video_length: 0.05,
    video_number: '814',
    size: 1942840,
    hints: 'wp_Seated_Active_Forward_Fold_hints',
    common_mistakes: 'wp_Seated_Active_Forward_Fold_common_mistakes',
    breathing: 'wp_Seated_Active_Forward_Fold_breathing',
    video_id: 'wp_Seated_Active_Forward_Fold',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Ankle Taps Into Explosive Jumps',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core, Shoulders',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAnkle%20Taps%20Into%20Explosive%20Jumps.jpg?alt=media&token=45009a5a-6908-43d4-b828-192b2c3940aa',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAnkle%20Taps%20Into%20Explosive%20Jumps.mp4?alt=media&token=c42261a5-a8eb-4a89-871c-34df7515db6d',
    video_id: 'C_Ankle_Taps_Into_Explosive_Jumps_1',
    video_length: 0.07,
    video_number: '511',
    size: 2649855,
    hints: 'C_Ankle_Taps_Into_Explosive_Jumps_1_hints',
    common_mistakes: 'C_Ankle_Taps_Into_Explosive_Jumps_1_common_mistakes',
    breathing: 'C_Ankle_Taps_Into_Explosive_Jumps_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Rear Delt Raises',
    is_free: true,
    primary_muscle: 'Rear Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Middle Shoulders, Traps, Rhomboids, Infraspinatus, Teres Minor',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Front Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRear%20Delt%20Raises.jpg?alt=media&token=33deaf3c-c59a-4e99-971b-32ddb4a4fbf6',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRear%20Delt%20Raises.mp4?alt=media&token=6c7bc519-293d-4e74-b015-265582873a28',
    video_id: 'TnS_Rear_Delt_Raises_18',
    video_length: 0.1,
    video_number: '85',
    size: 718770,
    hints: 'TnS_Rear_Delt_Raises_18_hints',
    common_mistakes: 'TnS_Rear_Delt_Raises_18_common_mistakes',
    breathing: 'TnS_Rear_Delt_Raises_18_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Clamshell',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FClamshell%20(R).jpg?alt=media&token=8550f3f7-aa84-4ce2-9e40-3238439f48ac',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FClamshell%20(R).mp4?alt=media&token=51cbbf24-9c69-48ad-a947-b93a43a2fb51',
    video_length: 0.05,
    video_number: '750',
    size: 1942840,
    hints: 'b_Clamshell_2_hints',
    common_mistakes: 'b_Clamshell_2_common_mistakes',
    breathing: 'b_Clamshell_2_breathing',
    is_other_side: 1,
    video_id: 'b_Clamshell_2',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Row Boats',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRow%20Boats.jpg?alt=media&token=a6068bf1-7ae3-4112-a95b-4ca4935f0276',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRow%20Boats.mp4?alt=media&token=eaa3e5d2-8c60-42bb-ad8f-5550b0b0c482',
    video_id: 'Abs_Row_Boats_12',
    video_length: 0.1,
    video_number: '44',
    size: 660679,
    hints: 'Abs_Row_Boats_12_hints',
    common_mistakes: 'Abs_Row_Boats_12_common_mistakes',
    breathing: 'Abs_Row_Boats_12_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Narrow Pulsing Front Squat',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FNarrow%20Pulsing%20Front%20Squat.jpg?alt=media&token=1dc4a2f7-2165-4c02-85d8-d2de1e4ffa04',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FNarrow%20Pulsing%20Front%20Squat.mp4?alt=media&token=d5cb5bac-c26e-4a98-9ef3-0022b1dd6b11',
    video_id: 'LnB_Narrow_Pulsing_Front_Squat_1',
    video_length: 0.05,
    video_number: '298',
    size: 2663849,
    hints: 'LnB_Narrow_Pulsing_Front_Squat_1_hints',
    common_mistakes: 'LnB_Narrow_Pulsing_Front_Squat_1_common_mistakes',
    breathing: 'LnB_Narrow_Pulsing_Front_Squat_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Heel Touches',
    is_free: false,
    primary_muscle: 'Upper Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs, Obliques',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHeel%20Touches.jpg?alt=media&token=72b6df34-cdf7-48c4-bbfa-59c375c5e584',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHeel%20Touches.mp4?alt=media&token=2442e6c1-061b-43d2-a66e-6e0527f2d3e1',
    video_id: 'Abs_Heel_Touches_1',
    video_length: 0.07,
    video_number: '207',
    size: 1525043,
    hints: 'Abs_Heel_Touches_1_hints',
    common_mistakes: 'Abs_Heel_Touches_1_common_mistakes',
    breathing: 'Abs_Heel_Touches_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Front To Back Hop',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFront%20To%20Back%20Hop.jpg?alt=media&token=4d2f165f-f039-44fb-a444-4b6dbf3bef4f',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFront%20To%20Back%20Hop.mp4?alt=media&token=9205e2e5-eaed-48da-8c4b-dd240d7d5986',
    video_id: 'h_Front_To_Back_Hop_1',
    video_length: 0.07,
    video_number: '545',
    size: 2141031,
    hints: 'h_Front_To_Back_Hop_1_hints',
    common_mistakes: 'h_Front_To_Back_Hop_1_common_mistakes',
    breathing: 'h_Front_To_Back_Hop_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'One Arm Plank',
    is_other_side: 1,
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOne%20Arm%20Plank%20(R).jpg?alt=media&token=343f4aaa-ce6a-42b0-b8e2-41239801ff21',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOne%20Arm%20Plank%20(R).mp4?alt=media&token=33a53784-a931-4290-8714-b0dffa866eae',
    video_id: 'pl_One_Arm_Plank_2',
    video_length: 0.07,
    video_number: '598',
    size: 386554,
    hints: 'pl_One_Arm_Plank_2_hints',
    common_mistakes: 'pl_One_Arm_Plank_2_common_mistakes',
    breathing: 'pl_One_Arm_Plank_2_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Single Arm High Plank',
    is_other_side: 0,
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Arm%20High%20Plank%20(L).jpg?alt=media&token=15dafd64-9518-46c1-a681-157b40ed2190',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Arm%20High%20Plank%20(L).mp4?alt=media&token=f9782642-6ad4-4656-8f0a-9cdb2361d2a6',
    video_id: 'pl_Single_Arm_High_Plank_1',
    video_length: 0.07,
    video_number: '599',
    size: 670676,
    hints: 'pl_Single_Arm_High_Plank_1_hints',
    common_mistakes: 'pl_Single_Arm_High_Plank_1_common_mistakes',
    breathing: 'pl_Single_Arm_High_Plank_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Alternating Knee Tap',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Knee%20Tap.jpg?alt=media&token=cd85b2fb-c427-4147-8a4a-cc89329a8fea',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Knee%20Tap.mp4?alt=media&token=a038e485-3bfc-4a49-bb77-0953acfdbbf2',
    video_id: 'h_Alternating_Knee_Tap_1',
    video_length: 0.07,
    video_number: '537',
    size: 1906760,
    hints: 'h_Alternating_Knee_Tap_1_hints',
    common_mistakes: 'h_Alternating_Knee_Tap_1_common_mistakes',
    breathing: 'h_Alternating_Knee_Tap_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Split Pulsing Squats',
    is_other_side: 1,
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSplit%20Pulsing%20Squats%20(R).jpg?alt=media&token=e092f54a-5844-4904-bafb-02662c531d7b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSplit%20Pulsing%20Squats%20(R).mp4?alt=media&token=26e3fed1-9dd6-43e0-a24f-f6837ae05b69',
    video_length: 0.05,
    video_number: '765',
    size: 1942840,
    hints: 'b_Split_Pulsing_Squats_hints',
    common_mistakes: 'b_Split_Pulsing_Squats_common_mistakes',
    breathing: 'b_Split_Pulsing_Squats_breathing',
    video_id: 'b_Split_Pulsing_Squats',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Tricep Push Together',
    is_free: true,
    primary_muscle: 'Triceps',
    rep_range: '20-30',
    secondary_muscle: 'Front Shoulders, Chest, Latissimus Dorsi',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Biceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTricep%20Push%20Together.jpg?alt=media&token=35332675-c2df-4c01-b319-bd44e4ecb36a',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTricep%20Push%20Together.mp4?alt=media&token=c624d238-e5fc-4b5f-abb9-2100534891f5',
    video_id: 'ub_Tricep_Push_Together',
    video_length: 0.07,
    video_number: '14',
    size: 422850,
    hints: 'ub_Tricep_Push_Together_hints',
    common_mistakes: 'ub_Tricep_Push_Together_common_mistakes',
    breathing: 'ub_Tricep_Push_Together_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Low Bicycle',
    is_free: true,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLow%20Bicycle.jpg?alt=media&token=837b4edd-4288-462b-9838-cfb36afd8400',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLow%20Bicycle.mp4?alt=media&token=9bb18e7c-534f-4e0a-9e3c-2a9793824226',
    video_id: 'Abs_Low_Bicycle_1',
    video_length: 0.07,
    video_number: '216',
    size: 2301895,
    hints: 'Abs_Low_Bicycle_1_hints',
    common_mistakes: 'Abs_Low_Bicycle_1_common_mistakes',
    breathing: 'Abs_Low_Bicycle_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs',
    name: 'Static Lunge',
    is_other_side: 1,
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStatic%20Lunge%20(R).jpg?alt=media&token=2150b200-f437-4288-90fc-d5fa761b84fc',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStatic%20Lunge%20(R).mp4?alt=media&token=3730534d-88c7-4551-bd9e-27f8096cc0a8',
    video_length: 0.05,
    video_number: '765',
    size: 1942840,
    hints: 'l_Static_Lunge_hints',
    common_mistakes: 'l_Static_Lunge_common_mistakes',
    breathing: 'l_Static_Lunge_breathing',
    video_id: 'l_Static_Lunge',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Rotational Chop',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRotational%20Chop.jpg?alt=media&token=41f76afb-d951-4747-a2f6-eaa65b9da3d1',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRotational%20Chop.mp4?alt=media&token=7a32d83a-346b-4fc5-983a-8cd7cef30872',
    video_id: 'C_Rotational_Chop_1',
    video_length: 0.07,
    video_number: '524',
    size: 2946465,
    hints: 'C_Rotational_Chop_1_hints',
    common_mistakes: 'C_Rotational_Chop_1_common_mistakes',
    breathing: 'C_Rotational_Chop_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Side Leg Raises',
    is_free: false,
    primary_muscle: 'Abs, Obliques',
    rep_range: 'Time Based',
    secondary_muscle: 'Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Leg%20Raises.jpg?alt=media&token=098517a8-a421-4dba-a169-fe6149fa72f1',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Leg%20Raises.mp4?alt=media&token=138e8f66-91cf-400f-819a-7c43eec0f2f1',
    video_id: 'Abs_Side_Leg_Raises_21',
    video_length: 0.05,
    video_number: '42',
    size: 417303,
    hints: 'Abs_Side_Leg_Raises_21_hints',
    common_mistakes: 'Abs_Side_Leg_Raises_21_common_mistakes',
    breathing: 'Abs_Side_Leg_Raises_21_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Front Kick',
    is_other_side: 1,
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFront%20Kick%20(R).jpg?alt=media&token=980b700c-e0f7-41eb-a7c8-53d066632194',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFront%20Kick%20(R).mp4?alt=media&token=072a5004-871d-4b5a-86ff-b8ea316b81e1',
    video_id: 'C_Front_Kick_2',
    video_length: 0.07,
    video_number: '509',
    size: 1742957,
    hints: 'C_Front_Kick_2_hints',
    common_mistakes: 'C_Front_Kick_2_common_mistakes',
    breathing: 'C_Front_Kick_2_breathing',
  },
  {
    category_id: 3,
    category_name: 'triceps & shoulders',
    name: 'Front Twist Raise',
    is_free: true,
    primary_muscle: 'Traps, Rhomboids, Middle Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Front Shoulders, Rear Shoulders, Teres Minor, Infraspinatus, Biceps',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Teres Major',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFront%20Twist%20Raise.jpg?alt=media&token=ed9db428-bad9-41b9-b7c8-e630cb471807',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFront%20Twist%20Raise.mp4?alt=media&token=2509fddb-310f-455c-8dc0-8a3bbb11bc33',
    video_id: 'TnS_Front_Twist_Raise_1',
    video_length: 0.07,
    video_number: '380',
    size: 2339416,
    hints: 'TnS_Front_Twist_Raise_1_hints',
    common_mistakes: 'TnS_Front_Twist_Raise_1_common_mistakes',
    breathing: 'TnS_Front_Twist_Raise_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Soccer Kick Pulses',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSoccer%20Kick%20Pulses%20(L).jpg?alt=media&token=179411dd-3a15-4089-ab00-d13062ea0beb',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSoccer%20Kick%20Pulses%20(L).mp4?alt=media&token=2b027c90-00d6-4f6b-a7e0-3c4c6cd719bf',
    video_length: 0.05,
    video_number: '778',
    size: 1942840,
    hints: 'b_Soccer_Kick_Pulses_1_hints',
    common_mistakes: 'b_Soccer_Kick_Pulses_1_common_mistakes',
    breathing: 'b_Soccer_Kick_Pulses_1_breathing',
    is_other_side: 0,
    video_id: 'b_Soccer_Kick_Pulses_1',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Butt Kicker Jump Squat',
    is_free: false,
    primary_muscle: 'Quads, Hamstrings, Glutes, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FButt%20Kicker%20Jump%20Squat.jpg?alt=media&token=6bef2e32-c9b6-4baf-a160-fb356449fc30',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FButt%20Kicker%20Jump%20Squat.mp4?alt=media&token=66f40256-1ac8-46f4-be3f-371ef77b2007',
    video_id: 'LnB_Butt_Kicker_Jump_Squat_1',
    video_length: 0.05,
    video_number: '289',
    size: 920108,
    hints: 'LnB_Butt_Kicker_Jump_Squat_1_hints',
    common_mistakes: 'LnB_Butt_Kicker_Jump_Squat_1_common_mistakes',
    breathing: 'LnB_Butt_Kicker_Jump_Squat_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Ab Rollups',
    is_free: false,
    primary_muscle: 'Upper Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs, Obliques',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAb%20Rollups.jpg?alt=media&token=d953caea-1803-49f5-a2c6-b5b506f269cd',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAb%20Rollups.mp4?alt=media&token=2ecf83bf-cb10-4e0a-a7af-db3fbb5d293b',
    video_id: 'Abs_Ab_Rollups_1',
    video_length: 0.07,
    video_number: '205',
    size: 3794651,
    hints: 'Abs_Ab_Rollups_1_hints',
    common_mistakes: 'Abs_Ab_Rollups_1_common_mistakes',
    breathing: 'Abs_Ab_Rollups_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Frog Jumps',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFrog%20Jumps.jpg?alt=media&token=cdf59046-ab78-4f74-bdb4-f7660ed086b5',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFrog%20Jumps.mp4?alt=media&token=27c547e4-53f9-45fe-93ba-364077a4931e',
    video_id: 'LnB_Frog_Jumps_1',
    video_length: 0.05,
    video_number: '296',
    size: 2541835,
    hints: 'LnB_Frog_Jumps_1_hints',
    common_mistakes: 'LnB_Frog_Jumps_1_common_mistakes',
    breathing: 'LnB_Frog_Jumps_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Wall Angel',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Angel.jpg?alt=media&token=350882e5-3b54-45e3-a7bd-496847add89a',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Angel.mp4?alt=media&token=0bca0326-145d-4f53-bb1d-bd12cff9f190',
    video_length: 0.05,
    video_number: '819',
    size: 1942840,
    hints: 'wp_Wall_Angel_hints',
    common_mistakes: 'wp_Wall_Angel_common_mistakes',
    breathing: 'wp_Wall_Angel_breathing',
    video_id: 'wp_Wall_Angel',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Skater',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSkater.jpg?alt=media&token=a5bde3a5-6999-475a-8004-51ceb939fa6b',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSkater.mp4?alt=media&token=02f9f4f0-1cec-460b-a3df-e907137992f6',
    video_id: 'h_Skater_1',
    video_length: 0.07,
    video_number: '564',
    size: 3934709,
    hints: 'h_Skater_1_hints',
    common_mistakes: 'h_Skater_1_common_mistakes',
    breathing: 'h_Skater_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Alternating Frogkicks',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Frogkicks.jpg?alt=media&token=36342868-d14e-4cbf-9c2c-11028f23a949',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Frogkicks.mp4?alt=media&token=6c099a63-6b8c-4151-b93b-3d8217513c33',
    video_length: 0.05,
    video_number: '745',
    size: 1942840,
    hints: 'b_Alternating_Frogkicks_hints',
    common_mistakes: 'b_Alternating_Frogkicks_common_mistakes',
    breathing: 'b_Alternating_Frogkicks_breathing',
    video_id: 'b_Alternating_Frogkicks',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Another Leg Kick',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAnother%20Leg%20Kick%20(R).jpg?alt=media&token=00bbbe27-7fe1-4535-b961-34d77f0bec69',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAnother%20Leg%20Kick%20(R).mp4?alt=media&token=2c757765-0b28-404b-9365-9aa031fe8ab5',
    video_length: 0.05,
    video_number: '803',
    size: 1942840,
    hints: 'wp_Another_Leg_Kick_2_hints',
    common_mistakes: 'wp_Another_Leg_Kick_2_common_mistakes',
    breathing: 'wp_Another_Leg_Kick_2_breathing',
    is_other_side: 1,
    video_id: 'wp_Another_Leg_Kick_2',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Plank Ups',
    is_free: true,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle: 'Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPlank%20Ups.jpg?alt=media&token=c69945b9-6557-491f-b06a-b1068e6cdb02',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPlank%20Ups.mp4?alt=media&token=41c8e93b-516c-429f-b6fc-6433cdcc107b',
    video_id: 'pl_Plank_Ups',
    video_length: 0.05,
    video_number: '739',
    size: 1670453,
    hints: 'pl_Plank_Ups_hints',
    common_mistakes: 'pl_Plank_Ups_common_mistakes',
    breathing: 'pl_Plank_Ups_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Rear Delt Openers',
    is_free: false,
    primary_muscle: 'Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Front Shoulders, Middle Shoulders, Serratus Anterior, Traps, Infraspinatus, Rhomboids,Teres Major, Teres Minor',
    subcategory_name: 'upper body',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRear%20Delt%20Openers.jpg?alt=media&token=ad31af40-490d-4937-8244-3811f372ee39',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRear%20Delt%20Openers.mp4?alt=media&token=b4f450fb-4f37-404b-b9b4-9522556c6a81',
    video_id: 'TnS_Rear_Delt_Openers_1',
    video_length: 0.07,
    video_number: '378',
    size: 1323115,
    hints: 'TnS_Rear_Delt_Openers_1_hints',
    common_mistakes: 'TnS_Rear_Delt_Openers_1_common_mistakes',
    breathing: 'TnS_Rear_Delt_Openers_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Side To Side Squat Pose',
    is_free: true,
    muscle_category: ['legs'],
    primary_muscle: 'Hip Flexors, Adductors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20To%20Side%20Squat%20Pose.jpg?alt=media&token=de95d227-2714-43bf-920b-096d72a8e2fc',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20To%20Side%20Squat%20Pose.mp4?alt=media&token=a5231589-e2d0-482d-8cb2-9b7fd2128301',
    video_id: 'lst_Side_To_Side_Squat_Pose_1',
    video_length: 0.07,
    video_number: '677',
    size: 1460676,
    hints: 'lst_Side_To_Side_Squat_Pose_1_hints',
    common_mistakes: 'lst_Side_To_Side_Squat_Pose_1_common_mistakes',
    breathing: 'lst_Side_To_Side_Squat_Pose_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Supported Semi Lunges',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSupported%20Semi%20Lunges%20(R).jpg?alt=media&token=bc353932-5fc3-4bd8-9331-3174f6c32b33',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSupported%20Semi%20Lunges%20(R).mp4?alt=media&token=f270ecbd-ea67-4a64-be1c-67deffb68e52',
    video_length: 0.05,
    video_number: '816',
    size: 1942840,
    hints: 'wp_Supported_Semi_Lunges_2_hints',
    common_mistakes: 'wp_Supported_Semi_Lunges_2_common_mistakes',
    breathing: 'wp_Supported_Semi_Lunges_2_breathing',
    is_other_side: 1,
    video_id: 'wp_Supported_Semi_Lunges_2',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Booty Flutter Kicks',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBooty%20Flutter%20Kicks.jpg?alt=media&token=1223c3b2-e2be-4652-a790-041b46ff5145',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBooty%20Flutter%20Kicks.mp4?alt=media&token=0e376c28-b261-481d-86f9-e0cf3c38bf1d',
    video_length: 0.05,
    video_number: '747',
    size: 1942840,
    hints: 'b_Booty_Flutter_Kicks_hints',
    common_mistakes: 'b_Booty_Flutter_Kicks_common_mistakes',
    breathing: 'b_Booty_Flutter_Kicks_breathing',
    video_id: 'b_Booty_Flutter_Kicks',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'High Glute Bridge Hold',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHigh%20Glute%20Bridge%20Hold.jpg?alt=media&token=5f13c727-b488-4f7b-9f9a-4218f389ca70',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHigh%20Glute%20Bridge%20Hold.mp4?alt=media&token=0aa9a61d-3e71-4a99-bee2-591f1b1450c2',
    video_length: 0.05,
    video_number: '759',
    size: 1942840,
    hints: 'b_High_Glute_Bridge_Hold_hints',
    common_mistakes: 'b_High_Glute_Bridge_Hold_common_mistakes',
    breathing: 'b_High_Glute_Bridge_Hold_breathing',
    video_id: 'b_High_Glute_Bridge_Hold',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Y Arm Openers',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Shoulders, Serratus Anterior, Chest, Latissimus Dorsi',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FY%20Arm%20Openers.jpg?alt=media&token=50139c0e-6f7a-4549-8d2a-fb201949ed66',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FY%20Arm%20Openers.mp4?alt=media&token=b14a425a-82fa-4014-9bf9-35cf6aaa635f',
    video_id: 'lst_Y_Arm_Openers_1',
    video_length: 0.07,
    video_number: '698',
    size: 2969356,
    hints: 'lst_Y_Arm_Openers_1_hints',
    common_mistakes: 'lst_Y_Arm_Openers_1_common_mistakes',
    breathing: 'lst_Y_Arm_Openers_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Head Tilt',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Traps',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHead%20Tilt.jpg?alt=media&token=4e74937c-78ca-4a98-a1d7-f72baccf7a12',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHead%20Tilt.mp4?alt=media&token=7d90614b-2cf1-46fe-ae8b-aa5eb30f925b',
    video_id: 'lst_Head_Tilt_1',
    video_length: 0.07,
    video_number: '731',
    size: 1469125,
    hints: 'lst_Head_Tilt_1_hints',
    common_mistakes: 'lst_Head_Tilt_1_common_mistakes',
    breathing: 'lst_Head_Tilt_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Broad Jump & Back Pedal',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBroad%20Jump%20%26%20Back%20Pedal.jpg?alt=media&token=6622572c-0a97-4ab5-90d9-37f5159bacf5',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBroad%20Jump%20%26%20Back%20Pedal.mp4?alt=media&token=9bb6674a-9d5c-4b65-a346-a1af5300945f',
    video_id: 'h_Broad_Jump_Back_Pedal_1',
    video_length: 0.07,
    video_number: '541',
    size: 1548611,
    hints: 'h_Broad_Jump_Back_Pedal_1_hints',
    common_mistakes: 'h_Broad_Jump_Back_Pedal_1_common_mistakes',
    breathing: 'h_Broad_Jump_Back_Pedal_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Parcel Up & Down',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FParcel%20Up%20%26%20Down.jpg?alt=media&token=ec1431b2-e832-4fe9-86d9-cba4981960cc',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FParcel%20Up%20%26%20Down.mp4?alt=media&token=44a612b2-f051-490d-81f8-eb9ab0ada29c',
    video_length: 0.05,
    video_number: '768',
    size: 1942840,
    hints: 'b_Parcel_Up_&_Down_hints',
    common_mistakes: 'b_Parcel_Up_&_Down_common_mistakes',
    breathing: 'b_Parcel_Up_&_Down_breathing',
    video_id: 'b_Parcel_Up_&_Down',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Bulgarian Split Squat',
    is_other_side: 1,
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBulgarian%20Split%20Squat%20(R).jpg?alt=media&token=e8b712ba-4275-4194-bb63-2d6d350db89e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Chair',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBulgarian%20Split%20Squat%20(R).mp4?alt=media&token=9424c759-6bc5-4586-a13e-52aada5e1101',
    video_id: 'LnB_Bulgarian_Split_Squat_2',
    video_length: 0.05,
    video_number: '743',
    size: 1942840,
    hints: 'LnB_Bulgarian_Split_Squat_2_hints',
    common_mistakes: 'LnB_Bulgarian_Split_Squat_2_common_mistakes',
    breathing: 'LnB_Bulgarian_Split_Squat_2_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Banana To Boat',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBanana%20To%20Boat.jpg?alt=media&token=853311bd-48f7-4da4-aa87-25bfa5b9ead9',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBanana%20To%20Boat.mp4?alt=media&token=d124e978-76c9-4495-b8f5-e517585aa615',
    video_id: 'Abs_BananaToBoat_31',
    video_length: 0.05,
    video_number: '54',
    size: 380302,
    hints: 'Abs_BananaToBoat_31_hints',
    common_mistakes: 'Abs_BananaToBoat_31_common_mistakes',
    breathing: 'Abs_BananaToBoat_31_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Leg Tuck Up',
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Glutes',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLeg%20Tuck%20Up.jpg?alt=media&token=d631a73b-0210-4aa0-a299-db0fa390fc4f',
    time: '75 seconds',
    time_to_play: 1.25,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLeg%20Tuck%20Up.mp4?alt=media&token=7914ab22-3cc6-4180-b1cf-30a9e191b0b9',
    video_id: 'lst_Leg_Tuck_Up_1',
    video_length: 0.07,
    video_number: '662',
    size: 888754,
    hints: 'lst_Leg_Tuck_Up_1_hints',
    common_mistakes: 'lst_Leg_Tuck_Up_1_common_mistakes',
    breathing: 'lst_Leg_Tuck_Up_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Straight Kick Pulses',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStraight%20Kick%20Pulses%20(R).jpg?alt=media&token=36baf067-21e0-43c0-b363-a08d318f618b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStraight%20Kick%20Pulses%20(R).mp4?alt=media&token=0f84d922-a593-4020-90b4-e3781ee7e3df',
    video_length: 0.05,
    video_number: '787',
    size: 1942840,
    hints: 'b_Straight_Kick_Pulses_2_hints',
    common_mistakes: 'b_Straight_Kick_Pulses_2_common_mistakes',
    breathing: 'b_Straight_Kick_Pulses_2_breathing',
    is_other_side: 1,
    video_id: 'b_Straight_Kick_Pulses_2',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Ab Crunch 90 Degree Knees',
    is_free: true,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAb%20Crunch%2090%20Degree%20Knees.jpg?alt=media&token=2da4badf-3f63-4769-bb6f-1181feb07810',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAb%20Crunch%2090%20Degree%20Knees.mp4?alt=media&token=6cefebf3-9d71-422a-8da5-2f3b6057b86f',
    video_id: 'Abs_Ab_Crunch_90_Degree_Knees_1',
    video_length: 0.07,
    video_number: '195',
    size: 893013,
    hints: 'Abs_Ab_Crunch_90_Degree_Knees_1_hints',
    common_mistakes: 'Abs_Ab_Crunch_90_Degree_Knees_1_common_mistakes',
    breathing: 'Abs_Ab_Crunch_90_Degree_Knees_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Levered Plank',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Abs',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLevered%20Plank.jpg?alt=media&token=8b0c8cb7-7fd3-4196-a435-33e61771735e',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLevered%20Plank.mp4?alt=media&token=af955be8-5265-4563-bbb2-d5b1bf7536c6',
    video_id: 'Abs_Levered_Plank_11',
    video_length: 0.05,
    video_number: '34',
    size: 397664,
    hints: 'Abs_Levered_Plank_11_hints',
    common_mistakes: 'Abs_Levered_Plank_11_common_mistakes',
    breathing: 'Abs_Levered_Plank_11_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Single Leg Calf Raise',
    is_free: false,
    primary_muscle: 'Calves',
    rep_range: '8-20',
    secondary_muscle: '',
    subcategory_name: 'legs',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Leg%20Calf%20Raise.jpg?alt=media&token=b4cf5460-9490-48fd-8270-7c13f1d54d9a',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Leg%20Calf%20Raise.mp4?alt=media&token=160e2c02-f021-49a8-ad91-5dd2e5b7aab1',
    video_id: 'LnB_Single_Leg_Calf_Raise_1',
    video_length: 0.05,
    video_number: '305',
    size: 1625396,
    hints: 'LnB_Single_Leg_Calf_Raise_1_hints',
    common_mistakes: 'LnB_Single_Leg_Calf_Raise_1_common_mistakes',
    breathing: 'LnB_Single_Leg_Calf_Raise_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Single Into Double Jackknives',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Into%20Double%20Jackknives.jpg?alt=media&token=b23809ee-f4ef-4c68-bd75-a0aafda3ef05',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Into%20Double%20Jackknives.mp4?alt=media&token=8adafe1f-fac2-4ddf-951d-eb8b51522e92',
    video_id: 'Abs_Single_Into_Double_Jackknives_1',
    video_length: 0.07,
    video_number: '188',
    size: 3358072,
    hints: 'Abs_Single_Into_Double_Jackknives_1_hints',
    common_mistakes: 'Abs_Single_Into_Double_Jackknives_1_common_mistakes',
    breathing: 'Abs_Single_Into_Double_Jackknives_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Rocker',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRocker.jpg?alt=media&token=c8dec23e-c82d-4a62-be56-9781d6016ec8',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRocker.mp4?alt=media&token=66f1d9ee-5b10-4682-97c8-998fd2843fb0',
    video_id: 'Abs_Rocker_1',
    video_length: 0.07,
    video_number: '200',
    size: 2571199,
    hints: 'Abs_Rocker_1_hints',
    common_mistakes: 'Abs_Rocker_1_common_mistakes',
    breathing: 'Abs_Rocker_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Fire Hydrant',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFire%20Hydrant.jpg?alt=media&token=f3f2abe8-2bac-469e-910f-87fb5b3c58a2',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFire%20Hydrant.mp4?alt=media&token=43d56d02-18dd-474c-9b03-472d858c4e0f',
    video_length: 0.05,
    video_number: '753',
    size: 1942840,
    hints: 'b_Fire_Hydrant_hints',
    common_mistakes: 'b_Fire_Hydrant_common_mistakes',
    breathing: 'b_Fire_Hydrant_breathing',
    video_id: 'b_Fire_Hydrant',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Squat Hold & Kungfu',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20Hold%20%26%20Kungfu.jpg?alt=media&token=bd93dbdd-1cc2-4c11-a38a-c4aa02f45573',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20Hold%20%26%20Kungfu.mp4?alt=media&token=176e61a2-cbf1-4fa6-b618-9e5cbe003c5a',
    video_length: 0.05,
    video_number: '779',
    size: 1942840,
    hints: 'b_Squat_Hold_&_Kungfu_hints',
    common_mistakes: 'b_Squat_Hold_&_Kungfu_common_mistakes',
    breathing: 'b_Squat_Hold_&_Kungfu_breathing',
    video_id: 'b_Squat_Hold_&_Kungfu',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Lateral Squat Jump',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Adductors, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLateral%20Squat%20Jump.jpg?alt=media&token=76d62465-ff93-4417-bc3d-7bf77f5ce5f4',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLateral%20Squat%20Jump.mp4?alt=media&token=1f4fe965-de51-4d45-a516-70aef043b0da',
    video_id: 'LnB_Lateral_Squat_Jump_1',
    video_length: 0.05,
    video_number: '292',
    size: 2768786,
    hints: 'LnB_Lateral_Squat_Jump_1_hints',
    common_mistakes: 'LnB_Lateral_Squat_Jump_1_common_mistakes',
    breathing: 'LnB_Lateral_Squat_Jump_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Plyo Split Squat',
    is_other_side: 0,
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPlyo%20Split%20Squat%20(L).jpg?alt=media&token=5a88efc7-b70c-4881-9800-1e6676a728bf',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPlyo%20Split%20Squat%20(L).mp4?alt=media&token=5915da33-a9f4-4b50-9126-83bc65338754',
    video_id: 'LnB_Plyo_Split_Squat_1',
    video_length: 0.05,
    video_number: '282',
    size: 2060718,
    hints: 'LnB_Plyo_Split_Squat_1_hints',
    common_mistakes: 'LnB_Plyo_Split_Squat_1_common_mistakes',
    breathing: 'LnB_Plyo_Split_Squat_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Warm Up Relaxing Jump',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWarm%20Up%20Relaxing%20Jump.jpg?alt=media&token=1b8f0636-991d-4a78-bd03-46b21578225b',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWarm%20Up%20Relaxing%20Jump.mp4?alt=media&token=18755904-a3cd-47fb-8d98-de7080864766',
    video_id: 'h_Warm_Up_Relaxing_Jump',
    video_length: 0.07,
    video_number: '544',
    size: 2717287,
    hints: 'h_Warm_Up_Relaxing_Jump_hints',
    common_mistakes: 'h_Warm_Up_Relaxing_Jump_common_mistakes',
    breathing: 'h_Warm_Up_Relaxing_Jump_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Wall Open Armed Bended Knee',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Open%20Armed%20Bended%20Knee%20(L).jpg?alt=media&token=aaa4e237-d5b9-4c28-b36d-7b0bd55e4b00',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Open%20Armed%20Bended%20Knee%20(L).mp4?alt=media&token=083413f9-a2ce-49d2-b9ec-3f41a8d104da',
    video_length: 0.05,
    video_number: '827',
    size: 1942840,
    hints: 'wp_Wall_Open_Armed_Bended_Knee_1_hints',
    common_mistakes: 'wp_Wall_Open_Armed_Bended_Knee_1_common_mistakes',
    breathing: 'wp_Wall_Open_Armed_Bended_Knee_1_breathing',
    is_other_side: 0,
    video_id: 'wp_Wall_Open_Armed_Bended_Knee_1',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Alternating Corkscrew',
    is_free: false,
    primary_muscle: 'Obliques, Lower Ab',
    rep_range: 'Time Based',
    secondary_muscle: 'Upper Abs, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Corkscrew.jpg?alt=media&token=21364149-3c03-40ec-b248-15806f46b215',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Corkscrew.mp4?alt=media&token=1ef3b6d7-5678-4d55-9056-f7bcbe153b4b',
    video_id: 'Abs_Alternating_Corkscrew_1',
    video_length: 0.07,
    video_number: '211',
    size: 2949332,
    hints: 'Abs_Alternating_Corkscrew_1_hints',
    common_mistakes: 'Abs_Alternating_Corkscrew_1_common_mistakes',
    breathing: 'Abs_Alternating_Corkscrew_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Hip Overs',
    is_free: false,
    primary_muscle: 'Lower Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Upper Abs, Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHip%20Overs.jpg?alt=media&token=8c8a7b67-8cf0-4386-b24c-16f83e1dfb2f',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHip%20Overs.mp4?alt=media&token=c681bf84-25c0-4a23-9565-4c73d827812e',
    video_id: 'Abs_Hip_Overs_1',
    video_length: 0.07,
    video_number: '194',
    size: 1119968,
    hints: 'Abs_Hip_Overs_1_hints',
    common_mistakes: 'Abs_Hip_Overs_1_common_mistakes',
    breathing: 'Abs_Hip_Overs_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Wide Curl Into Shoulder Press',
    is_free: true,
    primary_muscle: 'Biceps (Short Head), Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Biceps (Long Head), Brachialis, Forearm, Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Traps, Chest, Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWide%20Curl%20Into%20Shoulder%20Press.jpg?alt=media&token=df4da184-41e4-4635-957f-78621d48b782',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWide%20Curl%20Into%20Shoulder%20Press.mp4?alt=media&token=e128c2ed-9096-49b6-85c4-0c46412b6d3a',
    video_id: 'TnS_Wide_Curl_Into_Shoulder_Press_1',
    video_length: 0.07,
    video_number: '482',
    size: 2314991,
    hints: 'TnS_Wide_Curl_Into_Shoulder_Press_1_hints',
    common_mistakes: 'TnS_Wide_Curl_Into_Shoulder_Press_1_common_mistakes',
    breathing: 'TnS_Wide_Curl_Into_Shoulder_Press_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Soccer Kick Pulses',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSoccer%20Kick%20Pulses%20(R).jpg?alt=media&token=15a74cab-968c-42f5-9db4-3d5e0de76b9d',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSoccer%20Kick%20Pulses%20(R).mp4?alt=media&token=015dfdbc-b333-4c03-80d9-8794ae4a95a6',
    video_length: 0.05,
    video_number: '777',
    size: 1942840,
    hints: 'b_Soccer_Kick_Pulses_2_hints',
    common_mistakes: 'b_Soccer_Kick_Pulses_2_common_mistakes',
    breathing: 'b_Soccer_Kick_Pulses_2_breathing',
    is_other_side: 1,
    video_id: 'b_Soccer_Kick_Pulses_2',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Lateral Raise',
    is_free: true,
    primary_muscle: 'Middle Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Front Shoulders, Rear Shoulders, Traps, Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLateral%20Raise.jpg?alt=media&token=084451d7-5529-4c2b-a3fc-f361f840c925',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLateral%20Raise.mp4?alt=media&token=97f7e969-d4fb-4ef2-90c4-a5ca2d72fbd3',
    video_id: 'TnS_Lateral_Raise_38',
    video_length: 0.07,
    video_number: '81',
    size: 520173,
    hints: 'TnS_Lateral_Raise_38_hints',
    common_mistakes: 'TnS_Lateral_Raise_38_common_mistakes',
    breathing: 'TnS_Lateral_Raise_38_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Iron Butterfly',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FIron%20Butterfly.jpg?alt=media&token=cf59c3d5-f273-46ef-b96d-e2c4fb063e40',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FIron%20Butterfly.mp4?alt=media&token=e0093634-69bc-4e01-a56e-71f28976b438',
    video_id: 'Abs_Iron_Butterfly_28',
    video_length: 0.05,
    video_number: '8',
    size: 517485,
    hints: 'Abs_Iron_Butterfly_28_hints',
    common_mistakes: 'Abs_Iron_Butterfly_28_common_mistakes',
    breathing: 'Abs_Iron_Butterfly_28_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Pendulum Swings',
    is_other_side: 0,
    is_free: false,
    muscle_category: ['shoulders'],
    primary_muscle: 'Shoulders',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPendulum%20Swings%20(L).jpg?alt=media&token=77fadc17-95c0-41d6-b489-ea3412d7eb53',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Chair',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPendulum%20Swings%20(L).mp4?alt=media&token=0428d548-7b19-4b9e-9f31-a43876704c64',
    video_id: 'lst_Pendulum_Swings_1',
    video_length: 0.07,
    video_number: '740',
    size: 3851481,
    hints: 'lst_Pendulum_Swings_1_hints',
    common_mistakes: 'lst_Pendulum_Swings_1_common_mistakes',
    breathing: 'lst_Pendulum_Swings_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Squat & Reach Hop',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Shoulders, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20%26%20Reach%20Hop.jpg?alt=media&token=9fb799a3-742c-4304-8580-01f63aed0402',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20%26%20Reach%20Hop.mp4?alt=media&token=894a0c27-8ad5-4b25-b7a2-89b6383a5b4a',
    video_id: 'LnB_Squat_Reach_Hop_1',
    video_length: 0.05,
    video_number: '291',
    size: 1017280,
    hints: 'LnB_Squat_Reach_Hop_1_hints',
    common_mistakes: 'LnB_Squat_Reach_Hop_1_common_mistakes',
    breathing: 'LnB_Squat_Reach_Hop_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Arnold Press',
    is_free: true,
    primary_muscle: 'Front Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Chest, Traps, Middle Shoulders, Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Rear Shoulders, Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FArnold%20Press.jpg?alt=media&token=622cfcc2-f1cb-4317-bd51-8d07b532dfa9',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Chair',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FArnold%20Press.mp4?alt=media&token=0951a157-93ce-42fd-a5c3-620cb46c93d2',
    video_id: 'ub_Arnold_Press',
    video_length: 0.08,
    video_number: '68',
    size: 607078,
    hints: 'ub_Arnold_Press_hints',
    common_mistakes: 'ub_Arnold_Press_common_mistakes',
    breathing: 'ub_Arnold_Press_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Front And Side Raise',
    is_free: false,
    primary_muscle: 'Front Shoulders, Rear Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Chest, Latissimus Dorsi, Middle Shoulders, Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Traps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFront%20And%20Side%20Raise.jpg?alt=media&token=f5590011-e19a-48fc-a2fc-450a8e971005',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFront%20And%20Side%20Raise.mp4?alt=media&token=bb209e6e-9d2d-42ba-8fdc-afe1922a98bc',
    video_id: 'ub_Front_And_Side_Raise',
    video_length: 0.08,
    video_number: '62',
    size: 595273,
    hints: 'ub_Front_And_Side_Raise_hints',
    common_mistakes: 'ub_Front_And_Side_Raise_common_mistakes',
    breathing: 'ub_Front_And_Side_Raise_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Seated Hamstring Stretch',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstrings',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSeated%20Hamstring%20Stretch.jpg?alt=media&token=ade6199e-7100-4418-9b5f-a2a5f0161655',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSeated%20Hamstring%20Stretch.mp4?alt=media&token=52a0d35b-9285-4dae-baec-fc8be3b4d0b5',
    video_id: 'lst_Seated_Hamstring_Stretch_1',
    video_length: 0.07,
    video_number: '671',
    size: 1810937,
    hints: 'lst_Seated_Hamstring_Stretch_1_hints',
    common_mistakes: 'lst_Seated_Hamstring_Stretch_1_common_mistakes',
    breathing: 'lst_Seated_Hamstring_Stretch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Rocking Calf Raise',
    is_free: false,
    primary_muscle: 'Calves',
    rep_range: '8-20',
    secondary_muscle: '',
    subcategory_name: 'legs',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRocking%20Calf%20Raise.jpg?alt=media&token=7ee9dd99-76bd-49d4-bd30-75a3f3017268',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRocking%20Calf%20Raise.mp4?alt=media&token=6d086456-11f8-494c-99c3-408473a05038',
    video_id: 'LnB_Rocking_Calf_Raise_1',
    video_length: 0.05,
    video_number: '304',
    size: 2417838,
    hints: 'LnB_Rocking_Calf_Raise_1_hints',
    common_mistakes: 'LnB_Rocking_Calf_Raise_1_common_mistakes',
    breathing: 'LnB_Rocking_Calf_Raise_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'High Plank',
    is_free: true,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHigh%20Plank.jpg?alt=media&token=a9e2cf14-93df-4031-8c9b-1d613a351543',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHigh%20Plank.mp4?alt=media&token=f2deb3d5-086f-4c65-a1cb-c1837d1d59da',
    video_id: 'pl_High_Plank_1',
    video_length: 0.07,
    video_number: '589',
    size: 354337,
    hints: 'pl_High_Plank_1_hints',
    common_mistakes: 'pl_High_Plank_1_common_mistakes',
    breathing: 'pl_High_Plank_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Alternating Upper Circle Crunches',
    is_free: false,
    primary_muscle: 'Upper Abs, Obliques',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Upper%20Circle%20Crunches.jpg?alt=media&token=a2aeb7f1-7c7f-461c-b97b-87480292b0a9',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Upper%20Circle%20Crunches.mp4?alt=media&token=36a82e47-42ac-40da-8999-e5815c6c8b42',
    video_id: 'Abs_Alternating_Upper_Circle_Crunches_1',
    video_length: 0.07,
    video_number: '196',
    size: 3219983,
    hints: 'Abs_Alternating_Upper_Circle_Crunches_1_hints',
    common_mistakes: 'Abs_Alternating_Upper_Circle_Crunches_1_common_mistakes',
    breathing: 'Abs_Alternating_Upper_Circle_Crunches_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: '180 Jump Squat',
    is_free: true,
    primary_muscle: 'Quads, Hamstrings, Glutes, Adductors, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2F180%20Jump%20Squat.jpg?alt=media&token=abc68c12-7741-4eb2-8ab1-65e7f021ace3',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2F180%20Jump%20Squat.mp4?alt=media&token=04e95355-6ce4-413c-a823-acfe9eb41c65',
    video_id: 'LnB_180_Jump_Squat_1',
    video_length: 0.05,
    video_number: '286',
    size: 2121845,
    hints: 'LnB_180_Jump_Squat_1_hints',
    common_mistakes: 'LnB_180_Jump_Squat_1_common_mistakes',
    breathing: 'LnB_180_Jump_Squat_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Standing Leg Abduction',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStanding%20Leg%20Abduction%20(L).jpg?alt=media&token=c3dc0beb-290c-4e44-b0c7-5f3238bb428e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStanding%20Leg%20Abduction%20(L).mp4?alt=media&token=405e5550-d2e2-41fd-8c9a-8c065fb2aad2',
    video_length: 0.05,
    video_number: '786',
    size: 1942840,
    hints: 'b_Standing_Leg_Abduction_1_hints',
    common_mistakes: 'b_Standing_Leg_Abduction_1_common_mistakes',
    breathing: 'b_Standing_Leg_Abduction_1_breathing',
    is_other_side: 0,
    video_id: 'b_Standing_Leg_Abduction_1',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Lateral Squat Walk',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLateral%20Squat%20Walk.jpg?alt=media&token=13a10c3c-50be-40fb-aa89-639c23a308a6',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLateral%20Squat%20Walk.mp4?alt=media&token=71f0ed11-fee0-4a51-833e-b6b31dd3180b',
    video_length: 0.05,
    video_number: '762',
    size: 1942840,
    hints: 'b_Lateral_Squat_Walk_hints',
    common_mistakes: 'b_Lateral_Squat_Walk_common_mistakes',
    breathing: 'b_Lateral_Squat_Walk_breathing',
    video_id: 'b_Lateral_Squat_Walk',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Squat Into Knee Kick',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20Into%20Knee%20Kick.jpg?alt=media&token=3a56c6c6-7179-431b-a2c9-5ec10f40d678',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20Into%20Knee%20Kick.mp4?alt=media&token=900cfd99-ec22-47d9-abac-2a80afd215ed',
    video_id: 'LnB_Squat_Into_Knee_Kick_1',
    video_length: 0.05,
    video_number: '273',
    size: 2139697,
    hints: 'LnB_Squat_Into_Knee_Kick_1_hints',
    common_mistakes: 'LnB_Squat_Into_Knee_Kick_1_common_mistakes',
    breathing: 'LnB_Squat_Into_Knee_Kick_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Shoulder Roll',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Shoulders',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FShoulder%20Roll.jpg?alt=media&token=119101c1-0c0b-4af5-a0fa-00f582565f8c',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FShoulder%20Roll.mp4?alt=media&token=f51b8aa7-4570-4039-8834-5f2d4c7cd4c1',
    video_id: 'lst_Shoulder_Roll_1',
    video_length: 0.07,
    video_number: '716',
    size: 2736865,
    hints: 'lst_Shoulder_Roll_1_hints',
    common_mistakes: 'lst_Shoulder_Roll_1_common_mistakes',
    breathing: 'lst_Shoulder_Roll_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Arms Out Fingers & Forearm Stretch',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Forearm, Biceps',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FArms%20Out%20Fingers%20%26%20Forearm%20Stretch.jpg?alt=media&token=99f05f33-787f-4876-956e-c4d60752f61a',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FArms%20Out%20Fingers%20%26%20Forearm%20Stretch.mp4?alt=media&token=28de1833-7b28-45a7-a29e-ec41e44da226',
    video_id: 'lst_Arms_Out_Fingers_Forearm_Stretch_1',
    video_length: 0.07,
    video_number: '700',
    size: 1665003,
    hints: 'lst_Arms_Out_Fingers_Forearm_Stretch_1_hints',
    common_mistakes: 'lst_Arms_Out_Fingers_Forearm_Stretch_1_common_mistakes',
    breathing: 'lst_Arms_Out_Fingers_Forearm_Stretch_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Around The World',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Spine',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAround%20The%20World.jpg?alt=media&token=5d05d5ea-09f7-42c5-b935-0a8ff1119501',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAround%20The%20World.mp4?alt=media&token=a3848606-f162-4b3c-bd09-807dd35148fb',
    video_id: 'lst_Around_The_World_1',
    video_length: 0.07,
    video_number: '728',
    size: 3525195,
    hints: 'lst_Around_The_World_1_hints',
    common_mistakes: 'lst_Around_The_World_1_common_mistakes',
    breathing: 'lst_Around_The_World_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Plank & Sit Back',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPlank%20%26%20Sit%20Back.jpg?alt=media&token=0d2a8749-cec8-4b19-aa75-72db5ebff4ab',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPlank%20%26%20Sit%20Back.mp4?alt=media&token=bca008bb-33d1-475a-97f1-ee38e5df07c3',
    video_length: 0.05,
    video_number: '770',
    size: 1942840,
    hints: 'b_Plank_&_Sit_Back_hints',
    common_mistakes: 'b_Plank_&_Sit_Back_common_mistakes',
    breathing: 'b_Plank_&_Sit_Back_breathing',
    video_id: 'b_Plank_&_Sit_Back',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Cross Knee Planks',
    is_free: true,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCross%20Knee%20Planks.jpg?alt=media&token=d89d2f1c-d6d3-45db-a234-e1935909561e',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCross%20Knee%20Planks.mp4?alt=media&token=90db6954-d1e3-4a61-80bf-1be1d907a434',
    video_id: 'pl_Cross_Knee_Planks_1',
    video_length: 0.07,
    video_number: '590',
    size: 2306886,
    hints: 'pl_Cross_Knee_Planks_1_hints',
    common_mistakes: 'pl_Cross_Knee_Planks_1_common_mistakes',
    breathing: 'pl_Cross_Knee_Planks_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Lying Figure 4 Stretch',
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Glutes',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLying%20Figure%204%20Stretch.jpg?alt=media&token=12efd8a7-70cd-4c98-8a19-4b8d146b44bd',
    time: '90 seconds',
    time_to_play: 1.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLying%20Figure%204%20Stretch.mp4?alt=media&token=45d50255-2d0b-40c0-9b37-0ee8e32cde09',
    video_id: 'lst_Lying_Figure_4_Stretch_1',
    video_length: 0.07,
    video_number: '664',
    size: 10019849,
    hints: 'lst_Lying_Figure_4_Stretch_1_hints',
    common_mistakes: 'lst_Lying_Figure_4_Stretch_1_common_mistakes',
    breathing: 'lst_Lying_Figure_4_Stretch_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Hammer Raise Into Triple Tricep Extension',
    is_free: false,
    primary_muscle: 'Traps, Front Shoulders, Triceps',
    rep_range: '5-10',
    secondary_muscle:
      'Upper Chest, Middle Shoulders, Rear Shoulders Serratus Anterior, Rhomboids, Erector Spinae, Teres Major, Teres Minor, Forearm',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Core, Infraspinatus. Latissimus Dorsi, Lower Back',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHammer%20Raise%20Into%20Triple%20Tricep%20Extension.jpg?alt=media&token=3620553c-967d-4c30-a410-cf5a7c5aa338',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHammer%20Raise%20Into%20Triple%20Tricep%20Extension.mp4?alt=media&token=8159e6b4-fe18-46a8-bec1-e383d18b0b0f',
    video_id: 'TnS_Hammer_Raise_Into_Triple_Tricep_Extension_1',
    video_length: 0.07,
    video_number: '460',
    size: 2988563,
    hints: 'TnS_Hammer_Raise_Into_Triple_Tricep_Extension_1_hints',
    common_mistakes:
      'TnS_Hammer_Raise_Into_Triple_Tricep_Extension_1_common_mistakes',
    breathing: 'TnS_Hammer_Raise_Into_Triple_Tricep_Extension_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Single Leg Glute Bridge',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Leg%20Glute%20Bridge%20(L).jpg?alt=media&token=d8c26219-d162-4907-bdb2-83ce180eeead',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Leg%20Glute%20Bridge%20(L).mp4?alt=media&token=1f1a1280-2e7c-4384-8d7e-7742c3ec5b5a',
    video_length: 0.05,
    video_number: '776',
    size: 1942840,
    hints: 'b_Single_Leg_Glute_Bridge_1_hints',
    common_mistakes: 'b_Single_Leg_Glute_Bridge_1_common_mistakes',
    breathing: 'b_Single_Leg_Glute_Bridge_1_breathing',
    is_other_side: 0,
    video_id: 'b_Single_Leg_Glute_Bridge_1',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'V-Dive Into Heel Touch',
    is_free: false,
    primary_muscle: 'Upper Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs, Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FV-Dive%20Into%20Heel%20Touch.jpg?alt=media&token=0bc54022-3e2c-4210-9887-6c4e98648275',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FV-Dive%20Into%20Heel%20Touch.mp4?alt=media&token=acb240bb-863e-4629-b712-fa518896cb8e',
    video_id: 'Abs_V_Dive_Into_Heel_Touch_1',
    video_length: 0.07,
    video_number: '210',
    size: 1784550,
    hints: 'Abs_V_Dive_Into_Heel_Touch_1_hints',
    common_mistakes: 'Abs_V_Dive_Into_Heel_Touch_1_common_mistakes',
    breathing: 'Abs_V_Dive_Into_Heel_Touch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Hop Into Front Squat',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHop%20Into%20Front%20Squat.jpg?alt=media&token=a13faf36-cefb-4a97-8eba-fa170b784cb0',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHop%20Into%20Front%20Squat.mp4?alt=media&token=795f0780-50c2-453d-b56c-2c4429c3aeb0',
    video_id: 'LnB_Hop_Into_Front_Squat_1',
    video_length: 0.05,
    video_number: '299',
    size: 1321934,
    hints: 'LnB_Hop_Into_Front_Squat_1_hints',
    common_mistakes: 'LnB_Hop_Into_Front_Squat_1_common_mistakes',
    breathing: 'LnB_Hop_Into_Front_Squat_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    is_other_side: 0,
    name: 'One Leg Squats',
    is_free: false,
    primary_muscle: 'Quads, Glutes',
    rep_range: '5-10',
    secondary_muscle: 'Hamstrings, Adductors',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOne%20Leg%20Squats%20(L).jpg?alt=media&token=a6cbe52f-300f-4643-86ce-35c8ff49c71e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOne%20Leg%20Squats%20(L).mp4?alt=media&token=616cdb00-3b0c-4ef9-9eac-19adec4b2f61',
    video_id: 'LnB_One_Leg_Squats_5',
    video_length: 0.1,
    video_number: '116(1)',
    size: 705032,
    hints: 'LnB_One_Leg_Squats_5_hints',
    common_mistakes: 'LnB_One_Leg_Squats_5_common_mistakes',
    breathing: 'LnB_One_Leg_Squats_5_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Close Grip Shoulder Press',
    is_free: false,
    primary_muscle: 'Front Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Upper Chest, Traps, Middle Shoulders, Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Rear Shoulders, Middle Chest, Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FClose%20Grip%20Shoulder%20Press.jpg?alt=media&token=1d3eea89-3cee-4a59-8853-e04c385cbebc',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FClose%20Grip%20Shoulder%20Press.mp4?alt=media&token=e7ea2785-132f-49f0-a414-a88cf07367e1',
    video_id: 'TnS_Close_Grip_Shoulder_Press_1',
    video_length: 0.07,
    video_number: '428',
    size: 2886385,
    hints: 'TnS_Close_Grip_Shoulder_Press_1_hints',
    common_mistakes: 'TnS_Close_Grip_Shoulder_Press_1_common_mistakes',
    breathing: 'TnS_Close_Grip_Shoulder_Press_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Drunken Mountain Climbers',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FDrunken%20Mountain%20Climbers.jpg?alt=media&token=86077bd4-002f-4997-82fc-1c5a1efcc2e4',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FDrunken%20Mountain%20Climbers.mp4?alt=media&token=8e5807d2-798a-45c0-a28a-902ae917731e',
    video_id: 'pl_Drunken_Mountain_Climbers_1',
    video_length: 0.07,
    video_number: '592',
    size: 1931929,
    hints: 'pl_Drunken_Mountain_Climbers_1_hints',
    common_mistakes: 'pl_Drunken_Mountain_Climbers_1_common_mistakes',
    breathing: 'pl_Drunken_Mountain_Climbers_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Hamstring Curl',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHamstring%20Curl%20(R).jpg?alt=media&token=d1885187-9453-4995-b78c-88ba8824a474',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHamstring%20Curl%20(R).mp4?alt=media&token=8c57b1c4-cb3c-44fe-960e-dc9e4ae4621c',
    video_length: 0.05,
    video_number: '757',
    size: 1942840,
    hints: 'b_Hamstring_Curl__2_hints',
    common_mistakes: 'b_Hamstring_Curl__2_common_mistakes',
    breathing: 'b_Hamstring_Curl__2_breathing',
    is_other_side: 1,
    video_id: 'b_Hamstring_Curl__2',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Wide Tricep Kickback Into Shrug',
    is_free: false,
    primary_muscle: 'Triceps, Traps, Rhomboids',
    rep_range: '5-10',
    secondary_muscle:
      'Rear Shoulders, Levator Scapulae, Teres Major, Teres Minor',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWide%20Tricep%20Kickback%20Into%20Shrug.jpg?alt=media&token=4fe40f3b-bf94-4422-8b73-49a35af7a099',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWide%20Tricep%20Kickback%20Into%20Shrug.mp4?alt=media&token=473db060-175b-4d16-aff6-218dfa32f328',
    video_id: 'TnS_Wide_Tricep_Kickback_Into_Shrug_1',
    video_length: 0.07,
    video_number: '452',
    size: 2656171,
    hints: 'TnS_Wide_Tricep_Kickback_Into_Shrug_1_hints',
    common_mistakes: 'TnS_Wide_Tricep_Kickback_Into_Shrug_1_common_mistakes',
    breathing: 'TnS_Wide_Tricep_Kickback_Into_Shrug_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Low Jacks',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLow%20Jacks.jpg?alt=media&token=cfc5f4e0-f98f-4703-90a9-3891a1fc262f',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLow%20Jacks.mp4?alt=media&token=9f54fff4-e6ec-4b9b-8857-aa578614ebd1',
    video_id: 'h_Low_Jacks_1',
    video_length: 0.07,
    video_number: '556',
    size: 1204119,
    hints: 'h_Low_Jacks_1_hints',
    common_mistakes: 'h_Low_Jacks_1_common_mistakes',
    breathing: 'h_Low_Jacks_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Jumping Jack',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FJumping%20Jack.jpg?alt=media&token=161430c5-efe1-4a75-a042-4559c14fadbe',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FJumping%20Jack.mp4?alt=media&token=4ed64c7d-96f1-4ff7-bd1d-58336f6096d6',
    video_id: 'h_Jumping_Jack_1',
    video_length: 0.07,
    video_number: '550',
    size: 1656792,
    hints: 'h_Jumping_Jack_1_hints',
    common_mistakes: 'h_Jumping_Jack_1_common_mistakes',
    breathing: 'h_Jumping_Jack_1_breathing',
  },
  {
    category_id: 1,
    category_name: 'upper body',
    name: 'Out Curls',
    is_free: true,
    primary_muscle: 'Biceps (Short Head)',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Biceps (Long Head), Brachialis, Forearm',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOut%20Curls.jpg?alt=media&token=1e1ab792-e5dc-49c9-bcbf-bca3de3efedf',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOut%20Curls.mp4?alt=media&token=eab69d3b-f9f8-4c49-a829-ac096ed56b0a',
    video_id: 'CnB_Out_Curls_42',
    video_length: 0.07,
    video_number: '59',
    size: 502288,
    hints: 'CnB_Out_Curls_42_hints',
    common_mistakes: 'CnB_Out_Curls_42_common_mistakes',
    breathing: 'CnB_Out_Curls_42_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Mountain Climber Walk',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FMountain%20Climber%20Walk.jpg?alt=media&token=61531a62-6787-401f-bfdb-886dabb42238',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FMountain%20Climber%20Walk.mp4?alt=media&token=67157d1f-9a56-4226-928d-492a64cfd94a',
    video_length: 0.05,
    video_number: '767',
    size: 1942840,
    hints: 'b_Mountain_Climber_Walk_hints',
    common_mistakes: 'b_Mountain_Climber_Walk_common_mistakes',
    breathing: 'b_Mountain_Climber_Walk_breathing',
    video_id: 'b_Mountain_Climber_Walk',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Scissor Kicks Into Jackknives',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FScissor%20Kicks%20Into%20Jackknives.jpg?alt=media&token=ab2e7f48-f485-4028-8758-ab0ead193b8c',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FScissor%20Kicks%20Into%20Jackknives.mp4?alt=media&token=c4d08182-df95-471d-b0f2-bc866007f151',
    video_id: 'Abs_Scissor_Kicks_Into_Jackknives_1',
    video_length: 0.07,
    video_number: '208',
    size: 2099955,
    hints: 'Abs_Scissor_Kicks_Into_Jackknives_1_hints',
    common_mistakes: 'Abs_Scissor_Kicks_Into_Jackknives_1_common_mistakes',
    breathing: 'Abs_Scissor_Kicks_Into_Jackknives_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'X Drill Alternate Feet',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FX%20Drill%20Alternate%20Feet.jpg?alt=media&token=1e7f9157-6f04-4119-b1d5-6b09f4a86af8',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FX%20Drill%20Alternate%20Feet.mp4?alt=media&token=3fe26ec6-d5a1-44ee-8322-25eb192c8fd4',
    video_id: 'h_X_Drill_Alternate_Feet_1',
    video_length: 0.07,
    video_number: '573',
    size: 1668560,
    hints: 'h_X_Drill_Alternate_Feet_1_hints',
    common_mistakes: 'h_X_Drill_Alternate_Feet_1_common_mistakes',
    breathing: 'h_X_Drill_Alternate_Feet_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Behind The Back Jacks',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBehind%20The%20Back%20Jacks.jpg?alt=media&token=9f944a39-a257-493e-bed1-e7c59dd6594b',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBehind%20The%20Back%20Jacks.mp4?alt=media&token=971d6059-1e30-4d51-9cc2-895918a1607d',
    video_id: 'C_Behind_the_Back_Jacks',
    video_length: 0.07,
    video_number: '508',
    size: 1709183,
    hints: 'C_Behind_the_Back_Jacks_hints',
    common_mistakes: 'C_Behind_the_Back_Jacks_common_mistakes',
    breathing: 'C_Behind_the_Back_Jacks_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Hip Raises',
    is_free: false,
    primary_muscle: 'Lower Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Upper Abs, Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHip%20Raises.jpg?alt=media&token=724a1214-d12f-463a-aba5-a766185b3596',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHip%20Raises.mp4?alt=media&token=342f8170-c240-4019-b230-b2a016a03a40',
    video_id: 'Abs_Hip_Raises_15',
    video_length: 0.08,
    video_number: '24',
    size: 642195,
    hints: 'Abs_Hip_Raises_15_hints',
    common_mistakes: 'Abs_Hip_Raises_15_common_mistakes',
    breathing: 'Abs_Hip_Raises_15_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Knees',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FKnees.jpg?alt=media&token=2d6aa6ef-d147-4749-91c9-56a8f38d0166',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FKnees.mp4?alt=media&token=32c44b23-6c15-4ebc-acfa-a0a2aa291de7',
    video_id: 'C_Knees_1',
    video_length: 0.07,
    video_number: '516',
    size: 5099132,
    hints: 'C_Knees_1_hints',
    common_mistakes: 'C_Knees_1_common_mistakes',
    breathing: 'C_Knees_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Lying Quad Stretch',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Quads',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLying%20Quad%20Stretch.jpg?alt=media&token=ba234a81-1b20-4a0a-8ced-dff1eff53051',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLying%20Quad%20Stretch.mp4?alt=media&token=6fec7279-f8d6-4708-b4eb-ec302e5fc73b',
    video_id: 'lst_Lying_Quad_Stretch_1',
    video_length: 0.07,
    video_number: '666',
    size: 9058679,
    hints: 'lst_Lying_Quad_Stretch_1_hints',
    common_mistakes: 'lst_Lying_Quad_Stretch_1_common_mistakes',
    breathing: 'lst_Lying_Quad_Stretch_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Explosive',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FExplosive.jpg?alt=media&token=0145aadd-3f71-4359-9efa-b5a86af09bce',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FExplosive.mp4?alt=media&token=4665b68a-3c90-41ef-8b0e-86756e7ed4f6',
    video_id: 'pl_Explosive_1',
    video_length: 0.07,
    video_number: '626',
    size: 1288175,
    hints: 'pl_Explosive_1_hints',
    common_mistakes: 'pl_Explosive_1_common_mistakes',
    breathing: 'pl_Explosive_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Modified Jumping Jack',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FModified%20Jumping%20Jack.jpg?alt=media&token=146ece8f-8b25-4fcb-ab78-9537c7f3c209',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FModified%20Jumping%20Jack.mp4?alt=media&token=b30bd694-3f33-4613-900a-a70138fb1b9a',
    video_id: 'C_Modified_Jumping_Jack_1',
    video_length: 0.07,
    video_number: '521',
    size: 2668775,
    hints: 'C_Modified_Jumping_Jack_1_hints',
    common_mistakes: 'C_Modified_Jumping_Jack_1_common_mistakes',
    breathing: 'C_Modified_Jumping_Jack_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Alternating Reverse Side Crunch',
    is_free: false,
    primary_muscle: 'Obliques, Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Reverse%20Side%20Crunch.jpg?alt=media&token=f1583ff1-265d-4f4b-88e8-b83b90b09107',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Reverse%20Side%20Crunch.mp4?alt=media&token=15bf1bf7-4dee-4d61-940b-f240d9e1e9e7',
    video_id: 'Abs_Alternating_Reverse_Side_Crunch_1',
    video_length: 0.07,
    video_number: '184',
    size: 2627041,
    hints: 'Abs_Alternating_Reverse_Side_Crunch_1_hints',
    common_mistakes: 'Abs_Alternating_Reverse_Side_Crunch_1_common_mistakes',
    breathing: 'Abs_Alternating_Reverse_Side_Crunch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Booty Hold',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBooty%20Hold.jpg?alt=media&token=5e1570f2-6b37-4703-9d8c-4ea749b441a1',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBooty%20Hold.mp4?alt=media&token=2a6b3695-09c1-4055-b5f2-1385108d3f0c',
    video_length: 0.05,
    video_number: '748',
    size: 1942840,
    hints: 'b_Booty_Hold_hints',
    common_mistakes: 'b_Booty_Hold_common_mistakes',
    breathing: 'b_Booty_Hold_breathing',
    video_id: 'b_Booty_Hold',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Sidewalk Planks',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSidewalk%20Planks.jpg?alt=media&token=8c83418d-6e57-4499-9d6f-074770fea079',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSidewalk%20Planks.mp4?alt=media&token=4dacd787-da42-4843-9944-216535517a6c',
    video_id: 'pl_Sidewalk_Planks_1',
    video_length: 0.07,
    video_number: '593',
    size: 6063113,
    hints: 'pl_Sidewalk_Planks_1_hints',
    common_mistakes: 'pl_Sidewalk_Planks_1_common_mistakes',
    breathing: 'pl_Sidewalk_Planks_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Standing Knee Tuck',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStanding%20Knee%20Tuck.jpg?alt=media&token=39dc20ba-f530-44a4-99b5-8adb86c50552',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStanding%20Knee%20Tuck.mp4?alt=media&token=836631e7-57ae-473b-bc1e-a2b41a64992e',
    video_id: 'h_Standing_Knee_Tuck_1',
    video_length: 0.07,
    video_number: '568',
    size: 1405029,
    hints: 'h_Standing_Knee_Tuck_1_hints',
    common_mistakes: 'h_Standing_Knee_Tuck_1_common_mistakes',
    breathing: 'h_Standing_Knee_Tuck_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Seated Bikes',
    is_free: false,
    primary_muscle: 'Upper Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs, Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSeated%20Bikes.jpg?alt=media&token=df8cda22-882b-4631-8f7a-81ca21a69ab6',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSeated%20Bikes.mp4?alt=media&token=4f48f034-b607-4481-b4f5-4f316825037c',
    video_id: 'Abs_Seated_Bikes_25',
    video_length: 0.08,
    video_number: '36',
    size: 610581,
    hints: 'Abs_Seated_Bikes_25_hints',
    common_mistakes: 'Abs_Seated_Bikes_25_common_mistakes',
    breathing: 'Abs_Seated_Bikes_25_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Shuffle',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FShuffle.jpg?alt=media&token=e4a8103e-ba43-4ebc-927a-484189a2c12d',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FShuffle.mp4?alt=media&token=4727b58b-4a62-4cf7-b8ce-7cc784cbd364',
    video_id: 'C_Shuffle_1',
    video_length: 0.07,
    video_number: '525',
    size: 1466764,
    hints: 'C_Shuffle_1_hints',
    common_mistakes: 'C_Shuffle_1_common_mistakes',
    breathing: 'C_Shuffle_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Staggered Squat Jump',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStaggered%20Squat%20Jump.jpg?alt=media&token=fce3e9f5-76a5-48ae-8ef7-aba662bcebab',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStaggered%20Squat%20Jump.mp4?alt=media&token=824c1d8d-4b61-4140-9aa6-9d47f7d69b6f',
    video_id: 'h_Staggered_Squat_Jump_1',
    video_length: 0.07,
    video_number: '567',
    size: 3320357,
    hints: 'h_Staggered_Squat_Jump_1_hints',
    common_mistakes: 'h_Staggered_Squat_Jump_1_common_mistakes',
    breathing: 'h_Staggered_Squat_Jump_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Wall Russian Twists',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Russian%20Twists.jpg?alt=media&token=3c3f2038-4d71-4b96-b4dd-63ac07b62540',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Russian%20Twists.mp4?alt=media&token=69be1b54-2f66-4459-9ee8-42f7afe8b5a3',
    video_length: 0.05,
    video_number: '828',
    size: 1942840,
    hints: 'wp_Wall_Russian_Twists_hints',
    common_mistakes: 'wp_Wall_Russian_Twists_common_mistakes',
    breathing: 'wp_Wall_Russian_Twists_breathing',
    video_id: 'wp_Wall_Russian_Twists',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Clap Jacks',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FClap%20Jacks.jpg?alt=media&token=8747f708-c69e-4043-b608-1164fd9876b5',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FClap%20Jacks.mp4?alt=media&token=b8163439-aa36-4ea9-ad83-852f1c1a03c0',
    video_id: 'h_Clap_Jacks_1',
    video_length: 0.07,
    video_number: '542',
    size: 1864035,
    hints: 'h_Clap_Jacks_1_hints',
    common_mistakes: 'h_Clap_Jacks_1_common_mistakes',
    breathing: 'h_Clap_Jacks_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'total body',
    name: 'Lunge With Hammer Front Raise',
    is_free: false,
    primary_muscle: 'Quads, Hamstrings, Glutes, Front Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Adductors, Upper Chest, Middle Shoulders, Serratus Anterior',
    subcategory_name: 'total body',
    tertiary_muscle: 'Core, Calves, Rear Shoulders, Traps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLunge%20With%20Hammer%20Front%20Raise.jpg?alt=media&token=311061cf-f367-4211-af11-8b2a2fd7df44',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLunge%20With%20Hammer%20Front%20Raise.mp4?alt=media&token=fb57ba39-255c-46ba-9506-713caf3fed29',
    video_id: 'TnS_Lunge_With_Hammer_Front_Raise_1',
    video_length: 0.07,
    video_number: '468',
    size: 2859291,
    hints: 'TnS_Lunge_With_Hammer_Front_Raise_1_hints',
    common_mistakes: 'TnS_Lunge_With_Hammer_Front_Raise_1_common_mistakes',
    breathing: 'TnS_Lunge_With_Hammer_Front_Raise_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Lunge & Leg Raise',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLunge%20%26%20Leg%20Raise%20(R).jpg?alt=media&token=2f64644b-a3e3-40dd-bf84-174c2087a700',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLunge%20%26%20Leg%20Raise%20(R).mp4?alt=media&token=04426841-a915-469c-9cb7-90bf5136c92d',
    video_length: 0.05,
    video_number: '763',
    size: 1942840,
    hints: 'b_Lunge_&_Leg_Raise_2_hints',
    common_mistakes: 'b_Lunge_&_Leg_Raise_2_common_mistakes',
    breathing: 'b_Lunge_&_Leg_Raise_2_breathing',
    is_other_side: 1,
    video_id: 'b_Lunge_&_Leg_Raise_2',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Split Pulsing Squats',
    is_other_side: 0,
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSplit%20Pulsing%20Squats%20(L).jpg?alt=media&token=23add87f-b8ff-4400-8072-f53a87e7732b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSplit%20Pulsing%20Squats%20(L).mp4?alt=media&token=ea1df93c-e17d-4dbc-a5c1-4c60016c13a5',
    video_length: 0.05,
    video_number: '765',
    size: 1942840,
    hints: 'b_Split_Pulsing_Squats_hints',
    common_mistakes: 'b_Split_Pulsing_Squats_common_mistakes',
    breathing: 'b_Split_Pulsing_Squats_breathing',
    video_id: 'b_Split_Pulsing_Squats',
  },
  {
    category_id: 1,
    category_name: 'chest & biceps',
    name: 'Incline Push Up',
    is_free: false,
    primary_muscle: 'Lower Chest',
    rep_range: '10-20',
    secondary_muscle:
      'Middle Chest, Upper Chest, Shoulders, Triceps, Core, Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle:
      'Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae, Lower Body',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FIncline%20Push%20Up.jpg?alt=media&token=09c9a6f6-1f66-49bf-91b5-479c2afb2bd9',
    time: '45 seconds',
    time_to_play: 0.75,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FIncline%20Push%20Up.mp4?alt=media&token=84ae3ce2-5c4f-4805-8173-782c1d4b42e2',
    video_id: 'CnB_Incline_Push_Ups_2',
    video_length: 0.07,
    video_number: '741',
    size: 511456,
    hints: 'CnB_Incline_Push_Ups_2_hints',
    common_mistakes: 'CnB_Incline_Push_Ups_2_common_mistakes',
    breathing: 'CnB_Incline_Push_Ups_2_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Pivoting Upper Cuts',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPivoting%20Upper%20Cuts.jpg?alt=media&token=fe3a8372-236f-40ed-a1b5-7fd644a28cc7',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPivoting%20Upper%20Cuts.mp4?alt=media&token=8a3b6152-fd89-4d52-a2c1-9e88b2ce1901',
    video_id: 'C_Pivoting_Upper_Cuts_1',
    video_length: 0.07,
    video_number: '522',
    size: 1848317,
    hints: 'C_Pivoting_Upper_Cuts_1_hints',
    common_mistakes: 'C_Pivoting_Upper_Cuts_1_common_mistakes',
    breathing: 'C_Pivoting_Upper_Cuts_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'V Up And Roll Up',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle:
      'Obliques, Core, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FV%20Up%20And%20Roll%20Up.jpg?alt=media&token=c2e6d71e-c40b-4f37-bcbb-5f7510d70a1c',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FV%20Up%20And%20Roll%20Up.mp4?alt=media&token=400ce453-7295-4a17-becf-db02589865cd',
    video_id: 'Abs_VUpAndRollUp_26',
    video_length: 0.1,
    video_number: '49',
    size: 744802,
    hints: 'Abs_VUpAndRollUp_26_hints',
    common_mistakes: 'Abs_VUpAndRollUp_26_common_mistakes',
    breathing: 'Abs_VUpAndRollUp_26_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Pelvic Tilt Knee Lifts',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPelvic%20Tilt%20Knee%20Lifts.jpg?alt=media&token=910eae3f-d9da-4d2b-a851-290fa4111774',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPelvic%20Tilt%20Knee%20Lifts.mp4?alt=media&token=61dfed02-d3d7-464a-a106-6b547f4c25b1',
    video_length: 0.05,
    video_number: '769',
    size: 1942840,
    hints: 'b_Pelvic_Tilt_Knee_Lifts_hints',
    common_mistakes: 'b_Pelvic_Tilt_Knee_Lifts_common_mistakes',
    breathing: 'b_Pelvic_Tilt_Knee_Lifts_breathing',
    video_id: 'b_Pelvic_Tilt_Knee_Lifts',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Outer Thigh Circles',
    is_other_side: 0,
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOuter%20Thigh%20Circles%20(L).jpg?alt=media&token=48c91d9e-8793-47e4-8322-ee74e01b334d',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOuter%20Thigh%20Circles%20(L).mp4?alt=media&token=a2297891-4058-4163-bb62-f681066e42f3',
    video_id: 'lst_Outer_Thigh_Circles',
    video_length: 0.07,
    video_number: '657',
    size: 2003097,
    hints: 'lst_Outer_Thigh_Circles_hints',
    common_mistakes: 'lst_Outer_Thigh_Circles_common_mistakes',
    breathing: 'lst_Outer_Thigh_Circles_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Side To Side',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings Calves Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20To%20Side.jpg?alt=media&token=6fb894e3-cade-49e6-a508-9ce798f2f63f',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20To%20Side.mp4?alt=media&token=68491184-4ab8-4cfc-890f-40639d87411d',
    video_id: 'l_Side_To_Side',
    video_length: 0.05,
    video_number: '309',
    size: 3148991,
    hints: 'l_Side_To_Side_hints',
    common_mistakes: 'l_Side_To_Side_common_mistakes',
    breathing: 'l_Side_To_Side_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Arms Behind Head Push',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Traps, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FArms%20Behind%20Head%20Push.jpg?alt=media&token=1f7a0022-05c0-4f5c-92e5-b7c45da76bb8',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FArms%20Behind%20Head%20Push.mp4?alt=media&token=327f1215-9123-4c55-b752-3b2806668752',
    video_id: 'lst_Arms_Behind_Head_Push_1',
    video_length: 0.07,
    video_number: '727',
    size: 1377127,
    hints: 'lst_Arms_Behind_Head_Push_1_hints',
    common_mistakes: 'lst_Arms_Behind_Head_Push_1_common_mistakes',
    breathing: 'lst_Arms_Behind_Head_Push_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Squat Hold & Arms',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors, Shoulders',
    rep_range: 'Time Based',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20Hold%20%26%20Arms.jpg?alt=media&token=c14eb42e-b0f9-45e2-b834-1b9b15b5db46',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20Hold%20%26%20Arms.mp4?alt=media&token=bcf00009-d134-4e4a-adba-095a3f3d9a24',
    video_id: 'l_Squat_Hold_Arms',
    video_length: 0.05,
    video_number: '306',
    size: 758874,
    hints: 'l_Squat_Hold_Arms_hints',
    common_mistakes: 'l_Squat_Hold_Arms_common_mistakes',
    breathing: 'l_Squat_Hold_Arms_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Body Weight Empty Can',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Shoulders, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBody%20Weight%20Empty%20Can.jpg?alt=media&token=75eff4d5-a824-4abd-aabf-68d057ff1e4d',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBody%20Weight%20Empty%20Can.mp4?alt=media&token=8f87a635-4eeb-4960-aaa7-74509e555798',
    video_id: 'lst_Body_Weight_Empty_Can_1',
    video_length: 0.07,
    video_number: '710',
    size: 2693142,
    hints: 'lst_Body_Weight_Empty_Can_1_hints',
    common_mistakes: 'lst_Body_Weight_Empty_Can_1_common_mistakes',
    breathing: 'lst_Body_Weight_Empty_Can_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Squat To High Knee Jumps',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexor, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20To%20High%20Knee%20Jumps.jpg?alt=media&token=76616b24-ff6c-49d9-9ca6-112c43dcc0f4',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20To%20High%20Knee%20Jumps.mp4?alt=media&token=3dd36968-93ad-4a85-ba5b-08a64f6fa6ff',
    video_id: 'LnB_Squat_To_High_Knee_Jumps_1',
    video_length: 0.05,
    video_number: '293',
    size: 1650553,
    hints: 'LnB_Squat_To_High_Knee_Jumps_1_hints',
    common_mistakes: 'LnB_Squat_To_High_Knee_Jumps_1_common_mistakes',
    breathing: 'LnB_Squat_To_High_Knee_Jumps_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Chest Openers',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FChest%20Openers.jpg?alt=media&token=0890d933-f834-48c7-8c1b-f3f5c13adcbd',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FChest%20Openers.mp4?alt=media&token=5f7ba814-0f52-49f5-b288-3e5855aab2b4',
    video_length: 0.05,
    video_number: '806',
    size: 1942840,
    hints: 'wp_Chest_Openers_hints',
    common_mistakes: 'wp_Chest_Openers_common_mistakes',
    breathing: 'wp_Chest_Openers_breathing',
    video_id: 'wp_Chest_Openers',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Tuck Planks Opposite Side',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTuck%20Planks%20Opposite%20Side.jpg?alt=media&token=d871b83d-6842-4a00-958f-510a26006396',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTuck%20Planks%20Opposite%20Side.mp4?alt=media&token=0427ba32-5b09-4a80-88e3-39bc815ab2d9',
    video_id: 'pl_Tuck_Planks_Opposite_Side_1',
    video_length: 0.07,
    video_number: '594',
    size: 1915737,
    hints: 'pl_Tuck_Planks_Opposite_Side_1_hints',
    common_mistakes: 'pl_Tuck_Planks_Opposite_Side_1_common_mistakes',
    breathing: 'pl_Tuck_Planks_Opposite_Side_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'total body',
    name: 'Side Raise Into Front Lunge',
    is_free: false,
    primary_muscle: 'Middle Shoulders, Quads, Hamstrings, Glutes',
    rep_range: '5-10',
    secondary_muscle:
      'Front Shoulders, Rear Shoulders, Traps, Serratus Anterior, Adductors',
    subcategory_name: 'total body',
    tertiary_muscle: 'Latissimus Dorsi, Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Raise%20Into%20Front%20Lunge.jpg?alt=media&token=4ef42bc7-c4eb-42cc-bebe-179be3e14690',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Raise%20Into%20Front%20Lunge.mp4?alt=media&token=3bbf167b-3781-4d97-a775-f24c0fbb0c3b',
    video_id: 'TnS_Side_Raise_Into_Front_Lunge_1',
    video_length: 0.07,
    video_number: '462',
    size: 2568511,
    hints: 'TnS_Side_Raise_Into_Front_Lunge_1_hints',
    common_mistakes: 'TnS_Side_Raise_Into_Front_Lunge_1_common_mistakes',
    breathing: 'TnS_Side_Raise_Into_Front_Lunge_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Wall Sit',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Sit.jpg?alt=media&token=cf9e14e7-7424-4c61-9251-af7cce5bb731',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Sit.mp4?alt=media&token=3d3583e6-c68c-4df8-a86b-d38e8cef9a13',
    video_length: 0.05,
    video_number: '830',
    size: 1942840,
    hints: 'wp_Wall_Sit_hints',
    common_mistakes: 'wp_Wall_Sit_common_mistakes',
    breathing: 'wp_Wall_Sit_breathing',
    video_id: 'wp_Wall_Sit',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Alternating Halo Slam',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Chest, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Halo%20Slam.jpg?alt=media&token=8c76e38f-7fad-4ec2-8f61-e059e2b58a67',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Halo%20Slam.mp4?alt=media&token=242cad4b-d297-4398-94e1-b06bc242c4cf',
    video_id: 'h_Alternating_Halo_Slam_1',
    video_length: 0.07,
    video_number: '540',
    size: 2996975,
    hints: 'h_Alternating_Halo_Slam_1_hints',
    common_mistakes: 'h_Alternating_Halo_Slam_1_common_mistakes',
    breathing: 'h_Alternating_Halo_Slam_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Sumo Squat Variations',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '5-10',
    secondary_muscle: 'Hamstrings, Erector Spinae, Calves',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSumo%20Squat%20Variations.jpg?alt=media&token=fa60afba-a2b1-4e74-9b40-26b32fc10cda',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSumo%20Squat%20Variations.mp4?alt=media&token=11e42482-92f2-49de-aea0-62256b41abd2',
    video_id: 'LnB_Sumo_Squat_Variations_1',
    video_length: 0.05,
    video_number: '295',
    size: 3104093,
    hints: 'LnB_Sumo_Squat_Variations_1_hints',
    common_mistakes: 'LnB_Sumo_Squat_Variations_1_common_mistakes',
    breathing: 'LnB_Sumo_Squat_Variations_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Cuban Press',
    is_free: true,
    primary_muscle: 'Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Traps, Chest, Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCuban%20Press.jpg?alt=media&token=b1ff3757-f3e3-4258-a6aa-bda4212abc24',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Dumbbells',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCuban%20Press.mp4?alt=media&token=4621da27-3be5-45c6-a262-73f1e4b1a904',
    video_id: 'ub_Cuban_Press',
    video_length: 0.1,
    video_number: '103',
    size: 1343153,
    hints: 'ub_Cuban_Press_hints',
    common_mistakes: 'ub_Cuban_Press_common_mistakes',
    breathing: 'ub_Cuban_Press_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Elevated Penguins',
    is_free: true,
    primary_muscle: 'Obliques, Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FElevated%20Penguins.jpg?alt=media&token=19341ca3-c276-4c3f-b478-f638b63e7b25',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FElevated%20Penguins.mp4?alt=media&token=b75e428f-e42d-4679-a306-5cf2cffed276',
    video_id: 'Abs_Elevated_Penguins_1',
    video_length: 0.07,
    video_number: '185',
    size: 1625135,
    hints: 'Abs_Elevated_Penguins_1_hints',
    common_mistakes: 'Abs_Elevated_Penguins_1_common_mistakes',
    breathing: 'Abs_Elevated_Penguins_1_breathing',
  },
  {
    category_id: 1,
    category_name: 'upper body',
    name: 'Alternating Curl Hold',
    is_free: true,
    primary_muscle: 'Biceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Brachialis, Forearm',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Curl%20Hold.jpg?alt=media&token=d36a43c6-47ab-40cf-b26d-dea1b9703385',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Curl%20Hold.mp4?alt=media&token=91383745-495c-4592-9401-5bc604609e0a',
    video_id: 'CnB_Alternating_Curl_Hold_1',
    video_length: 0.1,
    video_number: '162',
    size: 1393310,
    hints: 'CnB_Alternating_Curl_Hold_1_hints',
    common_mistakes: 'CnB_Alternating_Curl_Hold_1_common_mistakes',
    breathing: 'CnB_Alternating_Curl_Hold_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Leg Openers',
    is_free: false,
    primary_muscle: 'Abs, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLeg%20Openers.jpg?alt=media&token=bec85995-fb25-47fa-a3ad-e212da2bc476',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLeg%20Openers.mp4?alt=media&token=cdc6fec9-b2a4-45ca-9bd4-48876803d113',
    video_id: 'Abs_Leg_Openers_1',
    video_length: 0.07,
    video_number: '198',
    size: 2191723,
    hints: 'Abs_Leg_Openers_1_hints',
    common_mistakes: 'Abs_Leg_Openers_1_common_mistakes',
    breathing: 'Abs_Leg_Openers_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: '90 Degree Sit Up To Toes',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2F90%20Degree%20Sit%20Up%20To%20Toes.jpg?alt=media&token=d7fb6b2f-5bed-4268-ac98-2fe745820310',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2F90%20Degree%20Sit%20Up%20To%20Toes.mp4?alt=media&token=9e435bd6-4197-4a32-a8fc-f003b65edbba',
    video_id: 'Abs_90_Degree_Sit_Up_To_Toes_1',
    video_length: 0.07,
    video_number: '193',
    size: 1976603,
    hints: 'Abs_90_Degree_Sit_Up_To_Toes_1_hints',
    common_mistakes: 'Abs_90_Degree_Sit_Up_To_Toes_1_common_mistakes',
    breathing: 'Abs_90_Degree_Sit_Up_To_Toes_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Standing Legs Spread Middle',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstrings',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStanding%20Legs%20Spread%20Middle.jpg?alt=media&token=69ecd056-98b4-4d8d-bf1b-c75f14651cbc',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStanding%20Legs%20Spread%20Middle.mp4?alt=media&token=6a7dc186-f270-4315-975d-eede5c62cd73',
    video_id: 'lst_Standing_Legs_Spread_Middle_1',
    video_length: 0.07,
    video_number: '681',
    size: 3785720,
    hints: 'lst_Standing_Legs_Spread_Middle_1_hints',
    common_mistakes: 'lst_Standing_Legs_Spread_Middle_1_common_mistakes',
    breathing: 'lst_Standing_Legs_Spread_Middle_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Scissor Kicks',
    is_free: false,
    primary_muscle: 'Lower Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Upper Abs, Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FScissor%20Kicks.jpg?alt=media&token=9365882d-6237-4461-9daf-3e7a65e3ac0c',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FScissor%20Kicks.mp4?alt=media&token=f5959cf0-4fa5-464b-bebd-ae1800eaf490',
    video_id: 'Abs_Scissor_Kicks_30',
    video_length: 0.05,
    video_number: '37',
    size: 382188,
    hints: 'Abs_Scissor_Kicks_30_hints',
    common_mistakes: 'Abs_Scissor_Kicks_30_common_mistakes',
    breathing: 'Abs_Scissor_Kicks_30_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Mid Crunchers',
    is_free: true,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FMid%20Crunchers.jpg?alt=media&token=f1d4db03-588c-4101-b2af-2d69a8659437',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FMid%20Crunchers.mp4?alt=media&token=0c705fd1-cb7d-44e3-9a15-6e0f05fd099c',
    video_id: 'Abs_Mid_Crunchers_18',
    video_length: 0.07,
    video_number: '38',
    size: 537017,
    hints: 'Abs_Mid_Crunchers_18_hints',
    common_mistakes: 'Abs_Mid_Crunchers_18_common_mistakes',
    breathing: 'Abs_Mid_Crunchers_18_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Seated Anterior Shoulder Stretch',
    is_free: false,
    primary_muscle: 'Front Neck',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSeated%20Anterior%20Shoulder%20Stretch.jpg?alt=media&token=3254cd6d-afd9-4ec5-a433-485e4cac7f8d',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSeated%20Anterior%20Shoulder%20Stretch.mp4?alt=media&token=63af6ec8-cdc0-4ad2-82a3-63718d43f2bf',
    video_id: 'lst_Seated_Anterior_Shoulder_Stretch',
    video_length: 0.07,
    video_number: '688',
    size: 1666899,
    hints: 'lst_Seated_Anterior_Shoulder_Stretch_hints',
    common_mistakes: 'lst_Seated_Anterior_Shoulder_Stretch_common_mistakes',
    breathing: 'lst_Seated_Anterior_Shoulder_Stretch_breathing',
  },
  {
    category_id: 3,
    category_name: 'total body',
    name: 'Squatting Side Crunch',
    is_free: false,
    primary_muscle: 'Core, Lower Body',
    rep_range: '10-25',
    secondary_muscle: 'Latissimus Dorsi',
    subcategory_name: 'upper body',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquatting%20Side%20Crunch.jpg?alt=media&token=1a6240f7-8ab7-4153-aa3b-1c2970d58b41',
    time: '45 seconds',
    time_to_play: 0.75,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquatting%20Side%20Crunch.mp4?alt=media&token=561ea59c-0206-4398-ac8b-faeb18041f88',
    video_id: 'TnS_Squatting_Side_Crunch_1',
    video_length: 0.07,
    video_number: '436',
    size: 1479373,
    hints: 'TnS_Squatting_Side_Crunch_1_hints',
    common_mistakes: 'TnS_Squatting_Side_Crunch_1_common_mistakes',
    breathing: 'TnS_Squatting_Side_Crunch_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Windshield Wiper',
    is_free: false,
    primary_muscle: 'Obliques, Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWindshield%20Wiper.jpg?alt=media&token=4e0cafcf-7fba-43a4-b038-bc31530dec0e',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWindshield%20Wiper.mp4?alt=media&token=0b56d0c1-f3ed-4b99-8421-559208048c43',
    video_id: 'Abs_Windshield_Wiper_1',
    video_length: 0.07,
    video_number: '214',
    size: 3929142,
    hints: 'Abs_Windshield_Wiper_1_hints',
    common_mistakes: 'Abs_Windshield_Wiper_1_common_mistakes',
    breathing: 'Abs_Windshield_Wiper_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Hands On Back Forward Lean',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Shoulders, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHands%20On%20Back%20Forward%20Lean.jpg?alt=media&token=9c130777-623f-45ca-b560-c788b9335beb',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHands%20On%20Back%20Forward%20Lean.mp4?alt=media&token=9faaacb9-cd14-4fe0-8cc3-2a02d67c4bd6',
    video_id: 'lst_Hands_On_Back_Forward_Lean_1',
    video_length: 0.07,
    video_number: '711',
    size: 1205150,
    hints: 'lst_Hands_On_Back_Forward_Lean_1_hints',
    common_mistakes: 'lst_Hands_On_Back_Forward_Lean_1_common_mistakes',
    breathing: 'lst_Hands_On_Back_Forward_Lean_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Single Arm Kickback',
    is_other_side: 1,
    is_free: false,
    primary_muscle: 'Triceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Rear Shoulders',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Latissimus Dorsi, Traps, Rhomboids',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Arm%20Kickback%20(R).jpg?alt=media&token=5ec3dd11-64ac-4f53-96b2-e498e82c5e8f',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Arm%20Kickback%20(R).mp4?alt=media&token=10675d1b-bb72-46d2-bbc1-82940c937712',
    video_id: 'TnS_Single_Arm_Kickback_2',
    video_length: 0.1,
    video_number: '174',
    size: 1399561,
    hints: 'TnS_Single_Arm_Kickback_2_hints',
    common_mistakes: 'TnS_Single_Arm_Kickback_2_common_mistakes',
    breathing: 'TnS_Single_Arm_Kickback_2_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Skull Crushers',
    is_free: true,
    primary_muscle: 'Triceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Forearm, Shoulders',
    subcategory_name: 'upper body',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSkull%20Crushers.jpg?alt=media&token=4821391c-8114-4c50-a7c6-75e77734a3ed',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSkull%20Crushers.mp4?alt=media&token=90cecd08-339c-48e3-b20f-a7de897171bb',
    video_id: 'TnS_Skull_Crushers_1',
    video_length: 0.1,
    video_number: '31',
    size: 680105,
    hints: 'TnS_Skull_Crushers_1_hints',
    common_mistakes: 'TnS_Skull_Crushers_1_common_mistakes',
    breathing: 'TnS_Skull_Crushers_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Alternating Side Hip Slides',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Side%20Hip%20Slides.jpg?alt=media&token=b8e47cdb-02a9-4642-9e46-7ced5f75d40b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Side%20Hip%20Slides.mp4?alt=media&token=62e65310-1d48-42c0-9f0f-907cb5125e31',
    video_length: 0.05,
    video_number: '802',
    size: 1942840,
    hints: 'wp_Alternating_Side_Hip_Slides_hints',
    common_mistakes: 'wp_Alternating_Side_Hip_Slides_common_mistakes',
    breathing: 'wp_Alternating_Side_Hip_Slides_breathing',
    video_id: 'wp_Alternating_Side_Hip_Slides',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Tricep Dips',
    is_free: true,
    primary_muscle: 'Triceps',
    rep_range: '20-30',
    secondary_muscle: 'Front Shoulders, Chest, Latissimus Dorsi',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Biceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTricep%20Dips.jpg?alt=media&token=a55c4d8e-bce5-453f-88fb-2735eda6fb5e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTricep%20Dips.mp4?alt=media&token=58a96eb1-c19c-4234-8db1-8b55b7419f7c',
    video_id: 'ub_Tricep_Dips',
    video_length: 0.07,
    video_number: '14',
    size: 422850,
    hints: 'ub_Tricep_Dips_hints',
    common_mistakes: 'ub_Tricep_Dips_common_mistakes',
    breathing: 'ub_Tricep_Dips_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Sumo Stretch',
    is_free: true,
    muscle_category: ['legs'],
    primary_muscle: 'Hip Flexors, Adductors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSumo%20Stretch.jpg?alt=media&token=162e31e1-9326-4af8-863b-b4ee1fab5fdb',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSumo%20Stretch.mp4?alt=media&token=bb9f23db-5cfa-444c-8110-0da3f961b909',
    video_id: 'lst_Sumo_Stretch_1',
    video_length: 0.07,
    video_number: '684',
    size: 2384879,
    hints: 'lst_Sumo_Stretch_1_hints',
    common_mistakes: 'lst_Sumo_Stretch_1_common_mistakes',
    breathing: 'lst_Sumo_Stretch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Toes In And Out Squat',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FToes%20In%20And%20Out%20Squat.jpg?alt=media&token=1887a9be-92a0-46aa-a889-7bec81a701bf',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FToes%20In%20And%20Out%20Squat.mp4?alt=media&token=61cf89b3-7174-4252-8297-4a523413ea8f',
    video_id: 'LnB_Toes_In_And_Out_Squat_1',
    video_length: 0.05,
    video_number: '278',
    size: 2899179,
    hints: 'LnB_Toes_In_And_Out_Squat_1_hints',
    common_mistakes: 'LnB_Toes_In_And_Out_Squat_1_common_mistakes',
    breathing: 'LnB_Toes_In_And_Out_Squat_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Forward and Backward Squat Walk',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FForward%20and%20Backward%20Squat%20Walk.jpg?alt=media&token=803d6b55-167e-4888-be91-2008c4dd47f9',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FForward%20and%20Backward%20Squat%20Walk.mp4?alt=media&token=4785bb0b-f45b-495d-9423-a80213cce4df',
    video_length: 0.05,
    video_number: '754',
    size: 1942840,
    hints: 'b_Forward_and_Backward_Squat_Walk_hints',
    common_mistakes: 'b_Forward_and_Backward_Squat_Walk_common_mistakes',
    breathing: 'b_Forward_and_Backward_Squat_Walk_breathing',
    video_id: 'b_Forward_and_Backward_Squat_Walk',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: '180 Jump',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core, Shoulders',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2F180%20Jump.jpg?alt=media&token=1a47afcf-07a1-48b8-a3e8-eb39d4c638ae',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2F180%20Jump.mp4?alt=media&token=aedb049b-84e6-435f-9cf1-405d973f1a8f',
    video_id: 'h_180_Jump_1',
    video_length: 0.07,
    video_number: '536',
    size: 1318784,
    hints: 'h_180_Jump_1_hints',
    common_mistakes: 'h_180_Jump_1_common_mistakes',
    breathing: 'h_180_Jump_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Lateral High Knee March',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLateral%20High%20Knee%20March.jpg?alt=media&token=9cd51e09-5377-4023-9601-6b020ba08d91',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLateral%20High%20Knee%20March.mp4?alt=media&token=a3d3dd21-540d-4513-94f3-8aaeeed804fe',
    video_id: 'h_Lateral_High_Knee_March_1',
    video_length: 0.07,
    video_number: '554',
    size: 2236230,
    hints: 'h_Lateral_High_Knee_March_1_hints',
    common_mistakes: 'h_Lateral_High_Knee_March_1_common_mistakes',
    breathing: 'h_Lateral_High_Knee_March_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Romanian Deadlift',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Erector Spinae',
    rep_range: '10–20',
    secondary_muscle:
      'Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRomanian%20Deadlift.jpg?alt=media&token=6511ecb5-5663-46c1-86af-79af1e210d37',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRomanian%20Deadlift.mp4?alt=media&token=7af281e0-e214-4aa7-8163-3c9bc1481f91',
    video_id: 'LnB_Romanian_Deadlift_1',
    video_length: 0.05,
    video_number: '266',
    size: 1340155,
    hints: 'LnB_Romanian_Deadlift_1_hints',
    common_mistakes: 'LnB_Romanian_Deadlift_1_common_mistakes',
    breathing: 'LnB_Romanian_Deadlift_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Leg Swing Side To Side',
    is_other_side: 0,
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Adductors, Glutes, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLeg%20Swing%20Side%20To%20Side%20(L).jpg?alt=media&token=c45fdbfd-0fc8-438c-9406-c61d60f1ed77',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLeg%20Swing%20Side%20To%20Side%20(L).mp4?alt=media&token=dfb97407-8be1-46e3-8404-32bfa66b90b3',
    video_id: 'lst_Leg_Swing_Side_To_Side_1',
    video_length: 0.07,
    video_number: '629',
    size: 2168710,
    hints: 'lst_Leg_Swing_Side_To_Side_1_hints',
    common_mistakes: 'lst_Leg_Swing_Side_To_Side_1_common_mistakes',
    breathing: 'lst_Leg_Swing_Side_To_Side_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'total body',
    name: 'Inner Wrist Curls',
    is_free: false,
    primary_muscle: 'Forearm',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Middle Shoulders',
    subcategory_name: 'upper body',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FInner%20Wrist%20Curls.jpg?alt=media&token=767be167-6606-458e-af5e-44e1ebfa59c8',
    time: '45 seconds',
    time_to_play: 0.75,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FInner%20Wrist%20Curls.mp4?alt=media&token=04e28743-c514-4d04-a297-6531c97a61af',
    video_id: 'TnS_Inner_Wrist_Curls_1',
    video_length: 0.07,
    video_number: '504',
    size: 854625,
    hints: 'TnS_Inner_Wrist_Curls_1_hints',
    common_mistakes: 'TnS_Inner_Wrist_Curls_1_common_mistakes',
    breathing: 'TnS_Inner_Wrist_Curls_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Chair Hamstring Stretch',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstrings',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FChair%20Hamstring%20Stretch.jpg?alt=media&token=8ac63f2e-2acf-48cc-85d2-90b548abdb8d',
    time: '75 seconds',
    time_to_play: 1.25,
    tools: 'Chair',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FChair%20Hamstring%20Stretch.mp4?alt=media&token=5d9994a6-2516-4143-89ca-323ff976cbea',
    video_id: 'lst_Chair_Hamstring_Stretch_1',
    video_length: 0.07,
    video_number: '646',
    size: 6365647,
    hints: 'lst_Chair_Hamstring_Stretch_1_hints',
    common_mistakes: 'lst_Chair_Hamstring_Stretch_1_common_mistakes',
    breathing: 'lst_Chair_Hamstring_Stretch_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Side Sweep',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Sweep.jpg?alt=media&token=e5f1579e-e3f6-4772-83ab-387aa1942d81',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Sweep.mp4?alt=media&token=c69b0f7a-4a14-42e0-b33d-5fc3927206ef',
    video_id: 'h_Side_Sweep_1',
    video_length: 0.07,
    video_number: '579',
    size: 2283200,
    hints: 'h_Side_Sweep_1_hints',
    common_mistakes: 'h_Side_Sweep_1_common_mistakes',
    breathing: 'h_Side_Sweep_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Jumping Jacks & Squat',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FJumping%20Jacks%20%26%20Squat.jpg?alt=media&token=f859d5d8-1c25-4085-a332-d46b92c8267f',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FJumping%20Jacks%20%26%20Squat.mp4?alt=media&token=9b42d3e7-b175-4cba-ac76-ee8511dd24e0',
    video_id: 'h_Jumping_Jacks_Squat_1',
    video_length: 0.07,
    video_number: '552',
    size: 1359109,
    hints: 'h_Jumping_Jacks_Squat_1_hints',
    common_mistakes: 'h_Jumping_Jacks_Squat_1_common_mistakes',
    breathing: 'h_Jumping_Jacks_Squat_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Marching Planks',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle: 'Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FMarching%20Planks.jpg?alt=media&token=ab98a31e-08c5-49cb-b386-23a085826c33',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FMarching%20Planks.mp4?alt=media&token=119733e7-35cf-4122-80ae-52a1b5da85e0',
    video_id: 'pl_Marching_Planks_1',
    video_length: 0.07,
    video_number: '585',
    size: 1474068,
    hints: 'pl_Marching_Planks_1_hints',
    common_mistakes: 'pl_Marching_Planks_1_common_mistakes',
    breathing: 'pl_Marching_Planks_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'cardio',
    name: 'Step Jacks',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStep%20Jacks.jpg?alt=media&token=556a0208-36d4-4dc9-9328-18029edc41ed',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStep%20Jacks.mp4?alt=media&token=289caba2-a05d-4c55-ad04-c10866e21a55',
    video_id: 'h_Step_Jacks',
    video_length: 0.07,
    video_number: '542',
    size: 1864035,
    hints: 'h_Step_Jacks_hints',
    common_mistakes: 'h_Step_Jacks_common_mistakes',
    breathing: 'h_Step_Jacks_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'The Kobe Crunch',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FThe%20Kobe%20Crunch.jpg?alt=media&token=ed4e8d36-49cb-45fd-beba-d32353d861ef',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FThe%20Kobe%20Crunch.mp4?alt=media&token=a0dad21d-0014-45e2-8c15-bed34a9f0980',
    video_id: 'Abs_The_Kobe_Crunch_1',
    video_length: 0.07,
    video_number: '206',
    size: 2498303,
    hints: 'Abs_The_Kobe_Crunch_1_hints',
    common_mistakes: 'Abs_The_Kobe_Crunch_1_common_mistakes',
    breathing: 'Abs_The_Kobe_Crunch_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Tate Press',
    is_free: false,
    primary_muscle: 'Triceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Forearm, Shoulders',
    subcategory_name: 'upper body',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTate%20Press.jpg?alt=media&token=14931aaa-2adf-4738-a9de-e68cadba47f8',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTate%20Press.mp4?alt=media&token=a97a03ac-2b39-43fc-9020-ced2772673c6',
    video_id: 'TnS_Tate_Press_59',
    video_length: 0.05,
    video_number: '39',
    size: 361366,
    hints: 'TnS_Tate_Press_59_hints',
    common_mistakes: 'TnS_Tate_Press_59_common_mistakes',
    breathing: 'TnS_Tate_Press_59_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Butterfly Openers',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FButterfly%20Openers.jpg?alt=media&token=846b63c8-568a-40d4-9834-ce9dc282983e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FButterfly%20Openers.mp4?alt=media&token=17368e62-c3ef-4f03-abae-421b89b6e0fa',
    video_length: 0.05,
    video_number: '805',
    size: 1942840,
    hints: 'wp_Butterfly_Openers_hints',
    common_mistakes: 'wp_Butterfly_Openers_common_mistakes',
    breathing: 'wp_Butterfly_Openers_breathing',
    video_id: 'wp_Butterfly_Openers',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Active Calf Stretch',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FActive%20Calf%20Stretch%20(R).jpg?alt=media&token=1f6a8d7f-33c5-4530-a7c1-9b7883c6c7fe',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FActive%20Calf%20Stretch%20(R).mp4?alt=media&token=c9eb5919-4fbb-4e9c-a2bd-79de31a90fdb',
    video_length: 0.05,
    video_number: '798',
    size: 1942840,
    hints: 'wp_Active_Calf_Stretch_2_hints',
    common_mistakes: 'wp_Active_Calf_Stretch_2_common_mistakes',
    breathing: 'wp_Active_Calf_Stretch_2_breathing',
    is_other_side: 1,
    video_id: 'wp_Active_Calf_Stretch_2',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Slow Mo Leg Lowers',
    is_free: false,
    primary_muscle: 'Lower Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Upper Abs, Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSlow%20Mo%20Leg%20Lowers.jpg?alt=media&token=46dea834-5bd1-4b90-abba-47e0244f9a8b',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSlow%20Mo%20Leg%20Lowers.mp4?alt=media&token=71a55e81-643e-4d95-a1b8-7c3a4c3c8269',
    video_id: 'Abs_Slow_Mo_Leg_Lowers_24',
    video_length: 0.13,
    video_number: '53',
    size: 865574,
    hints: 'Abs_Slow_Mo_Leg_Lowers_24_hints',
    common_mistakes: 'Abs_Slow_Mo_Leg_Lowers_24_common_mistakes',
    breathing: 'Abs_Slow_Mo_Leg_Lowers_24_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Leg Crunches',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLeg%20Crunches.jpg?alt=media&token=d9ff1818-1492-444f-8911-c73fe6ee89d0',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLeg%20Crunches.mp4?alt=media&token=2a443af2-2565-4328-a85b-ec0a38cbc7a8',
    video_id: 'Abs_Leg_Crunches_17',
    video_length: 0.07,
    video_number: '35',
    size: 477213,
    hints: 'Abs_Leg_Crunches_17_hints',
    common_mistakes: 'Abs_Leg_Crunches_17_common_mistakes',
    breathing: 'Abs_Leg_Crunches_17_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Behind Back Lateral Raise',
    is_free: true,
    primary_muscle: 'Middle Shoulders, Rear Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Front Shoulders, Traps, Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBehind%20Back%20Lateral%20Raise.jpg?alt=media&token=b6eee1f7-8a3e-4619-b418-122b221f833e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBehind%20Back%20Lateral%20Raise.mp4?alt=media&token=a43547c0-9d81-4acb-9649-6bd0cdb03085',
    video_id: 'TnS_Behind_Back_Lateral_Raise_1',
    video_length: 0.07,
    video_number: '425',
    size: 1562878,
    hints: 'TnS_Behind_Back_Lateral_Raise_1_hints',
    common_mistakes: 'TnS_Behind_Back_Lateral_Raise_1_common_mistakes',
    breathing: 'TnS_Behind_Back_Lateral_Raise_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Standing Side Crunch',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStanding%20Side%20Crunch.jpg?alt=media&token=2808613d-ae59-4cd7-8a0d-5fec34b7dcdf',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStanding%20Side%20Crunch.mp4?alt=media&token=2f51f3b6-0505-43ff-a2f7-d1085b5dee95',
    video_id: 'C_Standing_Side_Crunch_1',
    video_length: 0.07,
    video_number: '530',
    size: 2438170,
    hints: 'C_Standing_Side_Crunch_1_hints',
    common_mistakes: 'C_Standing_Side_Crunch_1_common_mistakes',
    breathing: 'C_Standing_Side_Crunch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Goblet Squat',
    is_free: true,
    primary_muscle: 'Quads, Glutes',
    rep_range: '15-20',
    secondary_muscle: 'Hamstrings, Adductors',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FGoblet%20Squat.jpg?alt=media&token=37db13bf-31c1-4e62-92af-f06559cbe856',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FGoblet%20Squat.mp4?alt=media&token=b92f6092-b4ba-4d46-a65b-d1cde88083ed',
    video_id: 'LnB_Goblet_Squat_14',
    video_length: 0.08,
    video_number: '98',
    size: 559749,
    hints: 'LnB_Goblet_Squat_14_hints',
    common_mistakes: 'LnB_Goblet_Squat_14_common_mistakes',
    breathing: 'LnB_Goblet_Squat_14_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Seated Straddle',
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Adductors, Hip Flexors, Glutes',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSeated%20Straddle.jpg?alt=media&token=b32bc928-5501-4aa3-aa1d-4ac1d878f754',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSeated%20Straddle.mp4?alt=media&token=d01a721b-40b9-454e-8426-e09120db3f1e',
    video_id: 'lst_Seated_Straddle_1',
    video_length: 0.07,
    video_number: '675',
    size: 1776270,
    hints: 'lst_Seated_Straddle_1_hints',
    common_mistakes: 'lst_Seated_Straddle_1_common_mistakes',
    breathing: 'lst_Seated_Straddle_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Wall Open Armed Bended Knee',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Open%20Armed%20Bended%20Knee%20(R).jpg?alt=media&token=403c46c6-8be7-420a-aa43-9d6e6e403126',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Open%20Armed%20Bended%20Knee%20(R).mp4?alt=media&token=07ca2c1f-325d-4a00-a74e-127f973b0bd1',
    video_length: 0.05,
    video_number: '826',
    size: 1942840,
    hints: 'wp_Wall_Open_Armed_Bended_Knee_2_hints',
    common_mistakes: 'wp_Wall_Open_Armed_Bended_Knee_2_common_mistakes',
    breathing: 'wp_Wall_Open_Armed_Bended_Knee_2_breathing',
    is_other_side: 1,
    video_id: 'wp_Wall_Open_Armed_Bended_Knee_2',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Clamshell',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FClamshell%20(L).jpg?alt=media&token=113e4457-3800-4914-b438-ce9000a10156',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FClamshell%20(L).mp4?alt=media&token=66854f7f-f6b7-4bcf-b6b6-8d121a6e23da',
    video_length: 0.05,
    video_number: '751',
    size: 1942840,
    hints: 'b_Clamshell_1_hints',
    common_mistakes: 'b_Clamshell_1_common_mistakes',
    breathing: 'b_Clamshell_1_breathing',
    is_other_side: 0,
    video_id: 'b_Clamshell_1',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Straddle Alternating Toe Hold',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hip Flexors, Hamstrings, Adductors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStraddle%20Alternating%20Toe%20Hold.jpg?alt=media&token=08397440-2b96-46d4-b093-bc0ad574d6e0',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStraddle%20Alternating%20Toe%20Hold.mp4?alt=media&token=7f3362fb-7e0f-4543-967a-ef2c7a73c0ab',
    video_id: 'lst_Straddle_Alternating_Toe_Hold_1',
    video_length: 0.07,
    video_number: '683',
    size: 5699110,
    hints: 'lst_Straddle_Alternating_Toe_Hold_1_hints',
    common_mistakes: 'lst_Straddle_Alternating_Toe_Hold_1_common_mistakes',
    breathing: 'lst_Straddle_Alternating_Toe_Hold_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Reach Through Series',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FReach%20Through%20Series.jpg?alt=media&token=9d8f2599-5d0a-4ff2-8cc0-0d0e2381e41e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FReach%20Through%20Series.mp4?alt=media&token=284f3669-8b51-4882-98df-29a2872fcdeb',
    video_length: 0.05,
    video_number: '811',
    size: 1942840,
    hints: 'wp_Reach_Through_Series_hints',
    common_mistakes: 'wp_Reach_Through_Series_common_mistakes',
    breathing: 'wp_Reach_Through_Series_breathing',
    video_id: 'wp_Reach_Through_Series',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Wall Squat Walk',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings Calves Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Squat%20Walk.jpg?alt=media&token=d84e3ec7-6a5f-43a4-ae33-22c2acfc4561',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Wall',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Squat%20Walk.mp4?alt=media&token=0c63bc4b-c310-479e-a67a-f1f0469ff464',
    video_id: 'LnB_Wall_Squat_Walk_1',
    video_length: 0.05,
    video_number: '310',
    size: 2024433,
    hints: 'LnB_Wall_Squat_Walk_1_hints',
    common_mistakes: 'LnB_Wall_Squat_Walk_1_common_mistakes',
    breathing: 'LnB_Wall_Squat_Walk_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Low Side Plank Twists',
    is_other_side: 1,
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLow%20Side%20Plank%20Twists%20(R).jpg?alt=media&token=3fb62014-895f-4172-aae3-06bf45e75033',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLow%20Side%20Plank%20Twists%20(R).mp4?alt=media&token=09005273-e877-4f97-a8f5-028a97a45b29',
    video_id: 'pl_Low_Side_Plank_Twists_2',
    video_length: 0.07,
    video_number: '596',
    size: 989948,
    hints: 'pl_Low_Side_Plank_Twists_2_hints',
    common_mistakes: 'pl_Low_Side_Plank_Twists_2_common_mistakes',
    breathing: 'pl_Low_Side_Plank_Twists_2_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Squat Into Side To Side Lunge',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '5-10',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20Into%20Side%20To%20Side%20Lunge.jpg?alt=media&token=bbcc8a45-53bb-4847-8476-3a76914e37fe',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20Into%20Side%20To%20Side%20Lunge.mp4?alt=media&token=7c3f24d7-9e51-4674-993c-54a3ec9f9e8d',
    video_id: 'LnB_Squat_Into_Side_To_Side_Lunge_1',
    video_length: 0.05,
    video_number: '271',
    size: 4025673,
    hints: 'LnB_Squat_Into_Side_To_Side_Lunge_1_hints',
    common_mistakes: 'LnB_Squat_Into_Side_To_Side_Lunge_1_common_mistakes',
    breathing: 'LnB_Squat_Into_Side_To_Side_Lunge_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Single Arm High Plank',
    is_other_side: 1,
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Arm%20High%20Plank%20(R).jpg?alt=media&token=a146127f-5f68-4440-bd40-e1651f5f3cdc',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Arm%20High%20Plank%20(R).mp4?alt=media&token=285ab7f9-f167-438a-ba69-79c0eb60bde2',
    video_id: 'pl_Single_Arm_High_Plank_2',
    video_length: 0.07,
    video_number: '600',
    size: 755759,
    hints: 'pl_Single_Arm_High_Plank_2_hints',
    common_mistakes: 'pl_Single_Arm_High_Plank_2_common_mistakes',
    breathing: 'pl_Single_Arm_High_Plank_2_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Jackknives',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FJackknives.jpg?alt=media&token=f0c35b70-b2b8-4355-96f3-2d8dafe74313',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FJackknives.mp4?alt=media&token=b5d8ad99-8648-4436-b450-7e4bc5790e0d',
    video_id: 'Abs_Jackknives_1',
    video_length: 0.07,
    video_number: '203',
    size: 2220726,
    hints: 'Abs_Jackknives_1_hints',
    common_mistakes: 'Abs_Jackknives_1_common_mistakes',
    breathing: 'Abs_Jackknives_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'upper body',
    is_free: true,
    name: 'Tricep Push Backs',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTricep%20Push%20Backs.jpg?alt=media&token=af3b5255-de22-40de-a585-729772563a94',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTricep%20Push%20Backs.mp4?alt=media&token=0ae0f6e4-7165-44eb-a252-1649a2391e35',
    video_length: 0.05,
    video_number: '833',
    size: 1942840,
    hints: 'ub_Tricep Push Backs_hints',
    common_mistakes: 'ub_Tricep Push Backs_common_mistakes',
    breathing: 'ub_Tricep Push Backs_breathing',
    video_id: 'ub_Tricep Push Backs',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    is_other_side: 0,
    name: 'One Leg Wall Squat',
    is_free: false,
    primary_muscle: 'Quads, Glutes',
    rep_range: 'Time Based',
    secondary_muscle: 'Hamstrings, Adductors',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOne%20Leg%20Wall%20Squat%20(L).jpg?alt=media&token=93febd7f-2eda-48d8-9a97-49e3ec6e626b',
    time: '45 seconds',
    time_to_play: 0.5,
    tools: 'Wall',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOne%20Leg%20Wall%20Squat%20(L).mp4?alt=media&token=1475efbe-0b37-4221-8814-59d9e3035404',
    video_id: 'LnB_One_Leg_Wall_Squat_8',
    video_length: 0.07,
    video_number: '126',
    size: 420128,
    hints: 'LnB_One_Leg_Wall_Squat_8_hints',
    common_mistakes: 'LnB_One_Leg_Wall_Squat_8_common_mistakes',
    breathing: 'LnB_One_Leg_Wall_Squat_8_breathing',
  },
  {
    category_id: 3,
    category_name: 'triceps & shoulders',
    name: 'Reverse Hand Raise',
    is_free: true,
    primary_muscle: 'Front Shoulders, Middle Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Traps',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Rear Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FReverse%20Hand%20Raise.jpg?alt=media&token=2b737fe6-0f67-4d99-abf3-046d22387ae9',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FReverse%20Hand%20Raise.mp4?alt=media&token=746cf8c4-e56d-4fe2-836f-5999c02c15ed',
    video_id: 'TnS_Reverse_Hand_Raise_1',
    video_length: 0.07,
    video_number: '398',
    size: 2259419,
    hints: 'TnS_Reverse_Hand_Raise_1_hints',
    common_mistakes: 'TnS_Reverse_Hand_Raise_1_common_mistakes',
    breathing: 'TnS_Reverse_Hand_Raise_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'High Glute Bridge',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHigh%20Glute%20Bridge.jpg?alt=media&token=5fb9d856-51c7-42c8-ad5a-e150679b77cb',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHigh%20Glute%20Bridge.mp4?alt=media&token=601e7f13-26e9-4e6e-9a04-3af172acfa56',
    video_length: 0.05,
    video_number: '760',
    size: 1942840,
    hints: 'b_High_Glute_Bridge_hints',
    common_mistakes: 'b_High_Glute_Bridge_common_mistakes',
    breathing: 'b_High_Glute_Bridge_breathing',
    video_id: 'b_High_Glute_Bridge',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Bear Plank Shoulder Taps',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBear%20Plank%20Shoulder%20Taps.jpg?alt=media&token=1cef3ec0-5961-4be4-be27-a8483808a9cb',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBear%20Plank%20Shoulder%20Taps.mp4?alt=media&token=c0f11163-e1e7-4537-88ad-62ae9f5a81e2',
    video_id: 'pl_Bear_Plank_Shoulder_Taps_1',
    video_length: 0.07,
    video_number: '611',
    size: 1044870,
    hints: 'pl_Bear_Plank_Shoulder_Taps_1_hints',
    common_mistakes: 'pl_Bear_Plank_Shoulder_Taps_1_common_mistakes',
    breathing: 'pl_Bear_Plank_Shoulder_Taps_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Bulgarian Split Squat',
    is_other_side: 0,
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBulgarian%20Split%20Squat%20(L).jpg?alt=media&token=a0153e89-c9c4-480c-b74b-7a6bfd315530',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Chair',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBulgarian%20Split%20Squat%20(L).mp4?alt=media&token=8c627e58-74e7-4922-826b-1bf600b8d19d',
    video_id: 'LnB_Bulgarian_Split_Squat_1',
    video_length: 0.05,
    video_number: '742',
    size: 2060718,
    hints: 'LnB_Bulgarian_Split_Squat_1_hints',
    common_mistakes: 'LnB_Bulgarian_Split_Squat_1_common_mistakes',
    breathing: 'LnB_Bulgarian_Split_Squat_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Lower Back Stretch',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Lower Back, Spinal',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLower%20Back%20Stretch.jpg?alt=media&token=f5d4be6c-059c-4c89-9f2d-367eba878b3e',
    time: '75 seconds',
    time_to_play: 1.25,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLower%20Back%20Stretch.mp4?alt=media&token=6b5da55d-92d4-4e4e-9280-aaf594a1344d',
    video_id: 'lst_Lower_Back_Stretch_1',
    video_length: 0.07,
    video_number: '733',
    size: 6879674,
    hints: 'lst_Lower_Back_Stretch_1_hints',
    common_mistakes: 'lst_Lower_Back_Stretch_1_common_mistakes',
    breathing: 'lst_Lower_Back_Stretch_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Tuck Crunch',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques',
    tertiary_muscle: 'Quads',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTuck%20Crunch.jpg?alt=media&token=243afbaa-38e2-41b1-be80-32f16d0dc6b2',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTuck%20Crunch.mp4?alt=media&token=db9c965c-ae5f-47fd-9dc7-2e86fa64e1db',
    video_id: 'Abs_Tuck_Crunch_6',
    video_length: 0.07,
    video_number: '19',
    size: 527154,
    hints: 'Abs_Tuck_Crunch_6_hints',
    common_mistakes: 'Abs_Tuck_Crunch_6_common_mistakes',
    breathing: 'Abs_Tuck_Crunch_6_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Up Downs',
    is_free: true,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FUp%20Downs.jpg?alt=media&token=c7a18d05-cfa6-4d8b-a3a2-e5d158b12daa',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FUp%20Downs.mp4?alt=media&token=4a34c181-f9bb-44aa-b533-00a01645dd06',
    video_id: 'pl_Up_Downs_1',
    video_length: 0.07,
    video_number: '619',
    size: 3527723,
    hints: 'pl_Up_Downs_1_hints',
    common_mistakes: 'pl_Up_Downs_1_common_mistakes',
    breathing: 'pl_Up_Downs_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Seated Cross Leg Stretch',
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Glutes',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSeated%20Cross%20Leg%20Stretch.jpg?alt=media&token=38f85d41-5b00-437d-ade6-5c1d3c2ef1bc',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSeated%20Cross%20Leg%20Stretch.mp4?alt=media&token=8c0047c0-38f1-4653-a11e-98268dccfa8e',
    video_id: 'lst_Seated_Cross_Leg_Stretch_1',
    video_length: 0.07,
    video_number: '670',
    size: 2521143,
    hints: 'lst_Seated_Cross_Leg_Stretch_1_hints',
    common_mistakes: 'lst_Seated_Cross_Leg_Stretch_1_common_mistakes',
    breathing: 'lst_Seated_Cross_Leg_Stretch_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Ab Crunch Knees Up Into Ab Dive Legs Down',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAb%20Crunch%20Knees%20Up%20Into%20Ab%20Dive%20Legs%20Down.jpg?alt=media&token=09336d57-fc06-4c94-896d-83d99e2a13bc',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAb%20Crunch%20Knees%20Up%20Into%20Ab%20Dive%20Legs%20Down.mp4?alt=media&token=76b33a5b-f17a-429c-8e1e-ee588c49c344',
    video_id: 'Abs_Ab_Crunch_Knees_Up_Into_Ab_Dive_Legs_Down_1',
    video_length: 0.07,
    video_number: '190',
    size: 2706845,
    hints: 'Abs_Ab_Crunch_Knees_Up_Into_Ab_Dive_Legs_Down_1_hints',
    common_mistakes:
      'Abs_Ab_Crunch_Knees_Up_Into_Ab_Dive_Legs_Down_1_common_mistakes',
    breathing: 'Abs_Ab_Crunch_Knees_Up_Into_Ab_Dive_Legs_Down_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Wall Pectoral Stretch',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Front Shoulders, Chest, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Pectoral%20Stretch.jpg?alt=media&token=1f5ee6e3-66b9-421f-af3c-fdc687dd41f6',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Wall',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Pectoral%20Stretch.mp4?alt=media&token=15fc642d-792a-4800-805b-eb0d01c45d8f',
    video_id: 'lst_Wall_Pectoral_Stretch_1',
    video_length: 0.07,
    video_number: '697',
    size: 5116791,
    hints: 'lst_Wall_Pectoral_Stretch_1_hints',
    common_mistakes: 'lst_Wall_Pectoral_Stretch_1_common_mistakes',
    breathing: 'lst_Wall_Pectoral_Stretch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Tricep Wall Push Up',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTricep%20Wall%20Push%20Up.jpg?alt=media&token=1deff4a3-1408-4e54-b197-05efef4f25db',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTricep%20Wall%20Push%20Up.mp4?alt=media&token=c28adc0a-c0dc-4293-a32d-72b1dccc459e',
    video_length: 0.05,
    video_number: '818',
    size: 1942840,
    hints: 'wp_Tricep_Wall_Push_Up_hints',
    common_mistakes: 'wp_Tricep_Wall_Push_Up_common_mistakes',
    breathing: 'wp_Tricep_Wall_Push_Up_breathing',
    video_id: 'wp_Tricep_Wall_Push_Up',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Wide Grip Mountain Climbers',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWide%20Grip%20Mountain%20Climbers.jpg?alt=media&token=592b84b2-f6d4-4882-9aef-5b773c84f93a',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWide%20Grip%20Mountain%20Climbers.mp4?alt=media&token=f2064649-7aa2-42ea-9682-9e832bbc4099',
    video_id: 'pl_Wide_Grip_Mountain_Climbers_1',
    video_length: 0.07,
    video_number: '624',
    size: 1817503,
    hints: 'pl_Wide_Grip_Mountain_Climbers_1_hints',
    common_mistakes: 'pl_Wide_Grip_Mountain_Climbers_1_common_mistakes',
    breathing: 'pl_Wide_Grip_Mountain_Climbers_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Sumo Squat Into Side To Side Lunge',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSumo%20Squat%20Into%20Side%20To%20Side%20Lunge.jpg?alt=media&token=88092d07-4978-4423-ab53-54115e42b7d7',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSumo%20Squat%20Into%20Side%20To%20Side%20Lunge.mp4?alt=media&token=270f5932-21f4-4a1b-bf1d-49413683cfd9',
    video_id: 'LnB_Sumo_Squat_Into_Side_To_Side_Lunge',
    video_length: 0.07,
    video_number: '738',
    size: 3276879,
    hints: 'LnB_Sumo_Squat_Into_Side_To_Side_Lunge_hints',
    common_mistakes: 'LnB_Sumo_Squat_Into_Side_To_Side_Lunge_common_mistakes',
    breathing: 'LnB_Sumo_Squat_Into_Side_To_Side_Lunge_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Wrist Circles',
    is_other_side: 1,
    is_free: false,
    primary_muscle: 'Wrists',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWrist%20Circles%20(R).jpg?alt=media&token=45505f9c-2fd8-43cf-aba0-44818613a2cd',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWrist%20Circles%20(R).mp4?alt=media&token=5a0c2e27-2a1d-4d6e-94ac-2f09ec03a81f',
    video_id: 'lst_Wrist_Circles_1',
    video_length: 0.07,
    video_number: '691',
    size: 4337017,
    hints: 'lst_Wrist_Circles_1_hints',
    common_mistakes: 'lst_Wrist_Circles_1_common_mistakes',
    breathing: 'lst_Wrist_Circles_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Arms Behind Pull Apart',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Chest, Front Shoulder, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FArms%20Behind%20Pull%20Apart.jpg?alt=media&token=8f095ec8-1821-49d5-ab2d-0737e9951e21',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FArms%20Behind%20Pull%20Apart.mp4?alt=media&token=72dbc05f-8c15-4e00-b450-d9dcfe4e92aa',
    video_id: 'lst_Arms_Behind_Pull_Apart_1',
    video_length: 0.07,
    video_number: '692',
    size: 1360404,
    hints: 'lst_Arms_Behind_Pull_Apart_1_hints',
    common_mistakes: 'lst_Arms_Behind_Pull_Apart_1_common_mistakes',
    breathing: 'lst_Arms_Behind_Pull_Apart_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Alternate Static Side Lunge',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings Calves Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternate%20Static%20Side%20Lunge.jpg?alt=media&token=d75f9159-52d3-4503-9d12-4ecc78cb92b0',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternate%20Static%20Side%20Lunge.mp4?alt=media&token=5c3b9611-05f4-4216-b786-2215a5a1b8b3',
    video_id: 'l_Alternate_Static_Side_Lunge',
    video_length: 0.05,
    video_number: '309',
    size: 3148991,
    hints: 'l_Alternate_Static_Side_Lunge_hints',
    common_mistakes: 'l_Alternate_Static_Side_Lunge_common_mistakes',
    breathing: 'l_Alternate_Static_Side_Lunge_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Squat Hold & Pulses',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20Hold%20%26%20Pulses.jpg?alt=media&token=fd7ff5ee-071c-4d1a-acbf-5cfea864435b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20Hold%20%26%20Pulses.mp4?alt=media&token=391e5f8f-85d9-4b2d-b746-5adf1191ed75',
    video_length: 0.05,
    video_number: '780',
    size: 1942840,
    hints: 'b_Squat_Hold_&_Pulses_hints',
    common_mistakes: 'b_Squat_Hold_&_Pulses_common_mistakes',
    breathing: 'b_Squat_Hold_&_Pulses_breathing',
    video_id: 'b_Squat_Hold_&_Pulses',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Wide Wrist Curl',
    is_free: false,
    primary_muscle: 'Forearm',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: '',
    subcategory_name: 'upper body',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWide%20Wrist%20Curl.jpg?alt=media&token=ab1d86e2-1e00-476b-819b-e7db8e3d8e77',
    time: '45 seconds',
    time_to_play: 0.75,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWide%20Wrist%20Curl.mp4?alt=media&token=d368043f-fafc-4be9-9463-98df438a4f44',
    video_id: 'TnS_Wide_Wrist_Curl_1',
    video_length: 0.07,
    video_number: '496',
    size: 574298,
    hints: 'TnS_Wide_Wrist_Curl_1_hints',
    common_mistakes: 'TnS_Wide_Wrist_Curl_1_common_mistakes',
    breathing: 'TnS_Wide_Wrist_Curl_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Touch The Sky Hold',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTouch%20The%20Sky%20Hold.jpg?alt=media&token=b075bb6c-7bdc-4808-bc81-34231304f5b5',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTouch%20The%20Sky%20Hold.mp4?alt=media&token=6ddad9af-5e4d-450a-88b8-d4c091c846f0',
    video_id: 'Abs_Touch_The_Sky_Hold_5',
    video_length: 0.05,
    video_number: '52',
    size: 378801,
    hints: 'Abs_Touch_The_Sky_Hold_5_hints',
    common_mistakes: 'Abs_Touch_The_Sky_Hold_5_common_mistakes',
    breathing: 'Abs_Touch_The_Sky_Hold_5_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Flappy Bird',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFlappy%20Bird.jpg?alt=media&token=475c0031-a757-433e-bf10-36a8a5fd9fbb',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFlappy%20Bird.mp4?alt=media&token=4fffde46-75b8-4562-be46-c3a55a411b88',
    video_id: 'C_Flappy_Bird',
    video_length: 0.07,
    video_number: '508',
    size: 1709183,
    hints: 'C_Flappy_Bird_hints',
    common_mistakes: 'C_Flappy_Bird_common_mistakes',
    breathing: 'C_Flappy_Bird_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Arm Upward Across',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Middle Shoulders, Rear Shoulders, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FArm%20Upward%20Across.jpg?alt=media&token=04c9b080-c0b8-4711-9f64-85a028738276',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FArm%20Upward%20Across.mp4?alt=media&token=bf77d93c-88e3-4936-ad79-a04b2c7f80d2',
    video_id: 'lst_Arm_Upward_Across_1',
    video_length: 0.07,
    video_number: '708',
    size: 4222375,
    hints: 'lst_Arm_Upward_Across_1_hints',
    common_mistakes: 'lst_Arm_Upward_Across_1_common_mistakes',
    breathing: 'lst_Arm_Upward_Across_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Backward Stretch',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Quads, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBackward%20Stretch.jpg?alt=media&token=43c1c31b-3236-46a2-bc96-8b273af41502',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBackward%20Stretch.mp4?alt=media&token=8c528134-8132-4cd0-8c4f-22fe1752dd94',
    video_id: 'lst_Backward_Stretch_1',
    video_length: 0.07,
    video_number: '641',
    size: 875917,
    hints: 'lst_Backward_Stretch_1_hints',
    common_mistakes: 'lst_Backward_Stretch_1_common_mistakes',
    breathing: 'lst_Backward_Stretch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    is_other_side: 1,
    name: 'Split Squats',
    is_free: false,
    primary_muscle: 'Quads, Glutes',
    rep_range: '10-15',
    secondary_muscle: 'Hamstrings Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSplit%20Squats%20(R).jpg?alt=media&token=7360c709-c7d4-4cd5-b04f-933d2523f22c',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSplit%20Squats%20(R).mp4?alt=media&token=ff8de3a7-bed6-46ac-a7bd-ed18319eb90b',
    video_id: 'LnB_Split_Squats_28',
    video_length: 0.07,
    video_number: '106',
    size: 452377,
    hints: 'LnB_Split_Squats_28_hints',
    common_mistakes: 'LnB_Split_Squats_28_common_mistakes',
    breathing: 'LnB_Split_Squats_28_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Jump Twist',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core, Shoulders',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FJump%20Twist.jpg?alt=media&token=81f6d8a6-93e6-4259-a4f2-4e01c7fe7cf8',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FJump%20Twist.mp4?alt=media&token=d0531acd-8e01-401f-9070-17457a8b0557',
    video_id: 'h_Jump_Twist',
    video_length: 0.07,
    video_number: '536',
    size: 1318784,
    hints: 'h_Jump_Twist_hints',
    common_mistakes: 'h_Jump_Twist_common_mistakes',
    breathing: 'h_Jump_Twist_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Curtsy Squats',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCurtsy%20Squats.jpg?alt=media&token=b4b3fdeb-2c98-40db-8e32-97a95660440a',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCurtsy%20Squats.mp4?alt=media&token=4aa10e01-d530-4cef-870e-b2d3f04bfa7e',
    video_length: 0.05,
    video_number: '752',
    size: 1942840,
    hints: 'b_Curtsy_Squats_hints',
    common_mistakes: 'b_Curtsy_Squats_common_mistakes',
    breathing: 'b_Curtsy_Squats_breathing',
    video_id: 'b_Curtsy_Squats',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Sumo Squats',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSumo%20Squats.jpg?alt=media&token=d56491cb-438a-4b72-9d4c-76d6db74a657',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSumo%20Squats.mp4?alt=media&token=771926eb-c133-4c9c-ba7c-20bdadf13f95',
    video_length: 0.05,
    video_number: '794',
    size: 1942840,
    hints: 'b_Sumo_Squats_hints',
    common_mistakes: 'b_Sumo_Squats_common_mistakes',
    breathing: 'b_Sumo_Squats_breathing',
    video_id: 'b_Sumo_Squats',
  },
  {
    category_id: 4,
    category_name: 'legs',
    name: 'Static Lunge',
    is_other_side: 0,
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStatic%20Lunge%20(L).jpg?alt=media&token=2fe73d34-f963-4916-bbda-510929611672',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStatic%20Lunge%20(L).mp4?alt=media&token=ae4a415d-670c-46f6-983e-b3e158df729d',
    video_length: 0.05,
    video_number: '765',
    size: 1942840,
    hints: 'l_Static_Lunge_hints',
    common_mistakes: 'l_Static_Lunge_common_mistakes',
    breathing: 'l_Static_Lunge_breathing',
    video_id: 'l_Static_Lunge',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Seated Knee Over Tuck',
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Glutes',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSeated%20Knee%20Over%20Tuck.jpg?alt=media&token=013f470f-4011-42fc-a7a4-c778b2f4b7d0',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSeated%20Knee%20Over%20Tuck.mp4?alt=media&token=6feda51d-f5f0-459c-b58d-ed299cddabf0',
    video_id: 'lst_Seated_Knee_Over_Tuck_1',
    video_length: 0.07,
    video_number: '673',
    size: 5787876,
    hints: 'lst_Seated_Knee_Over_Tuck_1_hints',
    common_mistakes: 'lst_Seated_Knee_Over_Tuck_1_common_mistakes',
    breathing: 'lst_Seated_Knee_Over_Tuck_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Inner Leg Lift',
    is_other_side: 0,
    is_free: true,
    primary_muscle: '',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'legs',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FInner%20Leg%20Lift%20(L).jpg?alt=media&token=ab0fe4dc-8764-49dd-bfd3-49376a3ab55f',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FInner%20Leg%20Lift%20(L).mp4?alt=media&token=93c098b9-e291-499b-8b22-644e7445b7af',
    video_id: 'l_Inner_Leg_Lift',
    video_length: 0.05,
    video_number: '309',
    size: 3148991,
    hints: 'l_Inner_Leg_Lift_hints',
    common_mistakes: 'l_Inner_Leg_Lift_common_mistakes',
    breathing: 'l_Inner_Leg_Lift_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Overhead Arm Pull',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Triceps, Latissimus Dorsi, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOverhead%20Arm%20Pull.jpg?alt=media&token=9219cc71-908d-4559-baa8-744672e644b9',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOverhead%20Arm%20Pull.mp4?alt=media&token=6169ed90-8790-4b2d-a089-cf942c028841',
    video_id: 'lst_Overhead_Arm_Pull_1',
    video_length: 0.07,
    video_number: '721',
    size: 4607935,
    hints: 'lst_Overhead_Arm_Pull_1_hints',
    common_mistakes: 'lst_Overhead_Arm_Pull_1_common_mistakes',
    breathing: 'lst_Overhead_Arm_Pull_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Close Grip Hammer Curl Into Side Extension',
    is_free: false,
    primary_muscle: 'Biceps, Brachialis, Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Forearm, Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Traps, Chest, Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FClose%20Grip%20Hammer%20Curl%20Into%20Side%20Extension.jpg?alt=media&token=5fdf2656-0575-4652-b2df-7cc93eff3d47',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FClose%20Grip%20Hammer%20Curl%20Into%20Side%20Extension.mp4?alt=media&token=2cdae886-c0cf-4ba3-81fc-485c3b341e0c',
    video_id: 'TnS_Close_Grip_Hammer_Curl_Into_Side_Extension_1',
    video_length: 0.07,
    video_number: '483',
    size: 2288401,
    hints: 'TnS_Close_Grip_Hammer_Curl_Into_Side_Extension_1_hints',
    common_mistakes:
      'TnS_Close_Grip_Hammer_Curl_Into_Side_Extension_1_common_mistakes',
    breathing: 'TnS_Close_Grip_Hammer_Curl_Into_Side_Extension_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Frog Variation Rocks',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Adductors, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFrog%20Variation%20Rocks.jpg?alt=media&token=a2be375f-12c4-4044-80cd-57d425fbf9ba',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFrog%20Variation%20Rocks.mp4?alt=media&token=6311aa0f-9e57-49e7-9068-5ddecd2f4828',
    video_id: 'lst_Frog_Variation_Rocks_1',
    video_length: 0.07,
    video_number: '654',
    size: 1178093,
    hints: 'lst_Frog_Variation_Rocks_1_hints',
    common_mistakes: 'lst_Frog_Variation_Rocks_1_common_mistakes',
    breathing: 'lst_Frog_Variation_Rocks_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    is_other_side: 1,
    name: 'Step Up',
    is_free: false,
    primary_muscle: 'Quads, Hamstrings, Glutes',
    rep_range: '10-15',
    secondary_muscle: 'Adductors',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStep%20Up%20(R).jpg?alt=media&token=8df408bd-9e2e-4834-be77-d830dc73b9ce',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Chair',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStep%20Up%20(R).mp4?alt=media&token=82571bd5-d5bc-4210-9794-79efbebff231',
    video_id: 'LnB_Step_Up_35',
    video_length: 0.1,
    video_number: '34',
    size: 670168,
    hints: 'LnB_Step_Up_35_hints',
    common_mistakes: 'LnB_Step_Up_35_common_mistakes',
    breathing: 'LnB_Step_Up_35_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Scissor Switch',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FScissor%20Switch.jpg?alt=media&token=20f8cccd-45b0-4fff-a606-3ef88b0df324',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FScissor%20Switch.mp4?alt=media&token=c06007e8-24c9-4ad2-a737-c9c9c7d54de1',
    video_id: 'h_Scissor_Switch_1',
    video_length: 0.07,
    video_number: '559',
    size: 2758571,
    hints: 'h_Scissor_Switch_1_hints',
    common_mistakes: 'h_Scissor_Switch_1_common_mistakes',
    breathing: 'h_Scissor_Switch_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'V Hold',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FV%20Hold.jpg?alt=media&token=9a16e4d7-845c-400c-aecf-52c02bfa8ba8',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FV%20Hold.mp4?alt=media&token=03dff9b2-a45b-4d3e-89ea-8d230dd5e541',
    video_id: 'Abs_V_Hold_8',
    video_length: 0.07,
    video_number: '33',
    size: 442068,
    hints: 'Abs_V_Hold_8_hints',
    common_mistakes: 'Abs_V_Hold_8_common_mistakes',
    breathing: 'Abs_V_Hold_8_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Halo Slam',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHalo%20Slam.jpg?alt=media&token=157821a5-fe5f-4e8a-bba1-415fee218a89',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHalo%20Slam.mp4?alt=media&token=2b93cf90-bfcd-4afc-aa24-5638fc4b3e25',
    video_id: 'h_Halo_Slam_1',
    video_length: 0.07,
    video_number: '575',
    size: 2606893,
    hints: 'h_Halo_Slam_1_hints',
    common_mistakes: 'h_Halo_Slam_1_common_mistakes',
    breathing: 'h_Halo_Slam_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Lying Leg Curls',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLying%20Leg%20Curls.jpg?alt=media&token=da5b02af-0139-4b20-97cc-4d4d58bde6b2',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLying%20Leg%20Curls.mp4?alt=media&token=b38e1cf5-4640-42ac-bbe1-11ef1f12087a',
    video_length: 0.05,
    video_number: '766',
    size: 1942840,
    hints: 'b_Lying_Leg_Curls_hints',
    common_mistakes: 'b_Lying_Leg_Curls_common_mistakes',
    breathing: 'b_Lying_Leg_Curls_breathing',
    video_id: 'b_Lying_Leg_Curls',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Wide Side Squat',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    secondary_muscle: 'Adductors, Erector Spinae',
    rep_range: '8-20',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWide%20Side%20Squat%20(L).jpg?alt=media&token=d3e6e1cf-0c1d-4559-ac93-d7a71721b7ef',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWide%20Side%20Squat%20(L).mp4?alt=media&token=7118183b-3f0b-40cc-8c96-17158ee0a513',
    video_length: 0.05,
    video_number: '796',
    size: 1942840,
    hints: 'b_Wide_Side_Squat_1_hints',
    common_mistakes: 'b_Wide_Side_Squat_1_common_mistakes',
    breathing: 'b_Wide_Side_Squat_1_breathing',
    is_other_side: 0,
    video_id: 'b_Wide_Side_Squat_1',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Wall Side Knee Bends',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Side%20Knee%20Bends.jpg?alt=media&token=c2ea5e99-630d-43b7-af4d-7c7972f55a24',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Side%20Knee%20Bends.mp4?alt=media&token=13b88dc7-c152-41ef-b4d7-ab1df24acf12',
    video_length: 0.05,
    video_number: '829',
    size: 1942840,
    hints: 'wp_Wall_Side_Knee_Bends_hints',
    common_mistakes: 'wp_Wall_Side_Knee_Bends_common_mistakes',
    breathing: 'wp_Wall_Side_Knee_Bends_breathing',
    video_id: 'wp_Wall_Side_Knee_Bends',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Back Scratch',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Middle Shoulders, Rear Shoulders',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBack%20Scratch.jpg?alt=media&token=97d7e6a6-4576-40b1-83ee-f493fe97f9ac',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBack%20Scratch.mp4?alt=media&token=bb9a2be4-1f90-4a4e-bf23-ebdd640027d1',
    video_id: 'lst_Back_Scratch_1',
    video_length: 0.07,
    video_number: '709',
    size: 3229727,
    hints: 'lst_Back_Scratch_1_hints',
    common_mistakes: 'lst_Back_Scratch_1_common_mistakes',
    breathing: 'lst_Back_Scratch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Wall Leg On Leg Twist',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Leg%20On%20Leg%20Twist.jpg?alt=media&token=87f4cf71-16b9-43e9-ba97-9e516052e8e7',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Leg%20On%20Leg%20Twist.mp4?alt=media&token=2baf0940-c3ba-4d0b-945e-2448f0a5675a',
    video_length: 0.05,
    video_number: '823',
    size: 1942840,
    hints: 'wp_Wall_Leg_On_Leg_Twist_hints',
    common_mistakes: 'wp_Wall_Leg_On_Leg_Twist_common_mistakes',
    breathing: 'wp_Wall_Leg_On_Leg_Twist_breathing',
    video_id: 'wp_Wall_Leg_On_Leg_Twist',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Shoulder Press',
    is_other_side: 0,
    is_free: true,
    primary_muscle: 'Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Traps, Chest, Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FShoulder%20Press%20(L).jpg?alt=media&token=9cf33ae6-803d-49f2-bda3-7846a64868d7',
    time: '60 seconds',
    time_to_play: 1,
    tools: '',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FShoulder%20Press%20(L).mp4?alt=media&token=ac415321-97af-4aba-9fc7-343a6742bb75',
    video_id: 'TnS_Shoulder_Press_42',
    video_length: 0.1,
    video_number: '103',
    size: 1343153,
    hints: 'TnS_Shoulder_Press_42_hints',
    common_mistakes: 'TnS_Shoulder_Press_42_common_mistakes',
    breathing: 'TnS_Shoulder_Press_42_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Wall Back Stretch',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Back%20Stretch%20(R).jpg?alt=media&token=9eedab21-8fb8-4c0e-ad80-a3556a57c899',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Back%20Stretch%20(R).mp4?alt=media&token=b2925bdd-fa13-4e4f-a2c5-ceec4feeae5d',
    video_length: 0.05,
    video_number: '820',
    size: 1942840,
    hints: 'wp_Wall_Back_Stretch_2_hints',
    common_mistakes: 'wp_Wall_Back_Stretch_2_common_mistakes',
    breathing: 'wp_Wall_Back_Stretch_2_breathing',
    is_other_side: 1,
    video_id: 'wp_Wall_Back_Stretch_2',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Hammer Rear Delt Raises',
    is_free: true,
    primary_muscle: 'Rear Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Middle Shoulders, Traps, Rhomboids, Infraspinatus, Teres Minor',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Front Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHammer%20Rear%20Delt%20Raises.jpg?alt=media&token=69779e73-e53c-4e2d-a466-2d6fd8d5f3a1',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHammer%20Rear%20Delt%20Raises.mp4?alt=media&token=cccc286a-b1a4-4416-b9ae-9c66baf24ee6',
    video_id: 'TnS_Hammer_Rear_Delt_Raises_35',
    video_length: 0.12,
    video_number: '86',
    size: 766428,
    hints: 'TnS_Hammer_Rear_Delt_Raises_35_hints',
    common_mistakes: 'TnS_Hammer_Rear_Delt_Raises_35_common_mistakes',
    breathing: 'TnS_Hammer_Rear_Delt_Raises_35_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Kneeling Wrist',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Wrists, Biceps',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FKneeling%20Wrist.jpg?alt=media&token=a017ec21-486d-4732-b326-204bd20ae575',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FKneeling%20Wrist.mp4?alt=media&token=189cd0bb-55c7-4bdc-81dc-ef45641deb02',
    video_id: 'lst_Kneeling_Wrist_1',
    video_length: 0.07,
    video_number: '699',
    size: 2620610,
    hints: 'lst_Kneeling_Wrist_1_hints',
    common_mistakes: 'lst_Kneeling_Wrist_1_common_mistakes',
    breathing: 'lst_Kneeling_Wrist_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Alternating Lunge',
    is_free: false,
    primary_muscle: 'Quads, Hamstrings, Glutes',
    rep_range: '10-16',
    secondary_muscle: 'Adductors',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Lunge.jpg?alt=media&token=3445f043-f1a0-42c6-9ce8-684db590b0df',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Lunge.mp4?alt=media&token=98fbb891-4863-4442-97b7-a558d28f270f',
    video_id: 'LnB_Alternating_Lunge_54',
    video_length: 0.08,
    video_number: '119',
    size: 568467,
    hints: 'LnB_Alternating_Lunge_54_hints',
    common_mistakes: 'LnB_Alternating_Lunge_54_common_mistakes',
    breathing: 'LnB_Alternating_Lunge_54_breathing',
  },
  {
    category_id: 1,
    category_name: 'upper body',
    name: 'Wall Curls',
    is_free: false,
    primary_muscle: 'Biceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Brachialis, Forearm',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Curls.jpg?alt=media&token=0df7a632-1a0b-47b0-ac19-d513407c97a8',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Wall',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Curls.mp4?alt=media&token=0c57e8b3-b651-43c1-a8f0-bf5f74564281',
    video_id: 'CnB_Wall_Curls_1',
    video_length: 0.1,
    video_number: '171',
    size: 1468567,
    hints: 'CnB_Wall_Curls_1_hints',
    common_mistakes: 'CnB_Wall_Curls_1_common_mistakes',
    breathing: 'CnB_Wall_Curls_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Ankle Circles',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Ankles, Calves',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAnkle%20Circles.jpg?alt=media&token=c5ef7ebc-98a5-4a80-86ec-a885d0b730fd',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAnkle%20Circles.mp4?alt=media&token=34c873ae-6c5b-4689-a2ce-44be329de240',
    video_id: 'lst_Ankle_Circles_1',
    video_length: 0.07,
    video_number: '639',
    size: 4781547,
    hints: 'lst_Ankle_Circles_1_hints',
    common_mistakes: 'lst_Ankle_Circles_1_common_mistakes',
    breathing: 'lst_Ankle_Circles_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Wall Straddle Stretch',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Straddle%20Stretch.jpg?alt=media&token=3d16433f-9829-4a0e-a511-c0a9f692c7f4',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Straddle%20Stretch.mp4?alt=media&token=c4c98b32-b039-4e9d-a7b1-ea9d3b265027',
    video_length: 0.05,
    video_number: '831',
    size: 1942840,
    hints: 'wp_Wall_Straddle_Stretch_hints',
    common_mistakes: 'wp_Wall_Straddle_Stretch_common_mistakes',
    breathing: 'wp_Wall_Straddle_Stretch_breathing',
    video_id: 'wp_Wall_Straddle_Stretch',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Knee Tuck Crunch',
    is_free: false,
    primary_muscle: 'Upper Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs, Obliques, Hip Flexors',
    tertiary_muscle: 'Quads',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FKnee%20Tuck%20Crunch.jpg?alt=media&token=7a5dad4d-7007-49de-bb7d-c1010b87b4bb',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FKnee%20Tuck%20Crunch.mp4?alt=media&token=c9fb3d6e-870b-48a6-9972-3ca0b24e177a',
    video_id: 'Abs_Knee_Tuck_Crunch_22',
    video_length: 0.05,
    video_number: '3',
    size: 422328,
    hints: 'Abs_Knee_Tuck_Crunch_22_hints',
    common_mistakes: 'Abs_Knee_Tuck_Crunch_22_common_mistakes',
    breathing: 'Abs_Knee_Tuck_Crunch_22_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Super Skater Jump',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSuper%20Skater%20Jump.jpg?alt=media&token=84febdb4-6c1e-4d25-8f1b-1bc6b2336c9a',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSuper%20Skater%20Jump.mp4?alt=media&token=80eef392-f365-466d-b291-5af2ebec02bc',
    video_id: 'h_Super_Skater_Jump_1',
    video_length: 0.07,
    video_number: '571',
    size: 3150566,
    hints: 'h_Super_Skater_Jump_1_hints',
    common_mistakes: 'h_Super_Skater_Jump_1_common_mistakes',
    breathing: 'h_Super_Skater_Jump_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Plie Squat Arm Circles',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Shoulders',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPlie%20Squat%20Arm%20Circles.jpg?alt=media&token=c223bf63-e90e-4fc9-ae46-e5cb12acb4ef',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPlie%20Squat%20Arm%20Circles.mp4?alt=media&token=39c267d9-b41b-4ebe-88a1-e5d5f630d77e',
    video_id: 'LnB_Plie_Squat_Arm_Circles_1',
    video_length: 0.05,
    video_number: '285',
    size: 1430151,
    hints: 'LnB_Plie_Squat_Arm_Circles_1_hints',
    common_mistakes: 'LnB_Plie_Squat_Arm_Circles_1_common_mistakes',
    breathing: 'LnB_Plie_Squat_Arm_Circles_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'upper body',
    name: 'Goodmorning',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Erector Spinae',
    rep_range: '15-20',
    secondary_muscle:
      'Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FGoodmorning.jpg?alt=media&token=98c470a4-b3ce-42ea-b2d9-3c001cbdc6a6',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FGoodmorning.mp4?alt=media&token=9262c3ab-2b92-41c9-b3e7-331910704fbe',
    video_id: 'ub_Goodmorning',
    video_length: 0.07,
    video_number: '104',
    size: 519976,
    hints: 'ub_Goodmorning_hints',
    common_mistakes: 'ub_Goodmorning_common_mistakes',
    breathing: 'ub_Goodmorning_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Squat Back Lunge',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings',
    rep_range: '3-4',
    secondary_muscle: 'Adductors',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20Back%20Lunge.jpg?alt=media&token=ad1d5d40-327e-434e-aa80-c63f16af5051',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20Back%20Lunge.mp4?alt=media&token=b5a35baf-5abe-4f06-82f6-7033f880950b',
    video_id: 'LnB_Squat_Back_Lunge_23',
    video_length: 0.12,
    video_number: '112',
    size: 727872,
    hints: 'LnB_Squat_Back_Lunge_23_hints',
    common_mistakes: 'LnB_Squat_Back_Lunge_23_common_mistakes',
    breathing: 'LnB_Squat_Back_Lunge_23_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Hanging Hamstring Stretch',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstrings',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHanging%20Hamstring%20Stretch.jpg?alt=media&token=4d0fb67a-04e0-40fa-a8db-f89eae1daaf1',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHanging%20Hamstring%20Stretch.mp4?alt=media&token=366c577b-d65d-4521-9fe5-c85fc4eada2d',
    video_id: 'lst_Hanging_Hamstring_Stretch_1',
    video_length: 0.07,
    video_number: '656',
    size: 1355453,
    hints: 'lst_Hanging_Hamstring_Stretch_1_hints',
    common_mistakes: 'lst_Hanging_Hamstring_Stretch_1_common_mistakes',
    breathing: 'lst_Hanging_Hamstring_Stretch_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Knee & Ankle Rotations',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Knees, Ankles',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FKnee%20%26%20Ankle%20Rotations.jpg?alt=media&token=b7e78bb1-f5da-4e0b-b353-ed65bec6b7ff',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FKnee%20%26%20Ankle%20Rotations.mp4?alt=media&token=0b23e22e-de46-423b-b729-c780b632e6e8',
    video_id: 'lst_Knee_Ankle_Rotations_1',
    video_length: 0.07,
    video_number: '659',
    size: 2879834,
    hints: 'lst_Knee_Ankle_Rotations_1_hints',
    common_mistakes: 'lst_Knee_Ankle_Rotations_1_common_mistakes',
    breathing: 'lst_Knee_Ankle_Rotations_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Outward Tricep Extension',
    is_free: false,
    primary_muscle: 'Triceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Rear Shoulders',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Latissimus Dorsi, Traps, Rhomboids',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOutward%20Tricep%20Extension.jpg?alt=media&token=cd5c07d2-68bb-42ca-9d6e-a38d441fe2e7',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOutward%20Tricep%20Extension.mp4?alt=media&token=a186afec-fa8c-4e94-8ceb-73521fe623d9',
    video_id: 'TnS_Outward_Tricep_Extension_1',
    video_length: 0.1,
    video_number: '175',
    size: 1190293,
    hints: 'TnS_Outward_Tricep_Extension_1_hints',
    common_mistakes: 'TnS_Outward_Tricep_Extension_1_common_mistakes',
    breathing: 'TnS_Outward_Tricep_Extension_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Quick Feet',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FQuick%20Feet.jpg?alt=media&token=98193f6a-eb00-47c0-b7d2-dc199e64d9bd',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FQuick%20Feet.mp4?alt=media&token=a0ce321f-9187-43bf-80b2-4f02afa21167',
    video_id: 'h_Quick_Feet_1',
    video_length: 0.07,
    video_number: '577',
    size: 1445814,
    hints: 'h_Quick_Feet_1_hints',
    common_mistakes: 'h_Quick_Feet_1_common_mistakes',
    breathing: 'h_Quick_Feet_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Side To Side Hop',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20To%20Side%20Hop.jpg?alt=media&token=fe7124dd-0944-41ee-a30e-0b0d2336ec39',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20To%20Side%20Hop.mp4?alt=media&token=d44bf834-42af-4110-8db7-a348ade27a41',
    video_id: 'h_Side_To_Side_Hop_1',
    video_length: 0.07,
    video_number: '562',
    size: 1581022,
    hints: 'h_Side_To_Side_Hop_1_hints',
    common_mistakes: 'h_Side_To_Side_Hop_1_common_mistakes',
    breathing: 'h_Side_To_Side_Hop_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Alternating Knee Tucks',
    is_free: true,
    muscle_category: ['booty'],
    primary_muscle: 'Glutes',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Knee%20Tucks.jpg?alt=media&token=12726de8-ae7a-4eed-a257-d28161bc122b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Knee%20Tucks.mp4?alt=media&token=ff5e44eb-2374-4d7f-8feb-086325225422',
    video_id: 'lst_Alternating_Knee_Tucks_1',
    video_length: 0.07,
    video_number: '638',
    size: 4033334,
    hints: 'lst_Alternating_Knee_Tucks_1_hints',
    common_mistakes: 'lst_Alternating_Knee_Tucks_1_common_mistakes',
    breathing: 'lst_Alternating_Knee_Tucks_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Butterfly Stretch',
    is_free: true,
    muscle_category: ['legs'],
    primary_muscle: 'Adductors,  Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FButterfly%20Stretch.jpg?alt=media&token=5056ddb9-a45c-4c3b-9338-50cc7a2748ad',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FButterfly%20Stretch.mp4?alt=media&token=6ffda955-fd02-445a-ac8f-0f0125048676',
    video_id: 'lst_Butterfly_Stretch_1',
    video_length: 0.07,
    video_number: '643',
    size: 1031461,
    hints: 'lst_Butterfly_Stretch_1_hints',
    common_mistakes: 'lst_Butterfly_Stretch_1_common_mistakes',
    breathing: 'lst_Butterfly_Stretch_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Lateral Raise Into Front Raise',
    is_free: false,
    primary_muscle: 'Front Shoulders, Middle Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Rear Shoulders, Upper Chest, Serratus Anterior, Traps',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Core, Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLateral%20Raise%20Into%20Front%20Raise.jpg?alt=media&token=bd763f42-4d3b-47b0-a7b9-f55340deb8e8',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLateral%20Raise%20Into%20Front%20Raise.mp4?alt=media&token=8a8500e4-a8c8-4539-a083-47df3edd98d8',
    video_id: 'TnS_Lateral_Raise_Into_Front_Raise_1',
    video_length: 0.07,
    video_number: '424',
    size: 1541481,
    hints: 'TnS_Lateral_Raise_Into_Front_Raise_1_hints',
    common_mistakes: 'TnS_Lateral_Raise_Into_Front_Raise_1_common_mistakes',
    breathing: 'TnS_Lateral_Raise_Into_Front_Raise_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Single Arm Kickback',
    is_other_side: 0,
    is_free: false,
    primary_muscle: 'Triceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Rear Shoulders',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Latissimus Dorsi, Traps, Rhomboids',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Arm%20Kickback%20(L).jpg?alt=media&token=0421339b-2c48-44c7-9cd7-3ec8278bf1d0',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Arm%20Kickback%20(L).mp4?alt=media&token=a0948368-c83f-4ee1-a40f-4aba3ce18d1b',
    video_id: 'TnS_Single_Arm_Kickback_1',
    video_length: 0.1,
    video_number: '173',
    size: 1349937,
    hints: 'TnS_Single_Arm_Kickback_1_hints',
    common_mistakes: 'TnS_Single_Arm_Kickback_1_common_mistakes',
    breathing: 'TnS_Single_Arm_Kickback_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Front Squats',
    is_free: true,
    primary_muscle: 'Quads, Glutes',
    rep_range: '15-20',
    secondary_muscle: 'Hamstrings Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFront%20Squats.jpg?alt=media&token=01b6a765-e41e-4941-88ba-ff41c6d452c8',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFront%20Squats.mp4?alt=media&token=02cef277-1c08-41b8-b33b-42ceddfbebb8',
    video_id: 'LnB_Front_Squats_51',
    video_length: 0.08,
    video_number: '108',
    size: 619560,
    hints: 'LnB_Front_Squats_51_hints',
    common_mistakes: 'LnB_Front_Squats_51_common_mistakes',
    breathing: 'LnB_Front_Squats_51_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Sumo Deadlift',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Erector Spinae',
    rep_range: '15-20',
    secondary_muscle:
      'Hamstrings, Adductors, Traps, Lower Back, Latissimus Dorsi',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSumo%20Deadlift.jpg?alt=media&token=95055277-adab-4e3b-a0a1-21995c45a9bb',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSumo%20Deadlift.mp4?alt=media&token=fa06462c-0c01-406a-8f25-e7fcac65fb99',
    video_id: 'LnB_Sumo_Deadlift_11',
    video_length: 0.07,
    video_number: '104',
    size: 519976,
    hints: 'LnB_Sumo_Deadlift_11_hints',
    common_mistakes: 'LnB_Sumo_Deadlift_11_common_mistakes',
    breathing: 'LnB_Sumo_Deadlift_11_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Front Raise',
    is_free: true,
    primary_muscle: 'Front Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Upper Chest, Middle Shoulders, Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Rear Shoulders, Traps, Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFront%20Raise.jpg?alt=media&token=8e0d88d6-ee42-4e44-aadd-30629c4befc9',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFront%20Raise.mp4?alt=media&token=66c3e60d-7962-41e2-99fa-304e8506f424',
    video_id: 'TnS_Front_Raise_24',
    video_length: 0.05,
    video_number: '57',
    size: 376422,
    hints: 'TnS_Front_Raise_24_hints',
    common_mistakes: 'TnS_Front_Raise_24_common_mistakes',
    breathing: 'TnS_Front_Raise_24_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Wall Leg Pulls',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Leg%20Pulls.jpg?alt=media&token=d8acdd9f-1d63-438a-8b0b-2c8704c01059',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Leg%20Pulls.mp4?alt=media&token=8671f19e-a90f-4b9d-a617-2cc7ddd247ac',
    video_length: 0.05,
    video_number: '824',
    size: 1942840,
    hints: 'wp_Wall_Leg_Pulls_hints',
    common_mistakes: 'wp_Wall_Leg_Pulls_common_mistakes',
    breathing: 'wp_Wall_Leg_Pulls_breathing',
    video_id: 'wp_Wall_Leg_Pulls',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Another Leg Kick',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAnother%20Leg%20Kick%20(L).jpg?alt=media&token=786df9a4-a91f-40ce-bf44-b4aa61272cdb',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAnother%20Leg%20Kick%20(L).mp4?alt=media&token=aef70d98-948f-4dfe-aa5a-6bea7d32d2e6',
    video_length: 0.05,
    video_number: '804',
    size: 1942840,
    hints: 'wp_Another_Leg_Kick_1_hints',
    common_mistakes: 'wp_Another_Leg_Kick_1_common_mistakes',
    breathing: 'wp_Another_Leg_Kick_1_breathing',
    is_other_side: 0,
    video_id: 'wp_Another_Leg_Kick_1',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: '3-Way Leg Crunch',
    is_free: false,
    primary_muscle: 'Obliques, Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2F3-Way%20Leg%20Crunch.jpg?alt=media&token=bc3f61d2-6267-42bb-983f-65543368a92f',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2F3-Way%20Leg%20Crunch.mp4?alt=media&token=9fa2a959-1295-412b-a7c2-df279a74ac04',
    video_id: 'Abs_3_Way_Leg_Crunch_1',
    video_length: 0.07,
    video_number: '209',
    size: 6580601,
    hints: 'Abs_3_Way_Leg_Crunch_1_hints',
    common_mistakes: 'Abs_3_Way_Leg_Crunch_1_common_mistakes',
    breathing: 'Abs_3_Way_Leg_Crunch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Squat Into Pulse Squat',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20Into%20Pulse%20Squat.jpg?alt=media&token=aab99b52-7a58-4cab-bee6-53dbe30f7eee',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20Into%20Pulse%20Squat.mp4?alt=media&token=b8862529-81d2-4757-978a-c2fc149d4b74',
    video_id: 'LnB_Squat_Into_Pulse_Squat_1',
    video_length: 0.05,
    video_number: '277',
    size: 2384778,
    hints: 'LnB_Squat_Into_Pulse_Squat_1_hints',
    common_mistakes: 'LnB_Squat_Into_Pulse_Squat_1_common_mistakes',
    breathing: 'LnB_Squat_Into_Pulse_Squat_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Narrow Squat',
    is_free: false,
    primary_muscle: 'Quads, Glutes',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FNarrow%20Squat.jpg?alt=media&token=f768b638-5e7f-4ea1-ac7e-b7c21ad69452',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FNarrow%20Squat.mp4?alt=media&token=f20749ba-cfc6-46ab-a4a8-dbc2ae52a691',
    video_id: 'LnB_Narrow_Squat_1',
    video_length: 0.05,
    video_number: '303',
    size: 2030155,
    hints: 'LnB_Narrow_Squat_1_hints',
    common_mistakes: 'LnB_Narrow_Squat_1_common_mistakes',
    breathing: 'LnB_Narrow_Squat_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Kneeling Back Stretch',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Spinal, Hip Flexors, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FKneeling%20Back%20Stretch.jpg?alt=media&token=b8da4a6d-a088-4705-a12e-a9aac21fbcb8',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FKneeling%20Back%20Stretch.mp4?alt=media&token=25848f43-89f7-4c5a-afb3-cd23899bc251',
    video_id: 'lst_Kneeling_Back_Stretch_1',
    video_length: 0.07,
    video_number: '732',
    size: 6135262,
    hints: 'lst_Kneeling_Back_Stretch_1_hints',
    common_mistakes: 'lst_Kneeling_Back_Stretch_1_common_mistakes',
    breathing: 'lst_Kneeling_Back_Stretch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Active Calf Stretch',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FActive%20Calf%20Stretch%20(L).jpg?alt=media&token=c44f1267-e2f6-49e8-ae5e-2633e611ddcb',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FActive%20Calf%20Stretch%20(L).mp4?alt=media&token=1f4c0724-ba0b-427e-9de5-7a7f13a8de81',
    video_length: 0.05,
    video_number: '799',
    size: 1942840,
    hints: 'wp_Active_Calf_Stretch_1_hints',
    common_mistakes: 'wp_Active_Calf_Stretch_1_common_mistakes',
    breathing: 'wp_Active_Calf_Stretch_1_breathing',
    is_other_side: 0,
    video_id: 'wp_Active_Calf_Stretch_1',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Predator Jacks',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPredator%20Jacks.jpg?alt=media&token=15b98e20-f980-47e9-8bfe-e15895f2871c',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPredator%20Jacks.mp4?alt=media&token=5c66b0d7-fc65-463e-944b-a44c1b8a75c9',
    video_id: 'h_Predator_Jacks_1',
    video_length: 0.07,
    video_number: '558',
    size: 3577466,
    hints: 'h_Predator_Jacks_1_hints',
    common_mistakes: 'h_Predator_Jacks_1_common_mistakes',
    breathing: 'h_Predator_Jacks_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Palm Down Shoulder Pulse',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Shoulders, Chest, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPalm%20Down%20Shoulder%20Pulse.jpg?alt=media&token=f2b17f58-ef0a-48d0-ac2c-c6d1e45645c2',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPalm%20Down%20Shoulder%20Pulse.mp4?alt=media&token=74306921-a9e7-4268-be1e-3b327d6d72e9',
    video_id: 'lst_Palm_Down_Shoulder_Pulse_1',
    video_length: 0.07,
    video_number: '713',
    size: 1474410,
    hints: 'lst_Palm_Down_Shoulder_Pulse_1_hints',
    common_mistakes: 'lst_Palm_Down_Shoulder_Pulse_1_common_mistakes',
    breathing: 'lst_Palm_Down_Shoulder_Pulse_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Seated Hamstring Stretches',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstrings',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSeated%20Hamstring%20Stretches.jpg?alt=media&token=dd310e8f-56fd-4a50-a4fb-ab8e55c60d5b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Chair',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSeated%20Hamstring%20Stretches.mp4?alt=media&token=226dd83f-f221-46dd-a163-dc05ac735cb1',
    video_id: 'lst_Seated_Hamstring_Stretches_1',
    video_length: 0.07,
    video_number: '672',
    size: 4538285,
    hints: 'lst_Seated_Hamstring_Stretches_1_hints',
    common_mistakes: 'lst_Seated_Hamstring_Stretches_1_common_mistakes',
    breathing: 'lst_Seated_Hamstring_Stretches_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Side Wrist Curl',
    is_free: false,
    primary_muscle: 'Forearm',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: '',
    subcategory_name: 'upper body',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Wrist%20Curl.jpg?alt=media&token=2c7761c9-d9cf-433d-a251-d4f4cd94a960',
    time: '45 seconds',
    time_to_play: 0.75,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Wrist%20Curl.mp4?alt=media&token=5cec9e91-f408-453d-b842-4ab3f52520b7',
    video_id: 'TnS_Side_Wrist_Curl_1',
    video_length: 0.07,
    video_number: '494',
    size: 717365,
    hints: 'TnS_Side_Wrist_Curl_1_hints',
    common_mistakes: 'TnS_Side_Wrist_Curl_1_common_mistakes',
    breathing: 'TnS_Side_Wrist_Curl_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Glute Bridges',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FGlute%20Bridges.jpg?alt=media&token=5bdc73c5-9784-4775-b206-942038d61279',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FGlute%20Bridges.mp4?alt=media&token=59e23dda-c84b-4de1-b4a3-f7f8b8eedbae',
    video_length: 0.05,
    video_number: '755',
    size: 1942840,
    hints: 'b_Glute_Bridges_hints',
    common_mistakes: 'b_Glute_Bridges_common_mistakes',
    breathing: 'b_Glute_Bridges_breathing',
    video_id: 'b_Glute_Bridges',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'March In Place',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FMarch%20In%20Place.jpg?alt=media&token=a9ba8d73-9aea-48bd-bafe-af8a19fb1ca5',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FMarch%20In%20Place.mp4?alt=media&token=ccba7b4e-6856-405c-9f8c-c54035d7988e',
    video_id: 'C_March_In_Place_1',
    video_length: 0.07,
    video_number: '520',
    size: 2218248,
    hints: 'C_March_In_Place_1_hints',
    common_mistakes: 'C_March_In_Place_1_common_mistakes',
    breathing: 'C_March_In_Place_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Alternating Elbow Towards Knee',
    is_free: false,
    primary_muscle: 'Obliques, Upper Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Elbow%20Towards%20Knee.jpg?alt=media&token=cab78f15-04e3-4d1f-9bb1-dea3349d0045',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Elbow%20Towards%20Knee.mp4?alt=media&token=502188a6-fd6d-4238-80d5-2ca46bc7fb24',
    video_id: 'Abs_Alternating_Elbow_Towards_Knee_1',
    video_length: 0.07,
    video_number: '192',
    size: 2112290,
    hints: 'Abs_Alternating_Elbow_Towards_Knee_1_hints',
    common_mistakes: 'Abs_Alternating_Elbow_Towards_Knee_1_common_mistakes',
    breathing: 'Abs_Alternating_Elbow_Towards_Knee_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Low Heel Tap',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLow%20Heel%20Tap.jpg?alt=media&token=3953835a-567c-4415-9ed4-8d865bbca9dc',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLow%20Heel%20Tap.mp4?alt=media&token=4fa0be9d-ef08-4c08-9eb9-9709c42406f4',
    video_id: 'C_Low_Heel_Tap_1',
    video_length: 0.07,
    video_number: '517',
    size: 2284077,
    hints: 'C_Low_Heel_Tap_1_hints',
    common_mistakes: 'C_Low_Heel_Tap_1_common_mistakes',
    breathing: 'C_Low_Heel_Tap_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Straight Leg Curl',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStraight%20Leg%20Curl%20(L).jpg?alt=media&token=11a8efd9-2c03-4021-9cf9-8ea1ea8e3445',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStraight%20Leg%20Curl%20(L).mp4?alt=media&token=912471c7-7b87-4113-9111-c0f0621e0bcb',
    video_length: 0.05,
    video_number: '790',
    size: 1942840,
    hints: 'b_Straight_Leg_Curl_hints',
    common_mistakes: 'b_Straight_Leg_Curl_common_mistakes',
    breathing: 'b_Straight_Leg_Curl_breathing',
    is_other_side: 0,
    video_id: 'b_Straight_Leg_Curl',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Crunch',
    is_free: true,
    primary_muscle: 'Upper Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs, Obliques',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCrunch.jpg?alt=media&token=5eb21b33-1972-4384-a71a-2d5d1a2c552e',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCrunch.mp4?alt=media&token=f43bbadb-caa5-4ece-a50e-cd569b4807cd',
    video_id: 'Abs_Crunch_4',
    video_length: 0.08,
    video_number: '17',
    size: 635510,
    hints: 'Abs_Crunch_4_hints',
    common_mistakes: 'Abs_Crunch_4_common_mistakes',
    breathing: 'Abs_Crunch_4_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Sidewalk High Plank',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSidewalk%20High%20Plank.jpg?alt=media&token=99217a8e-684f-490d-90f6-878416a1bc53',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSidewalk%20High%20Plank.mp4?alt=media&token=dfaab0aa-1c83-434c-bb65-60f0f347cd3e',
    video_id: 'pl_Sidewalk_High_Plank_1',
    video_length: 0.07,
    video_number: '610',
    size: 5323387,
    hints: 'pl_Sidewalk_High_Plank_1_hints',
    common_mistakes: 'pl_Sidewalk_High_Plank_1_common_mistakes',
    breathing: 'pl_Sidewalk_High_Plank_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'In Toe Tap',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FIn%20Toe%20Tap.jpg?alt=media&token=dfd86158-007f-423e-a1a7-e397c1b94106',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FIn%20Toe%20Tap.mp4?alt=media&token=a6cc30d9-96b5-40e8-a4f4-39d506a94a92',
    video_id: 'C_In_Toe_Tap_1',
    video_length: 0.07,
    video_number: '535',
    size: 2881850,
    hints: 'C_In_Toe_Tap_1_hints',
    common_mistakes: 'C_In_Toe_Tap_1_common_mistakes',
    breathing: 'C_In_Toe_Tap_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Wall Back Stretch',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Back%20Stretch%20(L).jpg?alt=media&token=d150fd37-7296-42ef-bcfe-e63193fb6356',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Back%20Stretch%20(L).mp4?alt=media&token=f36ac1d5-70d0-469e-aae8-f887036d4544',
    video_length: 0.05,
    video_number: '821',
    size: 1942840,
    hints: 'wp_Wall_Back_Stretch_1_hints',
    common_mistakes: 'wp_Wall_Back_Stretch_1_common_mistakes',
    breathing: 'wp_Wall_Back_Stretch_1_breathing',
    is_other_side: 0,
    video_id: 'wp_Wall_Back_Stretch_1',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Shoulder Extension Reaches',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Serratus Anterior, Shoulders',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FShoulder%20Extension%20Reaches.jpg?alt=media&token=812a815d-95e5-4f53-aa30-6108b684afe5',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FShoulder%20Extension%20Reaches.mp4?alt=media&token=847f9ce4-2f68-4013-ad0c-4b52cf9de651',
    video_id: 'lst_Shoulder_Extension_Reaches_1',
    video_length: 0.07,
    video_number: '715',
    size: 2853655,
    hints: 'lst_Shoulder_Extension_Reaches_1_hints',
    common_mistakes: 'lst_Shoulder_Extension_Reaches_1_common_mistakes',
    breathing: 'lst_Shoulder_Extension_Reaches_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Ab Hold',
    is_free: true,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAb%20Hold.jpg?alt=media&token=4b57b555-d7b7-4ee4-92d2-e639d6a14343',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAb%20Hold.mp4?alt=media&token=9a6f6e3c-6b43-460e-a288-ad3e3cb04e64',
    video_id: 'Abs_Ab_Hold_1',
    video_length: 0.07,
    video_number: '197',
    size: 664192,
    hints: 'Abs_Ab_Hold_1_hints',
    common_mistakes: 'Abs_Ab_Hold_1_common_mistakes',
    breathing: 'Abs_Ab_Hold_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Neck Flex Forward',
    is_free: false,
    primary_muscle: 'Back Neck',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FNeck%20Flex%20Forward.jpg?alt=media&token=3be30f34-b242-462e-80cb-4d8ae45ac4fb',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FNeck%20Flex%20Forward.mp4?alt=media&token=085a2bfa-d0d3-4f65-ad65-d651c1eb549f',
    video_id: 'lst_Neck_Flex_Forward_1',
    video_length: 0.07,
    video_number: '689',
    size: 1860927,
    hints: 'lst_Neck_Flex_Forward_1_hints',
    common_mistakes: 'lst_Neck_Flex_Forward_1_common_mistakes',
    breathing: 'lst_Neck_Flex_Forward_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'total body',
    name: 'Frontal Rotation',
    is_free: false,
    primary_muscle: 'Forearm',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: '',
    subcategory_name: 'upper body',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFrontal%20Rotation.jpg?alt=media&token=b9a16654-239e-4989-8f94-aaa53f2b7c55',
    time: '45 seconds',
    time_to_play: 0.75,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFrontal%20Rotation.mp4?alt=media&token=4d5cf525-5f07-4c48-a1ed-eabcf1e4d654',
    video_id: 'TnS_Frontal_Rotation_1',
    video_length: 0.07,
    video_number: '500',
    size: 838941,
    hints: 'TnS_Frontal_Rotation_1_hints',
    common_mistakes: 'TnS_Frontal_Rotation_1_common_mistakes',
    breathing: 'TnS_Frontal_Rotation_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Skier Swing',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSkier%20Swing.jpg?alt=media&token=47af5eae-6919-4076-9991-b256ac4e8a40',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSkier%20Swing.mp4?alt=media&token=37bc0021-749c-4fcf-be7d-3457b0179cb4',
    video_id: 'h_Skier_Swing_1',
    video_length: 0.07,
    video_number: '580',
    size: 1498014,
    hints: 'h_Skier_Swing_1_hints',
    common_mistakes: 'h_Skier_Swing_1_common_mistakes',
    breathing: 'h_Skier_Swing_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Back Raise Into Lateral Raise',
    is_free: false,
    primary_muscle: 'Front Shoulders, Middle Shoulders, Traps, Rhomboids',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Rear Shoulders, Upper Chest, Serratus Anterior, Teres Minor, Infraspinatus, Biceps',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Core, Teres Major',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBack%20Raise%20Into%20Lateral%20Raise.jpg?alt=media&token=df1cc08b-ccab-45db-a55b-2a926e9312e4',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBack%20Raise%20Into%20Lateral%20Raise.mp4?alt=media&token=402aba8a-a3a4-4672-a7ab-78d84a88117c',
    video_id: 'TnS_Back_Dumbbell_Raise_Into_Lateral_Raise_1',
    video_length: 0.07,
    video_number: '402',
    size: 2378036,
    hints: 'TnS_Back_Dumbbell_Raise_Into_Lateral_Raise_1_hints',
    common_mistakes:
      'TnS_Back_Dumbbell_Raise_Into_Lateral_Raise_1_common_mistakes',
    breathing: 'TnS_Back_Dumbbell_Raise_Into_Lateral_Raise_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Archer',
    is_free: true,
    primary_muscle: 'Front Shoulders, Middle Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Upper Chest, Traps, Serratus Anterior, Rear Shoulders',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Core, Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FArcher.jpg?alt=media&token=ca8b3c07-5d1d-4700-b6dd-4e2709642c4c',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FArcher.mp4?alt=media&token=28ff1713-f700-4354-b2d9-bc2358c71a77',
    video_id: 'TnS_Dumbbell_Archer_1',
    video_length: 0.07,
    video_number: '413',
    size: 2266708,
    hints: 'TnS_Dumbbell_Archer_1_hints',
    common_mistakes: 'TnS_Dumbbell_Archer_1_common_mistakes',
    breathing: 'TnS_Dumbbell_Archer_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Wrist Circles',
    is_other_side: 0,
    is_free: false,
    primary_muscle: 'Wrists',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWrist%20Circles%20(L).jpg?alt=media&token=03c830dc-8c95-42ea-902a-13bafc7fb6f1',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWrist%20Circles%20(L).mp4?alt=media&token=f6cca8dc-505e-4922-ada5-f502c882e4ad',
    video_id: 'lst_Wrist_Circles_1',
    video_length: 0.07,
    video_number: '691',
    size: 4337017,
    hints: 'lst_Wrist_Circles_1_hints',
    common_mistakes: 'lst_Wrist_Circles_1_common_mistakes',
    breathing: 'lst_Wrist_Circles_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Single Into Double Leg Lower',
    is_free: false,
    primary_muscle: 'Lower Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Upper Abs, Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Into%20Double%20Leg%20Lower.jpg?alt=media&token=9f862f40-ff71-485b-8e81-2e559e0026e3',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Into%20Double%20Leg%20Lower.mp4?alt=media&token=460ddf18-3e6b-4078-884c-645740ca7365',
    video_id: 'Abs_Single_Into_Double_Leg_Lower_1',
    video_length: 0.07,
    video_number: '204',
    size: 3553068,
    hints: 'Abs_Single_Into_Double_Leg_Lower_1_hints',
    common_mistakes: 'Abs_Single_Into_Double_Leg_Lower_1_common_mistakes',
    breathing: 'Abs_Single_Into_Double_Leg_Lower_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Alternating Toe Touch & Hop',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Toe%20Touch%20%26%20Hop.jpg?alt=media&token=9fefe468-78b3-4286-807e-5355b734df20',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Toe%20Touch%20%26%20Hop.mp4?alt=media&token=654fca51-e927-4fbc-b687-941821b00dfa',
    video_id: 'h_Alternating_Toe_Touch_Hop_1',
    video_length: 0.07,
    video_number: '539',
    size: 1450792,
    hints: 'h_Alternating_Toe_Touch_Hop_1_hints',
    common_mistakes: 'h_Alternating_Toe_Touch_Hop_1_common_mistakes',
    breathing: 'h_Alternating_Toe_Touch_Hop_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Beginner Burpees',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Chest, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBeginner%20Burpees.jpg?alt=media&token=67bc1516-0fff-4341-91db-4ba45cd190f6',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBeginner%20Burpees.mp4?alt=media&token=bd68cf25-fad3-472a-98d4-ccf340ecc89d',
    video_id: 'C_Beginner_Burpees_1',
    video_length: 0.07,
    video_number: '512',
    size: 2280691,
    hints: 'C_Beginner_Burpees_1_hints',
    common_mistakes: 'C_Beginner_Burpees_1_common_mistakes',
    breathing: 'C_Beginner_Burpees_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Hop Scotch',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHop%20Scotch.jpg?alt=media&token=633ac55b-d097-44c4-be9a-fe27df19088e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHop%20Scotch.mp4?alt=media&token=a08bd1c1-f52d-4a70-9b87-872016508639',
    video_id: 'C_Hop_Scotch_1',
    video_length: 0.07,
    video_number: '514',
    size: 3978464,
    hints: 'C_Hop_Scotch_1_hints',
    common_mistakes: 'C_Hop_Scotch_1_common_mistakes',
    breathing: 'C_Hop_Scotch_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Seal Jacks',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSeal%20Jacks.jpg?alt=media&token=1ee1d9c3-bb59-4fdb-b108-1eef8d6259ce',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSeal%20Jacks.mp4?alt=media&token=41fc45b3-2f49-4d85-aea2-d041e0c505bf',
    video_id: 'h_Seal_Jacks_1',
    video_length: 0.07,
    video_number: '560',
    size: 1545289,
    hints: 'h_Seal_Jacks_1_hints',
    common_mistakes: 'h_Seal_Jacks_1_common_mistakes',
    breathing: 'h_Seal_Jacks_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Roll Plank',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle: 'Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRoll%20Plank.jpg?alt=media&token=47127f73-cfe1-4cbb-b757-774f2c056bc5',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRoll%20Plank.mp4?alt=media&token=b9e5bb41-d130-463d-bb35-99838f91d7c5',
    video_id: 'pl_Roll_Plank_1',
    video_length: 0.07,
    video_number: '584',
    size: 2337366,
    hints: 'pl_Roll_Plank_1_hints',
    common_mistakes: 'pl_Roll_Plank_1_common_mistakes',
    breathing: 'pl_Roll_Plank_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Close Squat Into Wide Squat',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FClose%20Squat%20Into%20Wide%20Squat.jpg?alt=media&token=f1411ca0-9b13-4fca-b42d-6cd1e3941347',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FClose%20Squat%20Into%20Wide%20Squat.mp4?alt=media&token=801b5ae6-82c8-4703-bbbf-bb828983d489',
    video_id: 'LnB_Close_Squat_Into_Wide_Squat_1',
    video_length: 0.05,
    video_number: '268',
    size: 2981206,
    hints: 'LnB_Close_Squat_Into_Wide_Squat_1_hints',
    common_mistakes: 'LnB_Close_Squat_Into_Wide_Squat_1_common_mistakes',
    breathing: 'LnB_Close_Squat_Into_Wide_Squat_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Side Hops',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Hops.jpg?alt=media&token=fce6723b-fe1c-4f99-b28b-02c08948a55f',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Hops.mp4?alt=media&token=d5c7679a-d823-4634-9297-14e246491b29',
    video_id: 'h_Side_Hops_1',
    video_length: 0.07,
    video_number: '561',
    size: 1891422,
    hints: 'h_Side_Hops_1_hints',
    common_mistakes: 'h_Side_Hops_1_common_mistakes',
    breathing: 'h_Side_Hops_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Saddle',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Quads, Serratus Anterior, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSaddle.jpg?alt=media&token=f1094680-041e-459e-8c9b-a30423fefc0a',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSaddle.mp4?alt=media&token=7fa5a651-631c-47ec-acdd-0c8360de122a',
    video_id: 'lst_Saddle_1',
    video_length: 0.07,
    video_number: '668',
    size: 574606,
    hints: 'lst_Saddle_1_hints',
    common_mistakes: 'lst_Saddle_1_common_mistakes',
    breathing: 'lst_Saddle_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: '90/90 Hip Rotation',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Adductors, Glutes, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2F90_90%20Hip%20Rotation.jpg?alt=media&token=e0ce2b2d-cfe7-4279-8dce-d58bd9378c5e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2F90_90%20Hip%20Rotation.mp4?alt=media&token=f6bd6541-5cb1-4402-9a14-8a195896861a',
    video_id: 'lst_90_90_Hip_Rotation_1',
    video_length: 0.07,
    video_number: '687',
    size: 2922365,
    hints: 'lst_90_90_Hip_Rotation_1_hints',
    common_mistakes: 'lst_90_90_Hip_Rotation_1_common_mistakes',
    breathing: 'lst_90_90_Hip_Rotation_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Standing Mountain Climbers',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStanding%20Mountain%20Climbers.jpg?alt=media&token=37247ce3-003c-44a1-81ce-c475d299a188',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStanding%20Mountain%20Climbers.mp4?alt=media&token=cbb9a56d-ccd8-4424-89af-f2cc4eb0c848',
    video_id: 'h_Standing_Mountain_Climbers_1',
    video_length: 0.07,
    video_number: '569',
    size: 2016189,
    hints: 'h_Standing_Mountain_Climbers_1_hints',
    common_mistakes: 'h_Standing_Mountain_Climbers_1_common_mistakes',
    breathing: 'h_Standing_Mountain_Climbers_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Back Slaps',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle:
      'Chest, Shoulders, Rhomboids, Traps, Erector Spinae, Teres Major, Teres Minor, Infraspinatus, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBack%20Slaps.jpg?alt=media&token=58a3788f-513b-4567-85ac-5cb02254d762',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBack%20Slaps.mp4?alt=media&token=6865ea1d-53d2-47c7-9e9a-c408c3bde069',
    video_id: 'lst_Back_Slaps_1',
    video_length: 0.07,
    video_number: '693',
    size: 3819241,
    hints: 'lst_Back_Slaps_1_hints',
    common_mistakes: 'lst_Back_Slaps_1_common_mistakes',
    breathing: 'lst_Back_Slaps_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Side Sky Touches',
    is_free: true,
    primary_muscle: 'Upper Abs, Obliques',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Sky%20Touches.jpg?alt=media&token=85656b48-d9ab-430f-ae10-25209c3990f4',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Sky%20Touches.mp4?alt=media&token=64432290-eb05-417e-8230-6fcc806b3760',
    video_id: 'Abs_Side_Sky_Touches_14',
    video_length: 0.05,
    video_number: '40',
    size: 397171,
    hints: 'Abs_Side_Sky_Touches_14_hints',
    common_mistakes: 'Abs_Side_Sky_Touches_14_common_mistakes',
    breathing: 'Abs_Side_Sky_Touches_14_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Small Scissor Switch',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSmall%20Scissor%20Switch.jpg?alt=media&token=3e6cb31c-a1cd-4258-8fd6-33051443b65d',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSmall%20Scissor%20Switch.mp4?alt=media&token=9462f73c-84a4-4a22-86f5-f4ff0359a6b4',
    video_id: 'h_Small_Scissor_Switch_1',
    video_length: 0.07,
    video_number: '565',
    size: 2045517,
    hints: 'h_Small_Scissor_Switch_1_hints',
    common_mistakes: 'h_Small_Scissor_Switch_1_common_mistakes',
    breathing: 'h_Small_Scissor_Switch_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Leg Lift Windshield Wipers',
    is_free: false,
    primary_muscle: 'Obliques, Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLeg%20Lift%20Windshield%20Wipers.jpg?alt=media&token=5b523627-190c-49c0-9d96-5ffc8f280570',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLeg%20Lift%20Windshield%20Wipers.mp4?alt=media&token=351ac36e-0718-4ded-8fc4-55155b827cbd',
    video_id: 'Abs_Leg_Lift_Windshield_Wipers_1',
    video_length: 0.07,
    video_number: '191',
    size: 3856251,
    hints: 'Abs_Leg_Lift_Windshield_Wipers_1_hints',
    common_mistakes: 'Abs_Leg_Lift_Windshield_Wipers_1_common_mistakes',
    breathing: 'Abs_Leg_Lift_Windshield_Wipers_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Squat Side Kick',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Erector Spinae, Calves',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20Side%20Kick.jpg?alt=media&token=a603571e-b670-402f-807a-dfa1cfdc4a09',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20Side%20Kick.mp4?alt=media&token=43632520-67a6-4e80-a7aa-38ff1319ff40',
    video_id: 'LnB_Squat_Side_Kick_1',
    video_length: 0.05,
    video_number: '307',
    size: 2435502,
    hints: 'LnB_Squat_Side_Kick_1_hints',
    common_mistakes: 'LnB_Squat_Side_Kick_1_common_mistakes',
    breathing: 'LnB_Squat_Side_Kick_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Shoulder Press',
    is_other_side: 1,
    is_free: true,
    primary_muscle: 'Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Serratus Anterior',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Traps, Chest, Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FShoulder%20Press%20(R).jpg?alt=media&token=ab8b0d26-8894-404f-bb9c-ee339df4ad41',
    time: '60 seconds',
    time_to_play: 1,
    tools: '',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FShoulder%20Press%20(R).mp4?alt=media&token=032c8392-80e2-4ec2-9b26-29341005957f',
    video_id: 'TnS_Shoulder_Press_42',
    video_length: 0.1,
    video_number: '103',
    size: 1343153,
    hints: 'TnS_Shoulder_Press_42_hints',
    common_mistakes: 'TnS_Shoulder_Press_42_common_mistakes',
    breathing: 'TnS_Shoulder_Press_42_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Squat Shuffle',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core, Shoulders',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20Shuffle.jpg?alt=media&token=0601f1bd-2673-42c3-9a69-6d2aaa735d7e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20Shuffle.mp4?alt=media&token=940e4adc-314d-4825-9b55-f655bd29196a',
    video_id: 'C_Squat_Shuffle_1',
    video_length: 0.07,
    video_number: '527',
    size: 2017306,
    hints: 'C_Squat_Shuffle_1_hints',
    common_mistakes: 'C_Squat_Shuffle_1_common_mistakes',
    breathing: 'C_Squat_Shuffle_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Clap Crunch',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FClap%20Crunch.jpg?alt=media&token=8f1cfd17-ccca-43ec-bec8-1b4d90cfdd34',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FClap%20Crunch.mp4?alt=media&token=cf6d7ef7-7d91-44aa-9108-932b7c43a013',
    video_id: 'Abs_Clap_Crunch_1',
    video_length: 0.07,
    video_number: '213',
    size: 1728850,
    hints: 'Abs_Clap_Crunch_1_hints',
    common_mistakes: 'Abs_Clap_Crunch_1_common_mistakes',
    breathing: 'Abs_Clap_Crunch_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Knee Down Lunge Runner',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FKnee%20Down%20Lunge%20Runner.jpg?alt=media&token=2ca321c1-97d3-40f8-b922-257959c7bf64',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FKnee%20Down%20Lunge%20Runner.mp4?alt=media&token=21b96757-5da8-495a-994b-22cda720e8a4',
    video_id: 'C_Knee_Down_Lunge_Runner_1',
    video_length: 0.07,
    video_number: '515',
    size: 1425702,
    hints: 'C_Knee_Down_Lunge_Runner_1_hints',
    common_mistakes: 'C_Knee_Down_Lunge_Runner_1_common_mistakes',
    breathing: 'C_Knee_Down_Lunge_Runner_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Pogo Jump',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPogo%20Jump.jpg?alt=media&token=5cbc24a3-d676-438b-aa17-35de96b06b0e',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPogo%20Jump.mp4?alt=media&token=6107471e-b922-4d8b-9dbb-9cb00348056a',
    video_id: 'h_Pogo_Jump_1',
    video_length: 0.07,
    video_number: '576',
    size: 1612842,
    hints: 'h_Pogo_Jump_1_hints',
    common_mistakes: 'h_Pogo_Jump_1_common_mistakes',
    breathing: 'h_Pogo_Jump_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Cross Country Run',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCross%20Country%20Run.jpg?alt=media&token=a5eeeac5-9dad-45eb-bf0c-bd3f9542f58f',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCross%20Country%20Run.mp4?alt=media&token=a7aedba4-db28-46b1-b206-30dffa298f0b',
    video_id: 'h_Cross_Country_Run_1',
    video_length: 0.07,
    video_number: '544',
    size: 2717287,
    hints: 'h_Cross_Country_Run_1_hints',
    common_mistakes: 'h_Cross_Country_Run_1_common_mistakes',
    breathing: 'h_Cross_Country_Run_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Side Plank Lifts',
    is_other_side: 0,
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle: 'Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Plank%20Lifts%20(L).jpg?alt=media&token=b830cd6d-069d-4fac-991b-930c6a785539',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Plank%20Lifts%20(L).mp4?alt=media&token=2359c54d-9089-4f2a-8138-a4168f60b50f',
    video_id: 'pl_Side_Plank_Lifts_1',
    video_length: 0.07,
    video_number: '582',
    size: 704806,
    hints: 'pl_Side_Plank_Lifts_1_hints',
    common_mistakes: 'pl_Side_Plank_Lifts_1_common_mistakes',
    breathing: 'pl_Side_Plank_Lifts_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Calf Stretch',
    is_free: true,
    muscle_category: ['legs'],
    primary_muscle: 'Calves',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCalf%20Stretch.jpg?alt=media&token=2bfab381-26f2-4dbb-a634-c904fae72c24',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCalf%20Stretch.mp4?alt=media&token=f86f921d-584c-4fab-b5e2-7a90aa3d76fe',
    video_id: 'lst_Calf_Stretch_1',
    video_length: 0.07,
    video_number: '644',
    size: 3942299,
    hints: 'lst_Calf_Stretch_1_hints',
    common_mistakes: 'lst_Calf_Stretch_1_common_mistakes',
    breathing: 'lst_Calf_Stretch_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'One Leg Side Hops',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core, Shoulders',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOne%20Leg%20Side%20Hops.jpg?alt=media&token=6a03f288-aff5-4006-aa37-796f96c26124',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOne%20Leg%20Side%20Hops.mp4?alt=media&token=c2378fac-1d28-4b44-a1de-8ba40328f5a2',
    video_id: 'C_One_Leg_Side_Hops_1',
    video_length: 0.07,
    video_number: '510',
    size: 2787320,
    hints: 'C_One_Leg_Side_Hops_1_hints',
    common_mistakes: 'C_One_Leg_Side_Hops_1_common_mistakes',
    breathing: 'C_One_Leg_Side_Hops_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Outward Heel Taps',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOutward%20Heel%20Taps.jpg?alt=media&token=f27c7968-4705-4e43-a35b-480105c589c1',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOutward%20Heel%20Taps.mp4?alt=media&token=31658f34-a2d1-41af-b74c-cadc1a238800',
    video_id: 'h_Outward_Heel_Taps_1',
    video_length: 0.07,
    video_number: '557',
    size: 1413102,
    hints: 'h_Outward_Heel_Taps_1_hints',
    common_mistakes: 'h_Outward_Heel_Taps_1_common_mistakes',
    breathing: 'h_Outward_Heel_Taps_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Hollow Body Alternating Knee Raise',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: 'Quads',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHollow%20Body%20Alternating%20Knee%20Raise.jpg?alt=media&token=c9ecb984-4b7c-4030-8796-fcba7c7d47c3',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHollow%20Body%20Alternating%20Knee%20Raise.mp4?alt=media&token=49e517b1-757e-404e-b90b-1c796d865cec',
    video_id: 'Abs_Hollow_Body_Alternate_Knee_Raise_19',
    video_length: 0.1,
    video_number: '22',
    size: 748593,
    hints: 'Abs_Hollow_Body_Alternate_Knee_Raise_19_hints',
    common_mistakes: 'Abs_Hollow_Body_Alternate_Knee_Raise_19_common_mistakes',
    breathing: 'Abs_Hollow_Body_Alternate_Knee_Raise_19_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Reverse Plank',
    is_free: true,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Glutes, Hamstrings, Middle Shoulders, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FReverse%20Plank.jpg?alt=media&token=974afaa0-0223-455b-86f0-f564e0e3bbcf',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FReverse%20Plank.mp4?alt=media&token=50eb80b9-ac5f-4911-b1f7-e7447dc339eb',
    video_id: 'pl_Reverse_Plank_1',
    video_length: 0.07,
    video_number: '588',
    size: 512151,
    hints: 'pl_Reverse_Plank_1_hints',
    common_mistakes: 'pl_Reverse_Plank_1_common_mistakes',
    breathing: 'pl_Reverse_Plank_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Jumping Jack & Knee Jump',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FJumping%20Jack%20%26%20Knee%20Jump.jpg?alt=media&token=9e822c97-4081-4973-b87f-1b2aeeab96ac',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FJumping%20Jack%20%26%20Knee%20Jump.mp4?alt=media&token=14085006-9f85-46b3-b6d8-98f50e343ecf',
    video_id: 'h_Jumping_Jack_Knee_Jump_1',
    video_length: 0.07,
    video_number: '551',
    size: 1551835,
    hints: 'h_Jumping_Jack_Knee_Jump_1_hints',
    common_mistakes: 'h_Jumping_Jack_Knee_Jump_1_common_mistakes',
    breathing: 'h_Jumping_Jack_Knee_Jump_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Speed Bag',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSpeed%20Bag.jpg?alt=media&token=b186c0f6-8609-414a-ba60-1114e7e5ce1f',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSpeed%20Bag.mp4?alt=media&token=4a34a8bf-94d7-4bb7-9782-48052bf87492',
    video_id: 'C_Crossing_Punch_1',
    video_length: 0.07,
    video_number: '513',
    size: 2261815,
    hints: 'C_Crossing_Punch_1_hints',
    common_mistakes: 'C_Crossing_Punch_1_common_mistakes',
    breathing: 'C_Crossing_Punch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Wall Squats',
    is_free: false,
    primary_muscle: 'Quads, Glutes',
    rep_range: 'Time Based',
    secondary_muscle: 'Hamstrings, Adductors',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Squats.jpg?alt=media&token=f33fefab-53e5-470d-b6e2-269590c29c49',
    time: '60 seconds',
    time_to_play: 0.75,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Squats.mp4?alt=media&token=fd6eff84-db4a-485e-8faa-b8d910f4cdc7',
    video_id: 'L_Wall_Squats_55',
    video_length: 0.08,
    video_number: '129',
    size: 519888,
    hints: 'L_Wall_Squats_55_hints',
    common_mistakes: 'L_Wall_Squats_55_common_mistakes',
    breathing: 'L_Wall_Squats_55_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'March & Clap',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FMarch%20%26%20Clap.jpg?alt=media&token=187940a5-c01d-4323-b2de-9d329d13c125',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FMarch%20%26%20Clap.mp4?alt=media&token=f6513fbf-f469-435a-95ee-c24de483e532',
    video_id: 'C_March_Clap_1',
    video_length: 0.07,
    video_number: '519',
    size: 2618515,
    hints: 'C_March_Clap_1_hints',
    common_mistakes: 'C_March_Clap_1_common_mistakes',
    breathing: 'C_March_Clap_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Triangle Forward Bend',
    is_other_side: 0,
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstrings',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTriangle%20Forward%20Bend%20(L).jpg?alt=media&token=dcc2f0e7-5285-45bf-89c8-c08488b831e7',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTriangle%20Forward%20Bend%20(L).mp4?alt=media&token=db20ff7a-be0d-41d9-b05d-4cef56068c6e',
    video_id: 'lst_Triangle_Forward_Bend_1',
    video_length: 0.07,
    video_number: '686',
    size: 7224364,
    hints: 'lst_Triangle_Forward_Bend_1_hints',
    common_mistakes: 'lst_Triangle_Forward_Bend_1_common_mistakes',
    breathing: 'lst_Triangle_Forward_Bend_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Cross Body Glute Pull',
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Glutes, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCross%20Body%20Glute%20Pull.jpg?alt=media&token=e83cd4ae-a473-4eb4-8e83-ce22c98ad32e',
    time: '90 seconds',
    time_to_play: 1.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCross%20Body%20Glute%20Pull.mp4?alt=media&token=ca692145-a30e-4981-ba85-e6715dec0db0',
    video_id: 'lst_Cross_Body_Glute_Pull_1',
    video_length: 0.07,
    video_number: '650',
    size: 7449642,
    hints: 'lst_Cross_Body_Glute_Pull_1_hints',
    common_mistakes: 'lst_Cross_Body_Glute_Pull_1_common_mistakes',
    breathing: 'lst_Cross_Body_Glute_Pull_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Double Fast Feet',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FDouble%20Fast%20Feet.jpg?alt=media&token=8bb5f3f1-ccdf-444a-9844-96f547aa92c5',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FDouble%20Fast%20Feet.mp4?alt=media&token=35e4e7d4-4eed-4c8a-8aeb-9cd36fef3760',
    video_id: 'h_Double_Fast_Feet_1',
    video_length: 0.07,
    video_number: '574',
    size: 1097697,
    hints: 'h_Double_Fast_Feet_1_hints',
    common_mistakes: 'h_Double_Fast_Feet_1_common_mistakes',
    breathing: 'h_Double_Fast_Feet_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Standing Feet Together Reach',
    is_free: true,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstrings',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStanding%20Feet%20Together%20Reach.jpg?alt=media&token=c83b700c-e886-4acc-9029-79824f4c2cba',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStanding%20Feet%20Together%20Reach.mp4?alt=media&token=1abf43b0-d0b9-4572-8ecf-ddee3f993ff6',
    video_id: 'lst_Standing_Feet_Together_Reach_1',
    video_length: 0.07,
    video_number: '680',
    size: 3211716,
    hints: 'lst_Standing_Feet_Together_Reach_1_hints',
    common_mistakes: 'lst_Standing_Feet_Together_Reach_1_common_mistakes',
    breathing: 'lst_Standing_Feet_Together_Reach_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Neck Rotation Stretch',
    is_free: false,
    primary_muscle: 'Side Neck',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FNeck%20Rotation%20Stretch.jpg?alt=media&token=2a8a1376-fd7b-4071-b199-341841794a8c',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FNeck%20Rotation%20Stretch.mp4?alt=media&token=28549eed-b4ef-4a9d-8899-b830d379d1ac',
    video_id: 'lst_Neck_Rotation_Stretch_1',
    video_length: 0.07,
    video_number: '690',
    size: 2108220,
    hints: 'lst_Neck_Rotation_Stretch_1_hints',
    common_mistakes: 'lst_Neck_Rotation_Stretch_1_common_mistakes',
    breathing: 'lst_Neck_Rotation_Stretch_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Tuck Planks Same Side',
    is_free: true,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTuck%20Planks%20Same%20Side.jpg?alt=media&token=1f2cec8a-e5d6-4888-81a9-02cf2f1c5cd7',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTuck%20Planks%20Same%20Side.mp4?alt=media&token=d46414c5-2f90-45b8-8214-111e28f51ef1',
    video_id: 'pl_Tuck_Planks_Same_Side_1',
    video_length: 0.07,
    video_number: '591',
    size: 2636867,
    hints: 'pl_Tuck_Planks_Same_Side_1_hints',
    common_mistakes: 'pl_Tuck_Planks_Same_Side_1_common_mistakes',
    breathing: 'pl_Tuck_Planks_Same_Side_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Star Jump',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStar%20Jump.jpg?alt=media&token=13583293-4a28-4c41-9a8d-6e4d68c9a18c',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStar%20Jump.mp4?alt=media&token=994876cf-91ba-4d32-8cc8-ba9e91de1cef',
    video_id: 'h_Star_Jump_1',
    video_length: 0.07,
    video_number: '570',
    size: 2075767,
    hints: 'h_Star_Jump_1_hints',
    common_mistakes: 'h_Star_Jump_1_common_mistakes',
    breathing: 'h_Star_Jump_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Squat Pose',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hip Flexors, Adductors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20Pose.jpg?alt=media&token=d7e6d096-1cb3-458b-9a4d-e1c43573ad7d',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20Pose.mp4?alt=media&token=a71e86a8-f515-46f4-93e1-cd5e092ecac2',
    video_id: 'lst_Squat_Pose_1',
    video_length: 0.07,
    video_number: '679',
    size: 953103,
    hints: 'lst_Squat_Pose_1_hints',
    common_mistakes: 'lst_Squat_Pose_1_common_mistakes',
    breathing: 'lst_Squat_Pose_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Glute Bridge',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Adductors, Glutes, Hamstrings',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FGlute%20Bridge.jpg?alt=media&token=1a9b71ba-c710-4158-81c8-943035fc7ce1',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FGlute%20Bridge.mp4?alt=media&token=ba9edb18-3603-4279-80d4-3815e345c532',
    video_id: 'lst_Glute_Bridge_1',
    video_length: 0.07,
    video_number: '655',
    size: 1709461,
    hints: 'lst_Glute_Bridge_1_hints',
    common_mistakes: 'lst_Glute_Bridge_1_common_mistakes',
    breathing: 'lst_Glute_Bridge_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Switch Kick',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSwitch%20Kick.jpg?alt=media&token=da91fb29-9235-4a3b-8480-7353d96e2c82',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSwitch%20Kick.mp4?alt=media&token=7380ef15-876c-48e3-9678-a8549b1b5b12',
    video_id: 'h_Switch_Kick',
    video_length: 0.07,
    video_number: '572',
    size: 1488489,
    hints: 'h_Switch_Kick_hints',
    common_mistakes: 'h_Switch_Kick_common_mistakes',
    breathing: 'h_Switch_Kick_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Leg Lift Toe Touch',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLeg%20Lift%20Toe%20Touch.jpg?alt=media&token=d8d9555b-eb9b-487d-b18b-8032fd121acb',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLeg%20Lift%20Toe%20Touch.mp4?alt=media&token=398231da-11b1-4ce6-a955-c67c76dc821d',
    video_id: 'Abs_Leg_Lift_Toe_Touch_16',
    video_length: 0.08,
    video_number: '10',
    size: 598141,
    hints: 'Abs_Leg_Lift_Toe_Touch_16_hints',
    common_mistakes: 'Abs_Leg_Lift_Toe_Touch_16_common_mistakes',
    breathing: 'Abs_Leg_Lift_Toe_Touch_16_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Crossing Punch',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCrossing%20Punch.jpg?alt=media&token=70bfb90e-530f-493f-9995-98610d3ed41b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCrossing%20Punch.mp4?alt=media&token=87ee5b76-1995-4a8c-9f32-7e4fc053c425',
    video_id: 'C_Crossing_Punch_1',
    video_length: 0.07,
    video_number: '513',
    size: 2261815,
    hints: 'C_Crossing_Punch_1_hints',
    common_mistakes: 'C_Crossing_Punch_1_common_mistakes',
    breathing: 'C_Crossing_Punch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Side Lunge Touchdown',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings Calves Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Lunge%20Touchdown.jpg?alt=media&token=141c1055-0465-40db-b7d1-02f501564c92',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Lunge%20Touchdown.mp4?alt=media&token=4619318f-c886-4693-87e8-97f234829bb7',
    video_id: 'LnB_Side_Lunge_Touchdown_1',
    video_length: 0.05,
    video_number: '309',
    size: 3148991,
    hints: 'LnB_Side_Lunge_Touchdown_1_hints',
    common_mistakes: 'LnB_Side_Lunge_Touchdown_1_common_mistakes',
    breathing: 'LnB_Side_Lunge_Touchdown_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Leg Lift Into Hip Raise',
    is_free: false,
    primary_muscle: 'Lower Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Upper Abs, Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLeg%20Lift%20Into%20Hip%20Raise.jpg?alt=media&token=ac817d75-0d96-454f-89a5-467af49bc527',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLeg%20Lift%20Into%20Hip%20Raise.mp4?alt=media&token=eee1bd76-5fb5-4443-9f94-831ab54620a5',
    video_id: 'Abs_Leg_Lift_Into_Hip_Raise_1',
    video_length: 0.07,
    video_number: '189',
    size: 2249915,
    hints: 'Abs_Leg_Lift_Into_Hip_Raise_1_hints',
    common_mistakes: 'Abs_Leg_Lift_Into_Hip_Raise_1_common_mistakes',
    breathing: 'Abs_Leg_Lift_Into_Hip_Raise_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: true,
    name: 'Reverse Frog Stretch',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FReverse%20Frog%20Stretch%20(L).jpg?alt=media&token=18093d93-d579-4abe-b243-f554f81e9942',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FReverse%20Frog%20Stretch%20(L).mp4?alt=media&token=10871955-85a2-4157-80ad-d86e965bb5eb',
    video_length: 0.05,
    video_number: '813',
    size: 1942840,
    hints: 'wp_Reverse_Frog_Stretch_1_hints',
    common_mistakes: 'wp_Reverse_Frog_Stretch_1_common_mistakes',
    breathing: 'wp_Reverse_Frog_Stretch_1_breathing',
    is_other_side: 0,
    video_id: 'wp_Reverse_Frog_Stretch_1',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'V-Dives',
    is_free: false,
    primary_muscle: 'Upper Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs, Obliques',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FV-Dives.jpg?alt=media&token=00574fa3-ccb7-41eb-9509-fe97096edb48',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FV-Dives.mp4?alt=media&token=360d9a27-f9c8-49e7-bfee-2e70d0ed289f',
    video_id: 'Abs_V_Dives_1',
    video_length: 0.07,
    video_number: '201',
    size: 1878518,
    hints: 'Abs_V_Dives_1_hints',
    common_mistakes: 'Abs_V_Dives_1_common_mistakes',
    breathing: 'Abs_V_Dives_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'triceps & shoulders',
    name: 'Scissors',
    is_free: true,
    primary_muscle: 'Shoulders',
    rep_range: '10-20',
    secondary_muscle:
      'Traps, Serratus Anterior, Rhomboids, Teres Major, Teres Minor, Infraspinatus',
    subcategory_name: 'upper body',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FScissors.jpg?alt=media&token=6a488be7-0342-4d78-ba2c-e21ff1a035f9',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FScissors.mp4?alt=media&token=2c1bd483-79ba-4f0b-96b7-c788e0923044',
    video_id: 'TnS_Scissors_1',
    video_length: 0.07,
    video_number: '384',
    size: 1391996,
    hints: 'TnS_Scissors_1_hints',
    common_mistakes: 'TnS_Scissors_1_common_mistakes',
    breathing: 'TnS_Scissors_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Side Plank Lifts',
    is_other_side: 1,
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle: 'Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Plank%20Lifts%20(R).jpg?alt=media&token=d1eacd44-9934-41e8-9fbb-394525e53a11',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Plank%20Lifts%20(R).mp4?alt=media&token=35be8a67-4b13-409a-af71-bf9d4b8069e3',
    video_id: 'pl_Side_Plank_Lifts_2',
    video_length: 0.07,
    video_number: '583',
    size: 781233,
    hints: 'pl_Side_Plank_Lifts_2_hints',
    common_mistakes: 'pl_Side_Plank_Lifts_2_common_mistakes',
    breathing: 'pl_Side_Plank_Lifts_2_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Air Calf Raise',
    is_free: true,
    primary_muscle: 'Calves',
    rep_range: '10-20',
    secondary_muscle: 'Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAir%20Calf%20Raise.jpg?alt=media&token=9176cd9b-f26e-44b5-910f-a0f7a512a9ed',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAir%20Calf%20Raise.mp4?alt=media&token=1f8e5971-2841-44db-8dbf-4ae88c9ffe6f',
    video_id: 'LnB_Air_Calf_Raise_1',
    video_length: 0.05,
    video_number: '300',
    size: 1685589,
    hints: 'LnB_Air_Calf_Raise_1_hints',
    common_mistakes: 'LnB_Air_Calf_Raise_1_common_mistakes',
    breathing: 'LnB_Air_Calf_Raise_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Toe Slaps',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FToe%20Slaps.jpg?alt=media&token=9b764531-ac88-4b5b-bb2d-f2b67c758648',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FToe%20Slaps.mp4?alt=media&token=c67f7d90-ad5e-46ab-bee3-6107bec9f2d9',
    video_id: 'C_Toe_Slaps_1',
    video_length: 0.07,
    video_number: '532',
    size: 2036478,
    hints: 'C_Toe_Slaps_1_hints',
    common_mistakes: 'C_Toe_Slaps_1_common_mistakes',
    breathing: 'C_Toe_Slaps_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Roman Twist',
    is_free: false,
    primary_muscle: 'Upper Abs, Obliques',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRoman%20Twist.jpg?alt=media&token=2c94df88-db46-4ca0-8e4b-449d6821f990',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRoman%20Twist.mp4?alt=media&token=4f8b419d-2cef-45de-8ccc-cb4a67f7d2c5',
    video_id: 'Abs_Roman_Twist_10',
    video_length: 0.08,
    video_number: '11',
    size: 554340,
    hints: 'Abs_Roman_Twist_10_hints',
    common_mistakes: 'Abs_Roman_Twist_10_common_mistakes',
    breathing: 'Abs_Roman_Twist_10_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Slam',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSlam.jpg?alt=media&token=59b4120f-da74-4689-9bbf-4d01a57d052d',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSlam.mp4?alt=media&token=cd72bdb6-b819-486a-a13f-ba37efd4a8fb',
    video_id: 'h_Slam_1',
    video_length: 0.07,
    video_number: '581',
    size: 2069707,
    hints: 'h_Slam_1_hints',
    common_mistakes: 'h_Slam_1_common_mistakes',
    breathing: 'h_Slam_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Half Squat Hold & Arms',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings Calves Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHalf%20Squat%20Hold%20%26%20Arms.jpg?alt=media&token=776fdbd8-7edf-4ae6-a947-73c32d6a3bdd',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHalf%20Squat%20Hold%20%26%20Arms.mp4?alt=media&token=a62bed62-c955-408d-a890-f3bbba8d89c8',
    video_id: 'l_Half_Squat_Hold_Arms',
    video_length: 0.05,
    video_number: '309',
    size: 3148991,
    hints: 'l_Half_Squat_Hold_Arms_hints',
    common_mistakes: 'l_Half_Squat_Hold_Arms_common_mistakes',
    breathing: 'l_Half_Squat_Hold_Arms_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Torso Twist',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Spine',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTorso%20Twist.jpg?alt=media&token=b2e28779-9511-43f3-bead-6dd59c32c0c7',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTorso%20Twist.mp4?alt=media&token=7c367e51-fc83-403a-afd3-dfaf3e26610c',
    video_id: 'lst_Torso_Twist_1',
    video_length: 0.07,
    video_number: '737',
    size: 2611647,
    hints: 'lst_Torso_Twist_1_hints',
    common_mistakes: 'lst_Torso_Twist_1_common_mistakes',
    breathing: 'lst_Torso_Twist_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Standing Ab Twist',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStanding%20Ab%20Twist.jpg?alt=media&token=26f4e5b6-2726-4d5b-9c0d-99086895bc56',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStanding%20Ab%20Twist.mp4?alt=media&token=d7966c85-b94f-43b1-bbbf-e148dff5cfa6',
    video_id: 'C_Standing_Ab_Twist_1',
    video_length: 0.07,
    video_number: '529',
    size: 3055022,
    hints: 'C_Standing_Ab_Twist_1_hints',
    common_mistakes: 'C_Standing_Ab_Twist_1_common_mistakes',
    breathing: 'C_Standing_Ab_Twist_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Side Lunge Stretch',
    is_free: true,
    muscle_category: ['legs'],
    primary_muscle: 'Adductors, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Lunge%20Stretch.jpg?alt=media&token=0b38addf-cd64-4e4f-916f-cd496b5eef74',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Lunge%20Stretch.mp4?alt=media&token=7aadf66b-7c1a-4817-b42d-1a80ad0cac35',
    video_id: 'lst_Side_Lunge_Stretch_1',
    video_length: 0.07,
    video_number: '676',
    size: 4181143,
    hints: 'lst_Side_Lunge_Stretch_1_hints',
    common_mistakes: 'lst_Side_Lunge_Stretch_1_common_mistakes',
    breathing: 'lst_Side_Lunge_Stretch_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Punches',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPunches.jpg?alt=media&token=3cf81090-85ad-49ac-83de-eb23ee46f2c2',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPunches.mp4?alt=media&token=cac06514-59a2-4ce8-aa4a-1897bcd301d2',
    video_id: 'C_Punches_1',
    video_length: 0.07,
    video_number: '523',
    size: 1297560,
    hints: 'C_Punches_1_hints',
    common_mistakes: 'C_Punches_1_common_mistakes',
    breathing: 'C_Punches_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Cross Body Jack',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCross%20Body%20Jack.jpg?alt=media&token=5459ad61-ebc1-411d-a44c-b07b0406b274',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCross%20Body%20Jack.mp4?alt=media&token=4f141730-4a51-42bd-8507-bdd60c022b2a',
    video_id: 'h_Cross_Body_Jack_1',
    video_length: 0.07,
    video_number: '543',
    size: 2111344,
    hints: 'h_Cross_Body_Jack_1_hints',
    common_mistakes: 'h_Cross_Body_Jack_1_common_mistakes',
    breathing: 'h_Cross_Body_Jack_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Running In Place',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FRunning%20In%20Place.jpg?alt=media&token=7598610c-0212-4a3b-9c91-57f9d006de8d',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FRunning%20In%20Place.mp4?alt=media&token=e56bed0f-8179-467a-94b8-cb21ac8e5a7e',
    video_id: 'h_Running_In_Place_1',
    video_length: 0.07,
    video_number: '578',
    size: 1655058,
    hints: 'h_Running_In_Place_1_hints',
    common_mistakes: 'h_Running_In_Place_1_common_mistakes',
    breathing: 'h_Running_In_Place_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Cobra Stretch',
    is_free: true,
    muscle_category: ['planks', 'abs'],
    primary_muscle: 'Core, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCobra%20Stretch.jpg?alt=media&token=e560e1e6-7599-4f07-964a-348f798ea761',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCobra%20Stretch.mp4?alt=media&token=ddd6be65-0084-4d2c-807a-ed1789f0fbdc',
    video_id: 'lst_Cobra_Stretch_1',
    video_length: 0.07,
    video_number: '719',
    size: 4484128,
    hints: 'lst_Cobra_Stretch_1_hints',
    common_mistakes: 'lst_Cobra_Stretch_1_common_mistakes',
    breathing: 'lst_Cobra_Stretch_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Single Jackknives',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Jackknives.jpg?alt=media&token=0787374e-1397-40ea-b6d3-87897826fad8',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Jackknives.mp4?alt=media&token=afbac033-2e07-4f4b-90b1-8932695c64b1',
    video_id: 'Abs_Single_Jackknives_1',
    video_length: 0.07,
    video_number: '218',
    size: 2051015,
    hints: 'Abs_Single_Jackknives_1_hints',
    common_mistakes: 'Abs_Single_Jackknives_1_common_mistakes',
    breathing: 'Abs_Single_Jackknives_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'triceps & shoulders',
    name: 'Prayer Pushes',
    is_free: true,
    primary_muscle: 'Front Shoulders, Middle Shoulders',
    rep_range: '10-20',
    secondary_muscle: 'Traps',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Rear Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPrayer%20Pushes.jpg?alt=media&token=f5dfe33e-5801-402a-851a-0df99e40f937',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPrayer%20Pushes.mp4?alt=media&token=04b1d999-35f7-441e-b14c-bb1b640aea1e',
    video_id: 'TnS_Prayer_Pushes_1',
    video_length: 0.07,
    video_number: '422',
    size: 2157809,
    hints: 'TnS_Prayer_Pushes_1_hints',
    common_mistakes: 'TnS_Prayer_Pushes_1_common_mistakes',
    breathing: 'TnS_Prayer_Pushes_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Alternating Opposite Toe Tap',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core, Shoulders',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Opposite%20Toe%20Tap.jpg?alt=media&token=d0b19829-7607-41c5-be07-a3ba3dd7ee3e',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Opposite%20Toe%20Tap.mp4?alt=media&token=1b9c462f-a62d-4fe9-bdad-4e8c6d8eea5f',
    video_id: 'C_Alternating_Opposite_Toe_Tap_1',
    video_length: 0.07,
    video_number: '534',
    size: 1166158,
    hints: 'C_Alternating_Opposite_Toe_Tap_1_hints',
    common_mistakes: 'C_Alternating_Opposite_Toe_Tap_1_common_mistakes',
    breathing: 'C_Alternating_Opposite_Toe_Tap_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Walk The Plank',
    is_free: true,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle: 'Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWalk%20The%20Plank.jpg?alt=media&token=7a9c0224-2619-4761-b786-da79fe77982c',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWalk%20The%20Plank.mp4?alt=media&token=ccf08a14-5eb6-45d4-8964-455f94d4a49b',
    video_id: 'pl_Walk_The_Plank_1',
    video_length: 0.07,
    video_number: '586',
    size: 2996795,
    hints: 'pl_Walk_The_Plank_1_hints',
    common_mistakes: 'pl_Walk_The_Plank_1_common_mistakes',
    breathing: 'pl_Walk_The_Plank_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Standing Upper Back Stretch',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Latissimus Dorsi, Serratus Anterior, Triceps',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStanding%20Upper%20Back%20Stretch.jpg?alt=media&token=316825fb-74ff-43d0-898c-3737db0167d4',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStanding%20Upper%20Back%20Stretch.mp4?alt=media&token=933832e2-7222-4d9b-8ecd-39cdb9d1780c',
    video_id: 'lst_Standing_Upper_Back_Stretch',
    video_length: 0.07,
    video_number: '724',
    size: 3473419,
    hints: 'lst_Standing_Upper_Back_Stretch_hints',
    common_mistakes: 'lst_Standing_Upper_Back_Stretch_common_mistakes',
    breathing: 'lst_Standing_Upper_Back_Stretch_breathing',
  },
  {
    category_id: 1,
    category_name: 'upper body',
    name: 'Hammer Curl Into Regular Curl',
    is_free: false,
    primary_muscle: 'Biceps, Brachialis',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Forearm',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHammer%20Curl%20Into%20Regular%20Curl.jpg?alt=media&token=599ea2ee-b376-4f6b-a747-31d2dcacdf88',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHammer%20Curl%20Into%20Regular%20Curl.mp4?alt=media&token=830a8afd-b393-47c6-a53b-037966354e78',
    video_id: 'CnB_Hammer_Curl_Into_Regular_Curl_1',
    video_length: 0.1,
    video_number: '160',
    size: 1862319,
    hints: 'CnB_Hammer_Curl_Into_Regular_Curl_1_hints',
    common_mistakes: 'CnB_Hammer_Curl_Into_Regular_Curl_1_common_mistakes',
    breathing: 'CnB_Hammer_Curl_Into_Regular_Curl_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Wall Leg Spread Knee Touch',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWall%20Leg%20Spread%20Knee%20Touch.jpg?alt=media&token=2bfb37af-5d9e-4e09-aa4f-63161e1fbb8f',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWall%20Leg%20Spread%20Knee%20Touch.mp4?alt=media&token=a4814300-bb02-4814-a0f7-129fc78c10e0',
    video_length: 0.05,
    video_number: '825',
    size: 1942840,
    hints: 'wp_Wall_Leg_Spread_Knee_Touch_hints',
    common_mistakes: 'wp_Wall_Leg_Spread_Knee_Touch_common_mistakes',
    breathing: 'wp_Wall_Leg_Spread_Knee_Touch_breathing',
    video_id: 'wp_Wall_Leg_Spread_Knee_Touch',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'High Plank Opposite Hip Tap',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHigh%20Plank%20Opposite%20Hip%20Tap.jpg?alt=media&token=4bf7ff5c-2f0b-49d1-9404-160545daa92a',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHigh%20Plank%20Opposite%20Hip%20Tap.mp4?alt=media&token=335fcbaa-b17a-42cb-84a9-e036d547a8c0',
    video_id: 'pl_High_Plank_Opposite_Hip_Tap_1',
    video_length: 0.07,
    video_number: '609',
    size: 889032,
    hints: 'pl_High_Plank_Opposite_Hip_Tap_1_hints',
    common_mistakes: 'pl_High_Plank_Opposite_Hip_Tap_1_common_mistakes',
    breathing: 'pl_High_Plank_Opposite_Hip_Tap_1_breathing',
  },
  {
    category_id: 1,
    category_name: 'upper body',
    name: 'Hammer Curl',
    is_free: true,
    primary_muscle: 'Biceps, Brachialis',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Forearm',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHammer%20Curl.jpg?alt=media&token=141ed6f7-1655-4c5f-af71-ce9a38c12541',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHammer%20Curl.mp4?alt=media&token=fa977887-c51e-4dc7-8461-d964586e1d94',
    video_id: 'CnB_Hammer_Curl',
    video_length: 0.08,
    video_number: '49',
    size: 524468,
    hints: 'CnB_Hammer_Curl_hints',
    common_mistakes: 'CnB_Hammer_Curl_common_mistakes',
    breathing: 'CnB_Hammer_Curl_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Hurdle Steps Alternating',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHurdle%20Steps%20Alternating.jpg?alt=media&token=76f9c1f0-8b91-4da2-bb08-d45f7d2bd3d9',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHurdle%20Steps%20Alternating.mp4?alt=media&token=5a7a9a01-8b49-4a29-8e75-14c127e5ca89',
    video_id: 'h_Hurdle_Steps_Alternating_1',
    video_length: 0.07,
    video_number: '548',
    size: 1438623,
    hints: 'h_Hurdle_Steps_Alternating_1_hints',
    common_mistakes: 'h_Hurdle_Steps_Alternating_1_common_mistakes',
    breathing: 'h_Hurdle_Steps_Alternating_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Inner Leg Lift',
    is_other_side: 1,
    is_free: true,
    primary_muscle: '',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'legs',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FInner%20Leg%20Lift%20(R).jpg?alt=media&token=68eba1a0-e46e-4290-8899-45fb8a00ce48',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FInner%20Leg%20Lift%20(R).mp4?alt=media&token=bcb3b49e-449c-41f9-9862-972cb2ce1c72',
    video_id: 'l_Inner_Leg_Lift',
    video_length: 0.05,
    video_number: '309',
    size: 3148991,
    hints: 'l_Inner_Leg_Lift_hints',
    common_mistakes: 'l_Inner_Leg_Lift_common_mistakes',
    breathing: 'l_Inner_Leg_Lift_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Step Down X Vert Jump',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStep%20Down%20X%20Vert%20Jump.jpg?alt=media&token=dbf57b0e-bcc4-4bed-bd5a-d29df37a8953',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStep%20Down%20X%20Vert%20Jump.mp4?alt=media&token=78dd91fd-bd4a-4bf5-93f8-179b1285a9ac',
    video_id: 'C_Step_down_X_Vert_Jump_1',
    video_length: 0.07,
    video_number: '531',
    size: 1974904,
    hints: 'C_Step_down_X_Vert_Jump_1_hints',
    common_mistakes: 'C_Step_down_X_Vert_Jump_1_common_mistakes',
    breathing: 'C_Step_down_X_Vert_Jump_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Plie With Knee Extension',
    is_other_side: 0,
    is_free: true,
    primary_muscle: 'Quads, Glutes',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPlie%20With%20Knee%20Extension%20(L).jpg?alt=media&token=a7a98984-7451-4485-90b0-b1dd87fb098b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPlie%20With%20Knee%20Extension%20(L).mp4?alt=media&token=ca7f3571-3f3a-454b-8868-e315428992e8',
    video_id: 'l_Plie_With_Knee_Extension',
    video_length: 0.05,
    video_number: '285',
    size: 1430151,
    hints: 'l_Plie_With_Knee_Extension_hints',
    common_mistakes: 'l_Plie_With_Knee_Extension_common_mistakes',
    breathing: 'l_Plie_With_Knee_Extension_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Booty Toe Taps',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FBooty%20Toe%20Taps.jpg?alt=media&token=9723bc28-186b-4c22-a13d-dd2ab8be416c',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FBooty%20Toe%20Taps.mp4?alt=media&token=48d16b47-2840-484c-b670-3db0c443c90b',
    video_length: 0.05,
    video_number: '749',
    size: 1942840,
    hints: 'b_Booty_Toe_Taps_hints',
    common_mistakes: 'b_Booty_Toe_Taps_common_mistakes',
    breathing: 'b_Booty_Toe_Taps_breathing',
    video_id: 'b_Booty_Toe_Taps',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Soccer Ball Juggle',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core, Shoulders',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSoccer%20Ball%20Juggle.jpg?alt=media&token=309de993-3d94-4bc4-ba5a-436065a0dbc0',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSoccer%20Ball%20Juggle.mp4?alt=media&token=5538c42d-6e7a-46f8-9321-26eaadc1ec12',
    video_id: 'C_Soccer_Ball_Juggle_1',
    video_length: 0.07,
    video_number: '526',
    size: 3051760,
    hints: 'C_Soccer_Ball_Juggle_1_hints',
    common_mistakes: 'C_Soccer_Ball_Juggle_1_common_mistakes',
    breathing: 'C_Soccer_Ball_Juggle_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Triangle Forward Bend',
    is_other_side: 1,
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hamstrings',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTriangle%20Forward%20Bend%20(R).jpg?alt=media&token=4ada6984-3241-4e29-9e23-a8b564e3692c',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTriangle%20Forward%20Bend%20(R).mp4?alt=media&token=b3fb0d92-35be-4014-82eb-55eb3c2318d5',
    video_id: 'lst_Triangle_Forward_Bend_1',
    video_length: 0.07,
    video_number: '686',
    size: 7224364,
    hints: 'lst_Triangle_Forward_Bend_1_hints',
    common_mistakes: 'lst_Triangle_Forward_Bend_1_common_mistakes',
    breathing: 'lst_Triangle_Forward_Bend_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'X Ups',
    is_free: false,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FX%20Ups.jpg?alt=media&token=b44cdbed-c0a9-4fc8-8695-ab94f39d6a06',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FX%20Ups.mp4?alt=media&token=2ff925dd-9005-476a-bf17-7e2deea41bc6',
    video_id: 'Abs_X_Ups_1',
    video_length: 0.07,
    video_number: '217',
    size: 1455283,
    hints: 'Abs_X_Ups_1_hints',
    common_mistakes: 'Abs_X_Ups_1_common_mistakes',
    breathing: 'Abs_X_Ups_1_breathing',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Low Plank Arm Reaches',
    is_free: true,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLow%20Plank%20Arm%20Reaches.jpg?alt=media&token=105fe2e8-a457-4b85-90e9-39fea7ca0e51',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLow%20Plank%20Arm%20Reaches.mp4?alt=media&token=f98d028a-1af0-47a2-bb19-10e28805a518',
    video_id: 'pl_Low_Plank_Arm_Reaches_1',
    video_length: 0.07,
    video_number: '608',
    size: 1799694,
    hints: 'pl_Low_Plank_Arm_Reaches_1_hints',
    common_mistakes: 'pl_Low_Plank_Arm_Reaches_1_common_mistakes',
    breathing: 'pl_Low_Plank_Arm_Reaches_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'total body',
    name: 'Double Squat Into Shoulder Press',
    is_free: true,
    primary_muscle: 'Quads, Glutes Shoulders',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle:
      'Hamstrings, Adductors, Erector Spinae, Serratus Anterior',
    subcategory_name: 'total body',
    tertiary_muscle: 'Core, Calves, Traps, Chest, Latissimus Dorsi',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FDouble%20Squat%20Into%20Shoulder%20Press.jpg?alt=media&token=14d8fabe-d020-4ba9-b00a-741301e32c48',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FDouble%20Squat%20Into%20Shoulder%20Press.mp4?alt=media&token=7e65ce5c-af8c-4c26-8ab9-dc4148bd5bfd',
    video_id: 'TnS_Double_Squat_Into_Shoulder_Press_1',
    video_length: 0.07,
    video_number: '467',
    size: 1508182,
    hints: 'TnS_Double_Squat_Into_Shoulder_Press_1_hints',
    common_mistakes: 'TnS_Double_Squat_Into_Shoulder_Press_1_common_mistakes',
    breathing: 'TnS_Double_Squat_Into_Shoulder_Press_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Forward Head Roll',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Traps',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FForward%20Head%20Roll.jpg?alt=media&token=e89f10b9-49e3-427c-bc62-ad6b44b24b14',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FForward%20Head%20Roll.mp4?alt=media&token=45a9480f-5557-4ad1-81e2-7715f0ff9a6e',
    video_id: 'lst_Forward_Head_Roll_1',
    video_length: 0.07,
    video_number: '730',
    size: 2489614,
    hints: 'lst_Forward_Head_Roll_1_hints',
    common_mistakes: 'lst_Forward_Head_Roll_1_common_mistakes',
    breathing: 'lst_Forward_Head_Roll_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Hundred Pike',
    is_free: true,
    primary_muscle: 'Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Obliques, Hip Flexors',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FHundred%20Pike.jpg?alt=media&token=993161b2-4dbb-40a2-bbb5-fc5d1aff0c85',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FHundred%20Pike.mp4?alt=media&token=ec04e6b2-d653-40f8-a8b1-0dc28a209a0b',
    video_id: 'Abs_Hundred_Pike_1',
    video_length: 0.07,
    video_number: '202',
    size: 1176337,
    hints: 'Abs_Hundred_Pike_1_hints',
    common_mistakes: 'Abs_Hundred_Pike_1_common_mistakes',
    breathing: 'Abs_Hundred_Pike_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Sides & Front Squat Hop',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSides%20%26%20Front%20Squat%20Hop.jpg?alt=media&token=4e37cb6e-852e-42b6-91ea-6faf0f19dab2',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSides%20%26%20Front%20Squat%20Hop.mp4?alt=media&token=2855f740-4ceb-4e80-83cd-814fbf5fb6bc',
    video_id: 'h_Sides_Front_Squat_Hop_1',
    video_length: 0.07,
    video_number: '563',
    size: 1725504,
    hints: 'h_Sides_Front_Squat_Hop_1_hints',
    common_mistakes: 'h_Sides_Front_Squat_Hop_1_common_mistakes',
    breathing: 'h_Sides_Front_Squat_Hop_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Alternating Cross Over Lunge',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FAlternating%20Cross%20Over%20Lunge.jpg?alt=media&token=5a5218c8-4f32-4f18-ace7-82d79f73cabb',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FAlternating%20Cross%20Over%20Lunge.mp4?alt=media&token=37a5e57c-9e71-4181-bea5-379a752d9536',
    video_length: 0.05,
    video_number: '765',
    size: 1942840,
    hints: 'b_Alternating_Cross_Over_Lunge_hints',
    common_mistakes: 'b_Alternating_Cross_Over_Lunge_common_mistakes',
    breathing: 'b_Alternating_Cross_Over_Lunge_breathing',
    video_id: 'b_Alternating_Cross_Over_Lunge',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Straight Leg Donkey Kick',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStraight%20Leg%20Donkey%20Kick.jpg?alt=media&token=70f71d1e-b3fc-4ea5-8c5e-78912bc8f987',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStraight%20Leg%20Donkey%20Kick.mp4?alt=media&token=cd4dbecb-eb1c-43a3-8125-612bb6c571ac',
    video_length: 0.05,
    video_number: '791',
    size: 1942840,
    hints: 'b_Straight_Leg_Donkey_Kick_hints',
    common_mistakes: 'b_Straight_Leg_Donkey_Kick_common_mistakes',
    breathing: 'b_Straight_Leg_Donkey_Kick_breathing',
    video_id: 'b_Straight_Leg_Donkey_Kick',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Leg Swing',
    is_other_side: 0,
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Quads, Glutes, Hamstrings,  Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2Flst_Leg_Swing_1.jpg?alt=media&token=d85c9b89-f9ce-417a-88fb-06cfcd3ef4b7',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2Flst_Leg_Swing_1.mp4?alt=media&token=44fb1155-5568-4312-a835-dfe03a51823e',
    video_id: 'lst_Leg_Swing_1',
    video_length: 0.07,
    video_number: '627',
    size: 1506125,
    hints: 'lst_Leg_Swing_1_hints',
    common_mistakes: 'lst_Leg_Swing_1_common_mistakes',
    breathing: 'lst_Leg_Swing_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Circles Reverse',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Core',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCircles%20Reverse.jpg?alt=media&token=316f4e79-8331-42a5-a8ad-af5ed7dad767',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCircles%20Reverse.mp4?alt=media&token=6c670728-cd18-424f-bcc9-5bc21276596c',
    video_id: 'C_Circles_Reverse',
    video_length: 0.07,
    video_number: '508',
    size: 1709183,
    hints: 'C_Circles_Reverse_hints',
    common_mistakes: 'C_Circles_Reverse_common_mistakes',
    breathing: 'C_Circles_Reverse_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Cobra Stretch Side To Side',
    is_free: true,
    muscle_category: ['planks', 'abs'],
    primary_muscle: 'Core, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCobra%20Stretch%20Side%20To%20Side.jpg?alt=media&token=cfd6b088-60a6-4431-935c-fa7768b96d71',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCobra%20Stretch%20Side%20To%20Side.mp4?alt=media&token=32664a45-4e72-43c7-b17c-69690e6708a5',
    video_id: 'lst_Cobra_Stretch_Side_To_Side_1',
    video_length: 0.07,
    video_number: '720',
    size: 2606672,
    hints: 'lst_Cobra_Stretch_Side_To_Side_1_hints',
    common_mistakes: 'lst_Cobra_Stretch_Side_To_Side_1_common_mistakes',
    breathing: 'lst_Cobra_Stretch_Side_To_Side_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Full Body Stretch',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Latissimus Dorsi, Serratus Anterior, Triceps',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFull%20Body%20Stretch.jpg?alt=media&token=bbb0aa34-36f3-442f-9485-6e74b52809c2',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFull%20Body%20Stretch.mp4?alt=media&token=f7171b24-6506-4688-ba4c-6ff3a67c51e9',
    video_id: 'lst_Full_Body_Stretch',
    video_length: 0.07,
    video_number: '724',
    size: 3473419,
    hints: 'lst_Full_Body_Stretch_hints',
    common_mistakes: 'lst_Full_Body_Stretch_common_mistakes',
    breathing: 'lst_Full_Body_Stretch_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Forward Reverse Wrist Curl',
    is_free: false,
    primary_muscle: 'Forearm',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: '',
    subcategory_name: 'upper body',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FForward%20Reverse%20Wrist%20Curl.jpg?alt=media&token=89ce894e-d4b5-4c50-a2c8-1250af3e459d',
    time: '45 seconds',
    time_to_play: 0.75,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FForward%20Reverse%20Wrist%20Curl.mp4?alt=media&token=f1f935d5-b1a1-4f45-836b-82ff1733483f',
    video_id: 'TnS_Forward_Reverse_Wrist_Curl_1',
    video_length: 0.07,
    video_number: '498',
    size: 544022,
    hints: 'TnS_Forward_Reverse_Wrist_Curl_1_hints',
    common_mistakes: 'TnS_Forward_Reverse_Wrist_Curl_1_common_mistakes',
    breathing: 'TnS_Forward_Reverse_Wrist_Curl_1_breathing',
  },
  {
    category_id: 1,
    category_name: 'upper body',
    name: 'Curls',
    is_free: true,
    primary_muscle: 'Biceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Brachialis, Forearm',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FCurls.jpg?alt=media&token=b8fa6e24-086b-40cf-8577-c6c454a02eb6',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FCurls.mp4?alt=media&token=3fb3c818-872a-42e3-927b-7e0a9076a550',
    video_id: 'ub_Curls',
    video_length: 0.08,
    video_number: '834',
    size: 552916,
    hints: 'ub_Curls_hints',
    common_mistakes: 'ub_Curls_common_mistakes',
    breathing: 'ub_Curls_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Lunges',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLunges.jpg?alt=media&token=f9135a0d-c124-4c71-bf1a-0fc114384af1',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLunges.mp4?alt=media&token=280c0d13-41c2-4194-bc87-56e33758a630',
    video_length: 0.05,
    video_number: '765',
    size: 1942840,
    hints: 'b_Lunges_hints',
    common_mistakes: 'b_Lunges_common_mistakes',
    breathing: 'b_Lunges_breathing',
    video_id: 'b_Lunges',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Frog Pose Rocks',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Adductors, Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FFrog%20Pose%20Rocks.jpg?alt=media&token=0e18707a-6ec8-47bd-a742-f2a5b6c99654',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FFrog%20Pose%20Rocks.mp4?alt=media&token=6c5285b8-f90d-41f2-a1db-3190bee7afcd',
    video_id: 'lst_Frog_Pose_Rocks_1',
    video_length: 0.07,
    video_number: '653',
    size: 1582383,
    hints: 'lst_Frog_Pose_Rocks_1_hints',
    common_mistakes: 'lst_Frog_Pose_Rocks_1_common_mistakes',
    breathing: 'lst_Frog_Pose_Rocks_1_breathing',
  },
  {
    category_id: 2,
    category_name: 'abs',
    subcategory_name: 'abs',
    name: 'Sky Touches',
    is_free: false,
    primary_muscle: 'Upper Abs',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Abs, Obliques',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSky%20Touches.jpg?alt=media&token=0b77502f-d968-4db8-8f1a-dc801016cca2',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSky%20Touches.mp4?alt=media&token=e834e785-e024-493a-91a3-c9f4a2d7a60a',
    video_id: 'Abs_Sky_Touches_13',
    video_length: 0.08,
    video_number: '43',
    size: 604532,
    hints: 'Abs_Sky_Touches_13_hints',
    common_mistakes: 'Abs_Sky_Touches_13_common_mistakes',
    breathing: 'Abs_Sky_Touches_13_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    is_other_side: 0,
    name: 'Step Up',
    is_free: false,
    primary_muscle: 'Quads, Hamstrings, Glutes',
    rep_range: '10-15',
    secondary_muscle: 'Adductors',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStep%20Up%20(L).jpg?alt=media&token=ebee871e-1b74-4c7b-b7ca-51a4cc0c2158',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Chair',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStep%20Up%20(L).mp4?alt=media&token=3d691d13-761f-4016-9c7d-9484142d4dc3',
    video_id: 'LnB_Step_Up_16',
    video_length: 0.1,
    video_number: '33',
    size: 639750,
    hints: 'LnB_Step_Up_16_hints',
    common_mistakes: 'LnB_Step_Up_16_common_mistakes',
    breathing: 'LnB_Step_Up_16_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Sumo Squat Pulses',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSumo%20Squat%20Pulses.jpg?alt=media&token=69b47143-19b2-470c-bfa2-2ff9d46dfdad',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSumo%20Squat%20Pulses.mp4?alt=media&token=97e097b6-1fca-4875-84b1-1aa15b67549c',
    video_length: 0.05,
    video_number: '793',
    size: 1942840,
    hints: 'b_Sumo_Squat_Pulses_hints',
    common_mistakes: 'b_Sumo_Squat_Pulses_common_mistakes',
    breathing: 'b_Sumo_Squat_Pulses_breathing',
    video_id: 'b_Sumo_Squat_Pulses',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Leg Swing',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2Fwp_Leg_Swing_2.jpg?alt=media&token=18de7c6e-9dbc-49a0-9135-48819b4ac1a0',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2Fwp_Leg_Swing_2.mp4?alt=media&token=1b63b447-851d-479a-95aa-16830dbcdd2d',
    video_length: 0.05,
    video_number: '807',
    size: 1942840,
    hints: 'wp_Leg_Swing_2_hints',
    common_mistakes: 'wp_Leg_Swing_2_common_mistakes',
    breathing: 'wp_Leg_Swing_2_breathing',
    is_other_side: 1,
    video_id: 'wp_Leg_Swing_2',
  },
  {
    category_id: 10,
    category_name: 'planks',
    name: 'Plank Walk Out',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle:
      'Traps, Latissimus Dorsi, Front Shoulders, Rear Shoulders, Triceps',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPlank%20Walk%20Out.jpg?alt=media&token=e3170281-5204-48bc-a62a-ffed75be4791',
    time: '30 seconds',
    time_to_play: 0.5,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPlank%20Walk%20Out.mp4?alt=media&token=ca2a2a44-9be9-47e6-bffd-dec2b864625b',
    video_id: 'pl_Plank_Walk_Out_1',
    video_length: 0.07,
    video_number: '622',
    size: 3960552,
    hints: 'pl_Plank_Walk_Out_1_hints',
    common_mistakes: 'pl_Plank_Walk_Out_1_common_mistakes',
    breathing: 'pl_Plank_Walk_Out_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Pendulum Swings',
    is_other_side: 0,
    is_free: false,
    muscle_category: ['shoulders'],
    primary_muscle: 'Shoulders',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPendulum%20Swings%20(L).jpg?alt=media&token=77fadc17-95c0-41d6-b489-ea3412d7eb53',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Chair',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPendulum%20Swings%20(L).mp4?alt=media&token=0428d548-7b19-4b9e-9f31-a43876704c64',
    video_id: 'lst_Pendulum_Swings_1',
    video_length: 0.07,
    video_number: '714',
    size: 3851481,
    hints: 'lst_Pendulum_Swings_1_hints',
    common_mistakes: 'lst_Pendulum_Swings_1_common_mistakes',
    breathing: 'lst_Pendulum_Swings_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Supinated Fingers Down Pull',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Wrist, Forearm, Biceps',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSupinated%20Fingers%20Down%20Pull.jpg?alt=media&token=3db42057-224a-41e9-970a-780df08094cb',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSupinated%20Fingers%20Down%20Pull.mp4?alt=media&token=2b49a92f-a3df-4130-9d0f-bcf2b0e0fe39',
    video_id: 'lst_Supinated_Fingers_Down_Pull_1',
    video_length: 0.07,
    video_number: '704',
    size: 4220549,
    hints: 'lst_Supinated_Fingers_Down_Pull_1_hints',
    common_mistakes: 'lst_Supinated_Fingers_Down_Pull_1_common_mistakes',
    breathing: 'lst_Supinated_Fingers_Down_Pull_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Side Bend',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Latissimus Dorsi, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Bend.jpg?alt=media&token=cbf92270-18ea-4199-a123-370c49e17563',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Bend.mp4?alt=media&token=95898537-655f-4a57-89a7-aae6d0a42164',
    video_id: 'lst_Side_Bend_1',
    video_length: 0.07,
    video_number: '735',
    size: 4757046,
    hints: 'lst_Side_Bend_1_hints',
    common_mistakes: 'lst_Side_Bend_1_common_mistakes',
    breathing: 'lst_Side_Bend_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Overhead Stretch',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Latissimus Dorsi, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FOverhead%20Stretch.jpg?alt=media&token=21793bd8-012a-467b-940c-dbfecd37ca11',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Wall',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FOverhead%20Stretch.mp4?alt=media&token=1125aad3-851e-431b-9222-94763c66d5f6',
    video_id: 'lst_Overhead_Stretch_1',
    video_length: 0.07,
    video_number: '734',
    size: 1731854,
    hints: 'lst_Overhead_Stretch_1_hints',
    common_mistakes: 'lst_Overhead_Stretch_1_common_mistakes',
    breathing: 'lst_Overhead_Stretch_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Side Lunge Shoulder Press',
    is_other_side: 0,
    is_free: true,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexors, Shoulders',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings Calves Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSide%20Lunge%20Shoulder%20Press%20(L).jpg?alt=media&token=cdb9af8b-9eb7-4153-9943-83b7d028b386',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSide%20Lunge%20Shoulder%20Press%20(L).mp4?alt=media&token=062e7667-3e75-4a72-bfbc-0bd9deb9414a',
    video_id: 'l_Side_Lunge_Shoulder_Press',
    video_length: 0.05,
    video_number: '309',
    size: 3148991,
    hints: 'l_Side_Lunge_Shoulder_Press_hints',
    common_mistakes: 'l_Side_Lunge_Shoulder_Press_common_mistakes',
    breathing: 'l_Side_Lunge_Shoulder_Press_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Arm Circles Forward',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Shoulders',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FArm%20Circles%20Forward.jpg?alt=media&token=a9e4cd07-0840-4359-a082-61f0d22b5c29',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FArm%20Circles%20Forward.mp4?alt=media&token=fc1fa3e5-87d3-41d8-90de-900de034d424',
    video_id: 'lst_Arm_Circles_Forward_1',
    video_length: 0.07,
    video_number: '706',
    size: 992934,
    hints: 'lst_Arm_Circles_Forward_1_hints',
    common_mistakes: 'lst_Arm_Circles_Forward_1_common_mistakes',
    breathing: 'lst_Arm_Circles_Forward_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Pronated Finger Up Pull',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Wrist, Forearm',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FPronated%20Finger%20Up%20Pull.jpg?alt=media&token=e966f4cc-711e-4e51-ab7c-58689c8b1d6b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FPronated%20Finger%20Up%20Pull.mp4?alt=media&token=61a686bc-bc45-409e-bed4-40b9405d2811',
    video_id: 'lst_Pronated_Finger_Up_Pull_1',
    video_length: 0.07,
    video_number: '703',
    size: 4649237,
    hints: 'lst_Pronated_Finger_Up_Pull_1_hints',
    common_mistakes: 'lst_Pronated_Finger_Up_Pull_1_common_mistakes',
    breathing: 'lst_Pronated_Finger_Up_Pull_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Jump Squat',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2Fb_Jump_Squat.jpg?alt=media&token=04198b0d-6138-4e58-b819-54d69cd50871',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2Fb_Jump_Squat.mp4?alt=media&token=98275a32-4c9a-490f-a3dc-7f97daa881b7',
    video_length: 0.05,
    video_number: '761',
    size: 1942840,
    hints: 'b_Jump_Squat_hints',
    common_mistakes: 'b_Jump_Squat_common_mistakes',
    breathing: 'b_Jump_Squat_breathing',
    video_id: 'b_Jump_Squat',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Kneeling Openers',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Spinal, Chest, Shoulders, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FKneeling%20Openers.jpg?alt=media&token=03524460-2751-44f6-93aa-69573dd4b9ff',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FKneeling%20Openers.mp4?alt=media&token=4bca4e20-7895-4625-aa99-e2c5ac4d9511',
    video_id: 'lst_Kneeling_Openers_1',
    video_length: 0.07,
    video_number: '695',
    size: 3771124,
    hints: 'lst_Kneeling_Openers_1_hints',
    common_mistakes: 'lst_Kneeling_Openers_1_common_mistakes',
    breathing: 'lst_Kneeling_Openers_1_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Standing Arm Pull Lean',
    is_free: true,
    muscle_category: ['upper body'],
    primary_muscle: 'Latissimus Dorsi, Serratus Anterior, Triceps',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FStanding%20Arm%20Pull%20Lean.jpg?alt=media&token=e622329b-ab39-4876-8db0-e9a039cace23',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FStanding%20Arm%20Pull%20Lean.mp4?alt=media&token=7d5d3015-0eed-44a6-8b36-663e219861f3',
    video_id: 'lst_Standing_Arm_Pull_Lean_1',
    video_length: 0.07,
    video_number: '723',
    size: 4454463,
    hints: 'lst_Standing_Arm_Pull_Lean_1_hints',
    common_mistakes: 'lst_Standing_Arm_Pull_Lean_1_common_mistakes',
    breathing: 'lst_Standing_Arm_Pull_Lean_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Walking High Knees',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FWalking%20High%20Knees.jpg?alt=media&token=246c5673-7669-4d0a-a1b6-dd8701940c16',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FWalking%20High%20Knees.mp4?alt=media&token=31a8f861-201d-4598-920f-75781517046d',
    video_id: 'C_Walking_High_Knees_1',
    video_length: 0.07,
    video_number: '533',
    size: 3290894,
    hints: 'C_Walking_High_Knees_1_hints',
    common_mistakes: 'C_Walking_High_Knees_1_common_mistakes',
    breathing: 'C_Walking_High_Knees_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'triceps & shoulders',
    name: 'Tomahawk Raise',
    is_free: true,
    primary_muscle: 'Front Shoulders, Middle Shoulders',
    rep_range: '10-20',
    secondary_muscle: 'Traps',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Rear Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FTomahawk%20Raise.jpg?alt=media&token=7716221c-dedf-4e05-9061-2b2546d3b097',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FTomahawk%20Raise.mp4?alt=media&token=c7f24677-1dae-4b53-aa4c-0a8f0daee365',
    video_id: 'TnS_Tomahawk_Raise_1',
    video_length: 0.07,
    video_number: '382',
    size: 2535040,
    hints: 'TnS_Tomahawk_Raise_1_hints',
    common_mistakes: 'TnS_Tomahawk_Raise_1_common_mistakes',
    breathing: 'TnS_Tomahawk_Raise_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Leg Swing',
    is_other_side: 1,
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Quads, Glutes, Hamstrings,  Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2Flst_Leg_Swing_2.jpg?alt=media&token=c40d6b0f-dd7a-47bc-9f70-1b93acb06045',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2Flst_Leg_Swing_2.mp4?alt=media&token=2be4257b-aa6c-47f2-a787-67b170214368',
    video_id: 'lst_Leg_Swing_2',
    video_length: 0.07,
    video_number: '628',
    size: 1784305,
    hints: 'lst_Leg_Swing_2_hints',
    common_mistakes: 'lst_Leg_Swing_2_common_mistakes',
    breathing: 'lst_Leg_Swing_2_breathing',
  },
  {
    category_id: 9,
    category_name: 'upper body stretch',
    name: 'Kneeling Over Arm Side Lean',
    is_free: false,
    muscle_category: ['upper body'],
    primary_muscle: 'Shoulders, Latissimus Dorsi, Spinal, Serratus Anterior',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'upper body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FKneeling%20Over%20Arm%20Side%20Lean.jpg?alt=media&token=a162221b-ca3c-4c9d-bd9b-16ad0244c99a',
    time: '75 seconds',
    time_to_play: 1.25,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FKneeling%20Over%20Arm%20Side%20Lean.mp4?alt=media&token=2e02934e-8036-4820-8154-a58e498cd5b3',
    video_id: 'lst_Kneeling_Over_Arm_Side_Lean_1',
    video_length: 0.07,
    video_number: '712',
    size: 5164996,
    hints: 'lst_Kneeling_Over_Arm_Side_Lean_1_hints',
    common_mistakes: 'lst_Kneeling_Over_Arm_Side_Lean_1_common_mistakes',
    breathing: 'lst_Kneeling_Over_Arm_Side_Lean_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Leg Swing',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: 'Time Based',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2Fwp_Leg_Swing_1.jpg?alt=media&token=9fe18e34-d06d-47be-af56-e03206b72dfb',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2Fwp_Leg_Swing_1.mp4?alt=media&token=11904357-5140-4d32-93aa-1d6cf00d9272',
    video_length: 0.05,
    video_number: '808',
    size: 1942840,
    hints: 'wp_Leg_Swing_1_hints',
    common_mistakes: 'wp_Leg_Swing_1_common_mistakes',
    breathing: 'wp_Leg_Swing_1_breathing',
    is_other_side: 0,
    video_id: 'wp_Leg_Swing_1',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Squat',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat.jpg?alt=media&token=09ccc487-4692-488b-94cb-50243ed2b50b',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat.mp4?alt=media&token=3097926c-8aa4-4e58-ab37-93c719ddbfea',
    video_length: 0.05,
    video_number: '783',
    size: 1942840,
    hints: 'b_Squat_hints',
    common_mistakes: 'b_Squat_common_mistakes',
    breathing: 'b_Squat_breathing',
    video_id: 'b_Squat',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Reverse Wrist Curl',
    is_free: false,
    primary_muscle: 'Forearm',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: '',
    subcategory_name: 'upper body',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FReverse%20Wrist%20Curl.jpg?alt=media&token=6684b1b3-7921-4050-b111-1af47bcaeb32',
    time: '45 seconds',
    time_to_play: 0.75,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FReverse%20Wrist%20Curl.mp4?alt=media&token=0eb085cd-b4e8-44da-8032-52c0846db317',
    video_id: 'TnS_Reverse_Wrist_Curl_1',
    video_length: 0.07,
    video_number: '505',
    size: 831282,
    hints: 'TnS_Reverse_Wrist_Curl_1_hints',
    common_mistakes: 'TnS_Reverse_Wrist_Curl_1_common_mistakes',
    breathing: 'TnS_Reverse_Wrist_Curl_1_breathing',
  },
  {
    category_id: 6,
    category_name: 'cardio',
    name: 'Squat Sprinters',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSquat%20Sprinters.jpg?alt=media&token=ae11196d-a4ae-4f7e-bf46-3105434a93ee',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSquat%20Sprinters.mp4?alt=media&token=af7b2453-f00b-4cbd-94c9-71bdd37a9892',
    video_id: 'C_Squat_Sprinters_1',
    video_length: 0.07,
    video_number: '528',
    size: 1636145,
    hints: 'C_Squat_Sprinters_1_hints',
    common_mistakes: 'C_Squat_Sprinters_1_common_mistakes',
    breathing: 'C_Squat_Sprinters_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Squat Jacks',
    is_free: true,
    primary_muscle: 'Quads, Glutes, Adductors, Hip Flexor, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLnB_Squat_Jacks_1.jpg?alt=media&token=9c59df3c-ced9-44e1-a75c-8e11b12588b5',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLnB_Squat_Jacks_1.mp4?alt=media&token=323bcb31-9c1b-422c-849f-6fc6d0f43548',
    video_id: 'LnB_Squat_Jacks_1',
    video_length: 0.05,
    video_number: '294',
    size: 3107729,
    hints: 'LnB_Squat_Jacks_1_hints',
    common_mistakes: 'LnB_Squat_Jacks_1_common_mistakes',
    breathing: 'LnB_Squat_Jacks_1_breathing',
  },
  {
    category_id: 3,
    category_name: 'upper body',
    name: 'Single Into Double Tricep Extension',
    is_free: true,
    primary_muscle: 'Triceps',
    rep_range: 'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16',
    secondary_muscle: 'Rear Shoulders',
    subcategory_name: 'upper body',
    tertiary_muscle: 'Latissimus Dorsi, Traps, Rhomboids',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FSingle%20Into%20Double%20Tricep%20Extension.jpg?alt=media&token=d7ed3b69-1977-460a-b2a4-91b67ab7841a',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FSingle%20Into%20Double%20Tricep%20Extension.mp4?alt=media&token=a5089c0f-0f28-4ac0-9c5b-e89d0e178403',
    video_id: 'TnS_Single_Into_Double_Tricep_Extension_1',
    video_length: 0.1,
    video_number: '176',
    size: 1810322,
    hints: 'TnS_Single_Into_Double_Tricep_Extension_1_hints',
    common_mistakes:
      'TnS_Single_Into_Double_Tricep_Extension_1_common_mistakes',
    breathing: 'TnS_Single_Into_Double_Tricep_Extension_1_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'Squat Jacks',
    is_free: false,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: 'Chest',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2Fh_Squat_Jacks_1.jpg?alt=media&token=09751751-c6f3-4cc0-b180-d7b91de9012a',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2Fh_Squat_Jacks_1.mp4?alt=media&token=5c8c8161-7e0e-4bb1-a918-d6c542958e02',
    video_id: 'h_Squat_Jacks_1',
    video_length: 0.07,
    video_number: '566',
    size: 927398,
    hints: 'h_Squat_Jacks_1_hints',
    common_mistakes: 'h_Squat_Jacks_1_common_mistakes',
    breathing: 'h_Squat_Jacks_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Jump Squat',
    is_free: true,
    primary_muscle: 'Quads, Hamstrings, Glutes',
    rep_range: '15-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLnB_Jump_Squat_24.jpg?alt=media&token=2c69a636-9dd9-4620-80c7-67fc7cc7a85a',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLnB_Jump_Squat_24.mp4?alt=media&token=c58cdedc-f157-4ea8-8aa6-e00be87230d5',
    video_id: 'LnB_Jump_Squat_24',
    video_length: 0.05,
    video_number: '102',
    size: 417879,
    hints: 'LnB_Jump_Squat_24_hints',
    common_mistakes: 'LnB_Jump_Squat_24_common_mistakes',
    breathing: 'LnB_Jump_Squat_24_breathing',
  },
  {
    category_id: 7,
    category_name: 'hiit',
    name: 'In & Outs',
    is_free: true,
    primary_muscle: 'Cardiovascular System',
    rep_range: 'Time Based',
    secondary_muscle:
      'Lower Body, Core, Shoulders, Traps, Levator Scapulae, Rhomboids, Infraspinatus, Teres Major, Teres Minor, Latissimus Dorsi, Lower Back, Erector Spinae',
    subcategory_name: 'hiit',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2Fh_In_Outs_1.jpg?alt=media&token=3a6d59e2-ed3c-4d46-8573-8220824f1f28',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2Fh_In_Outs_1.mp4?alt=media&token=632c7db4-0b36-4c26-aebf-ca4890097569',
    video_id: 'h_In_Outs_1',
    video_length: 0.07,
    video_number: '549',
    size: 1595671,
    hints: 'h_In_Outs_1_hints',
    common_mistakes: 'h_In_Outs_1_common_mistakes',
    breathing: 'h_In_Outs_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'wall pilates',
    is_free: false,
    name: 'Seated Knee To Chest',
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-12',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'wall pilates',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2Fwp_Seated_Knee_To_Chest.jpg?alt=media&token=840d3e8e-6b58-4441-8aaa-370ac8277ae6',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2Fwp_Seated_Knee_To_Chest.mp4?alt=media&token=1d693775-d46a-4ced-b9e8-15c0eaa21eed',
    video_length: 0.05,
    video_number: '815',
    size: 1942840,
    hints: 'wp_Seated_Knee_To_Chest_hints',
    common_mistakes: 'wp_Seated_Knee_To_Chest_common_mistakes',
    breathing: 'wp_Seated_Knee_To_Chest_breathing',
    video_id: 'wp_Seated_Knee_To_Chest',
  },
  {
    category_id: 7,
    category_name: 'planks',
    name: 'In & Outs',
    is_free: false,
    primary_muscle: 'Core',
    rep_range: 'Time Based',
    secondary_muscle: 'Lower Body, Lower Back',
    subcategory_name: 'planks',
    tertiary_muscle: 'Rhomboids, Latissimus Dorsi, Traps, Triceps, Shoulders',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2Fp_In_Outs_1.jpg?alt=media&token=d205932a-a09b-4327-adf4-eb8f0e19506e',
    time: '20 seconds',
    time_to_play: 0.3333333333333333,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2Fp_In_Outs_1.mp4?alt=media&token=02b0eb4b-f767-4731-a14e-d59527ac8fc8',
    video_id: 'p_In_Outs_1',
    video_length: 0.07,
    video_number: '549',
    size: 1595671,
    hints: 'p_In_Outs_1_hints',
    common_mistakes: 'p_In_Outs_1_common_mistakes',
    breathing: 'p_In_Outs_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'booty',
    name: 'Squat Knee To Elbow',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hamstrings, Calves',
    rep_range: '8-20',
    secondary_muscle: 'Adductors, Erector Spinae',
    subcategory_name: 'booty',
    tertiary_muscle: 'Core',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2Fb_Squat_Knee_To_Elbow.jpg?alt=media&token=fff640f9-98c3-4d7f-b429-dde93f3c88fc',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2Fb_Squat_Knee_To_Elbow.mp4?alt=media&token=da3e5e8b-1b7f-40ae-9c5f-8978ea8897eb',
    video_length: 0.05,
    video_number: '781',
    size: 1942840,
    hints: 'b_Squat_Knee_To_Elbow_hints',
    common_mistakes: 'b_Squat_Knee_To_Elbow_common_mistakes',
    breathing: 'b_Squat_Knee_To_Elbow_breathing',
    video_id: 'b_Squat_Knee_To_Elbow',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Seated Knee To Chest',
    is_free: false,
    muscle_category: ['booty'],
    primary_muscle: 'Glutes',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2Flst_Seated_Knee_To_Chest_1.jpg?alt=media&token=0c2dce58-d4d6-4274-8c6d-704d212701d5',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'Chair',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2Flst_Seated_Knee_To_Chest_1.mp4?alt=media&token=9d041f3d-9324-4f09-a059-c6839a58190f',
    video_id: 'lst_Seated_Knee_To_Chest_1',
    video_length: 0.07,
    video_number: '674',
    size: 1945853,
    hints: 'lst_Seated_Knee_To_Chest_1_hints',
    common_mistakes: 'lst_Seated_Knee_To_Chest_1_common_mistakes',
    breathing: 'lst_Seated_Knee_To_Chest_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'lower body stretch',
    name: 'Hip Circles',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'lower body stretch',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2Flst_Hip_Circles_1.jpg?alt=media&token=7aeed133-bb91-4f6f-970d-005ecf1901b6',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2Flst_Hip_Circles_1.mp4?alt=media&token=34367c03-c41f-449a-b362-be477536fb4c',
    video_id: 'lst_Hip_Circles_1',
    video_length: 0.07,
    video_number: '657',
    size: 2003097,
    hints: 'lst_Hip_Circles_1_hints',
    common_mistakes: 'lst_Hip_Circles_1_common_mistakes',
    breathing: 'lst_Hip_Circles_1_breathing',
  },
  {
    category_id: 8,
    category_name: 'cardio',
    name: 'Hip Circles',
    is_free: false,
    muscle_category: ['legs'],
    primary_muscle: 'Hip Flexors',
    rep_range: 'Time Based',
    secondary_muscle: '',
    subcategory_name: 'cardio',
    tertiary_muscle: '',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2Fc_Hip_Circles_1.jpg?alt=media&token=a5878831-4f6a-4dde-aad8-22ae7c5ce394',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2Fc_Hip_Circles_1.mp4?alt=media&token=da127d12-90bc-4981-8796-a25d8e12b122',
    video_id: 'c_Hip_Circles_1',
    video_length: 0.07,
    video_number: '657',
    size: 2003097,
    hints: 'c_Hip_Circles_1_hints',
    common_mistakes: 'c_Hip_Circles_1_common_mistakes',
    breathing: 'c_Hip_Circles_1_breathing',
  },
  {
    category_id: 4,
    category_name: 'legs & back',
    name: 'Squat Knee To Elbow',
    is_free: false,
    primary_muscle: 'Quads, Glutes, Hip Flexors, Adductors',
    rep_range: '8-20',
    secondary_muscle: 'Hamstrings, Erector Spinae',
    subcategory_name: 'legs',
    tertiary_muscle: 'Core, Calves',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/thumbnails%2FLnB_Squat_Knee_To_Elbow_1.jpg?alt=media&token=a5c66539-2689-4f4b-9a25-a92a18a19ee6',
    time: '60 seconds',
    time_to_play: 1,
    tools: 'No Tools',
    url: 'https://firebasestorage.googleapis.com/v0/b/pilates-fitness.appspot.com/o/videos%2FLnB_Squat_Knee_To_Elbow_1.mp4?alt=media&token=bd88f964-76d9-4c94-bff7-d0d3495f7ff7',
    video_id: 'LnB_Squat_Knee_To_Elbow_1',
    video_length: 0.05,
    video_number: '290',
    size: 2315693,
    hints: 'LnB_Squat_Knee_To_Elbow_1_hints',
    common_mistakes: 'LnB_Squat_Knee_To_Elbow_1_common_mistakes',
    breathing: 'LnB_Squat_Knee_To_Elbow_1_breathing',
  },
];

export const ALL_AUDIOS_INFO = {
  audio5: '/assets/audios/5_4_3_2_1.mp3',
  audio3: '/assets/audios/3_2_1.mp3',
  otherSide: '/assets/audios/Other_Side.mp3',
  getReady: '/assets/audios/Get_ready.mp3',
  beginIn: '/assets/audios/Begin_in.mp3',
  ninetySecondRest: '/assets/audios/90_Seconds_Rest.mp3',
  sixtySecondRest: '/assets/audios/60_Seconds_Rest.mp3',
  nextExerciseIs: '/assets/audios/Next_Exercise_is.mp3',
  nextRest: '/assets/audios/Next_Rest_T.mp3',
  greatWorkout: '/assets/audios/Great_Work.mp3',
};

export const ALL_AUDIOS_INFO_NATIVE = {
  audio5: 'assets/audios/5_4_3_2_1.mp3',
  audio3: 'assets/audios/3_2_1.mp3',
  otherSide: 'assets/audios/Other_Side.mp3',
  getReady: 'assets/audios/Get_ready.mp3',
  beginIn: 'assets/audios/Begin_in.mp3',
  ninetySecondRest: 'assets/audios/90_Seconds_Rest.mp3',
  sixtySecondRest: 'assets/audios/60_Seconds_Rest.mp3',
  nextExerciseIs: 'assets/audios/Next_Exercise_is.mp3',
  nextRest: 'assets/audios/Next_Rest_T.mp3',
  greatWorkout: 'assets/audios/Great_Work.mp3',
};

export const ALL_ONBOARDING_KEYS = {
  gender: OnboardingPageKeys.gender,
  desiredResults: OnboardingPageKeys.desiredResults,
  userBody: OnboardingPageKeys.userBody,
  goalBody: OnboardingPageKeys.goalBody,
  bodyFat: OnboardingPageKeys.bodyFat,
  difficulty: OnboardingPageKeys.difficulty,
  daysCount: OnboardingPageKeys.daysCount,
  currentUserWeight: OnboardingPageKeys.currentUserWeight,
  goalWeight: OnboardingPageKeys.goalWeight,
  age: OnboardingPageKeys.age,
  height: OnboardingPageKeys.height,
  // equipment: OnboardingPageKeys.equipment,
  processPlan: OnboardingPageKeys.processPlan,
};

export const WorkoutDaysAndMuscles = {
  'Curvy Body Bootycamp': {
    1: {
      // 1 day a week, for 8 weeks
      0: ['booty'],
      1: ['booty, legs'],
      2: ['booty'],
      3: ['booty, legs'],
      4: ['booty'],
      7: ['booty, legs'],
      5: ['booty'],
      6: ['booty, legs'],
    },
    2: {
      0: ['booty', 'cardio'],
      1: ['booty, legs', 'hiit'],
      2: ['booty', 'cardio'],
      3: ['booty, legs', 'hiit'],
      4: ['booty', 'cardio'],
      5: ['booty, legs', 'hiit'],
      6: ['booty', 'cardio'],
      7: ['booty, legs', 'hiit'],
    },
    3: {
      0: ['booty', 'cardio', 'wall pilates'],
      1: ['booty, legs', 'hiit', 'wall pilates'],
      2: ['booty', 'cardio', 'wall pilates'],
      3: ['booty, legs', 'hiit', 'wall pilates'],
      4: ['booty', 'cardio', 'wall pilates'],
      5: ['booty, legs', 'hiit', 'wall pilates'],
      6: ['booty', 'cardio', 'wall pilates'],
      7: ['booty, legs', 'hiit', 'wall pilates'],
    },
    4: {
      0: ['booty', 'cardio', 'wall pilates', 'hiit'],
      1: ['booty, legs', 'cardio', 'wall pilates', 'hiit'],
      2: ['booty', 'cardio', 'wall pilates', 'hiit'],
      3: ['booty, legs', 'cardio', 'wall pilates', 'hiit'],
      4: ['booty', 'cardio', 'wall pilates', 'hiit'],
      5: ['booty, legs', 'cardio', 'wall pilates', 'hiit'],
      6: ['booty', 'cardio', 'wall pilates', 'hiit'],
      7: ['booty, legs', 'cardio', 'wall pilates', 'hiit'],
    },
    5: {
      0: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit'],
      1: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit'],
      2: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit'],
      3: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit'],
      4: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit'],
      5: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit'],
      6: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit'],
      7: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit'],
    },
    6: {
      0: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit', 'planks'],
      1: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit', 'abs'],
      2: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit', 'planks'],
      3: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit', 'abs'],
      4: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit', 'planks'],
      5: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit', 'abs'],
      6: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit', 'planks'],
      7: ['booty', 'cardio', 'wall pilates', 'legs', 'hiit', 'abs'],
    },
    7: {
      0: [
        'booty',
        'cardio',
        'wall pilates',
        'legs',
        'hiit',
        'planks',
        'lower body stretch',
      ],
      1: [
        'booty',
        'cardio',
        'wall pilates',
        'legs',
        'hiit',
        'abs',
        'upper body stretch',
      ],
      2: [
        'booty',
        'cardio',
        'wall pilates',
        'legs',
        'hiit',
        'planks',
        'lower body stretch',
      ],
      3: [
        'booty',
        'cardio',
        'wall pilates',
        'legs',
        'hiit',
        'abs',
        'upper body stretch',
      ],
      4: [
        'booty',
        'cardio',
        'wall pilates',
        'legs',
        'hiit',
        'planks',
        'lower body stretch',
      ],
      5: [
        'booty',
        'cardio',
        'wall pilates',
        'legs',
        'hiit',
        'abs',
        'upper body stretch',
      ],
      6: [
        'booty',
        'cardio',
        'wall pilates',
        'legs',
        'hiit',
        'planks',
        'lower body stretch',
      ],
      7: [
        'booty',
        'cardio',
        'wall pilates',
        'legs',
        'hiit',
        'abs',
        'upper body stretch',
      ],
    },
  },
  'ToneTastic Trek': {
    1: {
      // 1 day a week, for 8 weeks
      0: ['wall pilates'],
      1: ['booty'],
      2: ['cardio'],
      3: ['upper body'],
      4: ['wall pilates'],
      5: ['booty, legs'],
      6: ['hiit'],
      7: ['upper body'],
    },
    2: {
      0: ['wall pilates', 'booty'],
      1: ['cardio', 'upper body'],
      2: ['wall pilates', 'booty, legs'],
      3: ['hiit', 'upper body'],
      4: ['wall pilates', 'booty'],
      5: ['cardio', 'upper body'],
      6: ['wall pilates', 'booty, legs'],
      7: ['hiit', 'upper body'],
    },
    3: {
      0: ['wall pilates', 'booty', 'cardio'],
      1: ['upper body', 'wall pilates', 'booty, legs'],
      2: ['hiit', 'upper body', 'wall pilates'],
      3: ['booty', 'cardio', 'upper body'],
      4: ['wall pilates', 'booty, legs', 'hiit'],
      5: ['upper body', 'wall pilates', 'booty'],
      6: ['cardio', 'upper body', 'wall pilates'],
      7: ['booty, legs', 'hiit', 'upper body'],
    },
    4: {
      0: ['wall pilates', 'booty', 'cardio', 'upper body'],
      1: ['wall pilates', 'booty, legs', 'hiit', 'upper body'],
      2: ['wall pilates', 'booty', 'cardio', 'upper body'],
      3: ['wall pilates', 'booty, legs', 'hiit', 'upper body'],
      4: ['wall pilates', 'booty', 'cardio', 'upper body'],
      5: ['wall pilates', 'booty, legs', 'hiit', 'upper body'],
      6: ['wall pilates', 'booty', 'cardio', 'upper body'],
      7: ['wall pilates', 'booty, legs', 'hiit', 'upper body'],
    },
    5: {
      0: ['wall pilates', 'booty', 'cardio', 'upper body', 'hiit'],
      1: ['wall pilates', 'booty, legs', 'cardio', 'upper body', 'hiit'],
      2: ['wall pilates', 'booty', 'cardio', 'upper body', 'hiit'],
      3: ['wall pilates', 'booty, legs', 'cardio', 'upper body', 'hiit'],
      4: ['wall pilates', 'booty', 'cardio', 'upper body', 'hiit'],
      5: ['wall pilates', 'booty, legs', 'cardio', 'upper body', 'hiit'],
      6: ['wall pilates', 'booty', 'cardio', 'upper body', 'hiit'],
      7: ['wall pilates', 'booty, legs', 'cardio', 'upper body', 'hiit'],
    },
    6: {
      0: [
        'wall pilates',
        'booty',
        'upper body',
        'cardio',
        'hiit',
        'total body',
      ],
      1: [
        'wall pilates',
        'booty, legs',
        'upper body',
        'cardio',
        'hiit',
        'total body',
      ],
      2: [
        'wall pilates',
        'booty',
        'upper body',
        'cardio',
        'hiit',
        'total body',
      ],
      3: [
        'wall pilates',
        'booty, legs',
        'upper body',
        'cardio',
        'hiit',
        'total body',
      ],
      4: [
        'wall pilates',
        'booty',
        'upper body',
        'cardio',
        'hiit',
        'total body',
      ],
      5: [
        'wall pilates',
        'booty, legs',
        'upper body',
        'cardio',
        'hiit',
        'total body',
      ],
      6: [
        'wall pilates',
        'booty',
        'upper body',
        'cardio',
        'hiit',
        'total body',
      ],
      7: [
        'wall pilates',
        'booty, legs',
        'upper body',
        'cardio',
        'hiit',
        'total body',
      ],
    },
    7: {
      0: [
        'wall pilates',
        'booty',
        'upper body',
        'cardio',
        'hiit',
        'total body',
        'abs',
      ],
      1: [
        'wall pilates',
        'booty, legs',
        'upper body',
        'cardio',
        'hiit',
        'total body',
        'planks',
      ],
      2: [
        'wall pilates',
        'booty',
        'upper body',
        'cardio',
        'hiit',
        'total body',
        'abs',
      ],
      3: [
        'wall pilates',
        'booty, legs',
        'upper body',
        'cardio',
        'hiit',
        'total body',
        'planks',
      ],
      4: [
        'wall pilates',
        'booty',
        'upper body',
        'cardio',
        'hiit',
        'total body',
        'abs',
      ],
      5: [
        'wall pilates',
        'booty, legs',
        'upper body',
        'cardio',
        'hiit',
        'total body',
        'planks',
      ],
      6: [
        'wall pilates',
        'booty',
        'upper body',
        'cardio',
        'hiit',
        'total body',
        'abs',
      ],
      7: [
        'wall pilates',
        'booty, legs',
        'upper body',
        'cardio',
        'hiit',
        'total body',
        'planks',
      ],
    },
  },
  'PoundPlummet Path': {
    1: {
      // 1 day a week, for 8 weeks
      0: ['cardio'],
      1: ['hiit'],
      2: ['cardio'],
      3: ['hiit'],
      4: ['cardio'],
      5: ['hiit'],
      6: ['cardio'],
      7: ['hiit'],
    },
    2: {
      0: ['cardio', 'hiit'],
      1: ['cardio', 'hiit'],
      2: ['cardio', 'hiit'],
      3: ['cardio', 'hiit'],
      4: ['cardio', 'hiit'],
      5: ['cardio', 'hiit'],
      6: ['cardio', 'hiit'],
      7: ['cardio', 'hiit'],
    },
    3: {
      0: ['cardio', 'hiit', 'wall pilates'],
      1: ['cardio', 'hiit', 'wall pilates'],
      2: ['cardio', 'hiit', 'wall pilates'],
      3: ['cardio', 'hiit', 'wall pilates'],
      4: ['cardio', 'hiit', 'wall pilates'],
      5: ['cardio', 'hiit', 'wall pilates'],
      6: ['cardio', 'hiit', 'wall pilates'],
      7: ['cardio', 'hiit', 'wall pilates'],
    },
    4: {
      0: ['cardio', 'hiit', 'wall pilates', 'cardio'],
      1: ['hiit', 'wall pilates', 'cardio', 'hiit'],
      2: ['wall pilates', 'cardio', 'hiit', 'wall pilates'],
      3: ['cardio', 'hiit', 'wall pilates', 'cardio'],
      4: ['hiit', 'wall pilates', 'cardio', 'hiit'],
      5: ['wall pilates', 'cardio', 'hiit', 'wall pilates'],
      6: ['cardio', 'hiit', 'wall pilates', 'cardio'],
      7: ['hiit', 'wall pilates', 'cardio', 'hiit'],
    },
    5: {
      0: ['cardio', 'hiit', 'wall pilates', 'cardio', 'hiit'],
      1: ['wall pilates', 'cardio', 'hiit', 'wall pilates', 'cardio'],
      2: ['hiit', 'wall pilates', 'cardio', 'hiit', 'wall pilates'],
      3: ['cardio', 'hiit', 'wall pilates', 'cardio', 'hiit'],
      4: ['wall pilates', 'cardio', 'hiit', 'wall pilates', 'cardio'],
      5: ['cardio', 'hiit', 'wall pilates', 'cardio', 'hiit'],
      6: ['wall pilates', 'cardio', 'hiit', 'wall pilates', 'cardio'],
      7: ['hiit', 'wall pilates', 'cardio', 'hiit', 'wall pilates'],
    },
    6: {
      0: ['cardio', 'hiit', 'wall pilates', 'cardio', 'hiit', 'wall pilates'],
      1: ['cardio', 'hiit', 'wall pilates', 'cardio', 'hiit', 'wall pilates'],
      2: ['cardio', 'hiit', 'wall pilates', 'cardio', 'hiit', 'wall pilates'],
      3: ['cardio', 'hiit', 'wall pilates', 'cardio', 'hiit', 'wall pilates'],
      4: ['cardio', 'hiit', 'wall pilates', 'cardio', 'hiit', 'wall pilates'],
      5: ['cardio', 'hiit', 'wall pilates', 'cardio', 'hiit', 'wall pilates'],
      6: ['cardio', 'hiit', 'wall pilates', 'cardio', 'hiit', 'wall pilates'],
      7: ['cardio', 'hiit', 'wall pilates', 'cardio', 'hiit', 'wall pilates'],
    },
    7: {
      0: [
        'cardio',
        'hiit',
        'wall pilates',
        'cardio',
        'hiit',
        'wall pilates',
        'cardio',
      ],
      1: [
        'hiit',
        'wall pilates',
        'cardio',
        'hiit',
        'wall pilates',
        'cardio',
        'hiit',
      ],
      2: [
        'wall pilates',
        'cardio',
        'hiit',
        'wall pilates',
        'cardio',
        'hiit',
        'wall pilates',
      ],
      3: [
        'cardio',
        'hiit',
        'wall pilates',
        'cardio',
        'hiit',
        'wall pilates',
        'cardio',
      ],
      4: [
        'hiit',
        'wall pilates',
        'cardio',
        'hiit',
        'wall pilates',
        'cardio',
        'hiit',
      ],
      5: [
        'wall pilates',
        'cardio',
        'hiit',
        'wall pilates',
        'cardio',
        'hiit',
        'wall pilates',
      ],
      6: [
        'cardio',
        'hiit',
        'wall pilates',
        'cardio',
        'hiit',
        'wall pilates',
        'cardio',
      ],
      7: [
        'hiit',
        'wall pilates',
        'cardio',
        'hiit',
        'wall pilates',
        'cardio',
        'hiit',
      ],
    },
  },
  'Wonderland of Wellness': {
    1: {
      // 1 day a week, for 8 weeks
      0: ['wall pilates'],
      1: ['booty'],
      2: ['cardio'],
      3: ['upper body stretch, lower body stretch'],
      4: ['wall pilates'],
      5: ['booty'],
      6: ['cardio'],
      7: ['upper body stretch, lower body stretch'],
    },
    2: {
      0: ['wall pilates', 'booty'],
      1: ['cardio', 'upper body'],
      2: ['upper body stretch, lower body stretch', 'wall pilates'],
      3: ['booty, legs', 'cardio'],
      4: ['total body', 'upper body stretch, lower body stretch'],
      5: ['wall pilates', 'booty'],
      6: ['cardio', 'upper body'],
      7: ['upper body stretch, lower body stretch', 'wall pilates'],
    },
    3: {
      0: ['wall pilates', 'booty', 'cardio'],
      1: ['upper body', 'lower body stretch', 'wall pilates'],
      2: ['booty, legs', 'hiit', 'upper body'],
      3: ['upper body stretch', 'wall pilates', 'booty'],
      4: ['cardio', 'upper body', 'upper body stretch, lower body stretch'],
      5: ['wall pilates', 'booty, legs', 'hiit'],
      6: [
        'upper body',
        'upper body stretch, lower body stretch',
        'wall pilates',
      ],
      7: ['booty', 'cardio', 'upper body'],
    },
    4: {
      0: ['wall pilates', 'booty', 'cardio', 'upper body'],
      1: ['lower body stretch', 'wall pilates', 'booty, legs', 'hiit'],
      2: ['upper body', 'upper body stretch', 'wall pilates', 'booty'],
      3: [
        'cardio',
        'upper body',
        'upper body stretch, lower body stretch',
        'wall pilates',
      ],
      4: [
        'booty, legs',
        'hiit',
        'upper body',
        'upper body stretch, lower body stretch',
      ],
      5: [
        'wall pilates',
        'cardio',
        'upper body',
        'upper body stretch, lower body stretch',
      ],
      6: ['wall pilates', 'booty', 'hiit', 'upper body'],
      7: [
        'upper body stretch, lower body stretch',
        'wall pilates',
        'booty, legs',
        'cardio',
      ],
    },
    5: {
      0: [
        'wall pilates',
        'booty',
        'cardio',
        'upper body',
        'lower body stretch',
      ],
      1: ['wall pilates', 'booty', 'hiit', 'upper body', 'upper body stretch'],
      2: [
        'wall pilates',
        'booty, legs',
        'cardio',
        'upper body',
        'upper body stretch, lower body stretch',
      ],
      3: ['wall pilates', 'booty', 'hiit', 'upper body', 'lower body stretch'],
      4: [
        'wall pilates',
        'booty, legs',
        'cardio',
        'upper body',
        'upper body stretch',
      ],
      5: [
        'wall pilates',
        'booty',
        'hiit',
        'upper body',
        'upper body stretch, lower body stretch',
      ],
      6: [
        'wall pilates',
        'booty, legs',
        'cardio',
        'upper body',
        'lower body stretch',
      ],
      7: ['wall pilates', 'booty', 'hiit', 'upper body', 'upper body stretch'],
    },
    6: {
      0: [
        'wall pilates',
        'booty',
        'cardio',
        'upper body',
        'abs',
        'lower body stretch',
      ],
      1: [
        'wall pilates',
        'booty, legs',
        'hiit',
        'upper body',
        'planks',
        'upper body stretch',
      ],
      2: [
        'wall pilates',
        'booty',
        'cardio',
        'upper body',
        'abs',
        'upper body stretch, lower body stretch',
      ],
      3: [
        'wall pilates',
        'booty, legs',
        'hiit',
        'upper body',
        'planks',
        'lower body stretch',
      ],
      4: [
        'wall pilates',
        'booty',
        'cardio',
        'upper body',
        'abs',
        'upper body stretch',
      ],
      5: [
        'wall pilates',
        'booty, legs',
        'hiit',
        'upper body',
        'planks',
        'upper body stretch, lower body stretch',
      ],
      6: [
        'wall pilates',
        'booty',
        'cardio',
        'upper body',
        'abs',
        'lower body stretch',
      ],
      7: [
        'wall pilates',
        'booty, legs',
        'hiit',
        'upper body',
        'planks',
        'upper body stretch',
      ],
    },
    7: {
      0: [
        'wall pilates',
        'booty',
        'cardio',
        'upper body',
        'abs',
        'hiit',
        'lower body stretch',
      ],
      1: [
        'wall pilates',
        'booty, legs',
        'cardio',
        'upper body',
        'planks',
        'hiit',
        'upper body stretch',
      ],
      2: [
        'wall pilates',
        'booty',
        'cardio',
        'upper body',
        'abs',
        'hiit',
        'upper body stretch, lower body stretch',
      ],
      3: [
        'wall pilates',
        'booty, legs',
        'cardio',
        'upper body',
        'planks',
        'hiit',
        'lower body stretch',
      ],
      4: [
        'wall pilates',
        'booty',
        'cardio',
        'upper body',
        'abs',
        'hiit',
        'upper body stretch',
      ],
      5: [
        'wall pilates',
        'booty, legs',
        'cardio',
        'upper body',
        'planks',
        'hiit',
        'upper body stretch, lower body stretch',
      ],
      6: [
        'wall pilates',
        'booty',
        'cardio',
        'upper body',
        'abs',
        'hiit',
        'lower body stretch',
      ],
      7: [
        'wall pilates',
        'booty, legs',
        'cardio',
        'upper body',
        'planks',
        'hiit',
        'upper body stretch',
      ],
    },
  },
  'Bendy Body Boss': {
    1: {
      // 1 day a week, for 8 weeks
      0: ['wall pilates'],
      1: ['lower body stretch'],
      2: ['upper body stretch'],
      3: ['wall pilates'],
      4: ['lower body stretch'],
      5: ['upper body stretch'],
      6: ['wall pilates'],
      7: ['upper body stretch, lower body stretch'],
    },
    2: {
      0: ['wall pilates', 'lower body stretch'],
      1: ['upper body stretch', 'wall pilates'],
      2: ['upper body stretch, lower body stretch', 'wall pilates'],
      3: ['lower body stretch', 'upper body stretch'],
      4: ['wall pilates', 'lower body stretch'],
      5: ['upper body stretch', 'wall pilates'],
      6: ['upper body stretch, lower body stretch', 'wall pilates'],
      7: ['upper body stretch, lower body stretch', 'wall pilates'],
    },
    3: {
      0: ['wall pilates', 'lower body stretch', 'upper body stretch'],
      1: ['wall pilates', 'lower body stretch', 'upper body stretch'],
      2: ['wall pilates', 'lower body stretch', 'upper body stretch'],
      3: ['wall pilates', 'lower body stretch', 'upper body stretch'],
      4: ['wall pilates', 'lower body stretch', 'upper body stretch'],
      5: ['wall pilates', 'lower body stretch', 'upper body stretch'],
      6: ['wall pilates', 'lower body stretch', 'upper body stretch'],
      7: ['wall pilates', 'lower body stretch', 'upper body stretch'],
    },
    4: {
      0: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
      ],
      1: [
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
      ],
      2: [
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
      3: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
      ],
      4: [
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
      ],
      5: [
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
      6: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
      ],
      7: [
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'upper body stretch, lower body stretch',
      ],
    },
    5: {
      0: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
      ],
      1: [
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
      ],
      2: [
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
      3: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
      ],
      4: [
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
      ],
      5: [
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
      6: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
      ],
      7: [
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
      ],
    },
    6: {
      0: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
      1: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
      2: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
      3: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
      4: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
      5: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
      6: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
      7: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
    },
    7: {
      0: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
      ],
      1: [
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
      ],
      2: [
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
      3: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
      ],
      4: [
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
      ],
      5: [
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
      ],
      6: [
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
      ],
      7: [
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
        'upper body stretch',
        'wall pilates',
        'lower body stretch',
      ],
    },
  },
};

export const SALE_OPTIONS = [
  {
    title: 'Never Do The Same Workout Twice',
    description:
      "Mathematically, you have a better chance of getting struck by lightning than to ever repeat the same workout twice in the same order. Let's take our chest category alone for an example we have 81 total chest workouts, and on beginner level you will do 5 workouts. Permutation of 81 exercises while performing 5 at a time is equal to 81x80x79x78x77= 3,074,591,520 unique ways. (This claim holds true for all categories besides Cardio & HIIT)",
    icon: '/assets/sales/never_same.svg',
  },
  {
    title: 'Hide Ads',
    description: 'Enjoy a completely ad-free experience',
    icon: '/assets/sales/hide_ad.svg',
  },
];

export const CHOOSE_PLANS = {
  'Curvy Body Bootycamp':
    'Get Unlimited Access To Your Curvy Body Bootycamp Plan!',
  'ToneTastic Trek': 'Get Unlimited Access To Your ToneTastic Trek Plan!',
  'PoundPlummet Path': 'Get Unlimited Access To Your PoundPlummet Path Plan!',
  'Wonderland of Wellness':
    'Get Unlimited Access To Your Wonderland of Wellness Plan!',
  'Bendy Body Boss': 'Get Unlimited Access To Your Bendy Body Boss Plan!',
};

export const WEB_CHOOSE_PLANS = {
  'Curvy Body Bootycamp': 'get_access_curvy_booty_mobile',
  'ToneTastic Trek': 'get_access_tonetastic_mobile',
  'PoundPlummet Path': 'get_access_poundplummet_mobile',
  'Wonderland of Wellness': 'get_access_wonderland_well_mobile',
  'Bendy Body Boss': 'get_access_bendy_body_mobile',
};

export const SUB_OPTIONS_NO_ADS: SubOption[] = [
  {
    checked: false,
    label: 'BEST VALUE',
    name: AndroidSubscriptionProducts['com.lifebuddy.12monthslb'],
    iosName: 'com.lifebuddy.12monthslb',
    period: 'months',
    time: '12',
    subPeriod: 'yearly',
    finalCost: '4.17',
    cost: '49.99',
    discount: 58,
    currencyCode: 'USD',
    pricingTable: yearly,
  },
  {
    time: '6',
    subPeriod: 'monthly',
    period: 'months',
    name: AndroidSubscriptionProducts['com.lifebuddy.6monthslb'],
    iosName: 'com.lifebuddy.6monthslb',
    label: 'MOST POPULAR',
    checked: true,
    finalCost: '5.83',
    cost: '34.99',
    discount: 42,
    currencyCode: 'USD',
    pricingTable: sixmonthly,
  },
  {
    time: '1',
    subPeriod: 'monthly',
    period: 'month',
    name: AndroidSubscriptionProducts['com.lifebuddy.1monthlb'],
    iosName: 'com.lifebuddy.1monthlb',
    checked: false,
    finalCost: '',
    cost: '9.99',
    discount: 0,
    currencyCode: 'USD',
    pricingTable: monthly,
  },
];

export const userDesiredResultsData = [
  {
    title: 'Curvy Body Bootycamp',
    description: 'curvy_body_desc',
    id: 0,
    thumbnail: 'assets/goals/thumbnails/legs.jpg',
    salesVideo: '',
    // videoTitle: "Joe's Insane Success Story",
    // videoDescription:
    //   'In just 56 days, Joe achieved incredible muscle growth, lost weight, improved his flexibility, and enhanced his cardiovascular health using our wellness program.',
    // goalVideo: "https://drive.google.com/uc?export=download&id=1dSINIC2nH4C_H3hUCrt2czECXSdjoLYK", //"assets/goals/goal_videos/overallhealthwellness.mp4",
    goalVideo:
      'https://storage.googleapis.com/home-workout-1ad2d.appspot.com/thumbnails/High%20Pull%20Lateral%20Raise%20From%20Floor.mp4?alt=media&token=c125895b-b72e-4c18-b7f2-238a9cc6402a',
    usersJoined: '120K+ users joined',
    avatars: ['avatar1.jpg', 'avatar2.jpg', 'avatar16.jpg'],
    goalThumbnail: 'assets/goals/thumbnails/curvy_body_bootycamp.jpg',
  },
  {
    title: 'ToneTastic Trek',
    description: 'tonetastic_desc',
    id: 1,
    thumbnail: 'assets/goals/thumbnails/tonetastic_trek.jpg',
    salesVideo: '',
    // videoTitle: 'Gavin Gained 16 Pounds',
    // videoDescription:
    //   'In just 112 days, our muscle-building program helped Gavin gain an impressive 16 pounds.',
    // goalVideo: "assets/goals/goal_videos/buildmuscle.mp4",
    goalVideo:
      'https://storage.googleapis.com/home-workout-1ad2d.appspot.com/videos/CnB_Military_Push_Ups_10.mp4?alt=media&token=ed0c92e6-6120-4474-ae3d-ed3c6d67eb3e',
    usersJoined: '90K+ users joined',
    avatars: ['avatar4.jpg', 'avatar5.jpg', 'avatar6.jpg'],
    goalThumbnail: 'assets/goals/thumbnails/tonetastic_trek.jpg',
  },
  {
    title: 'PoundPlummet Path',
    description: 'poundplummet_desc',
    id: 2,
    thumbnail: 'assets/goals/thumbnails/poundplummet_path.jpg',
    salesVideo: '',
    // videoTitle: 'Anurag Lost 26.5 Pounds',
    // videoDescription:
    //   'In just 112 days, our weight loss program helped Anurag lose an impressive 26.5 pounds.',
    // goalVideo: "assets/goals/goal_videos/loseweight.mp4",
    goalVideo:
      'https://storage.googleapis.com/home-workout-1ad2d.appspot.com/videos/March%20%26%20Clap.mp4?alt=media&token=18943e19-6a4d-4726-a1d3-15c971aebf37',
    usersJoined: '75K+ users joined',
    avatars: ['avatar7.jpg', 'avatar8.jpg', 'avatar9.jpg'],
    goalThumbnail: 'assets/goals/thumbnails/poundplummet_path.jpg',
  },
  {
    title: 'Wonderland of Wellness',
    description: 'wonderland_desc',
    id: 3,
    thumbnail: 'assets/goals/thumbnails/wonderland_of_wellness.jpg',
    salesVideo: '',
    // videoTitle: 'Anurag Lost 26.5 Pounds',
    // videoDescription:
    //   'In merely 112 days, Anurag shed an astonishing 26.5 pounds and sculpted a remarkable six-pack, all thanks to our specialized program.',
    // goalVideo: "assets/goals/goal_videos/6pack.mp4",
    goalVideo:
      'https://storage.googleapis.com/home-workout-1ad2d.appspot.com/videos/The%20Kobe%20Crunch.mp4?alt=media&token=c65da558-9ac2-42af-8304-94774a2a9f32',
    usersJoined: '60K+ users joined',
    avatars: ['avatar10.jpg', 'avatar11.jpg', 'avatar12.jpg'],
    goalThumbnail: 'assets/goals/thumbnails/wonderland_of_wellness.jpg',
  },
  {
    title: 'Bendy Body Boss',
    description: 'bendy_desc',
    id: 4,
    thumbnail: 'assets/goals/thumbnails/bendy_body_boss.jpg',
    salesVideo: '',
    // videoTitle: "Joe's Insane Success Story",
    // videoDescription:
    //   'In just 56 days, Joe significantly increased his flexibility, which strengthened his muscles and joints, while also helping to alleviate stress and tension.',
    // goalVideo: "assets/goals/goal_videos/flexibility.mp4",
    goalVideo:
      'https://storage.googleapis.com/home-workout-1ad2d.appspot.com/videos/Deep%20Lunge.mp4?alt=media&token=228042df-3888-4217-9ebf-ce79a0248232',
    usersJoined: '12K+ users joined',
    avatars: ['avatar13.jpg', 'avatar14.jpg', 'avatar15.jpg'],
    goalThumbnail: 'assets/goals/thumbnails/bendy_body_boss.jpg',
  },
];

export const RECCOMMENDED_ITEMS: RecItem[] = [
  {
    id: 'homeworkout',
    icon: 'assets/home-workout-icon.png',
    title: 'Home Workout',
    description:
      'Stay fit on the go! Download our app for quick, effective no-equipment workouts.',
    bundleId:
      Capacitor.getPlatform() == 'ios'
        ? 'homeworkout://'
        : 'com.lifebuddy.homeworkout',
    iosLink:
      'https://apps.apple.com/us/app/home-workout-fitness-no-tools/id6471418680?ls=1',
  },
];
