import { AfterContentInit, Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CameraSource } from '@capacitor/camera';
import { Keyboard } from '@capacitor/keyboard';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import {
  LoadingController,
  ModalController,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { CurrentUserWeight } from 'src/app/models/user';
import { AchievementService } from 'src/app/services/achievement.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { CameraService } from '../../services/camera.service';
import { LoggerService } from '../../services/logger.service';
import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/user.service';
import { WeightProgressService } from '../../services/weight-progress.service';
import { CustomLoaderComponent } from '../custom-loader/custom-loader.component';
@Component({
  selector: 'app-weight-crud',
  templateUrl: './weight-crud.component.html',
  styleUrls: ['./weight-crud.component.scss'],
})
export class WeightCrudComponent implements AfterContentInit {
  @Input() title: string;
  @Input() date: string;
  @Input() id: string;
  @Input() weightPound: number;
  @Input() weightKg: number;
  @Input() imageUrl: string;
  @Input() filePath: any;
  @Input() currentUserWeight: CurrentUserWeight;

  language: any;

  sanitizedImage: SafeUrl;

  currentSegment: string;
  // maxDate: string;

  isoDate: string;

  // day: string;
  // month: string;
  // year: string;
  // yearValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

  constructor(
    private modalController: ModalController,
    public userService: UserService,
    private cameraService: CameraService,
    private loadingController: LoadingController,
    private loggerService: LoggerService,
    private notificationService: NotificationService,
    private popoverController: PopoverController,
    private webView: WebView,
    private domSanitizer: DomSanitizer,
    private weightProgressService: WeightProgressService,
    private platform: Platform,
    private achServ: AchievementService,
    private translateConfigServ: TranslateConfigService
  ) {
    this.language = this.translateConfigServ.getCurrentLang();
    // this.maxDate = moment().toISOString();
    const user = this.userService.getSyncUserOnce();
    this.currentSegment = user && user.metric ? user.metric : 'us';

    if (this.filePath) {
      try {
        this.sanitizedImage = this.domSanitizer.bypassSecurityTrustUrl(
          this.webView.convertFileSrc(this.filePath)
        );
      } catch (error) {
        this.loggerService.logError(error);
      }
    }
  }

  ngAfterContentInit() {
    if (this.filePath) {
      try {
        this.sanitizedImage = this.domSanitizer.bypassSecurityTrustUrl(
          this.webView.convertFileSrc(this.filePath)
        );
      } catch (error) {
        this.loggerService.logError(error);
      }
    }
    const date = new Date(this.date);
    // date.setFullYear(date.getDate());
    this.isoDate = date.toISOString();
    // this.month = this.date;
    // this.year = this.date;

    // console.log(this.day);
  }

  segmentChanged(ev: string) {
    this.currentSegment = ev;
  }

  weightMetricOrUs() {
    if (this.currentSegment === 'us') {
      this.weightKg = +(+this.weightPound * 0.45359237).toFixed(1);
    } else {
      this.weightPound = +(+this.weightKg * 2.20462262).toFixed(1);
    }
  }

  async hideKeyboard() {
    if (!this.platform.is('capacitor')) {
      return;
    }
    await Keyboard.hide();
  }

  async dismiss() {
    return this.modalController.dismiss().catch(() => {});
  }

  takePhoto() {
    this.cameraService
      .getPhoto(CameraSource.Camera, false)
      .then((img: string) => {
        this.imageUrl = img;
        this.filePath = '';
      })
      .catch((error) => {
        this.loggerService.logError(error);
      });
  }

  ionViewWillEnter() {
    if (this.platform.is('ios') && this.platform.is('capacitor')) {
      Keyboard.setAccessoryBarVisible({ isVisible: false });
    }
  }

  ionViewWillLeave() {
    if (this.platform.is('ios') && this.platform.is('capacitor')) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }
  }

  async moveCursoreToEnd(input, formControlName) {
    try {
      const placeholder = `${input.placeholder}`;
      const val = `${input.value}`;

      input.placeholder = '';
      this[formControlName] = '';

      setTimeout(() => {
        this[formControlName] = +val;
        input.placeholder = placeholder;
      }, 0);
    } catch (error) {
      console.log(error);
    }
  }

  removePhoto() {
    this.imageUrl = '';
    this.filePath = '';
  }

  validateForm() {
    let msg = '';
    if (
      this.currentSegment === 'us' ? this.weightPound < 22 : this.weightKg < 10
    ) {
      msg = `Your weight cannot be less then ${
        this.currentSegment === 'us' ? 22 + ' lbs' : 10 + ' kg'
      }`;
    }
    if (
      this.currentSegment === 'us'
        ? this.weightPound > 440
        : this.weightKg > 200
    ) {
      msg = `Please enter weight less then or equal to ${
        this.currentSegment === 'us' ? 440 + ' lbs' : 200 + ' kg'
      }`;
    }
    if (msg) {
      this.notificationService.showError(msg);
    }
    return msg;
  }

  async submit() {
    if (this.validateForm()) {
      return;
    }
    let controller: HTMLIonPopoverElement | HTMLIonLoadingElement;
    if (this.imageUrl && this.imageUrl.startsWith('data:image')) {
      controller = await this.popoverController.create({
        component: CustomLoaderComponent,
        keyboardClose: false,
        backdropDismiss: false,
        componentProps: {
          local: !this.userService.networkStatus,
        },
      });
    } else {
      controller = await this.loadingController.create();
    }

    await controller.present();
    try {
      const pounds = +this.weightPound.toFixed(1);
      const response = await this.weightProgressService
        .addWeightItem({
          id: this.id,
          date: this.date,
          weightPound: pounds,
          weightKg: +this.weightKg.toFixed(1),
          imageUrl: this.imageUrl || '',
        })
        .then(async (res) => {
          if (this.imageUrl) {
            await this.achServ.updateProgressPhotoAchievement();
          }

          await this.achServ.updateWeightLossAchievements(
            Math.max(this.currentUserWeight.startWeightPound - pounds, 0)
          );
          return res;
        });
      await controller.dismiss();
      await this.modalController.dismiss(response).catch(() => {});
    } catch (error) {
      if (error.code === 'storage/canceled') {
        await controller.dismiss();
        return;
      }
      this.notificationService.showError(
        'Oops! Something went wrong during add weight. Please try again later.'
      );
      this.loggerService.logError(error);
      await controller.dismiss();
    }
  }

  updateDate(event) {
    console.log(event, this.isoDate);
    this.date = new Date(event.detail.value).toString();
  }

  // updateValues(day, month, year) {
  //   console.log(day, month, year, this.day, this.month, this.year);
  //   this.day = day;
  //   this.month = month;
  //   this.year = year;

  //   const dayDate = new Date(this.day);
  //   const monthDate = new Date(this.month);
  //   const yearDate = new Date(this.year);

  //   this.date = moment().date(dayDate.getFullYear()).month(monthDate.getMonth()).year(yearDate.getFullYear()).toISOString(true);
  // }
}
