import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.scss'],
})
export class CustomLoaderComponent {

  @Input() local: boolean;

  constructor(
    private userService: UserService,
    private popoverController: PopoverController
  ) { }

  cancel() {
    try {
      this.userService.uploadRef.cancel();
    } catch (error) {
      this.popoverController.dismiss().catch(() => { });
    }
  }
}
