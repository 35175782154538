import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { BottomSheetComponent, SheetStates } from "../../shared/bottom-sheet/bottom-sheet-component";

@Component({
  selector: "app-radio-select",
  templateUrl: "./radio-select.component.html",
  styleUrls: ["./radio-select.component.scss"],
})
export class RadioSelectComponent {
  @ViewChild("bottomSheetRadio", { static: true }) bottomSheetRadio: BottomSheetComponent;
  @Output() modalSelect: EventEmitter<string | Array<{ name: string; value: string; isChecked?: boolean }>> = new EventEmitter();
  @Input() theme = "dark";

  selectedItem: string;
  title: string;
  isRadio: boolean;
  options: Array<{ name: string; value: string; isChecked?: boolean }>;

  constructor() {}

  public openModal(
    selectedItem: string,
    options: Array<{ name: string; value: string; isChecked?: boolean }>,
    title: string,
    isRadio = true
  ) {
    this.selectedItem = selectedItem;
    this.options = options;
    this.title = title;
    this.isRadio = isRadio;
    if (this.bottomSheetRadio) {
      setTimeout(() => {
        this.bottomSheetRadio.setState(SheetStates.Opened);
      }, 100);
    } else {
      setTimeout(() => {
        this.openModal(selectedItem, options, title, isRadio);
      }, 150);
    }
  }

  cancel() {
    this.modalSelect.emit("");
    this.bottomSheetRadio.setState(SheetStates.Closed);
  }

  submit() {
    if (this.isRadio) {
      this.modalSelect.emit(this.selectedItem);
    } else {
      this.modalSelect.emit(this.options);
    }
    this.bottomSheetRadio.setState(SheetStates.Closed);
  }
}
