<ion-header mode="ios"></ion-header>
<ion-content fullscreen="true" class="ion-text-center">
  <div class="header-container">
    <ion-toolbar mode="ios">
      <!-- <ion-title color="light">{{"login" | translate}}</ion-title> -->
      <ion-buttons slot="start" style="height: 100%">
        <ion-button
          *ngIf="email == ''"
          mode="ios"
          (click)="close(false)"
          fill="clear"
        >
          <ion-icon
            slot="icon-only"
            src="assets/newUI/icons/backBtnBlack.svg"
          ></ion-icon>
        </ion-button>
        <!-- <ion-back-button text mode="ios" icon="assets/newUI/icons/backBtn.svg" defaultHref="/onboarding"></ion-back-button> -->
      </ion-buttons>
    </ion-toolbar>
  </div>

  <div class="content-container">
    <!-- <img class="logo" draggable="false" src="assets/newUI/appLogo.png" /> -->

    <form
      [formGroup]="authForm"
      style="display: flex; flex-direction: column; gap: 4rem; padding: 2rem 0"
    >
      <h2
        style="
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          line-height: 42px;
        "
      >
        {{pageTitle | translate}}
      </h2>
      <div class="grid-container">
        <div class="grid-row">
          <div
            class="field-group"
            inViewport
            [inViewportOptions]="{threshold: [0]}"
            [class.disabled]="email != ''"
          >
            <input
              required
              id="email"
              formControlName="email"
              (keyup.enter)="passwordTab.focus()"
              #emailTab
              type="email"
              class="field-control"
            />
            <label
              class="field-label"
              [class.active-field-control]="authForm.get('email').value"
              for="email"
            >
              {{"email" | translate}}
            </label>
          </div>
        </div>

        <div class="grid-row" style="padding: 0 1rem">
          <div
            class="field-group"
            inViewport
            [inViewportOptions]="{threshold: [0]}"
          >
            <input
              required
              id="password"
              formControlName="password"
              (keyup.enter)="onSubmit(passwordTab, emailTab)"
              #passwordTab
              [type]="showHidePassword ? 'password' : 'text'"
              class="field-control"
            />
            <label
              class="field-label"
              [class.active-field-control]="authForm.get('password').value"
              for="password"
            >
              {{"password" | translate}}
            </label>

            <ion-icon
              class="field-icon"
              (click)="toggleShowHidePassword()"
              [src]="showHidePassword ? 'assets/newUI/icons/close-eye.svg' : 'assets/newUI/icons/open-eye.svg'"
            ></ion-icon>
          </div>
        </div>

        <div class="grid-row">
          <div class="forgot-password" (click)="openForgotPassword()">
            {{"forgot_password" | translate}}
          </div>
        </div>
      </div>
    </form>

    <div class="grid-row" style="padding: 1rem">
      <ion-button
        class="submit"
        (click)="onSubmit(passwordTab, emailTab)"
        expand="full"
        mode="ios"
        fill="clear"
      >
        {{"log in" | translate}}
      </ion-button>
    </div>

    <!-- <div class="other-options">
      {{"dont_have_account" | translate}}
      <span routerLink="/sign-up" routerDirection="forward" style="padding: 1rem 0"> {{"sign_up" | translate}} </span>
    </div> -->
  </div>
</ion-content>
