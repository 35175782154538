import { Component, Input, OnDestroy } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { HelperService } from './../../services/helper.service';

import { Subscription } from 'rxjs';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.page.html',
  styleUrls: ['./privacy-policy.page.scss'],
})
export class PrivacyPolicyPage implements OnDestroy {
  @Input() modal = false;

  backButtonRef: Subscription;

  constructor(
    public subscriptionService: SubscriptionService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private helperServ: HelperService,
    private platform: Platform
  ) {
    this.backButtonRef = this.platform.backButton.subscribeWithPriority(
      500,
      () => {
        this.destoryBackButton();
        this.goBack();
      }
    );
  }

  ngOnDestroy(): void {
    // this.backButtonRef.unsubscribe();
  }

  destoryBackButton() {
    if (this.backButtonRef) {
      this.backButtonRef.unsubscribe();
    }
  }

  mailTo() {
    this.helperServ.sendBugEmail();
  }

  goBack() {
    if (this.modal) {
      this.modalCtrl.dismiss();
    } else {
      this.navCtrl.back();
    }
  }
}
