<div class="onboarding-slide-container no-footer">
  <h1 class="onboarding-page-title">
    {{ "Choose the body you want" | translate }}
  </h1>
  <div class="segment-container">
    <div
      *ngFor="let goal of goalBodyTypes"
      class="segment-btn"
      [class.active-segment]="goalBodyControl?.value === goal"
      (click)="segmentChanged(goal)"
    >
      <div class="segment-meta">
        <ul class="segment-ul-container">
          <li class="segment-title">
            <span>{{ bodyTypes[goal].title | translate }}</span>
          </li>
        </ul>
        <div style="display: flex; align-items: flex-start; height: 100%">
          <img
            [src]="'assets/newUI/body_types/' + bodyTypes[goal].icon + '.png'"
            style="
              margin-top: -8px;
              max-width: 118px;
              pointer-events: none;
              min-height: 150%;
              min-width: 118px;
              object-fit: cover;
              object-position: top;
            "
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <ion-button *ngIf="nextBtn" expand="full" mode="ios" class="onboarding-next-btn" (click)="nextSlide()" fill="clear">
  {{ "Next" | translate }}
</ion-button> -->
