<div *ngIf="showing" class="banner">
  <span *ngIf="type == 1" class="text bold">
    <span *ngIf="!saleConfig.duration">{{ saleHours }} Hour Flash Sale </span>
    <span *ngIf="saleConfig.duration">Today Only</span>
    {{ subscriptionServ.salePercentage | number: "1.0-0" }}% Off - ENDS IN {{ countdown?.hours | number: "2.0" }}:{{
      countdown?.minutes | number: "2.0"
    }}:{{ countdown?.seconds | number: "2.0" }}
  </span>
  <span *ngIf="type == 2" class="text bold small">
    {{ subscriptionServ.salePercentage | number: "1.0-0" }}% OFF ALL PLANS AT CHECKOUT - ENDS IN {{ countdown?.hours | number: "2.0" }}:{{
      countdown?.minutes | number: "2.0"
    }}:{{ countdown?.seconds | number: "2.0" }}
  </span>
</div>
