import { Injectable, ErrorHandler } from '@angular/core';

// import * as Sentry from 'sentry-cordova';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService extends ErrorHandler {

  constructor() {
    super();
  }

  logError(error: any, message = 'LoggerService logError()') {
    super.handleError(error);

    if (environment.production) {
      try {
        // Sentry.addBreadcrumb({
        //   category: 'log',
        //   message,
        //   data: { error },
        //   level: Sentry.Severity.Error
        // });

        // Sentry.captureException(error.originalError || error);
      } catch (e) {
        console.error('LoggerService [ ERROR ]', e);
      }
    }
  }
}
