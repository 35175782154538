import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Workout } from 'src/app/models/plan';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import {
  BottomSheetComponent,
  SheetStates,
} from 'src/app/shared/bottom-sheet/bottom-sheet-component';

interface Option {
  name: string;
  value: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  index?: number;
}

@Component({
  selector: 'app-workout-select',
  templateUrl: './workout-select.component.html',
  styleUrls: ['./workout-select.component.scss'],
})
export class WorkoutSelectComponent {
  @ViewChild('bottomSheetRadio', { static: true })
  bottomSheetRadio: BottomSheetComponent;
  @Output() modalSelect: EventEmitter<{
    categories: string[];
    workout: Workout;
  }> = new EventEmitter();
  @Input() theme = 'dark';

  language: any;

  title: string;
  options: Option[];
  selectedOptions: Option[];
  isDisabledSelect: boolean;

  workout: Workout;

  loading: boolean = false;

  constructor(private translateConfigServ: TranslateConfigService) {
    this.language = this.translateConfigServ.getCurrentLang();

    this.isDisabledSelect = true;
  }

  public openModal(options: Option[], workout: Workout, title: string) {
    console.log('openModal', options);
    this.selectedOptions = [];
    this.options = options;
    this.workout = workout;
    this.initSelected(options);
    this.title = title;

    if (this.bottomSheetRadio) {
      setTimeout(() => {
        this.bottomSheetRadio.setState(SheetStates.Opened);
      }, 100);
    } else {
      setTimeout(() => {
        this.openModal(options, workout, title);
      }, 150);
    }
  }

  initSelected(options: Option[]) {
    for (const option of options) {
      if (option.isChecked) {
        this.selectedOptions.push(option);
        option.index = this.selectedOptions.length;
      }
    }

    this.isDisabled();
    this.isWorkoutsDisabled();
    this.checkISDisabledSelectBtn();
  }

  cancel() {
    this.modalSelect.emit(null);
    this.bottomSheetRadio.setState(SheetStates.Closed);
  }

  async submit() {
    this.loading = true;
    const resultOptions = this.selectedOptions.map((option) => option.value);

    this.modalSelect.emit({ categories: resultOptions, workout: this.workout });
    await this.bottomSheetRadio.setState(SheetStates.Closed);

    this.selectedOptions = [];
    this.loading = false;
  }

  isDisabled() {
    for (const elem of this.options) {
      const index = this.selectedOptions.indexOf(elem);

      const isTotalBodySelected = this.selectedOptions.find(
        (e) => e.value === 'total body'
      );

      const isCardioSelected = this.selectedOptions.find(
        (e) => e.value === 'cardio'
      );
      const isHiitSelected = this.selectedOptions.find(
        (e) => e.value === 'hiit'
      );
      const isRestSelected = this.selectedOptions.find(
        (e) => e.value === 'rest'
      );

      const isUpperStretchSelected = this.selectedOptions.find(
        (e) => e.value === 'upper body stretch'
      );
      const isLowerStretchSelected = this.selectedOptions.find(
        (e) => e.value === 'lower body stretch'
      );

      if (isUpperStretchSelected) {
        isUpperStretchSelected.isDisabled = false;
        if (elem.value == 'lower body stretch') {
          elem.isDisabled = false;
        } else {
          elem.isDisabled = true;
        }
      } else if (isLowerStretchSelected) {
        isLowerStretchSelected.isDisabled = false;
        if (elem.value == 'upper body stretch') {
          elem.isDisabled = false;
        } else {
          elem.isDisabled = true;
        }
      } else if (isTotalBodySelected) {
        isTotalBodySelected.isDisabled = false;
        elem.isDisabled = true;
      } else if (isCardioSelected) {
        isCardioSelected.isDisabled = false;
        elem.isDisabled = true;
      } else if (isHiitSelected) {
        isHiitSelected.isDisabled = false;
        elem.isDisabled = true;
      } else if (isRestSelected) {
        isRestSelected.isDisabled = false;
        elem.isDisabled = true;
      } else if (!isTotalBodySelected) {
        if (index < 0 && this.selectedOptions.length === 2) {
          elem.isDisabled = true;
        } else {
          elem.isDisabled = false;
        }
      } else if (!isCardioSelected) {
        if (index < 0 && this.selectedOptions.length === 2) {
          elem.isDisabled = true;
        } else {
          elem.isDisabled = false;
        }
      } else if (!isHiitSelected) {
        if (index < 0 && this.selectedOptions.length === 2) {
          elem.isDisabled = true;
        } else {
          elem.isDisabled = false;
        }
      } else if (!isRestSelected) {
        if (index < 0 && this.selectedOptions.length === 2) {
          elem.isDisabled = true;
        } else {
          elem.isDisabled = false;
        }
      }
    }
  }

  isWorkoutsDisabled() {
    const isAnyOptionActive = this.options.find((option) => option.isChecked);

    const totalBody = this.options.find(
      (option) => option.value === 'total body'
    );
    const cardio = this.options.find((option) => option.value === 'cardio');
    const hiit = this.options.find((option) => option.value === 'hiit');

    const upperStretch = this.options.find(
      (option) => option.value === 'upper body stretch'
    );
    const lowerStretch = this.options.find(
      (option) => option.value === 'lower body stretch'
    );

    const rest = this.options.find((option) => option.value === 'rest');

    if (isAnyOptionActive && isAnyOptionActive.value !== 'total body') {
      totalBody.isDisabled = true;
    } else {
      totalBody.isDisabled = false;
    }

    if (isAnyOptionActive && isAnyOptionActive.value !== 'cardio') {
      cardio.isDisabled = true;
    } else {
      cardio.isDisabled = false;
    }

    if (isAnyOptionActive && isAnyOptionActive.value !== 'hiit') {
      hiit.isDisabled = true;
    } else {
      hiit.isDisabled = false;
    }

    if (
      isAnyOptionActive &&
      isAnyOptionActive.value !== 'upper body stretch' &&
      isAnyOptionActive.value !== 'lower body stretch'
    ) {
      upperStretch.isDisabled = true;
    } else {
      upperStretch.isDisabled = false;
    }

    if (
      isAnyOptionActive &&
      isAnyOptionActive.value !== 'upper body stretch' &&
      isAnyOptionActive.value !== 'lower body stretch'
    ) {
      lowerStretch.isDisabled = true;
    } else {
      lowerStretch.isDisabled = false;
    }

    if (isAnyOptionActive && isAnyOptionActive.value !== 'rest') {
      rest.isDisabled = true;
    } else {
      rest.isDisabled = false;
    }
  }

  onChange(option: Option) {
    const index = this.selectedOptions.indexOf(option);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      option.isChecked = false;
      option.index = null;

      this.updateElemensIndex();
      this.isDisabled();
      this.isWorkoutsDisabled();
    } else if (this.selectedOptions.length !== 2) {
      this.selectedOptions.push(option);
      option.isChecked = true;
      option.index = this.selectedOptions.length;

      this.isDisabled();
      this.isWorkoutsDisabled();
    }
    this.checkISDisabledSelectBtn();
  }

  checkISDisabledSelectBtn() {
    let isChecked = false;
    this.options.forEach((e) => {
      if (e.isChecked) {
        isChecked = true;
      }
    });
    this.isDisabledSelect = !isChecked;
  }

  updateElemensIndex() {
    if (this.selectedOptions.length === 0) {
      return;
    }

    for (const item of this.selectedOptions) {
      item.index = this.selectedOptions.length;
    }
  }
}
