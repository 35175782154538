import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  transform(value: number, format?: 'm' | 's'): any {
    if (value) {
      const minutes = Math.floor(value / 60000);
      const seconds = ((value % 60000) / 1000).toFixed(0);
      if (format === 's') {
        return seconds;
      }
      if (format === 'm') {
        return minutes;
      }

      if (format === 'h') {
        if (minutes > 60) {
          const hours = Math.floor(minutes / 60);
          return hours + ':' + minutes;
        }
        return minutes + ':' + (+seconds < 10 ? '0' : '') + seconds;
      }

      return minutes + ':' + (+seconds < 10 ? '0' : '') + seconds;
    }
    return value;
  }
}
