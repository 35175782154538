import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import * as lodash from 'lodash';
import { TimeoutError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }
    // return error.message
    //   ? error.message
    //   : 'Oops! Something went wrong. Please try again later.';
    return 'Oops! Something went wrong. Please try again later.';
  }

  getClientStack(error: Error): string {
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse | TimeoutError): string {
    if (error instanceof TimeoutError) {
      return 'Oops! Server error. Please try again later.';
    }
    return error &&  error.error && error.error.message
      ? error.error.message
      : error && lodash.has(error, 'status') && (0 === error.status || 500 <= error.status)
        ? 'Oops! Server error. Please try again later.'
        : error.toString() === '[object Object]' ? JSON.stringify(error) : error.toString();
  }

  getServerStack(error: HttpErrorResponse | TimeoutError): string {
    // handle stack trace
    return 'stack';
  }
}
