import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";
import { Achievement } from "src/app/constants/achievements";
import { QUOTES, Quote } from "src/app/constants/quote";
import { OnboardingPageKeys } from "src/app/enums/Onboarding";
import { RadioSelectComponent } from "src/app/modals/radio-select/radio-select.component";
import { Workout } from "src/app/models/plan";
import { AchievementService } from "src/app/services/achievement.service";
import { OnboardingService } from "src/app/services/onboarding.service";
import { PlanService } from "src/app/services/plan.service";
import { TranslateConfigService } from "src/app/services/translate-config.service";
import { UserService } from "src/app/services/user.service";
import { WorkoutDetails, WorkoutService } from "../../services/workout.service";

@Component({
  selector: "app-workout-result",
  templateUrl: "./workout-result.page.html",
  styleUrls: ["./workout-result.page.scss"],
})
export class WorkoutResultPage implements OnDestroy {
  language: any;

  // public appRateVar: boolean = false
  currentTab: "goals" | "session";
  workoutData: WorkoutDetails;

  workoutsCompleted: number;

  quote: Quote;

  achievement: Achievement;

  isLastWorkout = false;

  subscriptions: Subscription[] = [];
  constructor(
    private workoutService: WorkoutService,
    private modalCtrl: ModalController,
    private platform: Platform,
    private planServ: PlanService,
    private achServ: AchievementService,
    private router: Router,
    private onboardingService: OnboardingService,
    private navCtrl: NavController,
    private userServ: UserService,
    private translateConfigServ: TranslateConfigService
  ) {
    this.language = this.translateConfigServ.getCurrentLang();
    this.currentTab = "session";
    this.workoutData = {};

    this.workoutData = this.workoutService.getWorkoutDetails(1);

    this.subscriptions.push(
      this.platform.backButton.subscribeWithPriority(300, (e) => {
        if (this.workoutData.canResume) {
          this.resumeWorkout();
        } else {
          this.openHome();
        }
      })
    );

    this.subscriptions.push(
      this.planServ.plan$.subscribe((workouts: Workout[]) => {
        this.workoutsCompleted = workouts?.filter((workout) => workout?.isCompleted)?.length || 0;

        if (!workouts || !workouts?.length) {
          return [];
        }

        this.isPlanCompleted(workouts);
      })
    );
  }

  ionViewWillEnter() {
    const randomNumber = Math.floor(Math.random() * QUOTES.length);
    this.quote = QUOTES[randomNumber];

    this.achievement = this.achServ.getRelevantMostProgressAchievement();
  }

  ionViewWillLeave() {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  segmentChanged(ev: "goals" | "session") {
    this.currentTab = ev;
  }

  resumeWorkout() {
    this.modalCtrl.dismiss(true).catch(() => {});
  }

  async openHome() {
    this.workoutService.navCtrl.navigateRoot("/home/plan" /* , rated ? {} : { queryParams: { review: true } } */);

    setTimeout(async () => {
      await this.dismiss();
    }, 500);
  }

  async dismiss() {
    await this.modalCtrl.dismiss().catch(() => {});
  }

  shareWorkout() {
    // this.workoutService.shareWorkout();
  }

  openCreatePlan(bottomSheet: RadioSelectComponent) {
    const options = [
      { name: "Create your own plan", value: "own" },
      { name: "Have Lifebuddy create you a plan", value: "lb" },
    ];
    const selectedItem = "own";
    bottomSheet.openModal(selectedItem, options, "Create a plan");
  }

  async modalSelectCreatePlan(event) {
    if (event) {
      this.onboardingService.resetOnboardingForm();
      if (event === "own") {
        this.onboardingService.isCustomePlan = true;
        this.onboardingService.setOnboardingPageKeys({
          difficulty: OnboardingPageKeys.difficulty,
          goalWeight: OnboardingPageKeys.goalWeight,
          desiredResults: OnboardingPageKeys.desiredResults,
        });
        await this.navCtrl.navigateRoot("/onboarding?back=/home/plan");
        await this.dismiss();
      } else if (event === "lb") {
        this.onboardingService.isCustomePlan = false;
        this.onboardingService.setOnboardingPageKeys({
          // days: OnboardingPageKeys.days,
          daysCount: OnboardingPageKeys.daysCount,
          difficulty: OnboardingPageKeys.difficulty,
          currentUserWeight: OnboardingPageKeys.currentUserWeight,
          goalWeight: OnboardingPageKeys.goalWeight,
          desiredResults: OnboardingPageKeys.desiredResults,
          goalBody: OnboardingPageKeys.goalBody,
          bodyFat: OnboardingPageKeys.bodyFat,
          processPlan: OnboardingPageKeys.processPlan,
        });
        await this.navCtrl.navigateRoot("/onboarding?back=/home/plan");
        await this.dismiss();
      }
    }
  }

  isPlanCompleted(workouts: Workout[]) {
    if (workouts && workouts?.length === 0) {
      return;
    }
    const filtered = workouts?.filter((w) => w?.lastWorkout);

    if (filtered?.length && filtered.length === 1 && filtered[0]?.isCompleted) {
      this.isLastWorkout = true;
    }

    if (filtered?.length && filtered.length === 2 && filtered[0]?.isCompleted && filtered[1]?.isCompleted) {
      this.isLastWorkout = true;
    }
  }
}
