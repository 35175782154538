<div *ngIf="waitingLayer" class="waiting-layer">
  <div class="waiting-layer-spinner"></div>
  <p>{{ "Loading" | translate }}...</p>
</div>
<div *ngIf="!isWeb" class="header-container">
  <ion-button
    mode="ios"
    (click)="dismiss()"
    style="
      position: absolute;
      z-index: 10;
      background: rgba(255, 255, 255, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      border-radius: 100px;
      font-size: 14px;
      --padding-end: 0px;
      --padding-start: 0px;
      margin-left: 10px;
    "
    fill="clear"
    color="light"
  >
    <ion-icon slot="icon-only" color="black" name="close"></ion-icon>
  </ion-button>
</div>

<ion-content *ngIf="!isWeb">
  <ng-container *ngTemplateOutlet="saleModalTemplate"></ng-container>
</ion-content>
<div *ngIf="isWeb" id="sale-web-container" class="web-container">
  <ng-container *ngTemplateOutlet="saleModalTemplate"></ng-container>
</div>

<ng-template #saleModalTemplate>
  <div id="pull-area" [class.isWeb]="isWeb">
    <div
      class="img-bg-wrap"
      [class.isWeb]="isWeb"
      inViewport
      [inViewportOptions]="{ threshold: [0] }"
      (inViewportAction)="onInViewport($event)"
    >
      <div
        class="img-bg"
        [style.background-image]="
          'linear-gradient(rgb(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgb(248, 248, 248) 100%)'
        "
        style="display: flex"
      >
        <ion-spinner style="margin: auto; z-index: -1"></ion-spinner>
      </div>
      <video
        poster="assets/sales/paywall_poster.png"
        webkit-playsinline
        [autoplay]="true"
        playsinline
        [muted]="true"
        [loop]="true"
        style="width: 100%"
        [preload]="'auto'"
      >
        <source
          *ngIf="isAndroid"
          [src]="'assets/sales/paywall_video.mp4'"
          type="video/mp4"
        />
      </video>
    </div>
    <div class="user-detail-container ion-margin-vertical">
      <div
        style="
          color: #000;
          font-weight: 700;
          font-size: 26px;
          padding: 0.5rem 0.5rem 1rem;
          text-align: center;
          line-height: 33.5px;
        "
      >
        {{ CHOOSE_PLANS[userPlan] | translate }}
      </div>
    </div>
  </div>

  <div class="subscription">
    <div *ngIf="isWeb" class="sub-header-slides" style="padding-top: 0">
      <swiper
        #screenshotSlider
        [config]="screenshotSlideOpts"
        class="slide-main screenshots"
        style="padding-bottom: 64px"
      >
        <ng-template
          class="screenshot-slide-inner"
          swiperSlide
          *ngFor="let i of [1, 2, 3, 4]"
        >
          <img
            style="width: 200px; pointer-events: none"
            [src]="'assets/sales/app_screenshot_' + i + '.png'"
          />
        </ng-template>
      </swiper>
    </div>

    <div *ngIf="!isWeb" class="sub-options">
      <ng-container *ngFor="let sub of subOptions; let i = index">
        <div
          class="sub-wrap"
          [class.sub-wrap-selected]="sub.checked"
          [class.fade-in-top]="sub.checked"
        >
          <div
            [class.sub-label-active]="sub.checked"
            [class.fade-in-top]="sub.checked"
            class="sub-label"
            *ngIf="sub.label && sub.checked"
          >
            {{ sub.label | translate }}
          </div>
          <div
            (click)="selectSub(sub, i)"
            class="sub"
            [class.sub-selected]="sub.checked"
            [style.padding]="sub.hasTrial ? '8px 6px' : ''"
          >
            <div *ngIf="!sub?.hasTrial">
              <div class="time">{{ sub.time }}</div>
              <div class="period">{{ sub.period | translate }}</div>
            </div>
            <div *ngIf="sub?.hasTrial">
              <div [style.opacity]="sub.checked ? '1' : '0.6'">🔥</div>
              <div class="period">Trial</div>
              <div class="period" style="font-size: 10px">
                {{ currencySymbol }} {{ sub.trialCost }} - first
                {{ sub.period | translate }} then {{ currencySymbol }}
                {{ sub.cost }}
                {{ sub.subPeriod | translate }}
              </div>
            </div>

            <div class="line"></div>

            <div>
              <div *ngIf="!sub?.hasTrial" class="final-cost">
                {{ currencySymbol }} {{ sub.cost | number }}
              </div>
              <div
                class="discount"
                *ngIf="sub.discount && sub.checked && !sub?.hasTrial"
              >
                <span>{{ "save" | translate }}</span> {{ sub.discount + "%" }}
              </div>
              <div class="cost" *ngIf="sub.finalCost && !sub?.hasTrial">
                {{ currencySymbol }} {{ sub.finalCost | number }}/{{
                  "mo" | translate
                }}
              </div>
              <div
                *ngIf="sub?.hasTrial"
                class="final-cost"
                style="margin-bottom: 0px"
              >
                {{ currencySymbol }} {{ sub.trialCost | number }}
              </div>
              <div *ngIf="sub.hasTrial" class="final-cost">
                first {{ sub.period | translate }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div
      *ngIf="!isWeb"
      style="display: flex; flex-direction: column; align-items: center"
    >
      <div
        *ngIf="platform.is('ios') && canFreeTrial"
        style="font-size: 12px; color: #999; padding-top: 4px"
      >
        3 days free, then {{ currencySymbol }}{{ selectedSub?.cost }}/{{
          selectedSub?.time
        }}
        {{ selectedSub?.period }}
      </div>
      <ion-button
        expand="full"
        mode="ios"
        class="subscription-restore animate_pulse"
        fill="clear"
        (click)="purchase()"
      >
        {{ (canFreeTrial ? "TRY FREE FOR 3 DAYS" : "Continue") | translate }}
      </ion-button>
      <!-- <div class="text main">{{ "TRY FREE CANCEL BEFORE 3 DAYS IF YOU DON'T LOVE IT" | translate }}</div> -->
      <div
        (click)="subscriptionService.trueCheckSubscription('restore')"
        style="text-decoration: underline; color: #969696"
      >
        {{ "restore_purchase" | translate }}
      </div>
    </div>

    <div
      style="
        gap: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4rem 1rem 2rem;
      "
    >
      <div
        style="
          color: #000;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 900;
          line-height: 27.5px;
        "
      >
        {{ "Used by over 350K, personalized for you" | translate }}
      </div>
      <div style="display: flex; align-items: center; gap: 8px">
        <div
          style="
            color: #000;
            text-align: center;
            font-size: 36px;
            font-style: normal;
            font-weight: 900;
            line-height: 27.5px;
          "
        >
          {{ "4.6" | translate }}
        </div>
        <ion-icon
          name="star"
          style="color: #fed500; font-size: 32px"
        ></ion-icon>
        <div
          style="
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 27.5px;
          "
        >
          {{ "2000+ Reviews" | translate }}
        </div>
      </div>

      <div style="display: flex; flex-wrap: wrap; gap: 1rem 0.5rem">
        <div class="review-bubble">
          <div
            style="
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 27.5px;
            "
          >
            Tiffany M.
          </div>
          <div style="display: flex; gap: 2px">
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
          </div>
          <div
            style="
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              padding-top: 12px;
            "
          >
            As a busy mom who barely has any me time, LifeBuddy is a dream
            because it fits wellness into my schedule like no gym ever could
          </div>
        </div>
        <div class="review-bubble">
          <div
            style="
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 27.5px;
            "
          >
            Chelsea B.
          </div>
          <div style="display: flex; gap: 2px">
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
          </div>
          <div
            style="
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              padding-top: 12px;
            "
          >
            Purchasing LifeBuddy was like finding a fitness treasure trove, it's
            superior to other apps and has been key in reshaping my body after
            childbirth
          </div>
        </div>
        <div class="review-bubble">
          <div
            style="
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 27.5px;
            "
          >
            Brittany T.
          </div>
          <div style="display: flex; gap: 2px">
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
          </div>
          <div
            style="
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              padding-top: 12px;
            "
          >
            LifeBuddy's workouts have been a revelation, striking a perfect
            balance between enjoyment and results, redefining fitness for me
          </div>
        </div>
        <div class="review-bubble">
          <div
            style="
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 27.5px;
            "
          >
            Jennifer H.
          </div>
          <div style="display: flex; gap: 2px">
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
            <ion-icon
              name="star"
              style="color: #fed500; font-size: 14px"
            ></ion-icon>
          </div>
          <div
            style="
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              padding-top: 12px;
            "
          >
            I'm in love with LifeBuddy's approach to exercise, as it's fun,
            fulfilling, and has enhanced my curves, all without any equipment
          </div>
        </div>
      </div>
    </div>

    <div class="ion-padding">
      <ion-item
        [lines]="'none'"
        style="--background: transparent; --padding-start: 0px"
      >
        <ion-icon
          slot="start"
          [src]="'assets/newUI/icons/check_circle_orange.svg'"
          style="font-size: 32px"
        ></ion-icon>
        <ion-label style="white-space: normal">{{
          "10-30 minute workouts with over 450 exercises." | translate
        }}</ion-label>
      </ion-item>
      <ion-item
        [lines]="'none'"
        style="--background: transparent; --padding-start: 0px"
      >
        <ion-icon
          slot="start"
          [src]="'assets/newUI/icons/check_circle_orange.svg'"
          style="font-size: 32px"
        ></ion-icon>
        <ion-label style="white-space: normal">{{
          "Best home workouts you can do from anywhere." | translate
        }}</ion-label>
      </ion-item>
      <ion-item
        [lines]="'none'"
        style="--background: transparent; --padding-start: 0px"
      >
        <ion-icon
          slot="start"
          [src]="'assets/newUI/icons/check_circle_orange.svg'"
          style="font-size: 32px"
        ></ion-icon>
        <ion-label style="white-space: normal">{{
          "Experience a new fitness challenge with each workout." | translate
        }}</ion-label>
      </ion-item>
    </div>

    <div [style.padding]="'2rem 1rem'">
      <div>
        <img
          [src]="'assets/sales/workout-banner.png'"
          style="pointer-events: none; border-radius: 12px"
        />

        <ion-text class="about">
          <!-- <span style="font-weight: 500">{{
            "Greetings my workout buddys!" | translate
          }}</span>
          <br /> -->
          {{ "my_name_is" | translate }}
          <a
            href="https://www.instagram.com/jessrightfitness?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            target="_blank"
            >"JessRightFitness"</a
          >
          {{ "my_name_is_2" | translate }}
        </ion-text>
      </div>

      <div class="sub-header-slides">
        <div class="slide-title">
          {{ "Frequently asked questions" | translate }}
        </div>
        <!-- class="no-fullscreen" -->
        <!-- style="width: 100%; height: 350px" -->
        <!-- (slideChange)="slideChange($event)" -->
        <!-- (swiper)="onSwiper($event)" -->
        <swiper #slider [config]="slideOpts" class="slide-main">
          <ng-template
            class="slide-inner"
            swiperSlide
            *ngFor="let option of saleOptions; let i = index"
          >
            <div class="number-bubble">{{ i + 1 }}</div>
            <div class="sale-card">
              <div>
                <h3 class="title">{{ option.title | translate }}</h3>
                <p class="description">{{ option.description | translate }}</p>
              </div>
            </div>
          </ng-template>
        </swiper>
        <!-- <ion-slides class="slide-main" #slider [options]="slideOpts">
          <ion-slide class="slide-inner" *ngFor="let option of saleOptions; let i = index">
            
          </ion-slide>
        </ion-slides> -->
      </div>
    </div>

    <div class="subscription">
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 1rem 0rem 1rem;
        "
      >
        <div *ngIf="!isWeb" class="header-video-wrap">
          <div
            style="
              display: flex;
              justify-content: center;
              padding: 0 1rem;
              position: relative;
            "
          >
            <img
              style="position: absolute; z-index: -1; pointer-events: none"
              [src]="'assets/newUI/right-arrows.png'"
            />
            <div
              style="
                display: flex;
                align-items: flex-start;
                justify-content: space-around;
                max-height: 224px;
                overflow: hidden;
                min-width: 100%;
              "
            >
              <img
                style="
                  max-width: 200px;
                  width: 50%;
                  pointer-events: none;
                  min-width: 150px;
                  object-fit: cover;
                  object-position: top;
                  min-height: 340px;
                "
                [src]="
                  'assets/newUI/body_types/' + stats?.before?.image + '.png'
                "
              />
              <img
                style="
                  max-width: 200px;
                  width: 50%;
                  pointer-events: none;
                  min-width: 150px;
                  object-fit: cover;
                  object-position: top;
                  min-height: 340px;
                "
                [src]="
                  'assets/newUI/body_types/' + stats?.after?.image + '.png'
                "
              />
            </div>
          </div>
          <div
            class="img-bg"
            style="
              display: flex;
              background-image: linear-gradient(
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) 50%,
                rgb(248, 248, 248) 100%
              );
              max-width: 120%;
              background-position: right top;
              background-size: cover;
              background-repeat: no-repeat;
              min-width: 100%;
              position: absolute;
              height: 100%;
              top: 1px;
            "
          ></div>
        </div>
        <div *ngIf="isWeb" class="header-video-wrap">
          <img
            [src]="salesThumbnail"
            style="z-index: 10; pointer-events: none"
            class="header-video"
          />
        </div>
        <div
          style="
            padding: 0rem 1rem 1rem;
            display: flex;
            flex-direction: column;
            gap: 8px;
          "
        >
          <div
            style="
              color: #000;
              text-align: center;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 27.5px;
            "
          >
            {{ salesVideoText.title | translate }}
          </div>
          <div
            style="
              color: #5c5c5c;
              text-align: center;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 21.5px;
            "
          >
            {{
              salesVideoText.description
                | weight : userServ.getSyncUserOnce().metric
                | translate
            }}
          </div>
        </div>
      </div>
      <div class="sub-options" [class.isWeb]="isWeb">
        <ng-container *ngFor="let sub of subOptions; let i = index">
          <div
            class="sub-wrap"
            [class.sub-wrap-selected]="sub.checked"
            [class.fade-in-top]="sub.checked"
            (click)="selectSub(sub, i)"
          >
            <div
              [class.sub-label-active]="sub.checked"
              [class.fade-in-top]="sub.checked"
              class="sub-label"
              *ngIf="sub.label && sub.checked"
            >
              {{ sub.label | translate }}
            </div>
            <div
              class="sub"
              [class.sub-selected]="sub.checked"
              [style.padding]="sub.hasTrial ? '8px 6px' : ''"
            >
              <div *ngIf="!sub?.hasTrial">
                <div class="time">{{ sub.time }}</div>
                <div class="period">{{ sub.period | translate }}</div>
              </div>
              <div *ngIf="sub?.hasTrial">
                <div [style.opacity]="sub.checked ? '1' : '0.6'">🔥</div>
                <div class="period">Trial</div>
                <div class="period" style="font-size: 10px">
                  $ {{ sub.trialCost }} - first
                  {{ sub.period | translate }} then $ {{ sub.cost }}
                  {{ sub.subPeriod | translate }}
                </div>
              </div>

              <div class="line"></div>

              <div>
                <div *ngIf="!sub?.hasTrial" class="final-cost">
                  {{ currencySymbol }}
                  {{
                    sub.cost
                      | number : "1." + (sub.cost.includes(".") ? "2-2" : "0-0")
                  }}
                </div>
                <div
                  class="discount"
                  *ngIf="sub.discount && sub.checked && !sub?.hasTrial"
                >
                  <span>{{ "save" | translate }}</span> {{ sub.discount + "%" }}
                </div>
                <div class="cost" *ngIf="sub.finalCost && !sub?.hasTrial">
                  {{ currencySymbol }}
                  {{
                    sub.finalCost
                      | number
                        : "1." + (sub.finalCost.includes(".") ? "2-2" : "0-0")
                  }}/{{ "mo" | translate }}
                </div>
                <div
                  *ngIf="sub?.hasTrial"
                  class="final-cost"
                  style="margin-bottom: 0px"
                >
                  {{ currencySymbol }} {{ sub.trialCost | number }}
                </div>
                <div *ngIf="sub.hasTrial" class="final-cost">
                  first {{ sub.period | translate }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div style="display: flex; flex-direction: column; align-items: center">
        <ion-button
          expand="full"
          mode="ios"
          class="subscription-restore animate_pulse"
          fill="clear"
          (click)="purchase()"
        >
          {{ (canFreeTrial ? "TRY FREE FOR 3 DAYS" : "Continue") | translate }}
        </ion-button>
        <div
          *ngIf="!isWeb"
          (click)="subscriptionService.trueCheckSubscription('restore')"
          style="text-decoration: underline; color: #969696"
        >
          {{ "restore_purchase" | translate }}
        </div>
      </div>

      <div *ngIf="isWeb" style="display: flex; justify-content: center">
        <p class="footer-sub-title subscription-bottext">
          By continuing, you agree that if you don't cancel at least 24 hours
          prior to the end of the current period subscription, you will
          automatically be charged the full price of
          {{ selectedSub.cost | number : "1.0-2" }}
          {{ selectedSub.currencyCode }} every {{ selectedSub.time }}
          {{ selectedSub.period }} until you cancel in settings. By continuing,
          I agree with
          <span
            class="bold bottom-text"
            style="text-decoration: underline"
            (click)="openTerms()"
            >Terms of Use</span
          >,
          <span
            class="bold bottom-text"
            style="text-decoration: underline"
            (click)="openPrivacyPolicy()"
            >Privacy Policy</span
          >,
          <span
            class="bold bottom-text"
            style="text-decoration: underline"
            (click)="openSubscription()"
            >Subscription Terms</span
          >.
        </p>
      </div>
    </div>
  </div>

  <div class="footer">
    <p *ngIf="!isWeb" class="subscription-bottext">
      <span class="footer-sub-title"
        >Recurring billing. Cancel anytime. Renews at full price.</span
      >
      <br />
      Your payment will be charged to your
      {{ isAndroid ? "Google Play" : "App Store" }} account, and your
      subscription will auto-renew for the same package length at
      <span class="bold">{{ isAndroid ? "full" : "same" }} price</span> until
      you cancel in your
      {{ isAndroid ? "Google Play Store" : "App Store" }} settings. By
      continuing, you agree to our terms and the auto-renewal.
    </p>
    <p *ngIf="isWeb" class="subscription-bottext guaranteed-text">
      Guaranteed
      <span style="color: var(--ion-color-orange)">Safe</span>
      Checkout
      <ion-icon
        style="font-size: 24px"
        [src]="'assets/newUI/icons/shield.svg'"
      ></ion-icon>
    </p>
    <div
      *ngIf="isWeb"
      style="
        display: flex;
        justify-content: center;
        padding: 4px 1rem;
        width: 100%;
      "
    >
      <div
        style="
          max-width: 560px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
        "
      >
        <img style="width: 50px" [src]="'assets/newUI/icons/visa.png'" />
        <img style="width: 50px" [src]="'assets/newUI/icons/mastercard.png'" />
        <img style="width: 50px" [src]="'assets/newUI/icons/amex.png'" />
        <img style="width: 50px" [src]="'assets/newUI/icons/ssl.png'" />
      </div>
    </div>
    <p *ngIf="!isWeb" class="sub-menu">
      <span
        class="bold bottom-text"
        style="text-decoration: underline"
        (click)="openTerms()"
        >Terms of Use</span
      >
      <span
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="separator"></div>
        &nbsp;&nbsp;&nbsp;&nbsp;</span
      >
      <span
        class="bold bottom-text"
        style="text-decoration: underline"
        (click)="openPrivacyPolicy()"
        >Privacy Policy</span
      >
      <span
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="separator"></div>
        &nbsp;&nbsp;&nbsp;&nbsp;</span
      >
      <span
        class="bold bottom-text"
        style="text-decoration: underline"
        (click)="openSubscription()"
        >Subscription Terms</span
      >
    </p>
  </div>
</ng-template>

<app-payment
  id="paymentModal"
  #paymentModal
  [selectedSub]="selectedSub"
  [customClass]="'zIndex30000'"
></app-payment>
