<!-- <ion-header mode="ios"></ion-header> -->
<ion-content fullscreen="true">
  <div class="header-container">
    <ion-toolbar mode="ios">
      <ion-buttons *ngIf="!(networkState | async)" slot="start">
        <ion-button color="black" disabled="true">
          <ion-icon
            slot="icon-only"
            src="assets/newUI/icons/no-internet.svg"
          ></ion-icon>
        </ion-button>
      </ion-buttons>

      <ion-title color="black" style="padding: 0 40px">
        <div style="white-space: normal">{{ "Instructions" | translate }}</div>
      </ion-title>

      <ion-buttons slot="end" mode="ios">
        <ion-button mode="ios" (click)="dismiss()">
          <ion-icon
            slot="icon-only"
            src="assets/newUI/icons/close.svg"
          ></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>

  <div class="content-container">
    <div
      style="
        text-align: center;
        font-size: 24px;
        line-height: 22px;
        font-weight: 700;
        padding: 0px 10px;
      "
    >
      {{ slideList[0]?.name | translate }}
    </div>

    <div class="segment-container" *ngIf="slideList?.length > 1">
      <div
        *ngFor="let slide of slideList"
        class="segment-btn"
        [class.active-segment]="currentSlide === slide.slideName"
        (click)="segmentChanged(slide.slideName)"
      >
        <span style="text-transform: capitalize">{{
          slide.slideName | translate
        }}</span>
      </div>
    </div>

    <ion-slides
      class="ion-no-padding"
      style="position: sticky; top: 0px; overflow: unset"
    >
      <ion-slide
        *ngFor="let slide of slideList; let i = index"
        class="ion-no-margin ion-no-padding"
      >
        <div
          class="content-wrapper"
          [style.margin]="slideList?.length === 1 ? '24px 0 0' : '0'"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <div
            style="
              width: 100%;
              position: sticky;
              top: 0px;
              object-fit: fill;
              right: 0;
              box-sizing: border-box;
              border: 0 none;
              overflow: hidden;
              margin: 0px 10px;
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <video
              (playing)="slide.isLoad = true"
              webkit-playsinline
              autoplay
              poster="assets/poster.png"
              #videoPlayerItem
              playsinline
              muted
              loop
              style="
                max-width: calc(100% - 20px);
                z-index: 10;
                border-radius: 8px;
              "
            >
              <source [src]="slide.url" type="video/mp4" />
            </video>
            <ion-spinner
              *ngIf="!slide.isLoad"
              style="position: absolute"
            ></ion-spinner>
          </div>

          <div class="info-container">
            <div class="info-wrapper bold grey-box">
              <div class="info-item" style="line-height: 22px; font-size: 16px">
                {{ "rep_range" | translate }}
              </div>
              <div
                class="info-item"
                *ngIf="
                  slide.rep_range ===
                  'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16'
                "
              >
                <div
                  style="display: flex; flex-direction: column; font-size: 14px"
                >
                  <span
                    style="display: flex; flex-wrap: wrap; line-height: 22px"
                  >
                    <span>{{ "Hypertrophy/Strength : 8-12" | translate }}</span>
                  </span>
                  <span
                    style="display: flex; flex-wrap: wrap; line-height: 22px"
                  >
                    <span>{{
                      "Endurance/Hypertrophy : 12-16" | translate
                    }}</span>
                  </span>
                </div>
              </div>
              <div
                class="info-item"
                *ngIf="
                  slide.rep_range !==
                  'Hypertrophy/Strength : 8-12||Endurance/Hypertrophy : 12-16'
                "
              >
                <span>{{ slide.rep_range | translate }}</span>
              </div>
            </div>

            <div *ngIf="slide.hints" class="info-wrapper bold grey-box">
              <div class="info-item" style="line-height: 22px; font-size: 16px">
                {{ "Hints" | translate }}
              </div>
              <div class="info-item">
                <span>{{ slide.hints | translate }}</span>
              </div>
            </div>

            <div
              *ngIf="slide.common_mistakes"
              class="info-wrapper bold grey-box"
            >
              <div class="info-item" style="line-height: 22px; font-size: 16px">
                {{ "Common Mistakes" | translate }}
              </div>
              <div class="info-item">
                <span>{{ slide.common_mistakes | translate }}</span>
              </div>
            </div>

            <div *ngIf="slide.breathing" class="info-wrapper bold grey-box">
              <div class="info-item" style="line-height: 22px; font-size: 16px">
                {{ "Breathing" | translate }}
              </div>
              <div class="info-item">
                <span>{{ slide.breathing | translate }}</span>
              </div>
            </div>

            <div class="grey-box">
              <div
                class="info-item"
                style="
                  line-height: 22px;
                  font-size: 16px;
                  font-weight: 700;
                  padding-bottom: 1rem;
                "
              >
                {{ "Muscle Focus" | translate }}
              </div>

              <div *ngIf="slide.primary_muscle" style="padding: 0 0 1rem 0">
                <div style="display: flex">
                  <div class="muscle-group red"></div>
                  <div style="padding-left: 8px; font-size: 16px">
                    <span
                      *ngIf="
                        isMuscle(slide.subcategory_name);
                        else cardioGroup1
                      "
                      class="bold"
                      >{{ "primary_muscles" | translate }}</span
                    >
                    <ng-template #cardioGroup1>
                      <span class="bold">{{
                        "Primary Focus Area" | translate
                      }}</span>
                    </ng-template>
                    <div>
                      <span style="font-size: 14px">{{
                        slide.primary_muscle | translate
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div style="padding: 0 0 1rem 0">
                <div *ngIf="slide.secondary_muscle" style="display: flex">
                  <div class="muscle-group blue"></div>
                  <div style="padding-left: 8px; font-size: 16px">
                    <span
                      *ngIf="
                        isMuscle(slide.subcategory_name);
                        else cardioGroup2
                      "
                      class="bold"
                      >{{ "secondary_muscles" | translate }}</span
                    >
                    <ng-template #cardioGroup2>
                      <span class="bold">{{
                        "Secondary Focus Area" | translate
                      }}</span>
                    </ng-template>
                    <div>
                      <span style="font-size: 14px">{{
                        slide.secondary_muscle | translate
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div style="padding: 0 0 1rem 0">
                <div *ngIf="slide.tertiary_muscle" style="display: flex">
                  <div class="muscle-group green"></div>
                  <div style="padding-left: 8px; font-size: 16px">
                    <span
                      *ngIf="
                        isMuscle(slide.subcategory_name);
                        else cardioGroup3
                      "
                      class="bold"
                      >{{ "tertiary_muscles" | translate }}</span
                    >
                    <ng-template #cardioGroup3>
                      <span class="bold">{{
                        "Tertiary Focus Area" | translate
                      }}</span>
                    </ng-template>
                    <div>
                      <span style="font-size: 14px">{{
                        slide.tertiary_muscle | translate
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <app-muscle-diagram
                [primary]="slide.primary_muscle"
                [secondary]="slide.secondary_muscle"
                [tertiary]="slide.tertiary_muscle"
                style="width: 100%; height: 288px"
              ></app-muscle-diagram>
            </div>
          </div>
        </div>
      </ion-slide>
    </ion-slides>
  </div>
</ion-content>
