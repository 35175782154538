import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import {
  enableIndexedDbPersistence,
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { ReactiveFormsModule } from '@angular/forms';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { Health } from '@awesome-cordova-plugins/health/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Network } from '@ionic-native/network/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import {
  Animation,
  IonicModule,
  IonicRouteStrategy,
  createAnimation,
} from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgChartsModule } from 'ng2-charts';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './global-error-handler';
import { IonicGestureConfig } from './ionic-gesture-config';
import { WeightPipe } from './pipes/weight.pipe';
import { OnboardingService } from './services/onboarding.service';
import { SharedModule } from './shared/shared.module';

import { registerLocaleData } from '@angular/common';
import localeAf from '@angular/common/locales/af';
import localeAm from '@angular/common/locales/am';
import localeAr from '@angular/common/locales/ar';
import localeAz from '@angular/common/locales/az';
import localeBe from '@angular/common/locales/be';
import localeBg from '@angular/common/locales/bg';
import localeBn from '@angular/common/locales/bn';
import localeCa from '@angular/common/locales/ca';
import localecs from '@angular/common/locales/cs';
import localeda from '@angular/common/locales/da';
import localede from '@angular/common/locales/de';
import localeel from '@angular/common/locales/el';
import localees from '@angular/common/locales/es';
import localeet from '@angular/common/locales/et';
import localeEu from '@angular/common/locales/eu';
import localefa from '@angular/common/locales/fa';
import localefi from '@angular/common/locales/fi';
import localefil from '@angular/common/locales/fil';
import localefr from '@angular/common/locales/fr';
import localegl from '@angular/common/locales/gl';
import localegu from '@angular/common/locales/gu';
import localehe from '@angular/common/locales/he';
import localehr from '@angular/common/locales/hr';
import localehu from '@angular/common/locales/hu';
import localeid from '@angular/common/locales/id';
import localeis from '@angular/common/locales/is';
import localeit from '@angular/common/locales/it';
import localeja from '@angular/common/locales/ja';
import localeka from '@angular/common/locales/ka';
import localekk from '@angular/common/locales/kk';
import localekm from '@angular/common/locales/km';
import localekn from '@angular/common/locales/kn';
import localeko from '@angular/common/locales/ko';
import localeky from '@angular/common/locales/ky';
import localelo from '@angular/common/locales/lo';
import localelt from '@angular/common/locales/lt';
import localelv from '@angular/common/locales/lv';
import localemk from '@angular/common/locales/mk';
import localeml from '@angular/common/locales/ml';
import localemn from '@angular/common/locales/mn';
import localemr from '@angular/common/locales/mr';
import localems from '@angular/common/locales/ms';
import localeMy from '@angular/common/locales/my';
import localene from '@angular/common/locales/ne';
import localenl from '@angular/common/locales/nl';
import localeno from '@angular/common/locales/no';
import localepa from '@angular/common/locales/pa';
import localepl from '@angular/common/locales/pl';
import localept from '@angular/common/locales/pt';
import localero from '@angular/common/locales/ro';
import localeru from '@angular/common/locales/ru';
import localesi from '@angular/common/locales/si';
import localesk from '@angular/common/locales/sk';
import localesl from '@angular/common/locales/sl';
import localesr from '@angular/common/locales/sr';
import localesv from '@angular/common/locales/sv';
import localesw from '@angular/common/locales/sw';
import localeta from '@angular/common/locales/ta';
import localete from '@angular/common/locales/te';
import localeth from '@angular/common/locales/th';
import localetr from '@angular/common/locales/tr';
import localeuk from '@angular/common/locales/uk';
import localeur from '@angular/common/locales/ur';
import localevi from '@angular/common/locales/vi';
import localeZhHans from '@angular/common/locales/zh-Hans';
import localeZhHant from '@angular/common/locales/zh-Hant';
import localezu from '@angular/common/locales/zu';

import localeHi from '@angular/common/locales/hi';
import localeHy from '@angular/common/locales/hy';
import localeSq from '@angular/common/locales/sq';

import {
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
//import { VideoCountdownComponent } from "./components/video-countdown/video-countdown.component";
import { ServiceWorkerModule } from '@angular/service-worker';
import { InViewportModule } from 'ng-in-viewport';
import { SwiperModule } from 'swiper/angular';
import { ModalsModule } from './shared/modals.module';
registerLocaleData(localeHi);
registerLocaleData(localeSq);
registerLocaleData(localeAf);
registerLocaleData(localeAm);
registerLocaleData(localeAr);
registerLocaleData(localeHy);
registerLocaleData(localeAz);
registerLocaleData(localeBn);
registerLocaleData(localeEu);
registerLocaleData(localeBe);
registerLocaleData(localeBg);
registerLocaleData(localeMy);
registerLocaleData(localeCa);
registerLocaleData(localeZhHans);
registerLocaleData(localeZhHant);
registerLocaleData(localehr);
registerLocaleData(localecs);
registerLocaleData(localeda);
registerLocaleData(localenl);
registerLocaleData(localeet);
registerLocaleData(localefil);
registerLocaleData(localefi);
registerLocaleData(localefr);
registerLocaleData(localegl);
registerLocaleData(localeka);
registerLocaleData(localede);
registerLocaleData(localeel);
registerLocaleData(localegu);
registerLocaleData(localehe);
registerLocaleData(localehu);
registerLocaleData(localeis);
registerLocaleData(localeid);
registerLocaleData(localeit);
registerLocaleData(localeja);
registerLocaleData(localekn);
registerLocaleData(localekk);
registerLocaleData(localekm);
registerLocaleData(localeko);
registerLocaleData(localeky);
registerLocaleData(localelo);
registerLocaleData(localelv);
registerLocaleData(localelt);
registerLocaleData(localemk);
registerLocaleData(localems);
registerLocaleData(localeml);
registerLocaleData(localemr);
registerLocaleData(localemn);
registerLocaleData(localene);
registerLocaleData(localeno);
registerLocaleData(localefa);
registerLocaleData(localepl);
registerLocaleData(localept);
registerLocaleData(localepa);
registerLocaleData(localero);
registerLocaleData(localeru);
registerLocaleData(localesr);
registerLocaleData(localesi);
registerLocaleData(localesk);
registerLocaleData(localesl);
registerLocaleData(localees);
registerLocaleData(localesw);
registerLocaleData(localesv);
registerLocaleData(localeta);
registerLocaleData(localete);
registerLocaleData(localeth);
registerLocaleData(localetr);
registerLocaleData(localeuk);
registerLocaleData(localeur);
registerLocaleData(localevi);
registerLocaleData(localezu);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super('');
  }

  toString() {
    return this.service.currentLang;
  }
}
// import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from "@angular/fire/compat/firestore";
// import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from "@angular/fire/compat/functions";
// import { USE_EMULATOR as USE_STORAGE_EMULATOR } from "@angular/fire/compat/storage";

// Sentry.init(
//   {
//     dsn: "https://e0e7bdbb15d345888bb86289107505ea@o305182.ingest.sentry.io/6209214",
//     beforeBreadcrumb: (breadcrumb) => {
//       if (breadcrumb.data) {
//         let data = { data: "" };
//         try {
//           data = { data: JSON.stringify(breadcrumb.data) };
//         } catch (error) {
//           console.error(error);
//         }
//         breadcrumb.data = data;
//       }
//       return breadcrumb;
//     },
//   },
//   SentryAngular.init
// );

const navAnimation = (_: HTMLElement, opts: any): Animation => {
  const rootTransition = createAnimation()
    .duration(opts.duration || 500)
    .easing('linear');

  const enterTransition = createAnimation().addElement(opts.enteringEl);
  const exitTransition = createAnimation().addElement(opts.leavingEl);

  enterTransition.fromTo('opacity', '0.1', '1');
  exitTransition.fromTo('opacity', '1', '0');

  rootTransition.addAnimation([enterTransition, exitTransition]);
  return rootTransition;
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot({
      navAnimation,
    }),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    SharedModule,
    ModalsModule,
    NgChartsModule,
    RoundProgressModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => {
      const firestore = getFirestore();
      enableIndexedDbPersistence(firestore);
      return firestore;
    }),
    provideStorage(() => getStorage()),
    provideAuth(() => getAuth()),
    provideFunctions(() => getFunctions()),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    InViewportModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000'
    }),
    SwiperModule,
  ],
  providers: [
    NativeStorage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    // { provide: USE_FIRESTORE_EMULATOR, useValue: !environment.production ? ["localhost", 8080] : undefined },
    // { provide: USE_FUNCTIONS_EMULATOR, useValue: !environment.production ? ["localhost", 5001] : undefined },
    // { provide: USE_STORAGE_EMULATOR, useValue: !environment.production ? ["localhost", 9199] : undefined },
    SQLite,
    WebView,
    // Facebook,
    Network,
    // AdMobPro,
    AppAvailability,
    // GooglePlus,
    Insomnia,
    OnboardingService,
    WeightPipe,
    Health,
    TranslatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
