import { NgModule } from "@angular/core";
import { ExtendDatePipe } from "./../pipes/extend-date.pipe";

import { DurationPipe } from "../pipes/duration.pipe";
import { PhotoThumbnailFormat } from "../pipes/photo-format.pipe";
import { WeightPipe } from "../pipes/weight.pipe";

@NgModule({
  imports: [],
  exports: [DurationPipe, PhotoThumbnailFormat, WeightPipe, ExtendDatePipe],
  declarations: [DurationPipe, PhotoThumbnailFormat, WeightPipe, ExtendDatePipe],
})
export class GlobalModule {}

declare global {
  var currentcomponent: string;
}
