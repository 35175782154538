<bottom-sheet #bottomSheet [class]="customClass">
  <!-- [style.max-height]="step == 0 ? 'calc(433px + 3.5rem)' : step == 1 ? 'calc(282px + 3.5rem)' : 'calc(167px + 3.5rem)'" -->
  <!-- [style.opacity]="animating ? '0' : '1'" -->
  <!-- style="transition: all 0.3s ease-in-out; max-height: 0" -->
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 2rem;
      gap: 1.5rem;
      min-width: 356px;
    "
  >
    <div
      style="
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        line-height: 33px;
      "
    >
      <span *ngIf="mode == 'login'">
        {{ "Log in to your account" | translate }}
      </span>
      <span *ngIf="mode == 'signup' && !isAnonymous">
        {{ "Sign up and try all categories for free" | translate }}
      </span>
      <span *ngIf="mode == 'signup' && isAnonymous">
        {{ "link_an_email_to_keep_your_data_safe" | translate }}
      </span>
    </div>
    <div class="grid-container">
      <div *ngIf="platform.is('ios')" class="grid-row apple-login">
        <ion-button
          (click)="signInWithApple()"
          mode="ios"
          class="btn btn-apple"
        >
          <div class="btn-content">
            <img
              style="width: unset; height: 24px; margin-left: 18px"
              src="assets/images/apple_logo.png"
              class="ion-margin-horizontal"
            />
            <b>{{ "Continue with" | translate }} Apple</b>
          </div>
        </ion-button>
      </div>

      <div class="grid-row">
        <ion-button
          (click)="signInWithGoogle()"
          mode="ios"
          class="btn btn-apple"
        >
          <div class="btn-content">
            <img
              src="assets/images/google_logo.png"
              class="ion-margin-horizontal"
            />
            <b>{{ "Continue with" | translate }} Google</b>
          </div>
        </ion-button>
      </div>

      <div *ngIf="mode == 'signup' && !isAnonymous" class="grid-row">
        <ion-button
          (click)="continueAsGuest()"
          class="btn ion-margin-bottom"
          mode="ios"
          fill="outline"
          color="black"
        >
          <div class="btn-content">
            <img
              src="assets/newUI/guest.svg"
              class="ion-margin-horizontal"
              style="width: 22px; margin-left: 17px"
            />
            <b>{{ "continue_as_guest" | translate }}</b>
          </div>
        </ion-button>
      </div>

      <div class="grid-row">
        <ion-button
          *ngIf="mode == 'login'"
          (click)="openLogin()"
          class="btn ion-margin-bottom"
          mode="ios"
          fill="outline"
          color="black"
        >
          <div class="btn-content">
            <ion-icon
              name="mail-outline"
              class="ion-margin-horizontal"
              style="font-size: 24px"
            ></ion-icon>
            <b>{{ "login_with_email" | translate }}</b>
          </div>
        </ion-button>
        <ion-button
          *ngIf="mode == 'signup'"
          (click)="openSignUp()"
          class="btn ion-margin-bottom"
          mode="ios"
          fill="outline"
          color="black"
        >
          <div class="btn-content">
            <img
              src="assets/newUI/emailLogo.png"
              class="ion-margin-horizontal"
            />
            <b>{{
              (isAnonymous ? "continue_with_email" : "sign_up_with_email")
                | translate
            }}</b>
          </div>
        </ion-button>
      </div>
    </div>
    <div
      *ngIf="mode == 'signup'"
      style="text-align: center; font-size: 14px; line-height: 22px"
    >
      By signing up, you agree to LifeBuddy’s
      <span style="text-decoration: underline" (click)="openTerms()"
        >Terms and Conditions</span
      >
      and
      <span style="text-decoration: underline" (click)="openPrivacy()"
        >Privacy Policy</span
      >
    </div>
  </div>
</bottom-sheet>
