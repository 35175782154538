<!-- <ion-header>
  <ion-toolbar style="--background: #1d1d1d; height: 0px; --min-height: 0px"> </ion-toolbar>
</ion-header> -->

<ion-content fullscreen="true">
  <app-radio-select
    #selectCreatePlanModal
    (modalSelect)="modalSelectCreatePlan($event)"
  ></app-radio-select>

  <div
    class="header-container"
    [class.legs]="workoutData?.categoryName?.split(',')[0].split(' ').join('_') == 'legs'"
    [style.background-image]="'url(assets/newUI/muscle_groups/' + workoutData?.categoryName?.split(',')[0].split(' ').join('_') + '.jpg)'"
    style="position: relative"
  >
    <div class="safe-area-bg-top"></div>

    <div
      style="
        position: absolute;
        height: 100%;
        width: 100%;
        background: black;
        opacity: 0.1;
        top: 0;
        z-index: 0;
      "
    ></div>
    <div style="width: 100%; display: flex; z-index: 10">
      <img
        style="z-index: 10; margin: 0.5rem auto; max-width: 174px"
        src="assets/lifebuddy.png"
      />
    </div>

    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        z-index: 10;
      "
    >
      <ion-icon
        style="font-size: 90px"
        src="assets/fire-cracker.svg"
      ></ion-icon>
      <div>{{"I Just Completed" | translate}}</div>
      <div
        style="font-weight: 700; font-size: 30px; text-transform: capitalize"
      >
        {{workoutData.categoryName | translate}}
      </div>
    </div>

    <div
      style="
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 10;
      "
    >
      <div style="display: flex">
        <div
          style="
            display: flex;
            font-style: italic;
            font-weight: 400;
            font-size: 36px;
          "
        >
          "
        </div>
        <div
          style="
            font-style: italic;
            font-weight: 400;
            font-size: 18px;
            line-height: 137.69%;
            padding: 0.5rem;
            text-align: center;
          "
        >
          {{quote?.body}}
        </div>
        <div
          style="
            align-items: flex-end;
            display: flex;
            font-style: italic;
            font-weight: 400;
            font-size: 36px;
            line-height: 0px;
          "
        >
          "
        </div>
      </div>
      <div>- {{quote?.author}}</div>
    </div>

    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0.5rem 1rem;
        z-index: 10;
      "
    >
      <div style="font-weight: 700; font-size: 48px">
        <span style="display: flex; justify-content: center">
          <div
            *ngIf="workoutData.workoutTime | duration:'m'"
            style="margin: 0 3px"
          >
            {{workoutData.workoutTime | duration:'m'}}
            <span>{{"m" | translate}}</span>
          </div>

          <div
            *ngIf="(workoutData.workoutTime | duration:'s') || !workoutData.workoutTime"
            style="margin: 0 3px"
          >
            {{workoutData.workoutTime | duration:'s'}}
            <span>{{"s" | translate}}</span>
          </div>
        </span>
      </div>
      <div
        style="display: flex; justify-content: center; align-items: flex-end"
      >
        <ion-icon
          style="width: 80px; height: 96px"
          src="assets/flame.svg"
        ></ion-icon>
        <div
          style="
            position: absolute;
            margin-bottom: 16px;
            font-weight: 700;
            font-size: 30px;
          "
        >
          {{workoutData.calories}}
        </div>
      </div>
    </div>
  </div>

  <div class="content-container" style="padding: 1rem 0">
    <ion-item
      *ngIf="achievement"
      style="--background: transparent"
      [lines]="'none'"
    >
      <ion-icon
        style="font-size: 68px; margin: 0 1rem 0 0"
        [src]="'assets/achievements/'+achievement.id+'.svg'"
      ></ion-icon>
      <ion-label style="margin: 0">
        <h2 style="font-weight: 600; font-size: 16px">
          {{achievement.title | translate}}
        </h2>
        <p
          style="
            color: #bababa;
            font-weight: 400;
            font-size: 14px;
            white-space: normal;
          "
        >
          {{achievement.description | translate}}
        </p>
        <div style="display: flex; align-items: center; gap: 8px">
          <ion-progress-bar
            style="border-radius: 8px; height: 8px"
            [value]="achievement.currentProgress/achievement.target"
          ></ion-progress-bar>
          <div style="font-weight: 600; font-size: 14px">
            {{achievement.currentProgress}}/{{achievement.target}}
          </div>
        </div>
      </ion-label>
    </ion-item>

    <div class="action-container">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          width: 100%;
        "
      >
        <ion-button
          *ngIf="workoutData.canResume"
          style="margin: 0 auto"
          (click)="resumeWorkout()"
          mode="ios"
          style="background: var(--ion-color-grey); color: black"
          expand="full"
          fill="clear"
        >
          {{"Resume Workout" | translate}}
        </ion-button>

        <ion-button
          *ngIf="!workoutData.canResume && isLastWorkout"
          type="submit"
          style="margin: 0 auto; background: #7e83ac"
          (click)="openCreatePlan(selectCreatePlanModal)"
          mode="ios"
          expand="full"
          fill="clear"
        >
          {{"Create New Plan" | translate}}
        </ion-button>

        <ion-button
          type="submit"
          style="margin: 0 auto"
          (click)="openHome()"
          mode="ios"
          expand="full"
          fill="clear"
        >
          {{"Main Menu" | translate}}
        </ion-button>
      </div>
      <div
        *ngIf="workoutData.canResume"
        style="padding: 1rem; text-align: center"
      >
        {{"Ending your workout early will not save your current progress to\
        your\ workout logs" | translate}}
      </div>
    </div>
  </div>
</ion-content>
