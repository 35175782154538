<bottom-sheet #bottomSheet>
  <div
    style="transition: all 0.3s ease-in-out; max-height: 0"
    [style.max-height]="
      step == 0
        ? 'calc(433px + 3.5rem)'
        : step == 1
        ? 'calc(282px + 3.5rem)'
        : 'calc(167px + 3.5rem)'
    "
    [style.opacity]="animating ? '0' : '1'"
  >
    <div
      class="modal-container text-center"
      id="step-1"
      [style.opacity]="step == 0 ? '1' : '0'"
      [style.display]="(step == 1 && !animating) || step > 1 ? 'none' : ''"
    >
      <div style="font-size: 48px">
        <span *ngIf="rating == 0"> 🙂 </span>
        <span *ngIf="rating == 1"> 🥺 </span>
        <span *ngIf="rating == 2"> 😢 </span>
        <span *ngIf="rating == 3"> 😔 </span>
        <span *ngIf="rating == 4"> 😀 </span>
        <span *ngIf="rating == 5"> 🤩 </span>
      </div>

      <h1>{{ "are_you_enjoying_lifebuddy" | translate }}?</h1>

      <h3>{{ "please_tap_a_star_to_rate_us" | translate }}</h3>

      <div style="display: flex; flex-direction: column">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 1rem 1rem 0.5rem;
          "
        >
          <ion-icon
            *ngFor="let i of [1, 2, 3, 4, 5]"
            [name]="rating >= i ? 'star' : 'star-outline'"
            [color]="rating >= i ? 'primary' : 'medium'"
            class="star"
            (click)="rate(i)"
            style="font-size: 32px"
          ></ion-icon>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0px 1rem;
            align-items: baseline;
          "
        >
          <div style="width: 32px"></div>
          {{ "the_best_we_can_get" | translate }} (•‿•)
          <ion-icon
            [src]="'assets/icon/arrow-right-up.svg'"
            style="font-size: 32px; padding-right: 1px"
          ></ion-icon>
        </div>
      </div>
      <div class="modal-btn-container">
        <ion-button
          [disabled]="rating == 0"
          expand="full"
          mode="ios"
          class="btn-submit"
          (click)="continue()"
          fill="clear"
        >
          {{ "continue" | translate }}
        </ion-button>
      </div>
    </div>

    <div
      class="modal-container"
      id="step2"
      #step2
      [style.opacity]="step == 1 && !animating ? '' : '0'"
      [style.display]="
        (step == 2 && !animating) || (step == 1 && animating) || step == 0
          ? 'none'
          : ''
      "
    >
      <h1 style="text-transform: uppercase; margin-top: 0px">
        {{ "thank_you" | translate }} 🙌
      </h1>

      <h3>{{ "what_can_we_do_to_improve" | translate }}?</h3>

      <div style="display: flex">
        <ion-textarea
          rows="5"
          style="background: var(--ion-color-grey); border-radius: 10px"
          [(ngModel)]="reviewText"
          [placeholder]="'give_us_your_feedback' | translate"
        >
        </ion-textarea>
      </div>
      <div class="modal-btn-container">
        <ion-button
          [disabled]="reviewText == ''"
          expand="full"
          mode="ios"
          class="btn-submit"
          (click)="continue()"
          fill="clear"
        >
          {{ "continue" | translate }}
        </ion-button>
      </div>
    </div>

    <div
      class="modal-container"
      id="step-3"
      [style.opacity]="step == 2 && !animating ? '' : '0'"
      [style.display]="(step == 2 && animating) || step < 2 ? 'none' : ''"
    >
      <h1 style="margin-top: 0px">{{ "thank_you" | translate }} 🙌</h1>

      <h3 style="min-height: 38.4px">
        {{
          "your_feedback_makes_it_easier_for_us_to_create_a_better_experience_for_you"
            | translate
        }}
        ✨
      </h3>

      <div class="modal-btn-container">
        <ion-button
          expand="full"
          mode="ios"
          class="btn-submit"
          (click)="continue()"
          fill="clear"
        >
          {{ "got_it" | translate }}
        </ion-button>
      </div>
    </div>
  </div>
</bottom-sheet>
