import { AfterContentInit, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { ModalController } from "@ionic/angular";
import { TranslateConfigService } from "src/app/services/translate-config.service";

import { UserService } from "src/app/services/user.service";
import { LoggerService } from "../../services/logger.service";

@Component({
  selector: "app-weight-progress-slide-show",
  templateUrl: "./weight-progress-slide-show.component.html",
  styleUrls: ["./weight-progress-slide-show.component.scss"],
})
export class WeightProgressSlideShowComponent implements AfterContentInit, OnChanges {
  @Input() weightHistory: Array<any>;
  @Input() metric: string;

  language: any;

  viewEntered: boolean;

  slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  constructor(
    private modalCtrl: ModalController,
    private domSanitizer: DomSanitizer,
    private webView: WebView,
    private loggerService: LoggerService,
    private userService: UserService,
    private translateConfigService: TranslateConfigService
  ) {
    this.language = this.translateConfigService.getCurrentLang();

    if (this.weightHistory) {
      this.weightHistory = this.weightHistory.map((item) => {
        if (item.filePath) {
          try {
            item = {
              ...item,
              sanitizedImage: this.domSanitizer.bypassSecurityTrustUrl(this.webView.convertFileSrc(item.filePath)),
            };
          } catch (error) {
            console.log(error);
            this.loggerService.logError(error);
          }
        }
        return item;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.weightHistory) {
      this.weightHistory = this.weightHistory.map((item) => {
        if (item.filePath) {
          try {
            item = {
              ...item,
              sanitizedImage: this.domSanitizer.bypassSecurityTrustUrl(this.webView.convertFileSrc(item.filePath)),
            };
          } catch (error) {
            console.log(error);
            this.loggerService.logError(error);
          }
        }
        return item;
      });
    }
  }

  ngAfterContentInit() {
    if (this.weightHistory) {
      this.weightHistory = this.weightHistory.map((item) => {
        if (item.filePath) {
          try {
            item = {
              ...item,
              sanitizedImage: this.domSanitizer.bypassSecurityTrustUrl(this.webView.convertFileSrc(item.filePath)),
            };
          } catch (error) {
            console.log(error);
            this.loggerService.logError(error);
          }
        }
        return item;
      });
    }
  }

  ionViewWillEnter() {
    this.viewEntered = true;
  }

  ionViewDidLeave() {
    this.viewEntered = false;
  }

  ionViewWillLeave() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
