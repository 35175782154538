import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SingleVideoComponent } from '../components/single-video/single-video.component';
import {
  ALL_VIDEOS_INFO,
  Category,
  CategorySettings,
  DifficultySettings,
} from '../constants';
// import { Category } from '../enums/Category';
import { Difficulty } from '../enums/Difficulty';
import { Video } from '../models/video';
import { FileManagerService } from './file-manager.service';
import { NetworkService } from './network.service';
import { NotificationService } from './notification.service';
import { SqlService } from './sql.service';
import { StorageService } from './storage.service';
import { SubscriptionService } from './subscription.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  private videos = new BehaviorSubject<Video[]>(ALL_VIDEOS_INFO);
  private firstIndexOtherSideArrayList: Array<any>;
  private secondIndexOtherSideArrayList: Array<any>;
  private secondIndexVideo: Array<any>;
  private firstIndexVideo: Array<any>;
  private restScreen: any;
  private videoDataByCategory: Array<Video>;
  private playlistVideos: {
    isLastWorkout: boolean;
    totalTime: number;
    videoDataCategoryList: Array<Video>;
    categoryName: Category;
    workoutId: string;
  };

  public videoDownloadIsDone = new BehaviorSubject<{
    sizeToDownload: string;
    isDone: boolean;
    refresh: boolean;
    action: string;
  }>({ sizeToDownload: '', isDone: true, refresh: false, action: '' });

  totalBodyCategories = [
    'total body',
    'booty',
    'wall pilates',
    'upper body',
    'legs',
  ];

  constructor(
    private modalCtrl: ModalController,
    private storageServ: StorageService,
    private networkService: NetworkService,
    private notificationService: NotificationService,
    private subServ: SubscriptionService,
    private userServ: UserService,
    private router: Router,
    private sqlServ: SqlService,
    private fileManagerServ: FileManagerService
  ) {
    this.firstIndexOtherSideArrayList = [];
    this.secondIndexOtherSideArrayList = [];
    this.secondIndexVideo = [];
    this.firstIndexVideo = [];
    this.videoDataByCategory = [];
    this.restScreen = {
      name: 'Rest',
      thumbnail: 'assets/images/rest_boy.png',
      time_to_play: 1,
      time: '60 seconds',
      video_length: 1.0,
      category_id: 2,
      tools: 'No Tools',
      video_id: 'Rest',
      url: 'assets/rest_boy.mp4',
    };

    this.sqlServ
      .openDB()
      .then(() => this.sqlServ.createAllTables())
      .then(() => {
        return this.sqlServ.fillVideosInfo(ALL_VIDEOS_INFO);
      })
      .then((res: Video[]) => {
        this.setVideos(res);
      });
  }

  setVideos(videos: Video[]): void {
    this.videos.next(videos);
  }

  getVideos(): Observable<Video[]> {
    return this.videos.asObservable();
  }

  getAvailableVideos(): Observable<Video[]> {
    return this.videos.asObservable().pipe(
      map((videos: Video[]) => {
        const vList = videos.filter(
          (item) =>
            item.is_other_side !== 1 &&
            item.is_three_video !== 2 &&
            item.is_three_video !== 3
        );
        let resultList: { [key: string]: any[] } = {};
        Object.keys(CategorySettings).forEach((key) => {
          resultList = {
            ...resultList,
            [key]: [],
          };
        });
        vList.forEach((e) => {
          if (!resultList[e.subcategory_name]) {
            console.log('e.subcategory_name', e.subcategory_name);
          } else {
            resultList[e.subcategory_name].push(e);
          }
        });
        return [].concat.apply([], Object.values(resultList));
      })
    );
  }

  getVideosOnce(): Video[] {
    return this.videos.value;
  }

  async openSingleVideo(video: Video) {
    if (video.name === this.restScreen.name) {
      return;
    }
    const thirdList = {
      1: video,
      2: undefined,
      3: undefined,
    };

    if (video.is_other_side === 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.videos.value.length; i++) {
        if (
          video.name === this.videos.value[i].name &&
          this.videos.value[i].is_other_side &&
          this.videos.value[i].subcategory_name === video.subcategory_name
        ) {
          thirdList['2'] = this.videos.value[i];
        }
      }
    } else if (video.is_three_video) {
      thirdList[video.is_three_video.toString()] = video;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.videos.value.length; i++) {
        if (
          video.group_name === this.videos.value[i].group_name &&
          this.videos.value[i].is_three_video
        ) {
          thirdList[this.videos.value[i].is_three_video.toString()] =
            this.videos.value[i];
        }
      }
    }

    const modal = await this.modalCtrl.create({
      component: SingleVideoComponent,
      componentProps: {
        video: thirdList['1'],
        otherSideItem: thirdList['2'],
        thirdSideItem: thirdList['3'],
        index: video.is_three_video,
      },
    });
    await modal.present();
    return modal;
  }

  // getShuffledArrayNoRepeats(array: Video[]): Video[] {
  //   const shuffledArray: Video[] = [];
  //   const count = array.length;
  //   const clonedArray = array.slice();

  //   for (let i = 0; i < count; i++) {
  //     let currentIndex = Math.floor(Math.random() * clonedArray.length);
  //     let currentItem = clonedArray[currentIndex];
  //     const lastItem = shuffledArray[shuffledArray.length - 1];
  //     while (lastItem && lastItem.video_number == currentItem.video_number) {
  //       currentIndex = Math.floor(Math.random() * clonedArray.length);
  //       currentItem = clonedArray[currentIndex];
  //     }

  //     shuffledArray.push(currentItem);
  //     clonedArray.splice(currentIndex, 1)[0];
  //   }
  //   return shuffledArray;
  // }

  isDublicateItems = (arr) => arr.some((v, i, a) => v === a[i + 1]);

  getShuffledArray<T>(array: T[]): T[] {
    const shuffledArray: T[] = [];
    const count = array.length;
    const clonedArray = array.slice();

    for (let i = 0; i < count; i++) {
      const currentIndex = Math.floor(Math.random() * clonedArray.length);
      const currentItem = clonedArray.splice(currentIndex, 1)[0];
      shuffledArray.push(currentItem);
    }
    return shuffledArray;
  }

  private getCategoryVideos(
    category: string,
    difficultyLevel: string,
    duration?: any,
    muscleCategory?: string
  ) {
    const settings = DifficultySettings[category][difficultyLevel];
    this.videoDataByCategory = this.getShuffledArray(
      this.videos.value.filter(
        (item) =>
          item.subcategory_name === category &&
          (muscleCategory &&
          muscleCategory != 'total body' &&
          muscleCategory != 'hiit' &&
          muscleCategory != 'cardio' &&
          muscleCategory != 'upper body stretch' &&
          muscleCategory != 'lower body stretch' &&
          muscleCategory != 'wall pilates'
            ? item?.muscle_category &&
              item?.muscle_category?.findIndex((c) => c == muscleCategory) != -1
            : true)
      )
    );
    this.videoDataByCategory = this.videoDataByCategory.filter(
      (item) =>
        item &&
        item.is_other_side !== 1 &&
        item.is_three_video !== 2 &&
        item.is_three_video !== 3
    );

    if (
      !this.subServ.trueCheckSubscriptionForPlayer() &&
      this.subServ.isFreemium()
    ) {
      this.videoDataByCategory = this.videoDataByCategory.filter(
        (v) => v.is_free
      );
    }

    const user = this.userServ.getSyncUserOnce();
    if (user.equipment && user.equipment.findIndex((e) => e == 'chair') == -1) {
      this.videoDataByCategory = this.videoDataByCategory.filter(
        (item) => item && (item.tools as string).indexOf('Chair') == -1
      );
    }

    this.restScreen.category_id = category;
    this.restScreen.time_to_play = settings.restTime;
    if (!this.videoDataByCategory.length) {
      return;
    }

    let totalTime = 0;
    let videoDataCategoryList = [];

    if (category == 'hiit') {
      let cardioVideos = this.videos.value.filter(
        (item) =>
          item.subcategory_name === 'cardio' &&
          item.is_other_side !== 1 &&
          item.is_three_video !== 2 &&
          item.is_three_video !== 3
      );

      if (
        !this.subServ.trueCheckSubscriptionForPlayer() &&
        this.subServ.isFreemium()
      ) {
        cardioVideos = cardioVideos.filter((v) => v.is_free);
      }

      const user = this.userServ.getSyncUserOnce();
      if (
        user.equipment &&
        user.equipment.findIndex((e) => e == 'chair') == -1
      ) {
        cardioVideos = cardioVideos.filter(
          (item) => item && (item.tools as string).indexOf('Chair') == -1
        );
      }

      const shuffledCardioVideos = this.getShuffledArray(cardioVideos);

      let hiitVideos = [];
      let cardVideos = [];

      let j = 0;
      for (let i = 0; totalTime < duration * 60 * 1000; i++) {
        const video = shuffledCardioVideos[i % shuffledCardioVideos.length];
        if (video) {
          cardVideos.push(video);
          totalTime += 1 * 60 * 1000; // All cardio videos must be 1 minute
        }
        if (i % 2 == 1) {
          const hiitVideo =
            this.videoDataByCategory[j % this.videoDataByCategory.length];
          hiitVideos.push(hiitVideo);
          totalTime += hiitVideo.time_to_play * 60 * 1000;
          j++;
        }
      }

      totalTime = 0;

      hiitVideos = hiitVideos.sort(() => (Math.random() > 0.5 ? 1 : -1));
      while (this.isDublicateItems(hiitVideos)) {
        hiitVideos = hiitVideos.sort(() => (Math.random() > 0.5 ? 1 : -1));
      }

      cardVideos = cardVideos.sort(() => (Math.random() > 0.5 ? 1 : -1));
      while (this.isDublicateItems(cardVideos)) {
        cardVideos = cardVideos.sort(() => (Math.random() > 0.5 ? 1 : -1));
      }

      for (let i = 0; totalTime < duration * 60 * 1000; i++) {
        const video = cardVideos[i % cardVideos.length];
        if (video) {
          videoDataCategoryList.push(video);
          totalTime += 1 * 60 * 1000; // All cardio videos must be 1 minute
        }
        if (i % 2 == 1) {
          const hiitVideo = hiitVideos[j % hiitVideos.length];
          videoDataCategoryList.push(hiitVideo);
          totalTime += hiitVideo.time_to_play * 60 * 1000;
          j++;
        }
      }
    } else if (category == 'cardio') {
      if (Number(duration) > this.videoDataByCategory.length) {
        const remaining = Number(duration) - this.videoDataByCategory.length;
        for (let i = 0; i < remaining; i++) {
          this.videoDataByCategory.push(
            this.videoDataByCategory[i % this.videoDataByCategory.length]
          );
        }

        this.videoDataByCategory = this.videoDataByCategory.sort(() =>
          Math.random() > 0.5 ? 1 : -1
        );
        while (this.isDublicateItems(this.videoDataByCategory)) {
          this.videoDataByCategory = this.videoDataByCategory.sort(() =>
            Math.random() > 0.5 ? 1 : -1
          );
        }
      }

      for (let i = 0; i < Number(duration); i++) {
        const video =
          this.videoDataByCategory[i % this.videoDataByCategory.length];
        if (video) {
          videoDataCategoryList.push(video);
          totalTime += 1 * 60 * 1000; // All cardio videos must be 1 minute
        }
      }
    } else {
      let num = settings.numOfVideos;

      for (let i = 0; i < num; i++) {
        videoDataCategoryList.push(this.videoDataByCategory[i]);
      }

      const newList = [];
      [...videoDataCategoryList].forEach((el, index) => {
        if (el) {
          newList.push(el);
          if (
            settings.restCount > 0 &&
            index < videoDataCategoryList.length - 1
          ) {
            newList.push({ ...this.restScreen });
          }
        }
      });

      videoDataCategoryList = newList;

      videoDataCategoryList.forEach((e) => {
        totalTime += e.time_to_play * 60 * 1000;
      });
    }

    const result = {
      totalTime,
      videoDataCategoryList,
    };

    return result;
  }

  private getTotalBodyVideos(difficultyLevel) {
    const settings = DifficultySettings['total body'][difficultyLevel];

    this.restScreen.category_id = 'total body';
    this.restScreen.time_to_play = settings.restTime;

    const videos = this.totalBodyCategories.map((category) => {
      return this.getCategoryVideos(category, difficultyLevel);
    });

    const workoutsList = videos.map((v) => v.videoDataCategoryList);
    const prepareWorkouts = []
      .concat(...workoutsList)
      .filter(
        (item) =>
          item &&
          item.is_other_side !== 1 &&
          item.is_three_video !== 2 &&
          item.is_three_video !== 3 &&
          item.name !== 'Rest'
      );

    const shuffledWorkouts = this.getShuffledArray(prepareWorkouts);

    let videoDataCategoryList = [];
    for (let i = 0; i < settings.numOfVideos; i++) {
      videoDataCategoryList.push(shuffledWorkouts[i]);
    }

    const newList = [];
    [...videoDataCategoryList].forEach((el, index) => {
      if (el) {
        newList.push(el);
        if (index < videoDataCategoryList.length - 1) {
          newList.push({ ...this.restScreen });
        }
      }
    });

    videoDataCategoryList = newList;

    let totalTime = 0;
    videoDataCategoryList.forEach((e) => {
      totalTime += e.time_to_play * 60 * 1000;
    });

    return {
      totalTime,
      videoDataCategoryList,
    };
  }

  getVideosByCategory(
    category: string,
    difficultyLevel,
    duration?: any,
    muscleCategory?: string
  ) {
    if (category === 'total body') {
      return this.getTotalBodyVideos(difficultyLevel);
    }
    return this.getCategoryVideos(
      category,
      difficultyLevel,
      duration,
      muscleCategory
    );
  }

  getSwipedVideo(
    direction,
    videos: Array<Video>,
    index: number,
    item: Video,
    category: string,
    warmUpCoolDown = false,
    muscle_category?: string
  ) {
    let videosByCategory;

    if (category == 'total body') {
      videosByCategory = this.videos.value.filter(
        (vid) =>
          this.totalBodyCategories.findIndex(
            (cat) => cat == vid.subcategory_name
          ) != -1
      );
    } else if (category == 'hiit') {
      videosByCategory = this.videos.value.filter(
        (vid) => vid.subcategory_name == item.subcategory_name
      );
    } else {
      videosByCategory = this.videos.value.filter(
        (vid) =>
          vid.subcategory_name == item.subcategory_name &&
          (muscle_category
            ? vid.muscle_category &&
              vid.muscle_category.findIndex((c) => c == muscle_category) != -1
            : true)
      );
    }
    if (
      !this.subServ.trueCheckSubscriptionForPlayer() &&
      this.subServ.isFreemium()
    ) {
      videosByCategory = videosByCategory.filter((vid) => vid.is_free);
    }

    const user = this.userServ.getSyncUserOnce();
    if (user.equipment && user.equipment.findIndex((e) => e == 'chair') == -1) {
      videosByCategory = videosByCategory.filter(
        (item) => item && (item.tools as string).indexOf('Chair') == -1
      );
    }

    let list = videosByCategory.filter(
      (el: Video) =>
        videos.findIndex(
          (itm: Video) => itm && el && itm.video_id === el.video_id
        ) < 0 &&
        el.is_other_side !== 1 &&
        el.is_three_video !== 2 &&
        el.is_three_video !== 3
    );
    if (list.length == 0) {
      list = videosByCategory.filter(
        (el: Video) =>
          el.is_other_side !== 1 &&
          el.is_three_video !== 2 &&
          el.is_three_video !== 3
      );
    }

    let currentIndex = index + direction;
    if (item && item.subcategory_name) {
      const newList = list.filter(
        (e) =>
          e.is_three_video !== 2 &&
          e.is_three_video !== 3 &&
          e.video_id != item.video_id
      );
      if (currentIndex >= newList.length) {
        currentIndex = 0;
      } else if (currentIndex < 0) {
        currentIndex = newList.length - 1;
      }
      const video = newList[currentIndex];
      return {
        video: video && video?.video_id ? video : list[0],
        index: currentIndex,
      };
    } else {
      if (currentIndex >= list.length) {
        currentIndex = 0;
      } else if (currentIndex < 0) {
        currentIndex = list.length - 1;
      }

      return {
        video: list[currentIndex],
        index: currentIndex,
      };
    }
  }

  getPlaylistVideos() {
    const newList = [];
    let totalTime = 0;

    this.playlistVideos.videoDataCategoryList.forEach((element) => {
      newList.push(element);
      totalTime += element.time_to_play;
      if (element.is_other_side === 0 || element.is_other_side === 1) {
        this.videos.value.forEach((e) => {
          if (
            e &&
            element.name === e.name &&
            element.is_other_side === 0 &&
            e.is_other_side === 1 &&
            e.subcategory_name === element.subcategory_name
          ) {
            newList.push({ ...e, rotateY: true, name: 'Other Side' });
            totalTime += e.time_to_play;
          } else if (
            e &&
            element.name === e.name &&
            element.is_other_side === 1 &&
            e.is_other_side === 0 &&
            e.subcategory_name === element.subcategory_name
          ) {
            newList.push({ ...e, rotateY: true, name: 'Other Side' });
            totalTime += e.time_to_play;
          }
        });
      }
      if (element.is_three_video === 1) {
        const tmpList = [];
        this.videos.value.forEach((e) => {
          if (
            e &&
            e.is_three_video !== 1 &&
            e.is_three_video &&
            e.group_name === element.group_name
          ) {
            tmpList.push(e);
          }
        });
        tmpList.sort((a: Video, b: Video) =>
          a.is_three_video > b.is_three_video ? 1 : -1
        );
        tmpList.forEach((e) => {
          newList.push(e);
          totalTime += e.time_to_play;
        });
      }
    });
    this.playlistVideos.videoDataCategoryList = newList;
    this.playlistVideos.totalTime = totalTime * 60 * 1000;
    return this.playlistVideos;
  }

  setPlaylistVideos(videoData: {
    isLastWorkout: boolean;
    totalTime: number;
    videoDataCategoryList: Array<Video>;
    categoryName;
    workoutId: string;
  }) {
    this.playlistVideos = videoData;
  }

  checkArraInArrayForCategoryList(root: string[], child: string[]) {
    const inArray = [];
    root.forEach((e) => {
      if (child.indexOf(e.toLowerCase()) !== -1) {
        inArray.push(true);
      } else {
        inArray.push(false);
      }
    });
    return inArray.filter((e) => e).length === child.length;
  }

  async openPlaylist(videoData: {
    isLastWorkout: boolean;
    totalTime: number;
    videoDataCategoryList: Array<Video>;
    categoryName;
    workoutId: string;
  }) {
    return this.storageServ.get('DownloadedCategories').then(async (list) => {
      if (
        !this.networkService.getStatusOnce() &&
        (!list ||
          !list.length ||
          (list.length &&
            !this.checkArraInArrayForCategoryList(
              list,
              videoData.categoryName.split(', ')
            )))
      ) {
        const videosCached = await Promise.all(
          videoData.videoDataCategoryList.map(async (v) => {
            const thumbCached = await this.networkService.isCached(v.thumbnail);
            console.log('Thumb cached: ', thumbCached);
            if (thumbCached) {
              const vidCached = await this.networkService.isCached(v.url);
              console.log('Vid cached: ', vidCached);
              if (vidCached) {
                return true;
              }
            }
            return false;
          })
        );
        console.log('videosCached: ', videosCached);
        const notCachedIndex = videosCached.findIndex((v) => !v);
        console.log('Not cached index', notCachedIndex);
        if (notCachedIndex !== -1) {
          return this.notificationService.showSuccess(
            'Oops! Looks like you do not have internet connection and this category videos are not downloaded.'
          );
        }
      }
      this.setPlaylistVideos(videoData);
      return this.router.navigate(['playlist']);
    });
  }

  getAllVideoInfo(category) {
    const categories =
      category.indexOf(', ') !== -1 ? category.split(', ') : [category];
    this.videoDataByCategory = this.videos.value.filter((item) => {
      return categories.indexOf(item.subcategory_name) !== -1;
    });

    this.firstIndexVideo = [];
    this.secondIndexVideo = [];

    if (categories.length === 2) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.videoDataByCategory.length; j++) {
        if (this.videoDataByCategory[j].subcategory_name === categories[0]) {
          this.firstIndexVideo.push(this.videoDataByCategory[j]);
          if (
            this.videoDataByCategory[j].is_other_side === 1 ||
            this.videoDataByCategory[j].is_three_video === 2 ||
            this.videoDataByCategory[j].is_three_video === 3
          ) {
            this.firstIndexOtherSideArrayList.push(this.videoDataByCategory[j]);
          }
        } else if (
          this.videoDataByCategory[j].subcategory_name === categories[1]
        ) {
          this.secondIndexVideo.push(this.videoDataByCategory[j]);

          if (this.videoDataByCategory[j].is_other_side === 1) {
            this.secondIndexOtherSideArrayList.push(
              this.videoDataByCategory[j]
            );
          }
        }
      }
    }

    this.firstIndexVideo = this.firstIndexVideo.filter(
      (item) =>
        item &&
        item.is_other_side !== 1 &&
        item.is_three_video !== 2 &&
        item.is_three_video !== 3
    );
    this.secondIndexVideo = this.secondIndexVideo.filter(
      (item) => item && item.is_other_side !== 1
    );
  }

  getFinalList(
    firstIndexVideos: any = [],
    secondIndexVideos: any = [],
    restObj: any
  ) {
    let finalArrayList = [];

    for (let i = 0; i < firstIndexVideos.length; i++) {
      finalArrayList.push(firstIndexVideos[i]);

      if (
        firstIndexVideos[i] &&
        firstIndexVideos[i].is_other_side &&
        firstIndexVideos[i].is_other_side === 0
      ) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.firstIndexOtherSideArrayList.length; j++) {
          if (
            firstIndexVideos[i].name ===
            this.firstIndexOtherSideArrayList[j].name
          ) {
            finalArrayList.push(this.firstIndexOtherSideArrayList[j]);
            break;
          }
        }
      }

      if (
        firstIndexVideos[i] &&
        firstIndexVideos[i].is_three_video &&
        firstIndexVideos[i].is_three_video === 1
      ) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.firstIndexOtherSideArrayList.length; j++) {
          if (this.firstIndexOtherSideArrayList[j].is_three_video === 2) {
            finalArrayList.push(this.firstIndexOtherSideArrayList[j]);
            break;
          }
        }
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.firstIndexOtherSideArrayList.length; j++) {
          if (this.firstIndexOtherSideArrayList[j].is_three_video === 3) {
            finalArrayList.push(this.firstIndexOtherSideArrayList[j]);
            break;
          }
        }
      }

      finalArrayList.push(secondIndexVideos[i]);

      if (
        secondIndexVideos[i] &&
        secondIndexVideos[i].is_other_side &&
        secondIndexVideos[i].is_other_side === 0
      ) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.secondIndexOtherSideArrayList.length; j++) {
          if (
            secondIndexVideos[i].name ===
            this.secondIndexOtherSideArrayList[j].name
          ) {
            finalArrayList.push(this.secondIndexOtherSideArrayList[j]);
            break;
          }
        }
      }
    }

    finalArrayList = finalArrayList.filter(
      (item) =>
        item &&
        item.video_id &&
        item.is_other_side !== 1 &&
        item.is_three_video !== 2 &&
        item.is_three_video !== 3
    );

    const newList = [];
    [...finalArrayList].forEach((el, index) => {
      if (el && el.video_id) {
        newList.push(el);
        if (restObj.restCount > 0 && index < restObj.restCount) {
          newList.push({ ...restObj });
        }
      }
    });

    console.log('Final List: ', finalArrayList, restObj, newList);
    finalArrayList = newList;

    let totalTime = 0;
    finalArrayList.forEach((e) => {
      totalTime += e.time_to_play;
      if (e.is_other_side === 0 || e.is_other_side === 1) {
        totalTime += e.time_to_play;
      }
    });

    return {
      totalTime: totalTime * 60 * 1000,
      videoDataCategoryList: finalArrayList,
    };
  }

  getSwipedList(
    videoDataCategoryList: Array<Video>,
    category,
    difficultyLevel,
    reInit = false,
    duration?: any,
    muscleCategory?: string
  ) {
    const categories =
      category.indexOf(', ') !== -1 ? category.split(', ') : [category];

    if (categories.length === 1) {
      return this.getVideosByCategory(
        categories[0],
        difficultyLevel,
        duration,
        muscleCategory
      );
    }

    videoDataCategoryList = videoDataCategoryList.filter(
      (e) => e && e.video_id
    );

    const settings = DifficultySettings[difficultyLevel];

    if (
      category.includes('lower body stretch') &&
      category.includes('upper body stretch') &&
      !muscleCategory
    ) {
      const settingsLeft = DifficultySettings[categories[0]][difficultyLevel];
      const settingsRight = DifficultySettings[categories[1]][difficultyLevel];

      settings.restTime = Math.max(
        settingsLeft.restTime,
        settingsRight.restTime
      );
      settings.restCount = Math.max(
        settingsLeft.restCount,
        settingsRight.restCount
      );
    }

    const firstList = [];
    const secondList = [];

    if (reInit) {
      this.getAllVideoInfo(category);
    }

    if (
      !this.subServ.trueCheckSubscriptionForPlayer() &&
      this.subServ.isFreemium()
    ) {
      this.firstIndexVideo = this.firstIndexVideo.filter(
        (item) => item && item.is_free
      );
      this.secondIndexVideo = this.secondIndexVideo.filter(
        (item) => item && item.is_free
      );
    }

    const user = this.userServ.getSyncUserOnce();
    if (user.equipment && user.equipment.findIndex((e) => e == 'chair') == -1) {
      this.firstIndexVideo = this.firstIndexVideo.filter(
        (item) => item && (item.tools as string).indexOf('Chair') == -1
      );
      this.secondIndexVideo = this.secondIndexVideo.filter(
        (item) => item && (item.tools as string).indexOf('Chair') == -1
      );
    }

    let saveList = [];
    this.firstIndexVideo.forEach((item) => {
      if (
        videoDataCategoryList.findIndex((e) => e.video_id === item.video_id) ===
        -1
      ) {
        firstList.push(item);
      } else {
        saveList.push(item);
      }
    });

    this.secondIndexVideo.forEach((item) => {
      if (
        videoDataCategoryList.findIndex((e) => e.video_id === item.video_id) ===
        -1
      ) {
        secondList.push(item);
      } else {
        saveList.push(item);
      }
    });

    if (muscleCategory) {
      this.firstIndexVideo = this.getShuffledArray(
        this.videos.value.filter(
          (item) =>
            category.includes(item.subcategory_name) &&
            (!item.is_other_side || item.is_other_side == 0) &&
            item.subcategory_name != 'upper body' &&
            (muscleCategory &&
            muscleCategory != 'total body' &&
            muscleCategory != 'hiit' &&
            muscleCategory != 'cardio' &&
            muscleCategory != 'upper body stretch' &&
            muscleCategory != 'lower body stretch' &&
            muscleCategory != 'wall pilates'
              ? item?.muscle_category &&
                item?.muscle_category?.findIndex((c) => c == muscleCategory) !=
                  -1
              : true)
        )
      );
      this.secondIndexVideo = this.getShuffledArray(
        this.videos.value.filter(
          (item) =>
            category.includes(item.subcategory_name) &&
            (!item.is_other_side || item.is_other_side == 0) &&
            item.subcategory_name != 'upper body' &&
            (muscleCategory &&
            muscleCategory != 'total body' &&
            muscleCategory != 'hiit' &&
            muscleCategory != 'cardio' &&
            muscleCategory != 'upper body stretch' &&
            muscleCategory != 'lower body stretch' &&
            muscleCategory != 'wall pilates'
              ? item?.muscle_category &&
                item?.muscle_category?.findIndex((c) => c == muscleCategory) !=
                  -1
              : true)
        )
      );
    } else {
      this.firstIndexVideo = this.getShuffledArray(firstList);
      this.secondIndexVideo = this.getShuffledArray(secondList);
    }

    saveList = this.getShuffledArray(saveList);
    console.log('Save List: ', saveList);
    let startSaveIndex = 0;

    const finalFirstList = [];
    const finalSecondList = [];

    for (let i = 0; i < settings.numOfVideos / 2; i++) {
      if (this.firstIndexVideo[i]) {
        finalFirstList.push(this.firstIndexVideo[i]);
      } else {
        finalFirstList.push(saveList[startSaveIndex++]);
      }
      if (this.secondIndexVideo[i]) {
        finalSecondList.push(this.secondIndexVideo[i]);
      } else {
        finalSecondList.push(saveList[startSaveIndex++]);
      }
    }

    const restObj = {
      ...this.restScreen,
      time_to_play: settings.restTime,
      restCount: settings.restCount,
    };

    console.log('Final List: ', finalFirstList, finalSecondList, restObj);

    return this.getFinalList(finalFirstList, finalSecondList, restObj);
  }

  setShuffledArray(
    category: string,
    difficultyLevel: Difficulty | 'WarmUpCoolDown',
    duration?: any,
    muscleCategory?: string
  ) {
    const categories =
      category.indexOf(', ') !== -1 ? category.split(', ') : [category];

    if (categories.length === 1) {
      return this.getVideosByCategory(
        categories[0],
        difficultyLevel,
        duration,
        muscleCategory
      );
    }

    const settings = DifficultySettings[difficultyLevel];

    if (
      category.includes('lower body stretch') &&
      category.includes('upper body stretch') &&
      !muscleCategory
    ) {
      const settingsLeft = DifficultySettings[categories[0]][difficultyLevel];
      const settingsRight = DifficultySettings[categories[1]][difficultyLevel];

      settings.restTime = Math.max(
        settingsLeft.restTime,
        settingsRight.restTime
      );
      settings.restCount = Math.max(
        settingsLeft.restCount,
        settingsRight.restCount
      );
    }

    if (
      !this.subServ.trueCheckSubscriptionForPlayer() &&
      this.subServ.isFreemium()
    ) {
      this.firstIndexVideo = this.firstIndexVideo.filter(
        (item) => item && item.is_free
      );
      this.secondIndexVideo = this.secondIndexVideo.filter(
        (item) => item && item.is_free
      );
    }

    const user = this.userServ.getSyncUserOnce();
    if (user.equipment && user.equipment.findIndex((e) => e == 'chair') == -1) {
      this.firstIndexVideo = this.firstIndexVideo.filter(
        (item) => item && (item.tools as string).indexOf('Chair') == -1
      );
      this.secondIndexVideo = this.secondIndexVideo.filter(
        (item) => item && (item.tools as string).indexOf('Chair') == -1
      );
    }

    if (muscleCategory) {
      this.firstIndexVideo = this.getShuffledArray(
        this.videos.value.filter(
          (item) =>
            category.includes(item.subcategory_name) &&
            item.subcategory_name != 'upper body' &&
            (muscleCategory &&
            muscleCategory != 'total body' &&
            muscleCategory != 'hiit' &&
            muscleCategory != 'cardio' &&
            muscleCategory != 'upper body stretch' &&
            muscleCategory != 'lower body stretch' &&
            muscleCategory != 'wall pilates'
              ? item?.muscle_category &&
                item?.muscle_category?.findIndex((c) => c == muscleCategory) !=
                  -1
              : true)
        )
      );
      this.secondIndexVideo = this.getShuffledArray(
        this.videos.value.filter(
          (item) =>
            category.includes(item.subcategory_name) &&
            item.subcategory_name != 'upper body' &&
            (muscleCategory &&
            muscleCategory != 'total body' &&
            muscleCategory != 'hiit' &&
            muscleCategory != 'cardio' &&
            muscleCategory != 'upper body stretch' &&
            muscleCategory != 'lower body stretch' &&
            muscleCategory != 'wall pilates'
              ? item?.muscle_category &&
                item?.muscle_category?.findIndex((c) => c == muscleCategory) !=
                  -1
              : true)
        )
      );
    } else {
      this.firstIndexVideo = this.getShuffledArray(this.firstIndexVideo);
      this.secondIndexVideo = this.getShuffledArray(this.secondIndexVideo);
    }

    const finalFirstList = [];
    const finalSecondList = [];

    for (let i = 0; i < settings.numOfVideos / 2; i++) {
      if (this.firstIndexVideo[i]) {
        finalFirstList.push(this.firstIndexVideo[i]);
      }
      if (this.secondIndexVideo[i]) {
        finalSecondList.push(this.secondIndexVideo[i]);
      }
    }

    const restObj = {
      ...this.restScreen,
      time_to_play: settings.restTime,
      restCount: settings.restCount,
    };

    return this.getFinalList(finalFirstList, finalSecondList, restObj);
  }

  getWarmUps(category: string) {
    const categories =
      category.includes('abs') && category.includes('planks')
        ? ['abs']
        : category.split(', ');

    const cardios = this.setShuffledArray('cardio', Difficulty.Beginner, 2);
    const stretches = categories.map((c) => {
      return this.setShuffledArray(
        c == 'legs' || c == 'lower body stretch' || c == 'booty'
          ? 'lower body stretch'
          : c == 'wall pilates' || c == 'total body'
          ? 'upper body stretch, lower body stretch'
          : 'upper body stretch',
        'WarmUpCoolDown',
        0,
        c
      );
    });

    return {
      totalTime:
        cardios.totalTime +
        stretches.reduce((acc, curr) => acc + curr.totalTime, 0),
      videoDataCategoryList: [
        ...cardios.videoDataCategoryList,
        ...stretches.reduce(
          (acc, curr) => [...acc, ...curr.videoDataCategoryList],
          []
        ),
      ],
    };
  }

  getCoolDowns(category: string) {
    const categories =
      category.includes('abs') && category.includes('planks')
        ? ['abs']
        : category.split(', ');

    const stretches = categories.map((c) => {
      return this.setShuffledArray(
        c == 'legs' || c == 'lower body stretch' || c == 'booty'
          ? 'lower body stretch'
          : c == 'wall pilates' || c == 'total body'
          ? 'upper body stretch, lower body stretch'
          : 'upper body stretch',
        'WarmUpCoolDown',
        0,
        c
      );
    });
    return {
      totalTime: stretches.reduce((acc, curr) => acc + curr.totalTime, 0),
      videoDataCategoryList: [
        ...stretches.reduce(
          (acc, curr) => [...acc, ...curr.videoDataCategoryList],
          []
        ),
      ],
    };
  }

  async downloadVideoCategory(category: string) {
    return this.fileManagerServ
      .downloadVideoCategory(category)
      .then((res: Video[]) => {
        return this.setVideos(res);
      });
  }

  async deleteVideoCategory(category: string) {
    return this.fileManagerServ
      .deleteVideoCategory(category)
      .then((res: Video[]) => {
        return this.setVideos(res);
      });
  }
}
