<bottom-sheet #bottomSheetRadio>
  <div class="modal-select-container">
    <p class="modal-header">{{ "Difficulty level" | translate }}</p>

    <div
      class="select-item"
      *ngFor="let item of options"
      (click)="level = item.value"
    >
      <label class="container">
        <ion-icon [src]="'assets/newUI/icons/' + item.name + '.svg'"></ion-icon>
        <span class="title">{{ item.name | translate }}</span>
        <input
          type="radio"
          [checked]="item.value == level"
          [value]="item.value"
          name="radio"
        />
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="modal-btn-container">
      <ion-button
        expand="full"
        mode="ios"
        class="btn-cancel"
        (click)="cancel()"
        shape="round"
        color="grey"
        style="font-size: 17px"
      >
        {{ "cancel" | translate }}
      </ion-button>

      <ion-button
        expand="full"
        mode="ios"
        class="btn-submit"
        (click)="submit()"
        shape="round"
        color="primary"
        style="font-size: 17px"
      >
        {{ "save" | translate }}
      </ion-button>
    </div>
  </div>
</bottom-sheet>
