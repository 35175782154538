import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { User } from "firebase/auth";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(public auth: AuthService, public router: Router) {}

  private authStateHandler(user: User) {
    if (!!user) {
      return true;
    }
    this.router.navigate(["/onboarding"]); //TODO: landing
    return false;
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.afAuth.authState.pipe(map((user) => this.authStateHandler(user)));
  }
}
