import { Directive, ElementRef, HostListener } from "@angular/core";
import { Keyboard } from "@capacitor/keyboard";
import { Platform } from "@ionic/angular";

@Directive({
  selector: "[appScrollIntoView]",
})
export class ScrollIntoViewDirective {
  constructor(private el: ElementRef, private platform: Platform) {}

  @HostListener("focus") onFocus() {
    if (!this.platform.is("capacitor")) {
      return;
    }
    Keyboard.addListener("keyboardDidShow", (info) => {
      try {
        if (this.platform.is("android")) {
          // console.log(
          //   "Ele",
          //   (this.el.nativeElement as HTMLElement).offsetTop,
          //   (this.el.nativeElement as HTMLElement).clientTop,
          //   (this.el.nativeElement as HTMLElement).scrollTop,
          //   (this.el.nativeElement as HTMLElement).parentElement.scrollTop,
          //   (this.el.nativeElement as HTMLElement).parentElement.clientTop,
          //   (this.el.nativeElement as HTMLElement).parentElement.offsetTop,
          //   (this.el.nativeElement as HTMLElement).clientHeight,
          //   (this.el.nativeElement as HTMLElement).offsetHeight,
          //   (this.el.nativeElement as HTMLElement).scrollHeight
          // );
          // console.log("Keyboard height: ", info.keyboardHeight, window.innerHeight);
          if (
            /* Object.values(this.el.nativeElement.parentElement.classList).indexOf("sn-viewport--out") !== -1 */
            (this.el.nativeElement as HTMLElement).parentElement.offsetTop +
              (this.el.nativeElement as HTMLElement).parentElement.offsetHeight >
            window.innerHeight - info.keyboardHeight
          ) {
            this.el.nativeElement.scrollIntoView({ block: "nearest", behavior: "auto" });
          }
        } else {
          if (Object.values(this.el.nativeElement.parentElement.classList).indexOf("sn-viewport--out") !== -1) {
            this.el.nativeElement.scrollIntoView({ block: "center", behavior: "auto" });
          }
          setTimeout(() => {
            if (Object.values(this.el.nativeElement.parentElement.classList).indexOf("sn-viewport--out") !== -1) {
              this.el.nativeElement.scrollIntoView({ block: "center", behavior: "auto" });
            }
          }, 300);
        }
      } catch (error) {
        console.log(error);
      }
    });
  }
}
