import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  // _storage: Storage;

  constructor(private storage: Storage) {
    // this.init();
  }

  async init() {
    return this.storage.create();
  }

  async get(id: string): Promise<any> {
    await this.init();
    return this.storage.get(id);
  }

  async set(id: string, body: any): Promise<any> {
    await this.init();
    return this.storage.set(id, body);
  }

  async remove(id: string): Promise<any> {
    await this.init();
    return this.storage.remove(id);
  }
}
