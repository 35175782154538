import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { RateApp } from "capacitor-rate-app";
import { Timestamp } from "firebase/firestore";
import { LoggerService } from "./logger.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class ReviewService {
  constructor(private userServ: UserService, private fireDB: AngularFirestore, private loggerService: LoggerService) {}

  async rateApp() {
    return RateApp.requestReview().catch((err) => {
      console.error(err);
    });
  }

  async hasReviewed() {
    const user = this.userServ.getSyncUserOnce();
    if (user) {
      this.userServ.setSyncUser({ ...user, hasReviewed: true });
    }
  }

  async saveReview(feedback: string, rating: number) {
    const user = this.userServ.getSyncUserOnce();
    if (user) {
      const feedbackData = {
        user_id: user.uid,
        user_email: user?.email,
        created_at: Timestamp.now(),
        feedback,
        rating,
      };

      this.fireDB
        .collection("Feedback")
        .add(feedbackData)
        .catch((error) => {
          console.error(error);
          this.loggerService.logError(error);
        });
    }
  }
}
