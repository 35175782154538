import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Camera,
  CameraResultType,
  CameraSource,
  ImageOptions,
} from '@capacitor/camera';
import { Platform } from '@ionic/angular';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CameraService {
  constructor(private platform: Platform, private http: HttpClient) {}

  async getPhoto(
    source: CameraSource = CameraSource.Camera,
    allowEditing = true,
    size: number = 2500
  ) {
    const options: ImageOptions = {
      quality: 70,
      width: size,
      height: size,
      resultType: CameraResultType.DataUrl,
      // destinationType: this.camera.DestinationType.DATA_URL,
      // encodingType: this.camera.EncodingType.JPEG,
      // mediaType: this.camera.MediaType.PICTURE,
      allowEditing,
      source,
    };

    if (this.platform.is('hybrid')) {
      return Camera.getPhoto({ ...options }).then(
        (imageData) => imageData.dataUrl
      );
    }

    return await this.http
      .get(`https://picsum.photos/1024/1024.jpg`, { responseType: 'blob' })
      .pipe(
        switchMap((response: any) => {
          const reader = new FileReader();
          reader.readAsDataURL(response);
          return new Promise((resolve, reject) => {
            reader.onload = () => {
              return resolve(reader.result as string);
            };
          });
        })
      )
      .toPromise();
  }
}
