import { Injectable } from '@angular/core';
import { Functions } from '@angular/fire/functions';
// import { AngularFireFunctions } from "@angular/fire/functions";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  // tslint:disable-next-line:variable-name
  private _status = new BehaviorSubject(navigator.onLine);

  constructor(private functions: Functions) {
    addEventListener('online', (e) => {
      this._status.next(true);
    });
    addEventListener('offline', (e) => {
      this._status.next(false);
    });
  }

  async isCached(url: string): Promise<boolean> {
    if ('caches' in window) {
      console.log('Caches in window');
      const isCached = await caches
        .match(url)
        .then((cachedResponse) => {
          console.log(`Cached response for ${url} - `, cachedResponse);
          if (cachedResponse) {
            // Serve cached content
            return cachedResponse.ok;
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.error('Error matching url in cache: ', err);
          return false;
        });
      console.log('Is cached: ', isCached);
      return isCached;
    } else {
      return false;
    }
  }

  get status(): Observable<boolean> {
    return this._status.asObservable();
  }

  getStatusOnce(): boolean {
    return this._status.value;
  }
}
