import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import { WebView } from "@ionic-native/ionic-webview/ngx";
import { Video } from "../models/video";

@Pipe({
  name: "photoThumbnailFormat",
})
export class PhotoThumbnailFormat implements PipeTransform {
  constructor(private webView: WebView, private sanitize: DomSanitizer) {}

  transform(video: Video): any {
    console.log("Video: ", video);
    if (video && video.file_thumbnail_url) {
      const url = this.sanitize.bypassSecurityTrustStyle(`url(${video.file_thumbnail_url})`);
      return url;
    }

    const url = this.sanitize.bypassSecurityTrustUrl(`url("${video && video.thumbnail ? video.thumbnail : ""}")`);
    return url;
  }
}
