<bottom-sheet #bottomSheet [noScroll]="true">
  <div class="modal-select-container darkBackground" *ngIf="isSingleCategory">
    <div *ngIf="!inProgress">
      <p style="text-align: center" class="modal-header">
        {{ "are_you_sure" | translate }}
      </p>
      <p style="text-align: center">
        {{
          "You want to " + (action === "download" ? "download" : "delete")
            | translate
        }}
        <span *ngFor="let category of targetPresentList; let i = index"
          >"{{ category | translate }}"{{
            i == targetPresentList.length - 1 ? "" : ", "
          }}</span
        >
      </p>

      <div class="modal-btn-container">
        <ion-button
          expand="full"
          mode="ios"
          class="btn-cancel"
          shape="round"
          color="grey"
          (click)="cancel()"
        >
          {{ "no" | translate }}
        </ion-button>
        <ion-button
          expand="full"
          mode="ios"
          class="btn-submit"
          shape="round"
          color="primary"
          (click)="onClickSingle()"
        >
          {{ "yes" | translate }}
        </ion-button>
      </div>
    </div>

    <div *ngIf="inProgress">
      <p class="modal-header">
        {{
          (action === "download"
            ? "Use videos offline"
            : "Delete offline videos"
          ) | translate
        }}
      </p>
      <p *ngIf="sizeToDownload">
        {{
          (action === "download" ? "Download size" : "Delete size") | translate
        }}
        {{ sizeToDownload }}
      </p>

      <div
        class="round-progress-wrap"
        *ngIf="inProgress && (progress | async) as progress"
      >
        <div class="round-progress-meta">
          <p class="progress-meta-percent">
            {{
              (progress?.current > 0 && progress?.total > 0
                ? (progress?.current * 100) / progress?.total >= 100
                  ? 100
                  : (progress?.current * 100) / progress?.total
                : 0
              ).toFixed(0)
            }}
            <span class="percent"> % </span>
          </p>
          <p class="progress-meta-wait">
            {{
              (action === "download"
                ? "videos_are_downloading_please_wait"
                : "Videos_are_deleting"
              ) | translate
            }}
          </p>
        </div>

        <round-progress
          class="round-progress"
          [current]="progress?.current"
          [max]="progress?.total"
          [color]="
            action === 'download' ? 'var(--ion-color-primary)' : '#FF6E6E'
          "
          [background]="'rgb(0, 0, 0, 0.1)'"
          [radius]="125"
          [stroke]="10"
          [semicircle]="false"
          [rounded]="true"
          [clockwise]="true"
          [duration]="800"
          [animation]="'easeInOutQuart'"
          [animationDelay]="0"
        >
        </round-progress>
      </div>

      <div class="modal-btn-container" [hidden]="action !== 'download'">
        <ion-button
          expand="full"
          mode="ios"
          class="btn-cancel"
          (click)="cancelDownload()"
          color="grey"
          shape="round"
        >
          {{ "cancel" | translate }}
        </ion-button>
      </div>
    </div>
  </div>

  <div class="modal-select-container" *ngIf="!isSingleCategory">
    <div
      style="
        overflow: scroll;
        padding: 0 20px;
        max-height: calc(
          100vh - 24px - 152px - 54px - var(--ion-safe-area-top) -
            var(--ion-safe-area-bottom)
        );
      "
    >
      <p class="modal-header">
        {{
          (action === "download"
            ? "Use videos offline"
            : "Delete offline videos"
          ) | translate
        }}
      </p>
      <p *ngIf="action == 'download' || downloadedCategories?.length > 0">
        {{
          (action === "download" ? "Download size" : "Delete size") | translate
        }}
        {{ sizeToDownload }}
      </p>
      <p *ngIf="action == 'delete' && downloadedCategories?.length == 0">
        {{ "You have no offline videos to delete" | translate }}
      </p>

      <div
        class="round-progress-wrap"
        *ngIf="inProgress && (progress | async) as progress"
        [style.margin-bottom]="action === 'download' ? '' : '1.5rem'"
      >
        <div class="round-progress-meta">
          <p class="progress-meta-percent">
            {{
              (progress?.current > 0 && progress?.total > 0
                ? (progress?.current * 100) / progress?.total >= 100
                  ? 100
                  : (progress?.current * 100) / progress?.total
                : 0
              ).toFixed(0)
            }}
            <span class="percent">%</span>
          </p>
          <p class="progress-meta-wait">
            {{
              (action === "download"
                ? "videos_are_downloading_please_wait"
                : "Videos_are_deleting"
              ) | translate
            }}
          </p>
        </div>

        <round-progress
          class="round-progress"
          [current]="progress?.current"
          [max]="progress?.total"
          [color]="
            action === 'download' ? 'var(--ion-color-primary)' : '#FF6E6E'
          "
          [background]="'rgb(0, 0, 0, 0.1)'"
          [radius]="125"
          [stroke]="10"
          [semicircle]="false"
          [rounded]="true"
          [clockwise]="true"
          [duration]="800"
          [animation]="'easeInOutQuart'"
          [animationDelay]="0"
        >
        </round-progress>
      </div>

      <div *ngIf="!inProgress">
        <ng-container *ngFor="let item of videosList">
          <div
            class="select-item"
            *ngIf="
              action == 'download' || (action == 'delete' && item.isDownloaded)
            "
          >
            <label
              class="container"
              [style.pointer-events]="
                item.isDownloaded && action == 'download' ? 'none' : ''
              "
            >
              <span class="title">{{ item.name | translate }}</span>
              <input
                type="checkbox"
                [(ngModel)]="item.isChecked"
                (ngModelChange)="calcVideosSize($event, item)"
                [value]="item.value"
                name="checkbox"
              />
              <span
                *ngIf="!item.isDownloaded || action == 'delete'"
                class="checkmark"
              ></span>
              <ion-icon
                style="position: absolute; right: 0px; font-size: 24px"
                *ngIf="item.isDownloaded && action == 'download'"
                [src]="'assets/icon/checkmark.svg'"
              ></ion-icon>
            </label>
          </div>
        </ng-container>
        <div *ngIf="videosList?.length > 3" style="height: 2rem"></div>
      </div>
    </div>
    <div
      class="modal-btn-container"
      [hidden]="inProgress && action !== 'download'"
      [class.shadow]="videosList?.length > 3"
    >
      <ion-button
        *ngIf="!inProgress"
        expand="full"
        mode="ios"
        class="btn-cancel"
        shape="round"
        color="grey"
        (click)="cancel()"
        style="font-size: 17px"
      >
        {{ "cancel" | translate }}
      </ion-button>

      <ion-button
        *ngIf="action == 'download' || downloadedCategories?.length > 0"
        expand="full"
        mode="ios"
        shape="round"
        [ngClass]="{
          'btn-cancel': inProgress,
          'btn-submit': !inProgress
        }"
        [color]="!inProgress ? 'primary' : 'grey'"
        (click)="inProgress ? cancelDownload() : onClick()"
        style="font-size: 17px"
      >
        {{
          (inProgress
            ? "cancel"
            : action === "download"
            ? "Download"
            : "Delete"
          ) | translate
        }}
      </ion-button>
    </div>
  </div>
</bottom-sheet>
