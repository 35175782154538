import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Functions } from '@angular/fire/functions';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import {
  LocalNotifications,
  LocalNotificationSchema,
  Schedule,
} from '@capacitor/local-notifications';
import {
  LoadingController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { base64StringToBlob } from 'blob-util';
import { FirebaseError } from 'firebase/app';
import { deleteUser, getAuth } from 'firebase/auth';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getBoolean, getNumber } from 'firebase/remote-config';
import { isEqual } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { environment, remoteConfig } from '../../environments/environment';
import { DEFAULT_WEEKLY_DAY } from '../constants';
import { Difficulty } from '../enums/Difficulty';
import { User } from '../models/user';
import { UserAdapter } from '../shared/user.adapter';
import { AnalyticsService } from './analytics.service';
import { LoggerService } from './logger.service';
import { NetworkService } from './network.service';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly DEFAULT_PROFILE_IMAGE = 'assets/newUI/default-profile.png';

  private imageIsUploading: boolean;
  private _user = new BehaviorSubject<User>({});
  private _userImg = new BehaviorSubject<{
    image?: string | any;
    isUploaded?: boolean;
  }>({
    image: this.DEFAULT_PROFILE_IMAGE,
    isUploaded: true,
  });
  uploadProgress: Observable<number>;
  downloadedCategories: Array<string> = [];
  networkStatus: boolean;
  uploadRef: any;

  constructor(
    private storageServ: StorageService,
    private http: HttpClient,
    private fireDB: AngularFirestore,
    private afStorage: AngularFireStorage,
    private adapter: UserAdapter,
    private network: NetworkService,
    private fns: AngularFireFunctions,
    private analyticsService: AnalyticsService,
    private loggerService: LoggerService,
    private loadingController: LoadingController,
    private navCtrl: NavController,
    private toastCtrl: ToastController, // private onboardingService: OnboardingService

    private func: Functions
  ) {
    this.getUserFromLocalStorage().then((user) => {
      this._user.next(user);
    });
    // this.downloadedCategories = [];
    this.network.status.subscribe((status) => {
      this.networkStatus = status;
      if (status && this._user.value && this._user.value.uid) {
        this.downloadImage(this._user.value.image);
        this.syncImage();
      }
    });
  }

  private isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  private mergeDeep(target, ...sources) {
    if (!sources.length) {
      return target;
    }
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }

  private setNotification(data: {
    id: number;
    name: string;
    time: string;
    isChecked: boolean;
  }): LocalNotificationSchema {
    let schedule: Schedule = {
      on: {
        weekday: moment().day(data.name).weekday() + 1,
        hour: moment(data.time).hour(),
        minute: moment(data.time).minute(),
      },
      allowWhileIdle: true,
    };
    return {
      id: data.id,
      title: 'Hone Workout',
      body: 'It`s time for workout!',
      // foreground: true,
      schedule,
    };
  }

  private async adjustNotification(
    reminder: Array<{
      name: string;
      time: string;
      isChecked: boolean;
      subReminders: Array<{ time: string; isChecked: boolean }>;
    }>
  ) {
    console.log('UserService::adjustNotification()');
    const notificationArray: LocalNotificationSchema[] = [];
    let index = 0;
    LocalNotifications.getPending().then((res) => {
      if (res.notifications.length > 0) {
        LocalNotifications.cancel({ notifications: res.notifications });
      }
    });
    reminder.forEach((e) => {
      if (e.isChecked) {
        notificationArray.push(this.setNotification({ ...e, id: index++ }));
      }
      e.subReminders.forEach((sub) => {
        if (sub.isChecked) {
          notificationArray.push(
            this.setNotification({ ...sub, name: e.name, id: index++ })
          );
        }
      });
    });
    if (notificationArray.length > 0) {
      const permission = await LocalNotifications.checkPermissions();
      if (permission.display != 'granted') {
        const requestedPerm = await LocalNotifications.requestPermissions();
        if (requestedPerm.display == 'granted') {
          LocalNotifications.schedule({ notifications: notificationArray });
        }
      } else {
        LocalNotifications.schedule({ notifications: notificationArray });
      }
    }
  }

  private async blobToBase64(blob: Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise(
      (resolve, reject) => (reader.onloadend = () => resolve(reader.result))
    ).catch(() => {});
  }

  callFireFunction() {
    if (this._user.value && this._user.value.uid) {
      const callable = this.fns.httpsCallable('calcStatisticHttp');
      const data = callable({ userUID: this._user.value.uid });
      return data
        .pipe(take(1))
        .toPromise()
        .catch((error) => {
          console.error('Error: calcStatisticHttp - ', error);
          // this.loggerService.logError(error);
        });
    }
    return Promise.resolve(null);
  }

  getMidnight(date: Date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ).getTime();
  }

  async getUserReminder(): Promise<
    Array<{
      name: string;
      time: string;
      isChecked: boolean;
      subReminders: Array<{ time: string; isChecked: boolean }>;
    }>
  > {
    return await this.storageServ.get('UserReminder');
  }

  async setUserReminder(
    reminder: Array<{
      name: string;
      time: string;
      isChecked: boolean;
      subReminders: Array<{ time: string; isChecked: boolean }>;
    }>
  ) {
    console.log('UserService::setUserReminder()', reminder);
    await this.adjustNotification(reminder);
    return await this.storageServ.set('UserReminder', reminder);
  }

  async resetUser(all: boolean) {
    console.log('UserService::resetUser()');
    await this.adjustNotification([]);
    this.storageServ.remove('Workouts');
    this.storageServ.remove('StoredUserImage');
    this.storageServ.remove('UserReminder');
    // this.userWeightCache = null;
    if (all) {
      const newUser = {
        uid: this._user.value.uid,
        email: this._user.value?.email,
        language: this._user.value.language,
        difficulty: Difficulty.Beginner,
        image: this._user.value.image,
        username: this._user.value.username,
        subscription: {
          isSubscribed: false,
          hasSubscribed: false,
          planName: '',
          transitionId: '',
        },
        exerciseInfo: {
          completedWeeklyGoals: 0,
          resetTime: moment()
            .day(DEFAULT_WEEKLY_DAY.split(' ')[0])
            .add(1, 'week')
            .hour(0)
            .minute(0)
            .second(0)
            .toDate()
            .getTime(),
        },
        countdownEnd: this._user.value?.countdownEnd ?? null,
        hasReviewed: this._user.value?.hasReviewed ?? false,
      } as User;
      this._user.next(newUser);
      this.storageServ.set('StoredUser', newUser);
      this._userImg.next({ image: this._user.value.image, isUploaded: true });
      this.storageServ.remove('UserWorkoutsList');
      this.storageServ.remove('UsersWeightProgress');

      await this.fireDB
        .collection('Users')
        .doc(this._user.value.uid)
        .set(newUser)
        .catch((error) => {
          console.error(error);
          this.loggerService.logError(error);
        });

      await this.fireDB
        .collection('Users')
        .doc(this._user.value.uid)
        .collection('planWorkouts')
        .ref.get({ source: 'server' })
        .then((e) => {
          const promises = [];
          e.forEach((item) => {
            promises.push(
              item.ref.delete().catch((error) => {
                this.loggerService.logError(error);
              })
            );
          });
          return Promise.all(promises);
        })
        .catch((error) => {
          console.error(error);
          this.loggerService.logError(error);
        });

      await this.fireDB
        .collection('Workout')
        .doc(this._user.value.uid)
        .collection('workouts')
        .ref.get({ source: 'server' })
        .then((e) => {
          const promises = [];
          e.forEach((item) => {
            promises.push(
              item.ref.delete().catch((error) => {
                this.loggerService.logError(error);
              })
            );
          });
          return Promise.all(promises);
        })
        .catch((error) => {
          console.error(error);
          this.loggerService.logError(error);
        });
      await this.fireDB
        .collection('UsersWeightProgress')
        .doc(this._user.value.uid)
        .set({})
        .catch((error) => {
          console.error(error);
          this.loggerService.logError(error);
        });
      await this.afStorage
        .ref(`/users/progress/${this._user.value.uid}`)
        .delete()
        .toPromise()
        .catch((error) => {
          console.error(error);
          this.loggerService.logError(error);
        });
      return;
    }
    this._user.next({});
    this._userImg.next({ image: this.DEFAULT_PROFILE_IMAGE, isUploaded: true });
    this.storageServ.remove('StoredUser');
    localStorage.removeItem('email');
    this.storageServ.remove('UserWorkoutsList');
    this.storageServ.remove('UsersWeightProgress');
  }

  async deleteUser() {
    const loading = await this.loadingController.create();
    await loading.present();

    const auth = getAuth();
    const user = auth.currentUser;

    deleteUser(user)
      // })
      .then(() => {
        this.resetUser(false);
        this.navCtrl.navigateRoot('/onboarding'); // TODO: landing
      })
      .catch(async (error: FirebaseError) => {
        let message = 'An error has occurred, please try again';
        if (error.code == 'auth/requires-recent-login') {
          message =
            'Please log out and log back in to re-authenticate & delete your data';
        }
        const toast = await this.toastCtrl.create({
          message,
          duration: 5000,
        });
        await toast.present();

        console.error(error);
        this.loggerService.logError(error);
      })
      .finally(async () => {
        await loading.dismiss();
      });
  }

  getDifficultyLevel(): Difficulty {
    return this._user.value.difficulty || Difficulty.Beginner;
  }

  async downloadImage(url: string) {
    if (
      !url ||
      !url.startsWith('http') ||
      (this._userImg.value &&
        this._user.value.uid &&
        this._userImg.value.isUploaded &&
        this._userImg.value.image !== this.DEFAULT_PROFILE_IMAGE) ||
      (this._userImg.value.image &&
        this._userImg.value.image !== this.DEFAULT_PROFILE_IMAGE)
    ) {
      return Promise.resolve();
    }
    const res = await this.http
      .get(url, { responseType: 'blob' })
      .pipe(take(1))
      .toPromise();
    const base64 = (await this.blobToBase64(res)) as string;
    const isUploaded = !!url.indexOf(environment.firebase.storageBucket);
    this.setSyncImage({ image: base64, isUploaded });
    return base64;
  }

  private syncUser(user: User) {
    try {
      user = JSON.parse(JSON.stringify(user));
      this.fireDB
        .collection('Users')
        .doc(user?.uid || this._user?.value?.uid)
        .set(user, { merge: true })
        .catch((error) => {
          console.error(error);
          this.loggerService.logError(error);
        });
    } catch (error) {
      console.error(error);
      this.loggerService.logError({ ...error, user });
    }
  }

  getFirestoreUser(uid?: string, onNext?: (user: User) => any) {
    const UID = uid ?? this._user.value.uid;
    if (!UID) {
      return;
    }
    onSnapshot(
      doc(getFirestore(), 'Users', UID),
      (doc) => {
        const user = this.adapter.adapt(doc.data());
        onNext(user);
      },
      (err) => {
        console.error('Error: ', err);
      }
    );
  }

  getSyncUser(): Observable<User> {
    return this._user.asObservable();
  }

  async getUserFromLocalStorage(): Promise<User> {
    return this.storageServ.get('StoredUser');
  }

  getSyncUserOnce(): User {
    return this._user.value;
  }

  async setSyncUser(user: any, downloadImage = false) {
    const storedUser = await this.storageServ.get('StoredUser');
    const updatedUser = this.mergeDeep(storedUser || {}, user);
    if (Capacitor.getPlatform() != 'web') {
      if (!isEqual(updatedUser, this._user.value)) {
        this.syncUser(updatedUser);
        this._user.next(updatedUser);
      }
      if (updatedUser.image != this._userImg.value.image) {
        if (!updatedUser.image) {
          this._userImg.next({
            image: this.DEFAULT_PROFILE_IMAGE,
            isUploaded: true,
          });
        } else {
          this._userImg.next({ image: updatedUser.image, isUploaded: true });
        }
      }
      if (downloadImage) {
        this.downloadImage(updatedUser.image);
      }

      if (user && user.uid) {
        try {
          this.analyticsService.setUserProperty('UID', user.uid);
        } catch (e) {}
      }
      if (user && user.email) {
        try {
          this.analyticsService.setUserProperty('EMAIL', user.email);
        } catch (e) {}
      }
    } else {
      this._user.next(updatedUser);
    }
    console.log('UserService::setSyncUser()', user);
    return await this.storageServ.set('StoredUser', updatedUser);
  }

  countdownEnded() {
    const now = new Date().getTime();
    const end = this._user.value?.countdownEnd;
    if (end && now > end * 1000) {
      return true;
    }
    return false;
  }

  async isDeviceInUse() {
    const device = await Device.getId();

    const callable = httpsCallable(this.func, 'isDeviceInUse');
    return callable({ device_uuid: device.identifier });
  }

  async isEmailInUse(email: string) {
    const callable = httpsCallable(this.func, 'isEmailInUse');
    return callable({ email });
  }

  async startCountdown() {
    if (this._user.value.countdownEnd) {
      return;
    }
    const countdownSeconds = getNumber(
      remoteConfig,
      environment.production ? 'freemium_countdown' : 'test_freemium_countdown'
    );
    if (countdownSeconds == 0 || this.isFreemium()) {
      return;
    }

    const now = new Date().getTime();

    let end = now / 1000 + countdownSeconds;

    this.setSyncUser({ countdownEnd: end });
    return end && now > end * 1000;
  }

  async setSyncImage(
    data: { image?: string | any; isUploaded?: boolean },
    sync = true
  ) {
    if (!this._user.value || !this._user.value.uid) {
      setTimeout(() => {
        this.setSyncImage(data, sync);
      }, 1000);
      return Promise.resolve();
    }
    const storedImage = await this.storageServ.get('StoredUserImage');
    const updatedImage = { ...storedImage, ...data };
    if (!updatedImage || !updatedImage.image || updatedImage.image === '') {
      updatedImage.image = this.DEFAULT_PROFILE_IMAGE;
    }
    this._userImg.next(updatedImage);
    await this.storageServ.set('StoredUserImage', updatedImage);
    if (
      updatedImage.image === this.DEFAULT_PROFILE_IMAGE ||
      updatedImage.isUploaded ||
      !sync
    ) {
      return Promise.resolve();
    }
    return this.syncImage();
  }

  async syncImage() {
    const storedImage = this._userImg.value;
    if (
      storedImage &&
      storedImage.image &&
      !storedImage.isUploaded &&
      !this.imageIsUploading &&
      storedImage.image != this.DEFAULT_PROFILE_IMAGE
    ) {
      const uid = this._user.value.uid;
      if (!uid) {
        console.log('UserService::syncImage()', 'uid - not found');
        return Promise.reject();
      }
      let file: any;
      try {
        file = base64StringToBlob(
          storedImage.image.split(',')[1],
          'image/jpeg'
        );
      } catch (e) {
        console.error('Error base64 to blob: ', e);
        return of(null);
      }
      this.imageIsUploading = true;
      console.log('UserService::syncImage()', 'start uploading');
      return (this.uploadProgress = this.afStorage
        .upload(`/users/images/${uid}`, file)
        .percentageChanges()
        .pipe(
          finalize(async () => {
            const ref = this.afStorage.ref(`/users/images/${uid}`);
            const url = await ref.getDownloadURL().pipe(take(1)).toPromise();
            this.imageIsUploading = false;
            console.log('UserService::syncImage()', 'image uploaded');
            this.setSyncUser({ image: url, uid: this._user.value.uid } as User);
            this.setSyncImage({ isUploaded: true }, false);
            setTimeout(() => {
              this.uploadProgress = of(null);
            }, 1000);
          })
        ));
    }
  }

  getSyncImage(): Observable<{ image?: string; isUploaded?: boolean }> {
    return this._userImg.asObservable();
  }

  getSyncImageOnce(): { image?: string; isUploaded?: boolean } {
    return this._userImg.value;
  }

  resetProgressOnMidnight() {
    const user = this.getSyncUserOnce();
    if (
      user &&
      user.exerciseInfo &&
      user.exerciseInfo.resetTime &&
      user.exerciseInfo.resetTime <= new Date().getTime()
    ) {
      console.log('UserService::resetProgressOnMidnight()', user);
      const newUser = {
        exerciseInfo: {
          completedWeeklyGoals: 0,
          resetTime: moment(user.exerciseInfo.resetTime)
            .add(1, 'week')
            .hour(0)
            .minute(0)
            .second(0)
            .toDate()
            .getTime(),
        },
      } as User;
      this.setSyncUser(newUser);
    }
  }

  async downloadWorkoutHistory() {
    const workouts = this.fireDB
      .collection('Workout')
      .doc(this._user.value.uid)
      .collection('workouts');

    const workoutsData = await workouts.ref.get();
    if (workoutsData && workoutsData.docs && workoutsData.docs.length) {
      const workoutsList = [];
      workoutsData.docs.forEach((item) => {
        workoutsList.push({
          ...item.data(),
          dateTime: moment(item.data().dateTime).isValid()
            ? item.data().dateTime
            : item.data().dateTime.toDate
            ? item.data().dateTime.toDate()
            : new Date(item.data().dateTime),
        });
      });

      this.storageServ.get('UserWorkoutsList').then((list) => {
        let uniqueList = workoutsList;
        if (list && list.length) {
          uniqueList = this.uniqueArray(
            workoutsList.concat(list),
            'workoutUID'
          );
        }
        console.log('downloadWorkoutHistory::', uniqueList);
        this.storageServ.set('UserWorkoutsList', uniqueList);
      });
    }
  }

  uniqueArray(array, key) {
    const result = [];
    const map = new Map();
    for (const item of array) {
      if (!map.has(item[key])) {
        map.set(item[key], true);
        result.push(item);
      }
    }
    return result;
  }

  private calcStatistic(data) {
    let workoutCount = 0;
    let totalCalorieBurned = 0;
    let totalWorkoutTime = 0;
    data.forEach((workoutsItem) => {
      totalCalorieBurned += Number(workoutsItem.calorieBurned);
      totalWorkoutTime += Number(workoutsItem.workoutTime);
      workoutCount++;
    });
    return {
      workoutCount,
      caloriesBurned: totalCalorieBurned,
      workoutTime: totalWorkoutTime,
    };
  }

  getWorkoutByDateTime(workouts: Array<any>, time) {
    return workouts.filter(
      (workout) => new Date(workout.dateTime) >= new Date(time)
    );
  }

  async calcWorkoutStat() {
    const workouts = await this.storageServ.get('UserWorkoutsList');
    if (!workouts || !workouts.length) {
      const initItem = {
        workoutCount: 0,
        caloriesBurned: 0,
        workoutTime: 0,
      };
      await this.setSyncUser({
        exerciseInfo: {
          daily: initItem,
          weekly: initItem,
          monthly: initItem,
          allTime: initItem,
        },
      } as User);
      return this._user.value;
    }
    workouts.sort((a, b) => {
      return new Date(b.dateTime) <= new Date(a.dateTime) ? -1 : 1;
    });

    const lastItem = workouts[0];

    const data = lastItem;
    const offSet = moment(data.dateTime).utcOffset();

    const midnight = moment()
      .utcOffset(offSet)
      .hour(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toDate()
      .getTime();
    const day = 24 * 60 * 60 * 1000;
    await this.setSyncUser({
      exerciseInfo: {
        daily: this.calcStatistic(
          this.getWorkoutByDateTime(workouts, moment(midnight).toDate())
        ),
        weekly: this.calcStatistic(
          this.getWorkoutByDateTime(
            workouts,
            moment(midnight - day * 6).toDate()
          )
        ),
        monthly: this.calcStatistic(
          this.getWorkoutByDateTime(
            workouts,
            moment(midnight - day * 29).toDate()
          )
        ),
        allTime: this.calcStatistic(workouts),
      },
    } as User);

    return this._user.value;
  }

  isFreemium() {
    const isFreemium = getBoolean(
      remoteConfig,
      environment.production ? 'freemium' : 'test_freemium'
    );
    return isFreemium;
  }
}
