import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';

import { BottomSheetComponent } from './bottom-sheet-component';

@NgModule({
    imports: [
        IonicModule,
        CommonModule
    ],
    declarations: [
        BottomSheetComponent
    ],
    exports: [
        BottomSheetComponent
    ]
})

export class BottomSheetModule { }
