import { AfterContentInit, AfterViewInit, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { IonSlides, ModalController, Platform } from "@ionic/angular";
import { Observable, Subscription } from "rxjs";
import { TranslateConfigService } from "src/app/services/translate-config.service";
import { Video } from "../../models/video";
import { AnalyticsService } from "../../services/analytics.service";
import { NetworkService } from "../../services/network.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-single-video",
  templateUrl: "./single-video.component.html",
  styleUrls: ["./single-video.component.scss"],
})
export class SingleVideoComponent implements AfterContentInit, AfterViewInit {
  @Input() private video: Video;
  @Input() private otherSideItem: any;
  @Input() private thirdSideItem: any;
  @Input() private index: any;

  @ViewChild(IonSlides) private ionSlides: IonSlides;
  @ViewChildren("videoPlayerItem") private videoRefList: QueryList<ElementRef>;

  language: any;

  videoSub: Subscription;

  private slideSubscription: Subscription;
  private networkSubscription: Subscription;

  networkState: Observable<boolean>;
  currentSlide: any;
  slideList: Array<any>;

  subscriptions: Subscription[] = [];

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private domSanitizer: DomSanitizer,
    private webView: WebView,
    private analyticsService: AnalyticsService,
    private networkService: NetworkService,
    private userService: UserService,
    private translateConfigServ: TranslateConfigService
  ) {
    this.language = this.translateConfigServ.getCurrentLang();
    this.slideList = [];
    this.networkState = this.networkService.status;

    this.subscriptions.push(
      this.platform.backButton.subscribeWithPriority(400, () => {
        this.destroySubs();
        this.dismiss();
      })
    );
  }

  ngAfterViewInit(): void {
    this.slideSubscription = this.ionSlides.ionSlideDidChange.subscribe((e) => {
      this.ionSlides.getActiveIndex().then((index) => {
        this.currentSlide = this.slideList[index].slideName;
      });
    });
    this.networkSubscription = this.networkState.subscribe((e) => {
      this.slideList.forEach((element, index: number) => {
        if (!this.videoRefList.toArray()[index].nativeElement.playing) {
          this.resetVideo(this.videoRefList.toArray()[index].nativeElement);
        }
      });
    });
  }

  ionViewWillEnter() {
    console.log("Keys: ", Object.keys(HTMLMediaElement.prototype));

    if (this.otherSideItem && this.thirdSideItem) {
      this.slideList = [
        {
          ...this.video,
          slideName: this.video.name,
          url: this.checkURL(this.video),
          isLoad: false,
        },
        {
          ...this.otherSideItem,
          slideName: this.otherSideItem.name,
          url: this.checkURL(this.otherSideItem),
          isLoad: false,
        },
        {
          ...this.thirdSideItem,
          slideName: this.thirdSideItem.name,
          url: this.checkURL(this.thirdSideItem),
          isLoad: false,
        },
      ];
      this.currentSlide = this.slideList[this.index - 1 || 0].name;
    } else if (this.otherSideItem && !this.thirdSideItem) {
      this.slideList = [
        {
          ...this.video,
          slideName: "One Side",
          safe_url: this.video.url,
          url: this.checkURL(this.video),
          isLoad: false,
        },
        {
          ...this.otherSideItem,
          slideName: "Other Side",
          url: this.checkURL(this.otherSideItem),
          isLoad: false,
        },
      ];
      this.currentSlide = "One Side";
    } else {
      this.slideList = [{ ...this.video, url: this.checkURL(this.video), isLoad: false }];
    }

    console.log("slideList", this.slideList);

    this.videoSub = this.ionSlides.ionSlidesDidLoad.subscribe((ev) => {
      const videos = document.getElementsByTagName("video");
      for (let i = 0; i < videos.length; i++) {
        videos.item(i).muted = true;
      }
    });
  }

  ngAfterContentInit() {
    this.errorCheck(this.slideList);
  }

  private errorCheck(list: Array<any>) {
    list.forEach((element, index: number) => {
      this.videoRefList.toArray()[index].nativeElement.onerror = (error) => {
        this.resetVideo(this.videoRefList.toArray()[index].nativeElement);
        this.analyticsService.logEvent("VIDEO-onerror", {
          error: this.videoRefList.toArray()[index].nativeElement.error,
          video_id: this.slideList[index].video_id,
        });
      };
      if (this.videoRefList.toArray()[index].nativeElement.error) {
        this.resetVideo(this.videoRefList.toArray()[index].nativeElement);
      }
    });
  }

  async resetVideo(element: HTMLMediaElement) {
    setTimeout(() => {
      element.load();
      element.defaultMuted = true;
      element.muted = true;
      if (element.readyState > 1) {
        this.resetVideo(element);
        console.log("-- RELOAD VIDEO --");
      }
    }, 1000);
  }

  private checkURL(video: Video) {
    if (this.platform.is("hybrid") && video.file_video_url) {
      return this.domSanitizer.bypassSecurityTrustUrl(video.file_video_url);
    }
    return video.url;
  }

  dismiss() {
    this.modalCtrl.dismiss().catch(() => {});
  }

  destroySubs() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ionViewWillLeave() {
    // this.userService.updateStatusBar(this.userService.userNavigation.current);
    this.destroySubs();

    if (this.slideSubscription) {
      this.slideSubscription.unsubscribe();
    }
    if (this.networkSubscription) {
      this.networkSubscription.unsubscribe();
    }
    this.slideList.forEach((_, index: number) => {
      try {
        this.videoRefList.toArray()[index].nativeElement.pause();
        this.videoRefList.toArray()[index].nativeElement.removeAttribute("src");
        this.videoRefList.toArray()[index].nativeElement.childNodes[0].removeAttribute("src");
        this.videoRefList.toArray()[index].nativeElement.load();
      } catch (error) {}
    });

    if (this.videoSub) {
      this.videoSub.unsubscribe();
    }
  }

  segmentChanged(value) {
    this.currentSlide = value;
    this.ionSlides.slideTo(this.slideList.findIndex((e) => e.slideName === this.currentSlide));
  }

  isMuscle(category: string) {
    return category !== "cardio" && category !== "hiit" && category !== "lower body stretch" && category !== "upper body stretch";
  }
}
