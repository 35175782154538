import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  SignInWithApple,
  SignInWithAppleOptions,
  SignInWithAppleResponse,
} from '@capacitor-community/apple-sign-in';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import {
  LoadingController,
  NavController,
  Platform,
  PopoverController,
} from '@ionic/angular';

import { LANGUAGE_LIST } from './../constants';
// import * as firebase from "firebase/app";

import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { FirebaseError } from 'firebase/app';
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthCredential,
  OAuthProvider,
  UserCredential,
  createUserWithEmailAndPassword,
  deleteUser,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  linkWithCredential,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import * as moment from 'moment';
import { PasswordInputComponent } from '../components/password-input/password-input.component';
import { DEFAULT_LANGUAGE, DEFAULT_WEEKLY_DAY } from '../constants';
import { Difficulty } from '../enums/Difficulty';
import { User } from '../models/user';
import { AnalyticsService } from './analytics.service';
import { NotificationService } from './notification.service';
import { OnboardingService } from './onboarding.service';
import { PlanService } from './plan.service';
import { PushNotificationService } from './push-notification.service';
import { SubscriptionService } from './subscription.service';
import { TranslateConfigService } from './translate-config.service';
import { UserService } from './user.service';
import { WeightProgressService } from './weight-progress.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  options: SignInWithAppleOptions = {
    clientId: 'com.lifebuddy.pilates',
    redirectURI: '',
    scopes: 'email name',
  };

  constructor(
    private weightProgressService: WeightProgressService,
    private notificationService: NotificationService,
    private loadingController: LoadingController,
    private onboardingService: OnboardingService,
    private analyticsService: AnalyticsService,
    private subscription: SubscriptionService,
    private popoverCtrl: PopoverController,
    private planService: PlanService,
    public userService: UserService,
    public navCtrl: NavController,
    private platform: Platform,
    private pushNotificationServ: PushNotificationService,
    private authFire: Auth,
    public afAuth: AngularFireAuth,
    private translateConfigServ: TranslateConfigService,
    private analyticsServ: AnalyticsService
  ) {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        GoogleAuth.initialize();
      }
    });
  }

  getAuthUser() {
    return this.authFire?.currentUser ?? null;
  }

  private async checkUserRegistrationStatus(user: User) {
    await this.userService.setSyncUser(user, true);
    if (Capacitor.getPlatform() !== 'web') {
      this.userService.downloadWorkoutHistory();
      this.weightProgressService.downloadWeightProgressPhotos();
      this.userService.callFireFunction();
      this.weightProgressService.getCurrentUserWeightProgress();
      // this.updateSentryScope(user);
      await this.planService.setUserDBPlanToStorage(user.uid);
    }
    if (user.registrationIsCompleted && user.planIsCompleted) {
      return user;
    }
    // this.onboardingService.resetOnboardingPageKeys();
    // this.onboardingService.isCustomePlan = false;
    // await this.navCtrl.navigateRoot("/onboarding");
    return false;
  }

  private async setUser(response: UserCredential, assign?: any) {
    const firestoreUser = await new Promise<User>((resolve) => {
      this.userService.getFirestoreUser(response.user?.uid, (user) => {
        resolve(user);
      });
    });

    try {
      if (
        Capacitor.getPlatform() != 'web' &&
        response.user &&
        response.user.uid
      ) {
        this.analyticsService.setUserProperty('UID', response.user.uid);
        await Purchases.logIn({ appUserID: response.user.uid });
      }
      if (
        Capacitor.getPlatform() != 'web' &&
        response.user &&
        response.user.email
      ) {
        this.analyticsService.setUserProperty('EMAIL', response.user.email);
      }
    } catch (e) {}

    console.log('Firestore User: ', firestoreUser);
    const device = await Device.getId();
    if (firestoreUser && firestoreUser.uid) {
      // user exist in firestore
      const language = await this.translateConfigServ.getCurrentLang();
      let userLanguage = DEFAULT_LANGUAGE;
      const foundIndex = LANGUAGE_LIST.findIndex((e) => e.value === language);
      if (foundIndex !== -1) {
        userLanguage = LANGUAGE_LIST[foundIndex].name;
      }
      this.analyticsService.setUserProperty('LANGUAGE', userLanguage);
      let user = { ...firestoreUser, language: userLanguage } as User;
      if (!user?.device_uuid) {
        user = { ...user, device_uuid: device.identifier };
      }

      if (!firestoreUser.email && response.user.email) {
        return this.checkUserRegistrationStatus({
          ...user,
          email: response.user.email,
        });
      } else {
        return this.checkUserRegistrationStatus({
          ...user,
        });
      }
    } else {
      const language = await this.translateConfigServ.getCurrentLang();
      let userLanguage = DEFAULT_LANGUAGE;
      const foundIndex = LANGUAGE_LIST.findIndex((e) => e.value === language);
      if (foundIndex !== -1) {
        userLanguage = LANGUAGE_LIST[foundIndex].name;
      }
      // mew user
      let user = {
        device_uuid: device.identifier,
        uid: response.user.uid,
        username: response.user.displayName,
        email: response.user.email,
        image: response.user.photoURL,
        language: userLanguage,
        difficulty: Difficulty.Beginner,
        registrationIsCompleted: false,
        planIsCompleted: false,
        subscription: {
          isSubscribed: false,
          hasSubscribed: false,
          planName: '',
          transitionId: '',
        },
        exerciseInfo: {
          completedWeeklyGoals: 0,
          resetTime: moment()
            .day(DEFAULT_WEEKLY_DAY.split(' ')[0])
            .add(1, 'week')
            .hour(0)
            .minute(0)
            .second(0)
            .toDate()
            .getTime(),
        },
        ...assign,
      } as User;

      const res = await this.userService.isDeviceInUse();
      if (res?.data) {
        user = {
          ...user,
          countdownEnd: (res.data as any)?.userDoc?.countdownEnd,
        } as User;
      }

      return this.checkUserRegistrationStatus(user);
    }
  }

  private linkAccount(data: { email: string; credential: any }) {
    // const auth = getAuth();
    return fetchSignInMethodsForEmail(this.authFire, data.email)
      .then(async (list) => {
        this.loadingController.dismiss().catch(() => {});
        if (list[0] === 'password') {
          const popover = await this.popoverCtrl.create({
            component: PasswordInputComponent,
            componentProps: {
              email: data.email,
              name: data.credential.providerId,
            },
            cssClass: 'password-input-popover',
          });
          await popover.present();
          const result = await popover.onWillDismiss();

          const loading = await this.loadingController.create();
          await loading.present();

          return result;
        } else if (list[0] === 'google.com') {
          await this.notificationService.showSuccess(
            `Please sign in for: ${data.email} to link your social account, Google.`
          );

          const loading = await this.loadingController.create();
          await loading.present();

          const googleLoginResult = await GoogleAuth.signIn(); //await this.googlePlus.login({});
          const { idToken, accessToken } = googleLoginResult.authentication;

          let gCredential;
          if (accessToken) {
            gCredential = GoogleAuthProvider.credential(
              idToken || null,
              accessToken
            );
          } else {
            gCredential = GoogleAuthProvider.credential(idToken);
          }
          return await signInWithCredential(this.authFire, gCredential);
        }
      })
      .then(async (response: any) => {
        if (response && response.data && response.data.password) {
          const res = await signInWithEmailAndPassword(
            this.authFire,
            data.email,
            response.data.password
          );
        }

        if (this.authFire.currentUser) {
          return await linkWithCredential(
            this.authFire.currentUser,
            data.credential
          );
        }
        throw new Error('Account linking failed');
      })
      .catch((error) => {
        console.error(
          'Error fetching sign in methods: ',
          JSON.stringify(error)
        );
        throw new Error('Account linking failed');
      });
  }

  async signInAnonymously() {
    const response = await signInAnonymously(this.authFire);

    await this.pushNotificationServ.registerNotifications();

    await this.analyticsServ.logEvent('anonymous_auth', {});

    const gender = this.onboardingService
      ?.getOnboardingForm()
      ?.get('gender').value;

    return this.setUser(response, {
      username: `${gender == 'male' ? 'John' : 'Jane'} Doe`,
    });
  }

  async logout(landingPage = true) {
    await this.analyticsServ.logEvent('logout', {});
    await this.userService.resetUser(false);
    await signOut(this.authFire);
    this.onboardingService.resetOnboardingPageKeys();
    this.onboardingService.isCustomePlan = false;
    landingPage ? this.navCtrl.navigateRoot('/onboarding') : null; // TODO: landing
  }

  async login(email: string, password: string) {
    const response = await signInWithEmailAndPassword(
      this.authFire,
      email,
      password
    );
    return this.setUser(response);
  }

  async loginWithApple(register = false) {
    if (!this.platform.is('hybrid')) {
      return;
    }

    const res: SignInWithAppleResponse = await SignInWithApple.authorize(
      this.options
    );

    const provider = new OAuthProvider('apple.com');

    const credential = provider.credential({
      idToken: res.response.identityToken,
    });

    if (!register && res.response?.email) {
      const emailInUse = await this.userService.isEmailInUse(
        res.response?.email
      );
      if (!emailInUse.data) {
        console.log('Email not in use');
        throw new FirebaseError('auth/user-not-found', 'User has not found');
      }
    }

    console.log('Signing in with credential');
    const response = await signInWithCredential(this.authFire, credential);

    if (getAdditionalUserInfo(response).isNewUser) {
      if (register) {
        this.analyticsServ.logEvent('login_page_finished', {
          auth_method: 'apple',
          onboarding: false,
          signup: true,
        });
      } else {
        await deleteUser(response.user);
        await this.logout(false);
        throw new FirebaseError('auth/user-not-found', 'User not found');
      }
    } else {
      this.analyticsServ.logEvent('login_page_finished', {
        auth_method: 'apple',
        onboarding: true,
        signup: false,
      });
    }

    console.log('Apple user::', res);
    await this.pushNotificationServ.registerNotifications();
    return this.setUser(response, {
      username: res.response.givenName
        ? res.response.givenName + ' ' + res.response.familyName
        : response.user.email.split('@')[0],
    });
  }

  async loginWithGoogle(register = false) {
    if (!this.platform.is('hybrid')) {
      return;
    }
    const googleLoginResult = await GoogleAuth.signIn().catch((err) => {
      console.error('Error: ', err);
      throw new Error(err);
    });

    console.log('Res: ', googleLoginResult);
    if (!googleLoginResult) {
      return false;
    }

    if (!register) {
      const emailInUse = await this.userService.isEmailInUse(
        googleLoginResult.email
      );
      if (!emailInUse.data) {
        throw new FirebaseError('auth/user-not-found', 'User not found');
      }
    }

    const { idToken, accessToken } = googleLoginResult.authentication;

    let credential: OAuthCredential;
    if (accessToken) {
      credential = GoogleAuthProvider.credential(idToken || null, accessToken);
    } else {
      credential = GoogleAuthProvider.credential(idToken);
    }
    let list = [];
    if (googleLoginResult && googleLoginResult.email) {
      list = await fetchSignInMethodsForEmail(
        this.authFire,
        googleLoginResult.email
      );
      if (list.length && list.indexOf('google.com') === -1) {
        // tslint:disable-next-line:no-shadowed-variable
        const response = await this.linkAccount({
          email: googleLoginResult.email,
          credential,
        });
        return this.setUser(response);
      }
    }
    console.log('Signing in with credential');
    const response = await signInWithCredential(this.authFire, credential);
    console.log('Response from sign in: ', response);
    if (getAdditionalUserInfo(response).isNewUser) {
      if (register) {
        this.analyticsServ.logEvent('login_page_finished', {
          auth_method: 'google',
          onboarding: false,
          signup: true,
        });
      } else {
        await this.logout(false);
        throw new FirebaseError('auth/user-not-found', 'User not found');
      }
    } else {
      this.analyticsServ.logEvent('login_page_finished', {
        auth_method: 'google',
        onboarding: true,
        signup: false,
      });
    }
    await this.pushNotificationServ.registerNotifications();
    return this.setUser(response);
  }

  async register(data: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) {
    const response = (await createUserWithEmailAndPassword(
      this.authFire,
      data.email,
      data.password
    )) as any;
    await this.pushNotificationServ.registerNotifications();
    return this.setUser(response, {
      username: `${data.firstName} ${data.lastName}`,
    });
  }

  async forgotPassword(email: string) {
    await sendPasswordResetEmail(this.authFire, email);
    this.notificationService.showSuccess(
      'Password reset email was sent successfully.'
    );
    // this.navCtrl.navigateRoot("/login-with-email");
  }

  async deleteUser() {
    await this.userService.deleteUser();
    this.onboardingService.resetOnboardingPageKeys();
    this.onboardingService.isCustomePlan = false;
  }

  async linkEmailPassWithAnonymousUser(email, password) {
    if (email && password && this.authFire.currentUser) {
      const credential = EmailAuthProvider.credential(email, password);
      // await signInWithEmailAndPassword(this.authFire, email, password);
      const response = await linkWithCredential(
        this.authFire.currentUser,
        credential
      );
      await this.analyticsServ.logEvent('link_anonymous', {
        auth_method: 'password',
      });

      console.log('Response from link: ', response);
      return this.setUser(response);
    }
    throw new Error('Account linking failed');
  }

  async linkGoogleWithAnonymousUser() {
    if (this.authFire.currentUser.isAnonymous) {
      const googleLoginResult = await GoogleAuth.signIn().catch((err) => {
        console.error('Error: ', err);
        throw new Error(err);
      });

      console.log('Res: ', googleLoginResult);
      if (!googleLoginResult) {
        return false;
      }

      const { idToken, accessToken } = googleLoginResult.authentication;

      let credential: OAuthCredential;
      if (accessToken) {
        credential = GoogleAuthProvider.credential(
          idToken || null,
          accessToken
        );
      } else {
        credential = GoogleAuthProvider.credential(idToken);
      }

      const response = await linkWithCredential(
        this.authFire.currentUser,
        credential
      );
      await this.analyticsServ.logEvent('link_anonymous', {
        auth_method: 'google',
      });
      console.log('Response from link: ', response);
      return this.setUser(response);
    }
    throw new Error('Account linking failed');
  }

  async linkAppleWithAnonymousUser() {
    if (this.authFire.currentUser.isAnonymous) {
      const res: SignInWithAppleResponse = await SignInWithApple.authorize(
        this.options
      );

      const provider = new OAuthProvider('apple.com');

      const credential = provider.credential({
        idToken: res.response.identityToken,
      });

      const response = await linkWithCredential(
        this.authFire.currentUser,
        credential
      );
      await this.analyticsServ.logEvent('link_anonymous', {
        auth_method: 'apple',
      });
      console.log('Response from link: ', response);
      return this.setUser(response);
    }
    throw new Error('Account linking failed');
  }
}
