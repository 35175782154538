import { Component, ElementRef, ViewChild } from "@angular/core";
import { AnimationController } from "@ionic/angular";
import { ReviewService } from "src/app/services/review.service";
import { TranslateConfigService } from "src/app/services/translate-config.service";
import { BottomSheetComponent, SheetStates } from "src/app/shared/bottom-sheet/bottom-sheet-component";

@Component({
  selector: "app-review",
  templateUrl: "./review.component.html",
  styleUrls: ["./review.component.scss"],
})
export class ReviewComponent {
  @ViewChild("bottomSheet", { static: true }) public bottomSheet: BottomSheetComponent;
  @ViewChild("step2", { static: true }) public step2: ElementRef;

  language: any;

  step = 0;

  rating: number = 0;
  reviewText: string = "";

  animating = false;

  constructor(
    private translateConfigServ: TranslateConfigService,
    private reviewServ: ReviewService,
    private animationCtrl: AnimationController
  ) {
    this.language = this.translateConfigServ.getCurrentLang();
  }

  rate(rating: number) {
    this.rating = rating;
  }

  public openModal() {
    if (this.bottomSheet) {
      setTimeout(() => {
        this.bottomSheet.setState(SheetStates.Opened);
      }, 100);
    } else {
      setTimeout(() => {
        this.openModal();
      }, 150);
    }
  }

  async continue() {
    if (this.step === 0) {
      await this.reviewServ.hasReviewed();
      if (this.rating >= 4) {
        this.showNativeReviewDialog();
      } else {
        this.step = 1;
        this.animating = true;
        setTimeout(() => {
          this.animating = false;
        }, 300);
      }
    } else if (this.step == 1) {
      await this.reviewServ.saveReview(this.reviewText, this.rating);

      this.step = 2;
      this.animating = true;
      setTimeout(() => {
        this.animating = false;
      }, 300);
    } else if (this.step == 2) {
      this.close();
    } else {
      console.log("Invalid step");
    }
  }

  close() {
    this.bottomSheet.setState(SheetStates.Closed);
  }

  async showReviewInput() {}

  async showNativeReviewDialog() {
    await this.reviewServ.rateApp();
    this.close();
  }
}
