<ion-header mode="ios"></ion-header>
<ion-content fullscreen="true">
  <div class="header-container">
    <ion-toolbar mode="ios">
      <ion-title color="black">{{ title | translate }}</ion-title>
      <ion-buttons slot="end" mode="ios">
        <ion-button mode="ios" (click)="dismiss()">
          <ion-icon
            slot="icon-only"
            src="assets/newUI/icons/close.svg"
          ></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>

  <div class="content-container">
    <!-- <div class="segment-container">
      <div class="segment-btn" [class.active-segment]="currentSegment === 'us'" (click)="segmentChanged('us')">
        <span>{{ "us_units" | translate }}</span>
      </div>
      <div class="segment-btn" [class.active-segment]="currentSegment === 'metric'" (click)="segmentChanged('metric')">
        <span>{{ "metric_units" | translate }}</span>
      </div>
    </div> -->

    <div class="listitem">
      <div class="content">
        <!-- <div class="formimg">
          <ion-icon class="iconimg" src="assets/newUI/icons/weight.svg"></ion-icon>
        </div> -->
        <div class="field-name">{{ "Weight" | translate }}</div>
        <div class="forminput" [hidden]="currentSegment !== 'us'">
          <input
            class="inputdata"
            (focus)="moveCursoreToEnd(weightP, 'weightPound')"
            #weightP
            [(ngModel)]="weightPound"
            (blur)="weightMetricOrUs()"
            (keyup.enter)="hideKeyboard()"
            [placeholder]="'Pound' | translate"
            type="number"
          />
          <span style="line-height: 21px; padding-left: 6px">{{
            "lbs" | translate
          }}</span>
        </div>
        <div class="forminput" [hidden]="currentSegment !== 'metric'">
          <input
            class="inputdata"
            [(ngModel)]="weightKg"
            (focus)="moveCursoreToEnd(weightK, 'weightKg')"
            #weightK
            (blur)="weightMetricOrUs()"
            (keyup.enter)="hideKeyboard()"
            [placeholder]="'Kilograms' | translate"
            type="number"
          />
          <span style="line-height: 21px; padding-left: 6px">{{
            "kg" | translate
          }}</span>
        </div>
      </div>
    </div>

    <div class="listitem">
      <div class="content" id="open-date-input">
        <!-- <div class="formimg">
          <ion-icon class="iconimg" src="assets/newUI/icons/calendar.svg"></ion-icon>
        </div> -->
        <div class="field-name">{{ "date" | translate }}</div>
        <div class="forminput">
          <ion-text class="inputdata">{{
            date | extendDate : "MMM dd, yyyy"
          }}</ion-text>
        </div>
        <ion-popover trigger="open-date-input" [arrow]="false">
          <ng-template>
            <ion-datetime
              (ionChange)="updateDate($event)"
              color="orange"
              mode="ios"
              [(ngModel)]="isoDate"
              presentation="date"
              [showDefaultButtons]="true"
            >
            </ion-datetime>
          </ng-template>
        </ion-popover>
      </div>
    </div>

    <div class="btn-container">
      <ion-button
        expand="block"
        *ngIf="imageUrl && (imageUrl === 'local' ? filePath : true)"
        (click)="removePhoto()"
        mode="ios"
        fill="clear"
      >
        {{ "remove_photo" | translate }}
      </ion-button>
      <ion-button expand="block" (click)="takePhoto()" mode="ios" fill="clear">
        {{ "take_progress_photo" | translate }}
      </ion-button>
    </div>

    <div
      *ngIf="imageUrl && (imageUrl === 'local' ? filePath : true)"
      class="img-container"
    >
      <img
        [src]="filePath ? sanitizedImage : imageUrl"
        alt
        style="pointer-events: none"
      />
    </div>
  </div>
  <div class="modal-btn-container shadow">
    <ion-button
      expand="full"
      mode="ios"
      class="btn-submit"
      (click)="submit()"
      fill="clear"
      style="font-size: 17px"
    >
      {{ "save" | translate }}
    </ion-button>
  </div>
</ion-content>

<!-- <ion-footer mode="ios">
  <div class="bottom-btn-container">
    <ion-button expand="block" (click)="dismiss()" mode="ios" style="height: 48px" class="bottom-btn-cancel" fill="clear">
      {{ "cancel" | translate }}
    </ion-button>
    <ion-button expand="block" (click)="submit()" mode="ios" style="height: 48px" class="bottom-btn-save" fill="clear">
      {{ "save" | translate }}
    </ion-button>
  </div>
</ion-footer> -->
