import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

export const bodyTypes = {
  slim: {
    title: 'Slim Body',
    icon: 'ectomorph',
  },
  fit: {
    title: 'Fit Body',
    icon: '10-14_slim_body',
  },
  lean_curvy: {
    title: 'Lean Curvy Body',
    icon: 'lean_curvy_body',
  },
  beach: {
    title: 'Beach Body',
    icon: 'beach',
  },
  instagram: {
    title: 'Instagram Body',
    icon: 'instagram_body',
  },
};
@Component({
  selector: 'app-goal-body',
  templateUrl: './goal-body.component.html',
  styleUrls: ['./goal-body.component.scss'],
})
export class GoalBodyComponent implements OnInit, OnDestroy {
  language: any;

  bodyTypes = bodyTypes;

  public nextBtn: boolean;
  public goalBodyControl: AbstractControl;
  public goalBodyTypes: Array<string>;

  subscriptions: Subscription[] = [];

  constructor(
    private onboardingService: OnboardingService,
    private translateConfigServ: TranslateConfigService
  ) {
    this.language = this.translateConfigServ.getCurrentLang();

    this.nextBtn = false;
    this.goalBodyControl = this.onboardingService.getControl('goalBody');
    this.checkNextBtn();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    this.constructBodyTypes(
      this.onboardingService.getControl('desiredResults')?.value
    );
    this.onboardingService
      .getControl('desiredResults')
      ?.valueChanges.subscribe((desiredResult) => {
        this.constructBodyTypes(desiredResult);
      });
  }

  segmentChanged(value) {
    this.goalBodyControl.setValue(value);
    this.onboardingService.setFormControlValue('goalBody', value);
    this.nextSlide();
    // this.checkNextBtn();
  }

  checkNextBtn() {
    if (!this.goalBodyControl) {
      return;
    }

    if (this.goalBodyControl.value) {
      this.nextBtn = true;
    } else {
      this.nextBtn = false;
    }
  }

  nextSlide() {
    // if (this.onboardingService.isCustomePlan) {
    //   this.onboardingService.goToPlanConstructor();
    // } else {
    // this.onboardingService.allowLoadingPlan(true);
    this.onboardingService.changeSlide(true);
    // }
  }

  constructBodyTypes(desiredResult: string) {
    if (
      desiredResult == 'PoundPlummet Path' ||
      desiredResult == 'Bendy Body Boss'
    ) {
      this.goalBodyTypes = ['slim', 'fit', 'lean_curvy'];
    } else if (desiredResult == 'Curvy Body Bootycamp') {
      this.goalBodyTypes = ['instagram', 'beach', 'lean_curvy'];
    } else if (
      desiredResult == 'ToneTastic Trek' ||
      desiredResult == 'Wonderland of Wellness'
    ) {
      this.goalBodyTypes = ['beach', 'instagram', 'lean_curvy'];
    }
  }
}
