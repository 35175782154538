import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Device } from '@capacitor/device';
import { ModalController, Platform } from '@ionic/angular';
import { StripeEmbeddedCheckout, loadStripe } from '@stripe/stripe-js';
import { Subscription } from 'rxjs';
import { SubOption } from 'src/app/models/sale';
import { LoginWithEmailPage } from 'src/app/pages/login-with-email/login-with-email.page';
import { PrivacyPolicyPage } from 'src/app/pages/privacy-policy/privacy-policy.page';
import { SignUpPage } from 'src/app/pages/sign-up/sign-up.page';
import { TermsAndConditionsPage } from 'src/app/pages/terms-and-conditions/terms-and-conditions.page';
import { AchievementService } from 'src/app/services/achievement.service';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { LoggerService } from 'src/app/services/logger.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import {
  BottomSheetComponent,
  SheetStates,
} from 'src/app/shared/bottom-sheet/bottom-sheet-component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, OnDestroy {
  @ViewChild('bottomSheet', { static: true })
  public bottomSheet: BottomSheetComponent;

  @Input() selectedSub: SubOption;
  @Input() customClass: string = '';

  @Output() auth = new EventEmitter<boolean>();
  language: any;

  shownSubOption: SubOption | undefined;

  checkout: StripeEmbeddedCheckout | undefined;

  email: string = '';

  sessionId: string = '';

  step = 1;

  isAnonymous = false;

  subscriptions: Subscription[] = [];

  constructor(
    private translateConfigServ: TranslateConfigService,
    private authService: AuthService,
    private router: Router,
    private achServ: AchievementService,
    private notificationService: NotificationService,
    private loggerService: LoggerService,
    private modalCtrl: ModalController,
    public platform: Platform,
    private helperServ: HelperService,
    private onboardingService: OnboardingService
  ) {
    this.language = this.translateConfigServ.getCurrentLang();

    this.subscriptions.push(
      this.translateConfigServ.$currentLang.subscribe((currentLang) => {
        this.language = currentLang;
        this.shownSubOption = null;
      })
    );
  }

  ngOnInit(): void {
    this.authService.afAuth.currentUser.then((user) => {
      this.isAnonymous = user?.isAnonymous;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  async initStripe() {
    if (!this.selectedSub) {
      return;
    }

    if (this.checkout) {
      if (this.shownSubOption?.name === this.selectedSub.name) {
        return;
      }
      this.checkout.unmount();
      this.checkout.destroy();
    }

    await this.helperServ.presentLoader();

    this.shownSubOption = this.selectedSub;

    const stripe = await loadStripe(environment.stripeApiKey).catch((error) => {
      console.error('Error: ', error);
      return;
    });
    if (!stripe) return;
    const country = localStorage.getItem('country');
    const response = await fetch(
      environment.production
        ? 'https://us-central1-pilates-fitness.cloudfunctions.net/createcheckoutsession'
        : 'http://127.0.0.1:5001/pilates-fitness/us-central1/createcheckoutsession',
      {
        method: 'POST',
        body: JSON.stringify({
          billing_period: this.selectedSub.name,
          deviceId: (await Device.getId()).identifier,
          country,
          locale: this.language,
          email: this.authService?.getAuthUser()?.email ?? '',
        }),
      }
    );

    const { clientSecret, sessionId, subscription } = await response.json();

    if (!subscription) {
      this.sessionId = sessionId;

      this.checkout = await stripe.initEmbeddedCheckout({
        clientSecret,
      });

      // Mount Checkout
      this.checkout.mount('#checkout');
    } else {
      await this.helperServ.dismissLoader();
      await this.notificationService.showSuccess(
        'You already have a subscription, please sign in to access it'
      );
      this.openApp();
      this.close();
      return;
    }

    await this.helperServ.dismissLoader();
  }

  async getPlan() {
    return this.onboardingService.setPlan();
  }

  openApp() {
    window.open(
      this.platform.is('android')
        ? 'https://play.google.com/store/apps/details?id=com.lifebuddy.pilates'
        : 'https://apps.apple.com/us/app/pilates-fitness-home-workout/id6478118632',
      '_blank'
    );
  }

  public async openModal() {
    this.initStripe();
    sessionStorage.setItem('payment', 'true');
    if (this.bottomSheet) {
      setTimeout(async () => {
        await this.bottomSheet.setState(SheetStates.Opened);
      }, 100);
    } else {
      setTimeout(async () => {
        await this.openModal();
      }, 150);
    }
  }

  submit() {
    this.step++;
  }

  async close() {
    sessionStorage.removeItem('payment');
    return this.bottomSheet.setState(SheetStates.Closed);
  }

  async continueAsGuest() {
    await this.helperServ.presentLoader();

    try {
      await this.authService.signInAnonymously();

      await this.close();
      this.auth.emit(true);
    } catch (error) {
      if (error.code !== '1001') {
        this.loggerService.logError(error);
        if (error.error) {
          this.notificationService.showError(error.code + ' - ' + error.error);
        }
      }
    }
    await this.helperServ.dismissLoader();
  }

  async openLogin() {
    // this.close()
    const modal = await this.modalCtrl.create({
      component: LoginWithEmailPage,
      cssClass: this.customClass,
    });

    await modal.present();

    modal.onDidDismiss().then(async (res) => {
      if (res.data) {
        this.auth.emit(true);
      }
      await this.close();
    });
  }

  async openSignUp() {
    const modal = await this.modalCtrl.create({
      component: SignUpPage,
      componentProps: { isAnonymous: this.isAnonymous },
      cssClass: this.customClass,
    });

    await modal.present();

    modal.onDidDismiss().then(async (res) => {
      if (res.data) {
        this.auth.emit(true);
        await this.close();
      }
    });
  }

  async openPrivacy() {
    const modal = await this.modalCtrl.create({
      component: PrivacyPolicyPage,
    });

    await modal.present();
  }

  async openTerms() {
    const modal = await this.modalCtrl.create({
      component: TermsAndConditionsPage,
    });

    await modal.present();
  }
}
