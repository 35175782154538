<ion-header class="ion-no-border">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start" mode="ios">
      <ion-button (click)="goBack()">
        <ion-icon slot="icon-only" name="close" color="black"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <h3 style="margin-top: 0">{{"How your free trial works" | translate}}</h3>
  <div class="container">
    <div style="padding-bottom: 2rem">
      <div class="purple-bar">
        <ion-icon class="first-icon" name="lock-open-outline"></ion-icon>
        <img class="second-icon" src="assets/sales/logo-thin.svg" alt="" />
        <ion-icon class="third-icon" name="checkmark-outline"></ion-icon>
        <div class="gradient-bar"></div>
      </div>
    </div>
    <div class="text-field">
      <div style="padding: 8px 0px 28px 0px; height: 112px">
        <p class="title">{{"Today" | translate}}</p>
        <p class="text">
          {{"Unlock full access to LifeBuddy's features" | translate}}
        </p>
      </div>
      <div style="height: 136px">
        <p class="title">{{"Day 1 - Day 2" | translate}}</p>
        <p class="text">
          {{("No hidden charges, cancel anytime during the trial by just a few\
          clicks in " + (currentPlatform == 'web' ? 'on our website' :
          currentPlatform == 'ios' ? 'in App Store' : 'in Google Play')) |
          translate}}
        </p>
      </div>
      <div>
        <p class="title">{{"Day 3" | translate}}</p>
        <p class="text">
          {{"Your account is charged, cancel anytime 24 hours before" |
          translate}}
        </p>
      </div>
    </div>
  </div>

  <div class="align">
    <ion-button
      expand="full"
      mode="ios"
      class="subscription-restore"
      fill="clear"
      (click)="goBack()"
      >{{ (canFreeTrial ? "TRY IT FOR FREE" : "continue") | translate
      }}</ion-button
    >
  </div>

  <div class="divider"></div>

  <div class="header-slides">
    <div class="slide-title">{{"Frequently asked questions" | translate}}</div>
    <ion-slides class="slide-main" #slider [options]="slideOpts">
      <ion-slide
        class="slide-inner"
        *ngFor="let option of saleOptions; let i = index;"
      >
        <div class="number-bubble">{{i+1}}</div>
        <div class="sale-card">
          <div>
            <h3 class="title">{{ option.title | translate }}</h3>
            <p class="description">{{ option.description | translate }}</p>
          </div>
        </div>
      </ion-slide>
    </ion-slides>
  </div>
</ion-content>
