import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NavController } from '@ionic/angular';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { ExercisePeriod } from '../enums/ExercisePeriod';
import { User } from '../models/user';
import { Video } from '../models/video';
import { Workout } from '../models/workout';
import { AchievementService } from './achievement.service';
import { HealthService } from './health.service';
import { LoggerService } from './logger.service';
import { NetworkService } from './network.service';
import { PlanService } from './plan.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { VideoService } from './video.service';

export interface WorkoutDetails {
  calories?: number;
  workoutTime?: number;
  weeklyGoal?: number;
  currentStatus?: number;
  // currentStatusPercent?: number;
  // currentStatusColor?: string;
  canResume?: boolean;
  workoutList?: Array<string>;
  categoryName?: string;
  workoutId?: string;
  restDay?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class WorkoutService {
  private workOutTime: number;
  private heartRate: number;
  private videoData: {
    totalTime: number;
    videoDataCategoryList: Array<Video>;
    workoutId?: string;
  };
  private date: Date;
  private categoryName: string;
  private isWorkoutStartSync: boolean;

  constructor(
    public navCtrl: NavController,
    private videoService: VideoService,
    private userService: UserService,
    private fns: AngularFireFunctions,
    private storageServ: StorageService,
    private networkService: NetworkService,
    private loggerService: LoggerService,
    private planService: PlanService,
    private achServ: AchievementService,
    private healthServ: HealthService
  ) {
    this.heartRate = 155;
    this.isWorkoutStartSync = false;
    this.networkService.status.subscribe((status) => {
      if (status) {
        this.syncWorkoutHistory();
      }
    });
  }

  async prepareWorkoutResult(
    videoData: {
      totalTime: number;
      videoDataCategoryList: Array<Video>;
      workoutId?: string;
    },
    workOutTime: number,
    categoryName: string
  ) {
    this.workOutTime = videoData.totalTime - workOutTime;
    this.categoryName = categoryName;
    this.videoData = videoData;
    const user = this.userService.getSyncUserOnce();
    if (!user.exerciseInfo || !user.exerciseInfo.firstExerciseTime) {
      this.userService.setSyncUser({
        exerciseInfo: {
          firstExerciseTime: new Date().getTime(),
        },
      });
    }
    return Promise.resolve();
  }

  async saveWorkoutResult(data: WorkoutDetails) {
    if (!data || data.canResume) {
      console.log(
        'WorkoutService::saveWorkoutResult()',
        'workout is not finished'
      );
      return;
    }

    await this.healthServ.store(
      data.categoryName as any,
      data.calories || 0,
      data.workoutTime
    );

    return this.userService
      .getSyncUser()
      .pipe(take(1))
      .toPromise()
      .then(
        async (user) => {
          const workout = {
            calorieBurned: data.calories || 0,
            dateTime: moment().toISOString(),
            workoutUID: new Date().valueOf(),
            workoutTime: data.workoutTime,
            workoutList: data.workoutList,
            categoryName: data.categoryName,
            workoutPlanItemId: data.workoutId,
            workoutId: data.workoutId,
          } as Workout;
          if (data.workoutId) {
            // If plan workout
            const completedWeeklyGoals = user.exerciseInfo.completedWeeklyGoals
              ? user.exerciseInfo.completedWeeklyGoals + 1
              : 1;
            this.userService.setSyncUser({
              exerciseInfo: {
                completedWeeklyGoals,
                lastExerciseTime: new Date().getTime(),
              },
            });

            console.log(
              'WorkoutService::completedWeeklyGoals',
              completedWeeklyGoals
            );

            try {
              await this.planService.markWorkoutAsCompleted(data.workoutId);
            } catch (error) {
              console.error('Error marking workout as completed: ', error);
              this.loggerService.logError(error);
            }
          }
          await this.saveWorkoutLocally(workout, user.uid);

          await this.userService.calcWorkoutStat();
          this.syncWorkoutHistory();

          const workoutHistory = await this.getWorkoutsHistory(
            ExercisePeriod.allTime,
            user.uid,
            0
          );

          await this.achServ.updateWorkoutEndedAchievements(
            workout,
            workoutHistory
          );

          console.log(
            'WorkoutService::saveWorkoutResult()',
            'user',
            user,
            'workout',
            workout
          );
        },
        (error) => {
          console.error('Error getting user: ', error);
          this.loggerService.logError(error);
        }
      );
  }

  getWorkoutsHistory(
    period: ExercisePeriod,
    uid: string,
    limit: number,
    categoryName?: string[]
  ) {
    const midnight = moment()
      .hour(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toDate()
      .getTime();
    const day = 24 * 60 * 60 * 1000;

    this.date = new Date();
    switch (period) {
      case ExercisePeriod.daily:
        this.date = moment(midnight).toDate();
        break;
      case ExercisePeriod.weekly:
        this.date = moment(midnight - day * 6).toDate();
        break;
      case ExercisePeriod.monthly:
        this.date = moment(midnight - day * 29).toDate();
        break;
      case ExercisePeriod.allTime:
        this.date = new Date(0);
        break;
      default:
        this.date = new Date(0);
        break;
    }
    return this.storageServ
      .get('UserWorkoutsList')
      .then((list: Array<any>) => {
        if (!list || !list.length) {
          return [];
        }
        if (categoryName && categoryName[0] !== 'All Categories') {
          return this.userService
            .getWorkoutByDateTime(list, this.date)
            .filter((item) => {
              let isPresent = false;

              categoryName.forEach((e) => {
                if (
                  (item.categoryName as string)
                    .toLowerCase()
                    .indexOf(e.toLowerCase()) !== -1
                ) {
                  isPresent = true;
                }
              });
              return isPresent;
            })
            .sort((a, b) =>
              new Date(b.dateTime) <= new Date(a.dateTime) ? -1 : 1
            );
        }
        return this.userService
          .getWorkoutByDateTime(list, this.date)
          .sort((a, b) =>
            new Date(b.dateTime) <= new Date(a.dateTime) ? -1 : 1
          );
      })
      .then((list: Array<any>) => {
        return list.map((item) => {
          let categories = [];
          if (item.categoryName.indexOf('&') !== -1) {
            categories = item.categoryName.split(' & ');
          } else {
            categories = item.categoryName.split(', ');
          }
          if (categories.length === 1) {
            item.categoryBannerName = this.capitalizeFirstLetter(categories[0]);
          } else {
            item.categoryBannerName = categories
              .map((e) => this.capitalizeFirstLetter(e))
              .join(' & ');
          }
          return item;
        });
      });
  }

  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  checkIsEndOfWorkout() {
    return !!(this.videoData.totalTime - this.workOutTime > 3 * 1000);
  }

  getWorkoutDetails(doneExercise: number): WorkoutDetails {
    if (!this.videoData.workoutId) {
      doneExercise = 0;
    }
    const user = this.userService.getSyncUserOnce();
    const canResume = !!(
      this.videoData.totalTime - this.workOutTime >
      3 * 1000
    );
    // const percent =
    //   user.exerciseInfo && user.exerciseInfo.completedWeeklyGoals >= 0
    //     ? (((canResume ? 0 : doneExercise) + user.exerciseInfo.completedWeeklyGoals) * 100) /
    //       (user.plan && user.plan.weeklyGoals ? user.plan.weeklyGoals : 1)
    //     : 0;
    return {
      calories: +this.calculateBurnedCalories(user).toFixed(0),
      workoutTime: this.workOutTime,
      // weeklyGoal: user.plan ? user.plan.weeklyGoals : 0,
      currentStatus:
        user.exerciseInfo && user.exerciseInfo.completedWeeklyGoals >= 0
          ? (canResume ? 0 : doneExercise) +
            user.exerciseInfo.completedWeeklyGoals
          : 0,
      // currentStatusPercent: percent <= 100 ? percent : 100,
      // currentStatusColor: this.calculateColor(percent <= 100 ? percent : 100),
      canResume,
      workoutList: this.videoData.videoDataCategoryList.reduce(
        (a, b) => (b.video_id ? [b.video_id, ...a] : [...a]),
        []
      ),
      categoryName: this.categoryName,
      workoutId: this.videoData.workoutId,
    };
  }

  calculateColor(percent: number): string {
    let color = 'rgba(125, 129, 171, 1)';
    if ((percent > 0 || percent === 0) && percent <= 50) {
      color = 'rgba(255, 0, 0, 1)';
    } else if (percent > 50 && percent <= 75) {
      color = 'rgba(255, 204, 0, 1)';
    } else if (percent > 75 && percent <= 90) {
      color = 'rgba(51, 204, 102, 1)';
    } else if (percent > 90 && percent <= 100) {
      color = 'rgba(125, 129, 171, 1)';
    }
    return color;
  }

  resumWorkout() {
    this.videoService.openPlaylist(this.videoService.getPlaylistVideos());
  }

  calculateBurnedCalories(user: User, workoutTime = this.workOutTime): number {
    if (!user || !user.currentUserWeight) {
      user = this.userService.getSyncUserOnce();
    }
    const time = workoutTime / 1000 / 60;
    const kg =
      user && user.currentUserWeight && user.currentUserWeight.weightKg
        ? user.currentUserWeight.weightKg
        : 1;
    if (user.gender === 'male') {
      return (
        Math.round(
          (user.age * 0.2017 -
            kg * 0.09036 +
            this.heartRate * 0.6309 -
            55.0969) *
            time
        ) / 4.184
      );
    } else {
      return (
        Math.round(
          (user.age * 0.074 -
            kg * 0.05741 +
            this.heartRate * 0.4472 -
            20.4022) *
            time
        ) / 4.184
      );
    }
  }

  getWorkoutTime(value) {
    if (value) {
      const minutes = Math.floor(value / 60000);
      return minutes + ' minutes';
    }
    return value;
  }

  saveWorkoutLocally(workout: Workout, uid) {
    this.storageServ.get('WorkoutCache').then((data: Array<any>) => {
      if (!data || !data.length) {
        data = [{ ...workout, uid }];
      }
      data.push({ ...workout, uid });
      data = this.userService.uniqueArray(data, 'workoutUID');
      console.log('saveWorkoutLocally::WorkoutCache', data);
      return this.storageServ.set('WorkoutCache', data);
    });

    return this.storageServ.get('UserWorkoutsList').then((data: Array<any>) => {
      if (!data || !data.length) {
        data = [{ ...workout, uid }];
      }
      data.push({ ...workout, uid });
      data = this.userService.uniqueArray(data, 'workoutUID');
      console.log('saveWorkoutLocally::UserWorkoutsList', data);
      return this.storageServ.set('UserWorkoutsList', data);
    });
  }

  syncWorkoutHistory() {
    if (this.isWorkoutStartSync || !this.networkService.getStatusOnce()) {
      return;
    }
    this.isWorkoutStartSync = true;
    this.storageServ.get('WorkoutCache').then((workoutList: Array<any>) => {
      console.log('syncWorkoutHistory:: old Workout Cache', workoutList);

      const callable = this.fns.httpsCallable('syncUserkWorkoutList');
      const data = callable({ workoutList: workoutList || [] });
      return data
        .pipe(take(1))
        .toPromise()
        .then((response) => {
          this.isWorkoutStartSync = false;
          if (workoutList && workoutList.length) {
            const newWorkoutList = workoutList.filter((el) => {
              return (
                response.findIndex((e) => el.workoutUID === e.workoutUID) < 0
              );
            });
            console.log(
              'syncWorkoutHistory:: new Workout Cache',
              newWorkoutList
            );
            this.storageServ.set('WorkoutCache', newWorkoutList);
          }
        })
        .catch((error) => {
          console.log('syncWorkoutHistory::error', error);
          this.loggerService.logError(error);
          this.isWorkoutStartSync = false;
        });
    });
  }
}
