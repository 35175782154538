import { DatePipe } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "extendDate",
  pure: false,
})
export class ExtendDatePipe extends DatePipe implements PipeTransform {
  private localeLanguage: string;

  constructor(@Inject(LOCALE_ID) locale: any) {
    super(locale);
    this.localeLanguage = locale.service.store.currentLang;
  }

  transform(value: any, format = "mediumDate", timezone?: string, locale?: string): any {
    if (format == "EEEEE" && this.isLongLanguage(locale)) {
      return super.transform(value, "EEE", timezone, locale);
    } else if (format == "EEEEE" && this.isMediumLanguage(locale)) {
      return super.transform(value, "EEEEEE", timezone, locale);
    } else {
      return super.transform(value, format, timezone, locale);
    }
  }

  isMediumLanguage(language: string) {
    return language === "az";
  }

  isLongLanguage(language: string) {
    return language === "en";
  }
}
