import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { LoginWithEmailPage } from 'src/app/pages/login-with-email/login-with-email.page';
import { PrivacyPolicyPage } from 'src/app/pages/privacy-policy/privacy-policy.page';
import { SignUpPage } from 'src/app/pages/sign-up/sign-up.page';
import { TermsAndConditionsPage } from 'src/app/pages/terms-and-conditions/terms-and-conditions.page';
import { AchievementService } from 'src/app/services/achievement.service';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { LoggerService } from 'src/app/services/logger.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import {
  BottomSheetComponent,
  SheetStates,
} from 'src/app/shared/bottom-sheet/bottom-sheet-component';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  @ViewChild('bottomSheet', { static: true })
  public bottomSheet: BottomSheetComponent;

  @Input() mode: 'signup' | 'login' = 'signup';
  @Input() customClass: string = '';

  @Output() auth = new EventEmitter<boolean>();
  language: any;

  isAnonymous = false;

  constructor(
    private translateConfigServ: TranslateConfigService,
    private authService: AuthService,
    private router: Router,
    private achServ: AchievementService,
    private notificationService: NotificationService,
    private loggerService: LoggerService,
    private modalCtrl: ModalController,
    public platform: Platform,
    private helperServ: HelperService
  ) {
    this.language = this.translateConfigServ.getCurrentLang();
  }

  ngOnInit(): void {
    this.authService.afAuth.currentUser.then((user) => {
      this.isAnonymous = user?.isAnonymous;
    });
  }

  public async openModal() {
    if (this.bottomSheet) {
      setTimeout(async () => {
        await this.bottomSheet.setState(SheetStates.Opened);
      }, 100);
    } else {
      setTimeout(async () => {
        await this.openModal();
      }, 150);
    }
  }

  async close() {
    return this.bottomSheet.setState(SheetStates.Closed);
  }

  async continueAsGuest() {
    await this.helperServ.presentLoader();

    try {
      await this.authService.signInAnonymously();

      await this.close();
      this.auth.emit(true);
    } catch (error) {
      if (error.code !== '1001') {
        this.loggerService.logError(error);
        if (error.error) {
          this.notificationService.showError(error.code + ' - ' + error.error);
        }
      }
    }
    await this.helperServ.dismissLoader();
  }

  async openLogin() {
    // this.close()
    const modal = await this.modalCtrl.create({
      component: LoginWithEmailPage,
      cssClass: this.customClass,
    });

    await modal.present();

    modal.onDidDismiss().then(async (res) => {
      if (res.data) {
        this.auth.emit(true);
      }
      await this.close();
    });
  }

  async openSignUp() {
    const modal = await this.modalCtrl.create({
      component: SignUpPage,
      componentProps: { isAnonymous: this.isAnonymous },
      cssClass: this.customClass,
    });

    await modal.present();

    modal.onDidDismiss().then(async (res) => {
      if (res.data) {
        this.auth.emit(true);
        await this.close();
      }
    });
  }

  async signInWithApple() {
    await this.helperServ.presentLoader();

    try {
      // tslint:disable-next-line:no-unused-expression
      if (this.isAnonymous) {
        if (await this.authService.linkAppleWithAnonymousUser()) {
          console.log('Successful anonymous link w/ Apple!!!');
          await this.close();
          this.auth.emit(true);
          await this.notificationService.presentLinkAccountToast();
        }
        await this.helperServ.dismissLoader();
      } else {
        const res = await this.authService.loginWithApple(
          this.mode == 'signup'
        );
        await this.helperServ.dismissLoader();
        if ((res && this.mode == 'login') || this.mode == 'signup') {
          this.auth.emit(true);
          await this.close();
        }
      }
    } catch (error) {
      if (error.code !== '1001') {
        this.loggerService.logError(error);
        const message = this.helperServ.mapErrorCode(error);
        this.notificationService.showError(message);
      }
      await this.helperServ.dismissLoader();
    }
  }

  async signInWithGoogle() {
    await this.helperServ.presentLoader();

    try {
      if (this.isAnonymous) {
        if (await this.authService.linkGoogleWithAnonymousUser()) {
          console.log('Successful anonymous link w/ Google!!!');
          await this.close();
          this.auth.emit(true);
          await this.notificationService.presentLinkAccountToast();
        }
        await this.helperServ.dismissLoader();
      } else {
        const res = await this.authService.loginWithGoogle(
          this.mode == 'signup'
        );
        await this.helperServ.dismissLoader();
        if ((res && this.mode == 'login') || this.mode == 'signup') {
          this.auth.emit(true);
          await this.close();
        }
      }
    } catch (error) {
      if (error !== 12501) {
        this.loggerService.logError(error);
        const message = this.helperServ.mapErrorCode(error);
        this.notificationService.showError(message);
      }
      await this.helperServ.dismissLoader();
    }
  }

  async openPrivacy() {
    const modal = await this.modalCtrl.create({
      component: PrivacyPolicyPage,
      cssClass: this.customClass,
    });

    await modal.present();
  }

  async openTerms() {
    const modal = await this.modalCtrl.create({
      component: TermsAndConditionsPage,
      cssClass: this.customClass,
    });

    await modal.present();
  }
}
