import { Injectable } from '@angular/core';
import {
  Health,
  HealthStoreOptions,
} from '@awesome-cordova-plugins/health/ngx';
import { Platform } from '@ionic/angular';
import { Category } from '../constants';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class HealthService {
  dataTypes = ['activity', 'calories', 'distance'];
  constructor(
    private health: Health,
    private userServ: UserService,
    private platform: Platform // private androidPermissions: AndroidPermissions
  ) {}

  async requestAuthorization() {
    if (this.platform.is('android')) {
      return;
    }

    return this.health
      .requestAuthorization([
        {
          write: this.dataTypes,
        },
      ])
      .then((res) => {
        if (
          this.platform.is('ios') &&
          !this.userServ.getSyncUserOnce()?.appleHealth
        ) {
          this.userServ.setSyncUser({
            uid: this.userServ.getSyncUserOnce().uid,
            appleHealth: true,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        alert('Error: ' + e);
      });
  }

  async store(workoutType: Category, calories: number, duration: any) {
    if (
      this.platform.is('ios') &&
      !this.userServ.getSyncUserOnce()?.appleHealth
    ) {
      return;
    } else if (this.platform.is('android')) {
      return;
    }

    await this.requestAuthorization();

    let dataPoint: HealthStoreOptions & { calories?: number } = {
      startDate: new Date(new Date().getTime() - duration), // three minutes ago
      endDate: new Date(),
      dataType: 'activity',
      value: this.mapActivityType(workoutType),
      sourceBundleId: this.platform.is('android')
        ? 'com.lifebuddy.pilates'
        : '',
      sourceName: 'Pilates Fitness',
    };

    if (this.platform.is('ios')) {
      dataPoint = {
        ...dataPoint,
        calories,
      };
    }
    return (
      this.health
        .store(dataPoint)
        // .then((res) => {
        // alert("Stored health data: " + JSON.stringify(res));
        // })
        .catch((err) => {
          console.error('Error trying to store health data: ', err);
          alert('Error: ' + JSON.stringify(err) + err);
          return err.message;
        })
    );

    // alert("Stored health data: " + JSON.stringify(res));
  }

  mapActivityType(workout: Category) {
    switch (workout) {
      case Category['total body']:
        return 'strength_training';
      case Category.booty:
        return 'strength_training';
      case Category['wall pilates']:
        return 'strength_training';
      case Category.hiit:
        return 'interval_training.high_intensity';
      case 'cardio':
        return 'other';
      case Category['upper body']:
        return 'strength_training';
      case 'legs':
        return 'strength_training';
      case 'abs':
        return 'core_training';
      case 'planks':
        return 'core_training';
      case 'upper body stretch':
        return 'flexibility';
      case 'lower body stretch':
        return 'flexibility';
    }
  }
}
